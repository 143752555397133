<template>
  <div class="content-right fl">
    <div class="content-title">
      <p class="fl">我的库存</p>
      <div class="fr">
        <p class="fl" @click="dialogVisible = true">
          <img src="../../assets/img/kc_icon_xz_n.png" alt="" /><span
            >添加</span
          >
        </p>
        <p class="fl" @mouseenter="enter">
          <el-upload
            class="upload-demo"
            action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/Company/analysis"
            name="file_url"
            :data="{ token: this.token, type: 2 }"
            accept=".xls,.xlsx"
            multiple
            :on-success="chenggong"
            :show-file-list="false"
            :limit="1"
          >
            <el-button size="small" type="primary"
              ><img class="frrr" src="../../assets/img/kc_icon_dr_n.png" /> 导入
            </el-button>
          </el-upload>
        </p>
        <p class="fl" @click="derive()">
          <img src="../../assets/img/kc_icon_dr_n.png" alt="" /><span
            >导出</span
          >
        </p>
        <div class="down" v-if="show" @click="down">
          <img src="../../assets/img/kc_icon_xz_s.png" /><span>下载模板</span>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="content-main">
      <div class="content-item-title">
        <p>序号</p>
        <p>仪器</p>
        <p>库存</p>
        <div class="clear"></div>
      </div>
      <div v-if="list.list.length < 1" class="m1">暂无库存</div>
      <div class="content-item" v-for="(item, index) in list.list" :key="index">
        <div class="item-box fr">
          <p>{{ (p - 1) * 10 + index + 1 }}</p>
        </div>
        <div class="item-box fr">
          <img class="fl" :src="item.picture" v-if="item.picture" />
          <img class="fl" src="../../assets/img/gywm_qysm_img.png" v-else />
          <p class="fl">{{ item.instrument_name }}</p>
        </div>
        <div class="item-box">
          <p>{{ item.inventory }}</p>
          <img
            src="../../assets/img/kc_but_jians.png"
            @click="chose_lose(item.id)"
          />
        </div>
        <div class="clear"></div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogVisible" width="20%" :before-close="close">
      <div class="top">
        <p class="fl">图片</p>
        <van-uploader :after-read="upload_head" :max-count="1">
          <img v-if="Img" class="fr" :src="Img" />
          <img
            v-else
            class="fr upload-pic"
            src="../../assets/img/pj_but_tjts.png"
          />
        </van-uploader>
      </div>
      <div class="content">
        <p class="fl">名称</p>
        <input
          class="fr"
          type="text"
          placeholder="请输入仪器名称"
          v-model="name"
        />
      </div>
      <div class="content">
        <p class="fl">库存</p>
        <input
          class="fr"
          placeholder="请输入仪器库存"
          v-model="num"
          onkeyup="value=value.replace(/[^\d]/g,'')"
        />
      </div>
      <div class="btn_box">
        <div class="btn" @click="btn">确认添加</div>
      </div>
    </el-dialog>
    <div class="page_box" v-if="(list.length = list.count)">
      <van-pagination
        v-model="p"
        :total-items="list.count"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { add, kc, lose, board, derive } from "../../api/User";
export default {
  data() {
    return {
      name: "",
      num: "",
      Img: "",
      token: "",
      dialogVisible: false,

      p: 1,
      list: [],
      show: false,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
  },
  methods: {
    handleCurrentChange(e) {},
    chenggong(response, file, fileList) {
      this.$message({
        message: "批量导入成功！",
        type: "success",
      });
      this.getList();
    },
    //导出
    derive() {
      derive({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "导出成功！",
            type: "success",
          });
          let url = "http://" + res.data;
          window.open(url);
        } else {
          this.$message.error(res.info);
        }
      });
    },
    //下载模板
    down() {
      board({ type: 6 }).then((res) => {
        let url = "http://" + res.data;
        window.open(url);
      });
    },

    enter() {
      this.show = !this.show;
    },
    chose_lose(id) {
      lose({ token: this.token, id: id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.getList();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    getList() {
      kc({ token: this.token, p: this.p }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
        }
      });
    },
    close() {
      this.dialogVisible = false;
      this.name = "";
      this.num = "";
      this.Img = "";
    },
    btn() {
      add({
        token: this.token,
        instrument_name: this.name,
        inventory: this.num,
        picture: this.Img,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.dialogVisible = false;
          this.getList();
          this.name = "";
          this.num = "";
          this.Img = "";
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //上传图片
    upload_head(file) {
      let fd = new FormData();
      // const formData = new FormData();
      // formData.append("img_file", file.file);
      if (file instanceof Array && file.length) {
        // 判断是否是多图上传 多图则循环添加进去
        file.forEach((item) => {
          fd.append(`img_file`, item.file); //第一个参数字符串可以填任意命名，第二个根据对象属性来找到file
        });
      } else {
        fd.append(`img_file`, file.file);
      }
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/cos_upload",
          fd,
          config
        )
        .then((res) => {
          if (res.data.status == 1) {
            this.Img = res.data.result;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  padding-bottom: 20px;
  .content-title {
    padding: 0 40px;
    position: relative;
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 45px;
      color: #333333;
    }
    div {
      p {
        padding-left: 10px;
        cursor: pointer;
        width: 80px;
        height: 45px;
        border: 2px solid #408df7;
        opacity: 1;
        border-radius: 5px;
        margin-right: 20px;

        img {
          width: 16px;
          height: 16px;
          margin: 5px 5px 0 0;
        }
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #408df7;
          opacity: 1;
        }
      }
    }
  }
  .content-main {
    padding: 20px 40px;
    .content-item-title {
      height: 48px;
      background-color: #efefef;
      p {
        float: left;
        line-height: 48px;
        text-align: center;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      p:nth-child(1) {
        width: 10%;
      }
      p:nth-child(2) {
        width: 55%;
      }
      p:nth-child(3) {
        width: 35%;
      }
    }
    .content-item:last-of-type {
      border-bottom: 1px solid #eeeeee;
    }
    .content-item {
      border: 1px solid #eeeeee;
      height: 120px;
      line-height: 120px;
      border-bottom: none;
      .item-box {
        float: left;
      }
      .item-box:nth-child(1) {
        width: 10%;
        text-align: center;
        p {
          border-right: 1px solid #eeeeee;
        }
      }
      .item-box:nth-child(2) {
        width: 65%;
        border-right: 1px solid #eeeeee;
        img {
          margin: 20px 20px 0;
          float: left;
          width: 120px;
          height: 80px;
          object-fit: cover;
        }
        p {
          font-size: 16px;
          line-height: 120px;
          color: #333333;
        }
      }
      .item-box:nth-child(3) {
        width: 25%;
        text-align: right;
        max-width: 200px;
        padding-left: 5%;
        p {
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          float: left;
        }
        img {
          cursor: pointer;
          float: right;
          margin: 45px 0 0 10px;
        }
      }
    }
  }
}
.top {
  height: 100px;
  line-height: 100px;
  p {
    font-size: 16px;
    color: #333333;
    line-height: 100px;
  }
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
  }
}
.content {
  height: 100px;
  line-height: 100px;
  p {
    font-size: 16px;
    color: #333333;
    line-height: 100px;
  }
  input {
    width: 35%;
    // margin-left: 50px;
  }
}
.van-uploader {
  float: right;
}
.btn {
  padding: 10px 20px;
  position: absolute;
  left: 36%;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}
.btn_box {
  height: 50px;
}
.down {
  cursor: pointer;
  width: 127px;
  height: 45px;
  background: #408df7;
  border: 2px solid #408df7;
  text-align: center;
  line-height: 45px;
  border-radius: 5px;
  position: absolute;
  top: 50px;
  right: 12%;
  span {
    font-size: 16px;
    color: #ffffff;
  }
}
/deep/.el-button--primary {
  color: #408df7;
  border-color: #fff;
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--primary:hover {
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  width: 55px;
}
/deep/.el-button--small,
.el-button--small.is-round {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  width: 55px;
  height: 45px;
  border-radius: 7px;
  margin-top: -2px;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-button--primary:focus {
  background: rgba(0, 0, 0, 0);
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
}
.page_box {
  margin: 0 30%;
}
</style>