<template>
  <div class="YC">
    <div class="YC_a" @click="goHref('/UserCenter?control=' + 10)">
      <img src="../assets/img/zb_icon_zx_n.png" alt="" />
      <span>在线咨询</span>
    </div>
    <div class="YC_b" v-show="gotop" @click="toTop">
      <img src="../assets/img/zb_icon_fhdb_n.png" alt="" />
      <span>返回顶部</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gotop: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
      goHref(path) {
      this.$router.push(path);
    },
    
    handleScroll() {
      let scrolltop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop >800 ? (this.gotop = true) : (this.gotop = false);
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
  },
};
</script>

<style lang="scss" scoped>
.YC {
  position: fixed;
  top: 50%;
  right: 5%;
  z-index: 1000;
}
.YC_b,
.YC_a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  background: #ffffff;
  right: 0px;
  margin-bottom: 10px;
  img {
    width: 28px;
    height: 28px;
  }
  span {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}
.YC_a:hover{
  cursor: pointer;
}
.YC_b:hover{
  cursor: pointer;
}
</style>