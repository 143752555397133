<template>
  <div class="container">
    <Top></Top>
    <div class="content-box">
      <el-row :gutter="20">
        <el-col :span="4">
          <div class="content-left fl">
            <div class="left-top">
              <div class="user-pic">
                <img :src="User.head_pic" alt="" />
              </div>
              <div class="user-name">
                <p>{{ User.user_name }}</p>
              </div>
              <div class="user-mobile">
                <p class="mobile">{{ User.mobile }}</p>
                <div class="user-vip" v-if="File.level == '普通会员'">
                  <img src="../../assets/img/pthy_img.png" alt="" />
                  <p>{{ File.level }}</p>
                </div>
                <div class="user-vip" v-if="File.level == '白银会员'">
                  <img src="../../assets/img/2.png" alt="" />
                  <p>{{ File.level }}</p>
                </div>
                <div class="user-vip" v-if="File.level == '黄金会员'">
                  <img src="../../assets/img/3.png" alt="" />
                  <p>{{ File.level }}</p>
                </div>
                <div class="user-vip" v-if="File.level == '钻石会员'">
                  <img src="../../assets/img/4.png" alt="" />
                  <p>{{ File.level }}</p>
                </div>
                <div class="user-vip" v-if="File.level == '终身荣誉会员'">
                  <img src="../../assets/img/5.png" alt="" />
                  <p>{{ File.level }}</p>
                </div>
              </div>
              <div class="out-login clear" @click="close_out">退出登录</div>
            </div>
            <div class="left-bottom clear">
              <div
                class="bottom-item"
                :class="{ active: control == 1 }"
                @click="changeMenu(1)"
              >
                <p>用户信息</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 2 }"
                @click="changeMenu(2)"
              >
                <p>仪器管理</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 3 }"
                @click="changeMenu(3)"
              >
                <p>我的工程师</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 4 }"
                @click="changeMenu(4)"
              >
                <p :class="[User.record_status == 0 ? 'active' : '']">
                  我的订单
                </p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 5 }"
                @click="changeMenu(5)"
              >
                <p>我的库存</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 6 }"
                @click="changeMenu(6)"
              >
                <p>会员中心</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 7 }"
                @click="changeMenu(7)"
              >
                <p>我的单位</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 8 }"
                @click="changeMenu(8)"
              >
                <p>地址管理</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 9 }"
                @click="changeMenu(9)"
              >
                <p>我的收藏</p>
              </div>
              <div
                class="bottom-item"
                :class="{ active: control == 10 }"
                @click="changeMenu(10)"
              >
                <p>联系客服</p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="20">
          <UserInfo v-if="control == 1"></UserInfo>
          <Instrument v-if="control == 2"></Instrument>
          <MyEngineer v-if="control == 3"></MyEngineer>
          <Order
            @change="change"
            @change2="change2"
            @change5="change5"
            @change6="change6"
            v-if="control == 4 && order_control == 1"
          ></Order>
          <OrderDetailGoods
           @change1="change1"
            @change2="change2"
            v-if="control == 4 && order_control == 2"
          ></OrderDetailGoods>
          <OrderDetailService
           @change1="change1"
            @change6="change6"
            v-if="control == 4 && order_control == 3"
          ></OrderDetailService>
          <OrderCommentGoods
            @change="change"
            @change1="change1"
            v-if="control == 4 && order_control == 4"
          ></OrderCommentGoods>
          <OrderCommentService
           @change1="change1"
            v-if="control == 4 && order_control == 5"
          ></OrderCommentService>
          <Inventory v-if="control == 5"></Inventory>
          <VipCenter   @change3="change3" v-if="control == 6 && order_control == 1"></VipCenter>
          <VipGoods v-if="control == 6 && order_control == 2"></VipGoods>
          <MyUnit v-if="control == 7"></MyUnit>
          <Address v-if="control == 8"></Address>
          <MyCollection v-if="control == 9"></MyCollection>
          <Service v-if="control == 10"></Service>
        </el-col>
      </el-row>
    </div>

    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import UserInfo from "./userInfo"; //用户信息
import Instrument from "./instrument"; // 仪器管理
import MyEngineer from "./myEngineer"; // 我的工程师
import Order from "./order"; // 我的订单
import OrderDetailGoods from "./orderDetailGoods"; // 商品订单详情
import OrderDetailService from "./orderDetailService"; // 服务订单详情
import Inventory from "./inventory"; // 我的库存
import VipCenter from "./vipCenter"; // 会员中心
import VipGoods from "./vipGoods"; // 会员中心
import MyUnit from "./myUnit"; // 我的单位
import Address from "./address"; // 我的单位
import MyCollection from "./myCollection"; // 我的收藏
import OrderCommentGoods from "./orderCommentGoods"; // 联系客服
import Service from "./service"; // 联系客服
import OrderCommentService from "./orderCommentService";
import { UseInfo, user_file, browse } from "../../api/index";
export default {
  components: {
    Top,
    Bottom,
    UserInfo,
    Instrument,
    MyEngineer,
    Order,
    Inventory,
    VipCenter,
    VipGoods,
    MyUnit,
    Address,
    MyCollection,
    Service,
    OrderDetailGoods,
    OrderDetailService,
    OrderCommentGoods,
    OrderCommentService,
  },
  data() {
    return {
      control: 1,
      order_control: 1,
      vip_center_control: 1,
      token: "",
      User: "",
      File: "",
    };
  },
  watch: {
    $route(to, from) {
      this.control = this.$route.query.control;
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.control = this.$route.query.control;
    this.head = localStorage.getItem("head_pic");
    this.getUserInfo();
    this.getUser_file();
  },
  methods: {
    //个人档案
    getUser_file() {
      user_file({ token: this.token }).then((res) => {
        if (res.status == 1) {
          // console.log(res);
          this.File = res.result;
          localStorage.setItem('File',res.result.level)
        } else {
          this.$message("未登录，请登录");
          setTimeout(() => {
            localStorage.removeItem("token");
            this.$router.replace("/");
          }, 1000);
        }
      });
    },
    //服务订单评价
    change6() {
      this.control = 4;
      this.order_control = 5;
    },
    //服务订单详情
    change5() {
      this.control = 4;
      this.order_control = 3;
    },
    //会员中心
    change4() {
      this.control = 6;
      this.order_control = 1;
    },
    //会员中心的商品
    change3() {
      this.control = 6;
      this.order_control = 2;
    },
    //评价
    change2() {
      this.control = 4;
      this.order_control = 4;
    },
    //我的订单
    change1() {
      this.control = 4;
      this.order_control = 1;
    },
    //订单详情
    change() {
      this.control = 4;
      this.order_control = 2;
    },
    //退出登录
    close_out() {
      let that = this;
      this.$confirm("即将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已退出!",
          });
          localStorage.removeItem("token");
          setTimeout(() => {
            that.$router.replace("/");
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消退出",
          });
        });
    },
    //个人信息
    getUserInfo() {
      UseInfo({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.User = res.result;
        }
      });
    },
    //切换 左侧菜单
    changeMenu(control) {
      if (control != this.control) {
        this.control = control;
      }
      if (control == 4) {
        this.order_control = 1;
        browse({ type:1,token: this.token }).then((res) => {
          this.getUserInfo();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fcfcfc;
  min-width: 1440px;
}
.content-box {
  padding: 20px 10%;
}
.content-left {
  width: 100%;
  .left-top {
    background-color: #ffffff;
    // width: 260px;
    height: 280px;
    padding: 20px;
    .user-pic {
      width: 100%;
      text-align: center;
      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        margin: 10px auto;
        object-fit: cover;
      }
    }
    .user-name {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        text-align: center;
        color: #333333;
        padding: 10px 0;
      }
    }
    .user-mobile {
      padding: 10px 0;
      text-align: center;
      .mobile {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #999999;
      }
      .user-vip {
        text-align: center;
        img {
          display: inline-block;
          width: 26px;
          height: 24px;
          margin: 0 5px;
          vertical-align: middle;
        }
        p {
          display: inline-block;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
        vertical-align: middle;
        }
      }
    }
    .out-login {
      text-align: center;
      padding: 10px 0;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: #408df7;
      cursor: pointer;
    }
  }
  .left-bottom {
    margin: 20px 0 40px 0;
    background-color: #ffffff;
    .bottom-item {
      width: 100%;
      height: 55px;
      p {
        height: 55px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 55px;
        font-weight: 400;
        color: #333333;
        cursor: pointer;
        position: relative;
      }
      p.active::after {
        background-color: #ff0000;
        width: 6px;
        height: 6px;
        content: " ";
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 30px;
        top: 24px;
        cursor: pointer;
      }
    }
    div.active {
      background-color: #f2f2f2;
    }
    div.active::after {
      background-color: #408df7;
      width: 4px;
      height: 28px;
      content: " ";
      display: inline-block;
      position: relative;
      bottom: 40px;
      left: 0;
    }
  }
}
</style>
