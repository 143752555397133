<template>
  <div class="content-right fl">
    <div class="content-title fl">
      <p class="fl" @click="chose()">会员中心</p>
      <p class="fl">>商品列表</p>
    </div>
    <div class="vip-goods-box">
     <div class="goods-item" v-for="(item,index) in list.list" :key="index" @click="goHref('/CommodityDetails?id='+item.goods_id)">
        <img :src="item.original_img" alt="" />
        <p class="goods-name one-letter">{{item.goods_name}}</p>
        <p class="vip-price">会员价：<span class="price">￥{{item.shop_price}}</span></p>
      </div>
    </div>
    <div class="page_box">
      <van-pagination
        v-model="p"
        :total-items="list.count"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { vipGoods } from "../../api/User";
export default {
  data(){
    return{
        list:[],
        p:1,
    }
  },
  created(){
      this.getList()
  },
  methods:{
    chose(){
         this.$parent.change4();
    },
    getList() {
      vipGoods({p:this.p}).then((res) => {
        this.list = res.data;
      });
    },
        goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-title {
    width: 100%;
    padding: 0 30px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
  .vip-goods-box {
    padding: 50px 30px  30px;
    .goods-item {
      background: #ffffff;
      border: 1px solid #eeeeee;
      float: left;
      width: 18%;
      padding: 10px;
      margin: 1%;
      img {
        width: 100%;
        height:160px;
      }
      .goods-name {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
      }
      .vip-price {
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        color: #999999;
        .price {
          font-weight: bold;
          color: #ff0000;
        }
      }
    }
    .goods-item:nth-child(5n + 1) {
      margin-left: 0;
    }
    .goods-item:nth-child(5n) {
      margin-right: 0;
    }
  }
}
.page_box{
  float: left;
  margin: 20px   40%;
}
</style>