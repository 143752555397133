<template>
  <div class="right_box">
    <div class="right_top">
      <div class="title">招投标数据(汇总)</div>
      <div class="right_top_list fl">
        <p>进口展示</p>
        <span>{{0}}</span>
      </div>
      <div class="right_top_list fl">
        <p>需求公示</p>
        <span>{{total.bids.need_bids}}</span>
      </div>
      <div class="right_top_list fl">
        <p>中标公告</p>
        <span>{{total.bids.win_bids}}</span>
      </div>
      <div class="right_top_list fl">
        <p>招标公告</p>
        <span>{{total.bids.call_bids}}</span>
      </div>
      <div class="right_top_list fl">
        <p>废标公示</p>
        <span>{{total.bids.bad_bids}}</span>
      </div>
    </div>
    <div class="clear"></div>
    <div class="border"></div>
    <div class="right_bottom">
      <div class="title">招投标数据(分项)</div>
      <div class="right_bottom_title fl">
        <!-- <p @click="chose(0)" :class="type == 0 ? 'active' : ''">进口展示</p> -->
        <p @click="chose(1)" :class="type == 1 ? 'active' : ''">需求公示</p>
        <p @click="chose(2)" :class="type == 2 ? 'active' : ''">中标公告</p>
        <p @click="chose(3)" :class="type == 3 ? 'active' : ''">招标公告</p>
        <p @click="chose(4)" :class="type == 4 ? 'active' : ''">废标公示</p>
      </div>
      <div class="name">
        <p>项目名称</p>
        <p>项目信息</p>
      </div>
      <div class="name fl" v-if="count==0" style="margin-left:150px">
        <p>暂无信息</p>
      </div>
      <div class="right_list fl" v-for="(item,index) in page" :key="index">
        <p class="fl one-letter">{{item.title}}</p>
        <span class="two-letter fl">{{item.source}}</span>
      </div>
      <div class="clear"></div>
    </div>
    <el-pagination
      v-if="count!=0"
      style="float:left;margin-top:20px"
      background
      layout="prev, pager, next"
      :total="count"
      @current-change="change"
    ></el-pagination>
  </div>
</template>

<script>
import { admin_toal, bids } from "../api/company";
export default {
  data() {
    return {
      type: 1,
      total: "",
      count: "",
      page: [],
    };
  },
  mounted() {
    this.getToal();
    this.getPage(1);
  },

  methods: {
    getPage(num) {
      bids({ type: this.type, p: num }).then((res) => {
        this.page = res.data.list;
        this.count = res.data.count;
      });
    },
    getToal() {
      admin_toal({}).then((res) => {
        this.total = res.data;
      });
    },
    change(value) {
      this.getPage(value);
    },
    chose(i) {
      this.type = i;
      this.getPage(1);
    },
  },
};
</script>

<style lang='scss' scoped>
.right_box {
  width: 25%;
  min-height: 800px;
  position: absolute;
  right: 50px;
  top: 100px;
  background: url("../assets/img/zhaotoub_bg_1.png") no-repeat;
  background-size: 100% 100%;
  padding: 20px;
}
.title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
}
.right_top_list {
  width: 50%;
  height: 80px;

  background: linear-gradient(
    90deg,
    rgba(10, 84, 234, 0.37) 0%,
    rgba(10, 84, 234, 0) 100%
  );
  opacity: 1;
  border-radius: 4px;
  padding: 10px 0 0 20px;
  margin: 20px 0;
  p {
    font-size: 14px;
    color: #ffffff;
  }
  span {
    font-size: 22px;
    color: #00c0ff;
  }
}
.border {
  border-bottom: 0.5px dashed #ffffff;
  opacity: 0.3;
  margin-bottom: 20px;
}
.right_bottom_title {
  height: 60px;
  line-height: 60px;
  width: 100%;
  p {
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    background: #000000;
    width: 25%;
    float: left;
    cursor: pointer;
  }
}
.active {
  background: linear-gradient(
    180deg,
    rgba(10, 84, 234, 0) 0%,
    #0a54ea 100%
  ) !important;
  opacity: 0.5 !important;
}
.name {
  height: 40px;
  line-height: 40px;
  float: left;
  padding-left: 30px;
  p {
    font-size: 15px;
    color: #ffffff;
    float: left;
    font-weight: bold;
    margin-right: 70px;
  }
}
.right_list {
  width: 100%;
  height: 60px;
  // background: #0a54ea;
  // opacity: 0.3;
  background: rgba(10, 84, 234, 0.37);
  line-height: 60px;
  padding: 0 20px;
  font-size: 12px;
  color: #ffffff;
  p {
    width: 20%;
    text-align: center;
    // opacity:1 !important;
  }
  span {
    padding-left: 70px;
    width: 70%;
    line-height: 20px !important;
    margin-top: 10px;
    text-align: center;
  }
}
.right_list:nth-of-type(2n) {
  // opacity: 0.08 !important;
  background: rgba(10, 84, 234, 0.08) !important;
}
.el-pagination /deep/ .btn-prev {
  background: rgba(10, 84, 234, 0.3) !important;
}
.el-pagination /deep/ .btn-next {
  background: rgba(10, 84, 234, 0.3) !important;
}
.el-pagination /deep/.el-pager li:not(.disabled).active {
  background: #0a54ea !important;
}
.el-pagination /deep/ .el-pager li {
  background: rgba(10, 84, 234, 0.3) !important;
}
.el-pagination /deep/ .btn-prev {
  margin: 0 2px !important;
}
.el-pagination /deep/ li {
  margin: 0 2px !important;
}
</style>