<template>
  <div>
    <Top></Top>
    <div class="img_box">
      <div class="img_text">
        <img src="../../assets/img/syssj_icon.png" />
        <p>实验室建设方案</p>
        <p class="a2">Laboratory construction scheme</p>
        <img class="a1" src="../../assets/img/top_img_x.png" />
        <div>
          基于物联传感+信息化+大数据， 提供高端分析仪器仪表、信息化软件、运维服务、
          运营服务、检测服务、咨询服务及环境治理装备等创新产品和业务组合。
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="pd_space_260">
      <div class="content">
        <div class="main clear">
          <div class="left-menu fl">
            <el-menu
              default-active="1-1"
              text-color="#999999"
              active-text-color="#408DF7"
              :unique-opened="true"
            >
              <el-submenu index="1">
                <template slot="title">
                  <span>方案获取</span>
                </template>
                <el-menu-item index="1-1" @click="inde('整体布局规划设计', 8)"
                  >整体布局规划设计</el-menu-item
                >
                <el-menu-item index="1-2" @click="inde('家具工程', 9)"
                  >家具工程</el-menu-item
                >
                <el-menu-item index="1-3" @click="inde('通风工程', 10)"
                  >通风工程</el-menu-item
                >
                <el-menu-item index="1-4" @click="inde('装修工程', 11)"
                  >装修工程</el-menu-item
                >
                <el-menu-item index="1-5" @click="inde('供气工程', 12)"
                  >供气工程</el-menu-item
                >
              </el-submenu>
              <el-submenu index="2">
                <template slot="title">
                  <span>业务简介</span>
                </template>
                <el-menu-item index="2-1" @click="wenz('整体布局规划设计', 14)"
                  >整体布局规划设计</el-menu-item
                >
                <el-menu-item index="2-2" @click="wenz('家具工程', 16)"
                  >家具工程</el-menu-item
                >
                <el-menu-item index="2-3" @click="wenz('通风工程', 17)"
                  >通风工程</el-menu-item
                >
                <el-menu-item index="2-4" @click="wenz('装修工程', 18)"
                  >装修工程</el-menu-item
                >
                <el-menu-item index="2-5" @click="wenz('供气工程', 19)"
                  >供气工程</el-menu-item
                >
              </el-submenu>
            </el-menu>
          </div>
        </div>
        <!-- 右边框 -->
        <div class="right-x" v-if="xians">
          <div class="right-title">
            <span>{{ title }}</span>
            <button @click="tijiao()">提交</button>
          </div>
          <div class="right-message">
            <div
              class="message-stlye"
              v-for="(item, index) in planList"
              :key="index"
              :style="index % 2 === 0 ? 'margin-right:2%;' : ''"
              :class="index === borderr ? 'borderrr' : ''"
              @click="biankuang(index, item.id)"
            >
              <input type="radio" name="danxuan" :checked="borderr === index" /><span>{{
                item.title
              }}</span>
            </div>
            <el-pagination
              background:false
              layout="prev, pager, next"
              :total="planList.length"
              :page-size="num"
              @current-change="handleCurrentChange"
              :current-page.sync="p"
            >
            </el-pagination>
          </div>
        </div>
        <div class="right-x" v-if="xiansa">
          <div class="right-title">
            <span>{{ title }}</span>
          </div>
          <div class="right-messages" v-if="wenzs.result.content">
            <span class="time">发布时间：{{ wenzs.result.add_time }}</span>
            <div v-html="wenzs.result.content"></div>
          </div>
          <p v-if="!wenzs.result.content" style="text-align: center;">暂无内容</p>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import { construction, article, construction_submission } from "../../api/index";
export default {
  components: { Top, Bottom },
  data() {
    return {
      borderr: 0, //列表商品边框颜色
      num: 22, //每页获取多少条
      cat_id: "",
      p: 1, //分页从1开始
      lab_project: "", //整体布局规划设计id
      lab_furniture: "", //家具工程id
      lab_ventilate: "", //通风工程id
      lab_decoration: "", //装修工程id
      lab_air: "", //供气工程id
      planList: [], //方案内容
      title: "", //方案标题
      xians: false, //控制方案div显示
      xiansa: false, //控制文章div显示
      wenzs: [], //文章
      erj: 0, //二级分类
      currentPage: 1,
      token: "",
      ind1: "",
      ind2: "",
      ind3: "",
      ind4: "",
      ind5: "",
      stat: true, //禁止重复提交
    };
  },

  created() {
    this.inde("整体布局规划设计", 8);
    this.token = localStorage.getItem("token");
  },
  methods: {
    inde(str, index) {
      if (index != null) {
        this.erj = index;
        this.borderr = 0;
        this.cat_id = index;
        this.title = str;
        this.p = 1;
      }
      var data = {
        cat_id: this.erj,
        p: this.p,
      };
      construction(data).then((res) => {
        if (index == 8) {
          this.lab_project = res.data[0].id;
        } else if (index == 9) {
          this.lab_furniture = res.data[0].id;
        } else if (index == 10) {
          this.lab_ventilate = res.data[0].id;
        } else if (index == 11) {
          this.lab_decoration = res.data[0].id;
        } else {
          this.lab_air = res.data[0].id;
        }
        this.planList = res.data;
        this.xians = true;
        this.xiansa = false;
      });
    },
    biankuang(index, id) {
      this.borderr = index;
      if (this.cat_id == 8) {
        this.lab_project = id;
        this.ind1 = index;
      } else if (this.cat_id == 9) {
        this.lab_furniture = id;
        this.ind2 = index;
      } else if (this.cat_id == 10) {
        this.lab_ventilate = id;
        this.ind3 = index;
      } else if (this.cat_id == 11) {
        this.lab_decoration = id;
        this.ind4 = index;
      } else {
        this.lab_air = id;
        this.ind5 = index;
      }
    },
    tijiao() {
      var data = {
        token: this.token,
        lab_project: this.lab_project,
        lab_furniture: this.lab_furniture,
        lab_ventilate: this.lab_ventilate,
        lab_decoration: this.lab_decoration,
        lab_air: this.lab_air,
      };
      var that = this;

      construction_submission(data).then((res) => {
        if (res.status == 1) {
          if (!this.lab_air) {
            this.$message.error("请选择供气工程！");
            return;
          }
          this.$message({
            message: res.info,
            type: "success",
            onClose: function () {
              that.$router.replace({
                path: "./nullBack",
                component: "nullBack",
              });
            },
          });
          // this.$message.success(res.info,function(){
          //   this.$router.replace({
          //     path: "/nullBack",
          //     component:"nullBack"
          //   })
          // })
        } else {
          this.$message.error(res.info);
        }
      });
    },
    wenz(str, index) {
      this.title = str;
      var data = {
        cat_id: index,
      };
      article(data).then((res) => {
        this.xians = false;
        this.xiansa = true;
        this.wenzs = res;
      });
    },
    handleCurrentChange(val) {
      this.p = val;
      this.inde();
    },
  },
};
</script>
<style lang="scss" scoped>
.img_box {
  width: 100%;
  height: 400px;
  background: url("../../assets/img/sysjz_top_img.png");
  position: relative;
}
.img_text {
  position: absolute;
  top: 70px;
  width: 50%;
  height: 10px;
  text-align: center;
  left: 25%;
  right: 25%;
  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px 0 5px;
  }
  .a2 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }
  .a1 {
    margin: 30px 0 40px;
  }
  div {
    font-size: 18px;
    color: #ffffff;
  }
}
//
.content {
  width: 100%;
  min-height: 500px;
  padding: 20px 0 30px;
  margin: 0 auto;
}
.main {
  width: 100%;
  .left-menu {
    width: 20%;
  }
}
.el-submenu:nth-last-of-type(1) {
  padding: 0 !important;
}
.el-submenu {
  padding-bottom: 10px !important;
  // border-bottom:1px solid  #eeeeee;
}
.el-menu {
  border: none;
}
.el-menu-item {
  border: 0.5px solid #eeeeee !important;
  padding-left: 20px !important;
}

.el-submenu /deep/.el-submenu__title {
  height: 60px !important;
  border: 1px solid #eeeeee !important;
}
.right-x {
  width: 79.5%;
  padding: 0 46px;
  float: right;
  border: 1px solid #eeeeee;
  .right-title {
    width: 100%;
    height: 94px;
    border-bottom: 1px solid #eeeeee;
    opacity: 1;
    margin-bottom: 26px;
    span {
      width: 176px;
      height: 29px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 94px;
      color: #333333;
      opacity: 1;
    }
    button {
      float: right;
      margin-top: 27px;
      width: 100px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      color: #408df7;
      background: rgba(0, 0, 0, 0);
    }
  }
  .right-message {
    width: 100%;
    .message-stlye {
      width: 49%;
      float: left;
      height: 60px;
      background: #ffffff;
      border: 1px solid #cccccc;
      opacity: 1;
      border-radius: 5px;
      margin-bottom: 20px;
      padding-left: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      input {
        margin-right: 23px;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        opacity: 1;
        line-height: 60px;
      }
    }
    .el-pagination {
      width: 100%;
      float: left;
      text-align: center;
      margin-top: 21px;
      margin-bottom: 39px;
      /deep/ .el-pager li {
        padding: 0 3px;
        font-size: 16px;
        min-width: 35.5px;
        background: rgba(0, 0, 0, 0);
        height: 40px;
        width: 40px;
        line-height: 40px;
        margin: 0 5px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
        border: 1px solid #cccccc;
        border-radius: 3px;
      }
      /deep/ .el-pager li:hover {
        border: 1px solid #409eff;
        z-index: 2;
      }
      /deep/ .el-pager li.active {
        color: #409eff;
        border: 1px solid #409eff;
        cursor: default;
      }
      /deep/ button {
        padding: 0;
        font-size: 16px;
        min-width: 35.5px;
        background: rgba(0, 0, 0, 0);
        height: 40px;
        width: 40px;
        line-height: 40px;
        border: 1px solid #cccccc;
        border-radius: 3px;

        margin: 0 5px;
      }
      /deep/ button:hover {
        border: 1px solid #409eff;
      }
      /deep/ .el-pagination {
        white-space: nowrap;
        padding: 2px 5px;
        color: #666666;
        font-weight: 500;
      }
    }

    .message-stlye:hover {
      cursor: pointer;
    }
    .borderrr {
      border: 1px solid #408df7;
    }
  }
}
.right-messages{ 
  /deep/div p img{
    max-width: 100%;
  }
  .time {
  display: inline-block;
  width: 100%;
  text-align: center;
}}
input[type="radio"]:checked::before {
  content: "\2713";
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: -1px;
  width: 100%;
  height: 100%;
  border: 1px solid #408df7;
  color: #408df7;
  font-size: 8px;
  font-weight: bold;
  border-radius: 0px;
  line-height: 16px;
  text-align: center;
}
input[type="radio"] {
  height: 16px;
  width: 16px;
  border: solid 1px #dfdfdf;
  background-color: transparent;
  -webkit-appearance: none;
  position: relative;
  border-radius: 0;
}
.el-menu-item {
  min-width: 0 !important;
}
</style>
