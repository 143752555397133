<template>
  <div class="container">
    <Top ref="child"></Top>
    <div class="img_box">
      <div class="img_text">
        <img src="../../assets/img/yqwx_icon.png" />
        <p>维修维护</p>
        <p class="a2">Laboratory construction scheme</p>
        <img class="a1" src="../../assets/img/top_img_x.png" />
        <div>
          基于物联传感+信息化+大数据， 提供高端分析仪器仪表、信息化软件、运维服务、
          运营服务、检测服务、咨询服务及环境治理装备等创新产品和业务组合。
        </div>
      </div>
    </div>
    <div class="main">
      <div class="left-menu fl">
        <el-menu
          :default-active="the_default_str"
          text-color="#999999"
          active-text-color="#408DF7"
          :unique-opened="true"
        >
          <el-submenu index="1">
            <template slot="title">
              <span>安装调试</span>
            </template>
            <el-menu-item index="1-1" @click="menuChange('1-1','电话报装')">电话报装</el-menu-item>
            <el-menu-item index="1-2" @click="menuChange('1-2')">信息录入</el-menu-item>
            <el-menu-item index="1-3" @click="menuChange('1-3')">工程进度</el-menu-item>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span>故障维修</span>
            </template>
            <el-menu-item index="2-1" @click="menuChange('2-1')">填写信息</el-menu-item>
            <el-menu-item index="2-2" @click="menuChange('2-2','电话报修')">电话报修</el-menu-item>
            <el-menu-item index="2-3" @click="menuChange('2-3')">工程进度</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span>仪器保养</span>
            </template>
            <el-menu-item index="3-1" @click="menuChange('3-1')">填写信息</el-menu-item>
            <el-menu-item index="3-2" @click="menuChange('3-2','电话报需')">电话报需</el-menu-item>
            <el-menu-item index="3-3" @click="menuChange('3-3')">工程进度</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <Telphone :diaoyong="content" v-if="control == 0"></Telphone>
      <InfoInput v-if="control == 1"></InfoInput>
      <InstallationProgress v-if="control == 2"></InstallationProgress>
      <RepairInput
        v-bind:toChildrenDatab="toChildrenDatab"
        v-if="control == 3"
      ></RepairInput>
      <RepairProgress v-if="control == 4"></RepairProgress>
      <MaintainInput
        v-bind:toChildrenData="toChildrenData"
        v-if="control == 5"
      ></MaintainInput>
      <MaintainProgress v-if="control == 6"></MaintainProgress>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import Telphone from "./telphone"; //电话报装、电话报修、电话报需
import InfoInput from "./infoInput"; //安装调试-信息录入
import InstallationProgress from "./installationProgress"; //安装调试-工程进度
import MaintainInput from "./maintainInput"; //仪器保养-信息填写
import MaintainProgress from "./maintainProgress"; //仪器保养-工程进度
import RepairInput from "./repairInput";
import RepairProgress from "./repairProgress"; //仪器维修-工程进度
export default {
  data() {
    return {
      control: 0,
      the_default_str: "1",
      toChildrenData: {},
      toChildrenDatab: {},
      content:''
    };
  },
  components: {
    Top,
    Bottom,
    Telphone,
    InfoInput,
    InstallationProgress,
    MaintainInput,
    MaintainProgress,
    RepairInput,
    RepairProgress,
  },
  created() {
    this.token = localStorage.getItem("token");
    this.the_default();
    if (this.$route.params.index) {
      if (this.$route.params.index == 1) {
        this.the_default_str = "3-1";
        this.menuChange("3-1");
        this.toChildrenData = this.$route.params.item;
      } else {
        this.the_default_str = "2-1";
        this.menuChange("2-1");
        this.toChildrenDatab = this.$route.params.item;
      }
    }
  },
  methods: {
    menuChange(itemIndex,str) {
      this.content = str
      if (itemIndex == "1-1" || itemIndex == "2-2" || itemIndex == "3-2") {
        this.control = 0;
      }
      if (itemIndex == "1-2") {
        this.control = 1;
      }
      if (itemIndex == "1-3") {
        if (!this.token) {
          this.$message.error("请先登录，然后再进行查看工程进度操作");
          return false;
        }
        this.control = 2;
      }
      if (itemIndex == "2-1") {
        this.control = 3;
      }
      if (itemIndex == "2-3") {
        if (!this.token) {
          this.$message.error("请先登录，然后再进行查看工程进度操作");
          return false;
        }
        this.control = 4;
      }
      if (itemIndex == "3-1") {
        this.control = 5;
      }
      if (itemIndex == "3-3") {
        if (!this.token) {
          this.$message.error("请先登录，然后再进行查看工程进度操作");
          return false;
        }
        this.control = 6;
      }
    },
    the_default() {
      // this.the_default_str = '2-1'
      this.menuChange(this.the_default_str);
    },
    state(){
      // console.log('adsfasdf')
      this.$refs.child.getUserInfo()
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-width: 1440px;
  min-height: 1000px;
  background-color: #fcfcfc;
}
.img_box {
  width: 100%;
  height: 400px;
  background: url("../../assets/img/sysjz_top_img.png");
  position: relative;
}
.img_text {
  position: absolute;
  top: 70px;
  width: 50%;
  height: 10px;
  text-align: center;
  left: 25%;
  right: 25%;
  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px 0 5px;
  }
  .a2 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }
  .a1 {
    margin: 30px 0 40px;
  }
  div {
    font-size: 18px;
    color: #ffffff;
  }
}

.main {
  margin: 20px 5%;
  min-height: 600px;
  .left-menu {
    width: 20%;
    border: 1px solid #eeeeee;
    border-radius: 5px;
  }
}
/deep/.el-submenu {
  margin-bottom: 10px;
  border: solid 1px #e6e6e6;
  background: #ffffff;
}
/deep/.el-submenu:nth-child(3) {
  margin-bottom: 0;
}
/deep/.el-menu {
  border: 0 !important;
  background: rgba(0, 0, 0, 0);
}
.left-menu {
  border: 0 !important;
}
// /deep/ .el-icon-arrow-down:before {
//     content: "+";
// }
</style>
