<template>
  <div class="content-right fl">
    <div class="content-right-tab">
      <p
        :class="{ active: activeInfo == 'person' }"
        @click="toggleUserInput('person')"
      >
        个人资料
      </p>
      <p
        :class="{ active: activeInfo == 'unit' }"
        @click="toggleUserInput('unit')"
      >
        单位信息
      </p>
    </div>
    <div class="content-user" v-if="activeInfo == 'person'">
      <div class="user-pic-box">
        <img v-if="imageUrl" class="fl" :src="imageUrl" />
        <img v-else :src="User.head_pic" class="fl" />
        <div class="user_head" @click="show_choseHead">
          <img v-if="imageUrl" :src="imageUrl" />
          <img v-else :src="User.head_pic" />
          <p>修改头像</p>
        </div>
      </div>
      <div class="input-item">
        <div class="item-left">
          <span>*</span>
          <p>姓名</p>
          <input type="text" placeholder="请输入真实姓名" v-model="name" />
        </div>
        <div class="item-right">
          <span>*</span>
          <p>单位</p>
          <input
            type="text"
            placeholder="请输入单位名称"
            v-model="unit"
            disabled="disabled"
          />
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-left">
          <span>*</span>
          <p>职务</p>
          <input
            type="text"
            placeholder="请输入职务"
            v-model="post"
            disabled="disabled"
          />
        </div>
        <div class="item-right">
          <span>*</span>
          <p>邮箱</p>
          <input type="text" placeholder="请输入邮箱" v-model="email" />
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-left">
          <span>*</span>
          <p>手机</p>
          <input type="text" placeholder="请输入手机" v-model="phone" />
        </div>
      </div>
      <div class="input-item-long clear">
        <div class="item-long">
          <span>*</span>
          <p>地址</p>
          <input type="text" placeholder="请输入地址" v-model="site" />
        </div>
      </div>
      <div class="submit" @click="btn">保存</div>
      <div class="colleague-student">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="我的同事" name="colleague">
            <div class="colleague-superior">
              <div class="add-superior fl" @click="showFriend">
                <img src="../../assets/img/grzx_wod_but_tjts.png" alt="" />
                <p>添加同事</p>
              </div>
              <div class="m1" v-if="list.userfriendlist.length < 1">
                暂无信息
              </div>
              <div class="superior-list fl">
                <div
                  class="superior-item"
                  v-for="(item, index) in list.userfriendlist"
                  :key="index"
                  @click="del(item.f_id)"
                >
                  <img
                    :src="item.head_pic"
                    :class="[del_id == item.f_id ? 'superior_img' : '']"
                  />
                  <div
                    class="superior_img_text"
                    :class="[del_id == item.f_id ? 'block1' : '']"
                  >
                    删除
                  </div>
                  <p class="one-letter">{{ item.user_name }}</p>
                </div>
              </div>
            </div>
            <van-pagination
              v-model="p"
              :total-items="list.count"
              class="block fl"
              @change="getList()"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
            <!-- <div class="colleague-subordinate clear">
              <div class="add-subordinate fl">
                <img src="../../assets/img/grzx_wod_but_tjts.png" alt="" />
                <p>添加下级</p>
              </div>
              <div class="subordinate-list fl">
                <div class="subordinate-item">
                  <img src="../../assets/img/grzx_wod_but_tjts.png" alt="" />
                  <p>王哈哈</p>
                </div>
              </div>
            </div> -->
          </el-tab-pane>
          <el-tab-pane label="我的学生" name="student">
            <div class="student">
              <div class="add-student fl" @click="showFriend">
                <img src="../../assets/img/grzx_wod_but_tjts.png" alt="" />
                <p>添加学生</p>
              </div>

              <div class="m1" v-if="list.userfriendlist.length < 1">
                暂无信息
              </div>
              <div class="student-list fl">
                <div
                  class="student-item"
                  v-for="(item, index) in list.userfriendlist"
                  :key="index"
                  @click="del(item.f_id)"
                >
                  <img
                    :src="item.head_pic"
                    :class="[del_id == item.f_id ? 'superior_img' : '']"
                  />
                  <p class="one-letter">{{ item.user_name }}</p>
                  <div
                    class="superior_img_text1"
                    :class="[del_id == item.f_id ? 'block1' : '']"
                  >
                    删除
                  </div>
                </div>
              </div>
            </div>
            <van-pagination
              v-model="p"
              :total-items="list.count"
              class="block fl"
              @change="getList()"
            >
              <template #prev-text>
                <van-icon name="arrow-left" />
              </template>
              <template #next-text>
                <van-icon name="arrow" />
              </template>
              <template #page="{ text }">{{ text }}</template>
            </van-pagination>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-unit" v-if="activeInfo == 'unit'">
      <div class="input-item clear">
        <div class="item-left">
          <p>单位名称</p>
          <input
            type="text"
            placeholder="请输入单位名称"
            v-model="unit"
            disabled="disabled"
          />
        </div>
        <div class="item-right">
          <p>纳税人识别号</p>
          <input
            type="text"
            placeholder="请输入纳税人识别号"
            v-model="taxes_code"
          />
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-left">
          <p>单位所在地</p>
          <div class="area_box">
            <el-cascader
              :options="options"
              ref="cascaderAddr"
              :placeholder="unit_city"
              v-model="unit_city"
              @change="handleChange"
            >
            </el-cascader>
          </div>
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-single">
          <p>详细地址</p>
          <input
            type="text"
            placeholder="请输入详细地址"
            v-model="unit_address"
          />
        </div>
        <div class="clear"></div>
      </div>
      <el-divider></el-divider>
      <div class="input-item clear">
        <div class="item-left">
          <p>单位开户行</p>
          <input type="text" placeholder="请输入单位开户行" v-model="bank" />
        </div>
        <div class="item-right">
          <p>开户行账号</p>
          <input type="text" placeholder="请输入开户行账号" v-model="account" />
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-left">
          <p>收货人</p>
          <input type="text" placeholder="请输入收货人" v-model="user_name" />
        </div>
        <div class="item-right">
          <p>电话</p>
          <input type="text" placeholder="请输入电话" v-model="phone" />
        </div>
      </div>
      <div class="input-item clear">
        <div class="item-single">
          <p>收货地址</p>
          <input type="text" placeholder="请输入收货地址" v-model="address" />
        </div>
      </div>
      <div class="submit-btn" @click="btn1()">
        <p>保存</p>
      </div>
    </div>
    <van-popup v-model="show" class="pop">
      <img
        src="../../assets/img/pop_but_gb.png"
        @click="show = false"
        class="close"
      />
      <div class="pop_left">
        <div class="pop_left_top">
          <p>设置头像</p>
          <img v-if="imageUrl" :src="imageUrl" />
          <img v-else :src="User.head_pic" />
          <van-uploader :after-read="upLoad" :max-count="1">
            <div class="fl">选择需要上传图片</div>
          </van-uploader>
        </div>
        <div class="pop_list_bottom">
          <p>说明</p>
          <ul>
            <li>图片格式为 JPG/PNG/BMP</li>
            <li>图片格式大小不要超过5M</li>
          </ul>
        </div>
      </div>
      <div class="pop_right">
        <p>头像预览</p>
        <img v-if="imageUrl" :src="imageUrl" />
        <img v-else :src="User.head_pic" />
        <div @click="show = false">保存</div>
      </div>
    </van-popup>
    <!-- 添加同事，同学弹框 -->
    <van-popup v-model="show1" class="pop1">
      <img src="../../assets/img/pop_but_gb.png" @click="close" class="close" />
      <div class="pop1_top">
        <p class="fl">搜索用户</p>
        <img class="fr" src="../../assets/img/yqgj_top_logo.png" />
      </div>
      <input placeholder="请输入用户名" v-model="username" @input="goSearch" />
      <div class="pop1_list_box">
        <div class="m1" v-if="UserList.length < 1">暂无搜索内容</div>
        <div
          class="pop1_list fl"
          v-for="(item, index) in UserList"
          :key="index"
          @mouseenter="chose(index, item.user_id)"
          @mouseleave="close1(index)"
          @click="choose(index, item.user_id)"
          :class="[User_index == index ? 'active1' : '', eyy ? 'r88' : '']"
        >
          <img :src="item.head_pic" />
          <p
            class="one-letter"
            :class="[User_id == item.user_id ? 'active' : '']"
          >
            {{ item.user_name }}
          </p>
        </div>
      </div>
      <div class="add" @click="add">添加用户</div>
    </van-popup>
  </div>
</template>
<script>
import { UseInfo, UpUser } from "../../api/index";
import {
  Friendlist,
  Search,
  addFriend,
  delFriend,
  upUnit,
} from "../../api/User";
let pcas = require("../../assets/js/area.json");
export default {
  data() {
    return {
      activeInfo: "person",
      activeName: "colleague",
      User: "",
      name: "",
      unit: "", //单位名称
      phone: "",
      email: "",
      site: "",
      post: "",
      show: false,
      imageUrl: "",
      index: "", //同事，学生列表
      p: 1,
      is_type: "",
      list: [], //同事，学生列表
      show1: false,
      username: "",
      UserList: [],
      User_id: "", //好友id
      del_id: "", // 删除id
      taxes_code: "", //纳税人识别号
      bank: "", //银行
      account: "", //开户行账号
      address: "", //地址
      user_name: "", //收货人
      unit_address: "", //单位 详细地址
      unit_city: [], //单位所在地
      options: pcas,
      district: "",
      city: "",
      eyy: false,
      province: "",
      User_index: -1,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getUserInfo();
    this.getList(); //同学，朋友列表
  },
  methods: {
    //选择同事 鼠标滑过
    chose(index, id) {
      this.User_index = index;
      this.User_id = id;
      this.eyy = false;
    },
    //鼠标点击
    choose(index, id) {
      this.User_index = index;
      this.User_id = id;
      this.eyy = true;
    },
    close1() {
      this.User_index = -1;
    },
    //修改单位资料
    btn1() {
      let data = {
        token: this.token,
        unit_name: this.unit,
        address: this.unit_address,
        invoice_province: this.province,
        invoice_city: this.city,
        invoice_district: this.district,
        plane_tel: this.phone,
        invoice_taxpayer_code: this.taxes_code,
        invoice_deposit_bank: this.bank,
        invoice_bank_account: this.account,
        invoice_user_address: this.address,
        invoice_user_name: this.user_name,
      };
      upUnit(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getUserInfo();
        } else  {
          this.$message.error(res.msg);
        }
      });
    },
    //选择省市区
    handleChange(e, form, thsAreaCode) {
      thsAreaCode = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels; // 注意2： 获取label值
      // console.log(thsAreaCode); // 注意3： 最终结果是个一维数组对象
      this.province = thsAreaCode[0];
      this.city = thsAreaCode[1];
      this.district = thsAreaCode[2];
    },

    //
    show_area() {
      this.showArea = true;
    },
    //删除接口
    del_friend() {
      delFriend({ f_id: this.del_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.getList();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    // 删除
    diss() {
      // console.log(123);
      let that = this;
      this.$confirm("您确定要删除吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.del_friend();
        })
        .catch(() => {
          this.del_id = 0;
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    del(id) {
      this.del_id = id;
      this.diss();
    },
    //分页
    gotopage() {
      this.getList();
    },
    close() {
      this.show1 = false;
      this.UserList = [];
    },
    //添加同事
    add() {
      if (this.index == 0) {
        this.is_type = 1;
      } else {
        this.is_type = 2;
      }
      let data = {
        token: this.token,
        user_id: this.User_id,
        is_type: this.is_type,
      };
      addFriend(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.show1 = false;
            this.getList();
            this.UserList = [];
            this.username = "";
            this.User_id = "";
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        }
      });
    },
    //搜索同事学生
    goSearch() {
      Search({ keywords: this.username }).then((res) => {
        if (res.status == 1) {
          this.UserList = res.result;
        }
      });
    },

    showFriend() {
      this.show1 = true;
    },
    handleClick(e) {
      this.index = e.index;
      this.getList();
    },
    //同学，朋友列表
    getList() {
      if (this.index == 0) {
        this.is_type = 1;
      } else {
        this.is_type = 2;
      }
      let data = {
        token: this.token,
        p: this.p,
        is_type: this.is_type,
      };
      Friendlist(data).then((res) => {
        if (res.status == 1) {
          this.list = res.result;
        }
      });
    },

    //个人资料修改
    btn() {
      let data = {
        token: this.token,
        head_pic: this.imageUrl ? this.imageUrl : this.User.head_pic,
        mobile: this.phone,
        unit_name: this.unit,
        email: this.email,
        user_address: this.site,
        user_position: this.post,
        user_name: this.name,
      };
      UpUser(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
          this.getUserInfo();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //上传头像
    upLoad(file) {
      const formData = new FormData();
      formData.append("img_file", file.file);
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic",
          formData,
          config
        )
        .then((res) => {
          if (res.data.status == 1) {
            this.imageUrl = res.data.result.url;
            // console.log(this.imageUrl);
          }
        });
    },
    //选择头像
    show_choseHead() {
      this.show = true;
    },
    //个人信息
    getUserInfo() {
      UseInfo({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.User = res.result;
          this.name = res.result.user_name;
          this.unit = res.result.company_name;
          this.phone = res.result.mobile;
          this.email = res.result.email;
          this.site = res.result.user_address;
          this.post = res.result.position_name;
          this.taxes_code = res.result.invoice_taxpayer_code;
          this.bank = res.result.invoice_deposit_bank;
          this.account = res.result.invoice_bank_account;
          this.user_name = res.result.invoice_user_name;
          this.address = res.result.invoice_user_address;
          this.unit_address = res.result.address;
          this.unit_city = [
            res.result.invoice_province +
              res.result.invoice_city +
              res.result.invoice_district,
          ];
        }
      });
    },
    //切换 个人资料 单位信息
    toggleUserInput(activeInfo) {
      if (activeInfo != this.activeInfo) {
        this.activeInfo = activeInfo;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-right-tab {
    height: 70px;
    border-bottom: 1px solid #eeeeee;
    p {
      width: 100px;
      height: 70px;
      line-height: 70px;
      margin: 0 35px;
      float: left;
      cursor: pointer;
    }
    p.active::after {
      background-color: #408df7;
      width: 42px;
      height: 2px;
      content: " ";
      display: inline-block;
      position: relative;
      top: -58%;
      left: 8%;
    }
  }
  .content-user {
    padding: 40px;
    .user-pic-box {
      position: relative;
      margin-bottom: 40px;
      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .input-item {
      padding: 10px 0;
      span,
      p,
      input {
        float: left;
      }
      .item-left,
      .item-right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .item-left {
        float: left;
        width: 50%;
      }
      .item-right {
        width: 50%;
        float: right;
      }
      span {
        color: #ff3b30;
        font-size: 16px;
      }
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
        padding: 0 5px;
      }
      input {
        width: 80%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 15px;
        margin: 0 10px;
      }
    }
    .input-item-long {
      padding: 10px 0;
      .item-long {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        p,
        span,
        input {
          float: left;
        }
        span {
          color: #ff3b30;
          font-size: 16px;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
          padding: 0 5px;
        }
        input {
          width: 91%;
          height: 50px;
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 15px;
          margin: 0 10px;
        }
      }
    }
    .colleague-student {
      .colleague-superior {
        padding: 15px 0;
        height: 350px;
        .add-superior {
          width: 80px;
          cursor: pointer;
          img {
            width: 80px;
            height: 80px;
          }
          p {
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .superior-list {
          height: 350px;
          margin-left: 30px;
          float: left;
          overflow-y: auto;
          width: 85%;
          .superior-item {
            cursor: pointer;
            float: left;
            width: 80px;
            margin-right: 40px;
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              object-fit: cover;
              position: relative;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              color: #333333;
              padding: 10px 0;
              margin: 0 auto;
              max-width: 56px;
            }
          }
        }
      }
      .colleague-subordinate {
        border-top: 1px solid #eeeeee;
        padding: 15px 0;
        .add-subordinate {
          img {
            width: 80px;
            height: 80px;
          }
          p {
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .subordinate-list {
          .subordinate-item {
            float: left;
            margin: 0 20px;
            img {
              width: 80px;
              height: 80px;
            }
            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              text-align: center;
              color: #333333;
              padding: 10px 0;
            }
          }
        }
        .subordinate-list {
          .subordinate-item {
            float: left;
            margin: 0 20px;
            img {
              width: 80px;
              height: 80px;
            }
            p {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              text-align: center;
              color: #333333;
              padding: 10px 0;
            }
          }
        }
      }
      .student {
        padding: 15px 0;
        .add-student {
          cursor: pointer;
          img {
            width: 80px;
            height: 80px;
          }
          p {
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
        .student-list {
          height: 350px;
          margin-left: 30px;
          float: left;
          overflow-y: auto;
          width: 85%;
          .student-item {
            cursor: pointer;
            float: left;
            width: 80px;
            margin-right: 40px;
            img {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              object-fit: cover;
              position: relative;
            }
            p {
              font-size: 14px;
              font-weight: 400;
              text-align: center;
              color: #333333;
              padding: 10px 0;
              margin: 0 auto;
              max-width: 56px;
            }
          }
        }
      }
    }
  }
  .content-unit {
    padding: 30px;
    .input-item {
      padding: 10px 0;
      span,
      p,
      input {
        float: left;
      }
      .item-left,
      .item-right {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .item-left {
        float: left;
        width: 50%;
      }
      .item-right {
        width: 50%;
        float: right;
      }
      span {
        color: #ff3b30;
        font-size: 16px;
      }
      p {
        width: 20%;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
        padding: 0 5px;
        text-align: right;
      }
      input {
        width: 80%;
        height: 50px;
        background: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 2px;
        padding: 15px;
        margin: 0 10px;
      }
      .item-single {
        display: flex;
        flex-direction: row;
        align-items: center;
        p {
          width: 9.5%;
        }
        input {
          width: 90%;
          height: 50px;
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 15px;
          margin: 0 10px;
        }
      }
    }
    .submit-btn {
      cursor: pointer;
      padding: 20px 0 20px 11%;
      p {
        width: 210px;
        height: 50px;
        background: #408df7;
        color: #ffffff;
        text-align: center;
        font-size: 16px;
        line-height: 50px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
  }
}
.submit {
  width: 210px;
  height: 50px;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
  margin-left: 58px;
  cursor: pointer;
}
.user_head {
  cursor: pointer;
  img {
    opacity: 0.8;
    margin-left: 60px;
  }
  p {
    position: absolute;
    color: #ffffff;
    font-size: 16px;
    top: 30px;
    left: 155px;
  }
}
.pop {
  width: 800px;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  padding: 62px;
}
.pop_left {
  float: left;
}
.pop_left_top {
  p {
    font-size: 16px;
    color: #333333;
    text-align: left;
  }
  img {
    width: 260px;
    height: 260px;
    object-fit: cover;
    margin: 20px 0 30px;
  }
  div {
    width: 260px;
    height: 60px;
    background: rgba(64, 141, 247, 0.09);
    border: 1px solid #408df7;
    opacity: 1;
    border-radius: 2px;
    font-size: 16px;
    color: #408df7;
    line-height: 60px;
    cursor: pointer;
    text-align: center;
  }
}
.pop_list_bottom {
  padding-top: 40px;
  text-align: left;
  p {
    font-size: 16px;
    color: #333333;
  }
  ul {
    margin: 35px 0 0 50px;
    list-style: inherit !important;
  }
  li {
    list-style: inherit !important;
    font-size: 16px;
    padding-bottom: 10px;
    color: #999999;
  }
}
.pop_right {
  float: right;
  p {
    font-size: 16px;
    color: #333333;
    text-align: left;
  }
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    text-align: left;
    margin: 100px 0;
  }
  div {
    width: 260px;
    height: 60px;
    background: #ff3b30;
    opacity: 1;
    border-radius: 2px;
    line-height: 60px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
  }
}
.van-uploader {
  display: block;
}
.close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}

.pop1 {
  width: 1200px;
  height: 682px;
  background: #ffffff;
  border-radius: 5px;
  padding: 0 175px;
  input {
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-bottom: 1px solid #006efc;
  }
}
.pop1_top {
  padding-top: 80px;
  p {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
  }
}
.pop1_list_box {
  width: 100%;
  height: 280px;
  margin: 40px 0;
  overflow-y: auto;
}
.add {
  width: 180px;
  height: 45px;
  border: 2px solid #408df7;
  color: #408df7;
  font-size: 16px;
  text-align: center;
  line-height: 45px;
  cursor: pointer;
}
.pop1_list {
  width: 100px;
  height: 130px;
  text-align: center;
  margin-right: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  p {
    font-size: 14px;
    color: #333333;
    max-width: 56px;
    margin: 0 auto;
  }
}
.active {
  color: #408df7 !important;
}
.active1 {
  width: 100px;
  height: 130px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}
.r88 {
  box-shadow: none !important;
}
.block {
  margin: 0 45%;
}
.superior_img {
  opacity: 0.4;
}
.superior_img_text {
  text-align: center;
  font-size: 14px;
  margin: -49px 0 30px;
  color: #333333;
  cursor: pointer;
  display: none;
  z-index: 10;
}
.superior_img_text1 {
  text-align: center;
  font-size: 14px;
  margin: -90px 0 30px;
  color: #333333;
  cursor: pointer;
  display: none;
  z-index: 10;
}
.block1 {
  display: block;
}
.pop3 {
  width: 500px;
  height: 400px;
}
.van-picker-column {
  overflow-y: auto;
}
.area_box {
  margin-left: 5px;
  width: 77%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dddddd;
  opacity: 1;
  border-radius: 2px;
}
.el-input__icon {
  text-align: right;
}
.area_box /deep/ .el-input__inner {
  color: #333333 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 48px;
}
.area_box /deep/ .el-cascader {
  width: 100% !important;
}
.el-cascader /deep/ .el-input__inner ::-webkit-input-placeholder {
  color: #333333 !important;
}
</style>