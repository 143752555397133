<template>
  <div>
    <Header></Header>
    <div class="img_box">
      <div class="img_text">
        <img src="../../assets/img/yqwx_icon.png" />
        <p>关于我们</p>
        <p class="a2">about us</p>
        <img class="a1" src="../../assets/img/top_img_x.png" />
        <div>
          {{ list.title }}
        </div>
      </div>
    </div>
    <div class="brief_box">
      <div class="brief">
        <div class="text fl">公司简介</div>
        <img src="../../assets/img/gywm_gsjj_img.png" />
        <div class="brief_left fl">
          <div class="brief_top">
            <p></p>
            <span>贵州众创仪云科技有限公司</span>
          </div>
          <div class="title">{{ list.content }}</div>
        </div>
      </div>
    </div>
    <div class="vision_box">
      <div class="vision">
        <p>企业愿景</p>
        <div>
          {{ list.author }}
        </div>
      </div>
    </div>
    <div class="mission_box">
      <div class="mission">
        <img src="../../assets/img/gywm_qysm_img.png" />
        <div class="mission_list">
          <p>企业使命</p>
          <div class="word">{{ list.description }}</div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { about } from "../../api/index";

export default {
  components: { Header, Bottom },
  data() {
    return {
      list: "",
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      about({}).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img_box {
  width: 100%;
  height: 400px;
  background: url("../../assets/img/sysjz_top_img.png");
  position: relative;
}
.img_text {
  position: absolute;
  top: 70px;
  width: 40%;
  height: 10px;
  text-align: center;
  left: 30%;
  right: 30%;
  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px 0 5px;
  }
  .a2 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }
  .a1 {
    margin: 30px 0 40px;
  }
  div {
    font-size: 18px;
    color: #ffffff;
  }
}
.brief_box {
  height: 730px;
  padding: 0 260px;
  background: #f9f9f9;
  position: relative;
}
.brief {
  height: 100%;
  padding: 88px 0 42px;
  img {
    width: 900px;
    height: 600px;
    float: right;
  }
}
.text {
  font-size: 36px;
  color: #333333;
  font-weight: bold;
  margin: 0 0 37px 80px;
}
.brief_left {
  position: absolute;
  bottom: 114px;
  z-index: 99;
  width: 776px;
  height: 454px;
  background: #ffffff;
}
.vision_box {
  width: 100%;
  height: 320px;
  background: url("../../assets/img/gywm_bg.png") no-repeat;
}
.vision {
  text-align: center;
  padding: 0 530px;
  height: 320px;
  p {
    font-size: 36px;
    color: #ffffff;
    font-weight: bold;
    padding: 76px 0 36px;
  }
  div {
    font-size: 21px;
    color: #ffffff;
  }
}
.mission_box {
  padding: 0 260px;
  background: #f9f9f9;
  height: 770px;
}
.mission {
  width: 100%;
  height: 770px;
  position: relative;
  padding: 70px 0 100px;
}
.mission_list {
  width: 776px;
  height: 454px;
  background: #ffffff;
  position: absolute;
  bottom: 175px;
  right: 0;
  padding: 37px 30px 55px 69px;
  p {
    font-size: 36px;
    color: #333333;
    font-weight: bold;
  }
}
.brief_top {
  padding: 30px 0 30px 30px;
  p {
    width: 7px;
    height: 31px;
    background: #408df7;
    float: left;
  }
  span {
    float: left;
    font-size: 24px;
    color: #333333;
    margin-left: 30px;
  }
}
.title {
  margin-top: 30px;
  overflow-y: auto;
  float: left;
  padding: 0 30px 0 70px;
  font-size: 18px;
  color: #333333;
}
.word {
  margin-top: 30px;
  overflow-y: auto;
  font-size: 18px;
  color: #333333;
}
</style>