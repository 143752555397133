<template>
  <div class="container">
    <Top ref="mychild"></Top>
    <div class="img_box">
      <img src="../../assets/img/sy_lb_img.png" />
    </div>
    <div class="img_box_list_box">
      <el-row :gutter="30">
        <el-col :span="6">
          <div class="img_box_list" @click="goHref('/Maintain')">
            <div class="img_box_list_text">
              <p>维修维护</p>
              <span>Maintenance</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="img_box_list two" @click="goHref('/laboratory')">
            <div class="img_box_list_text">
              <p>实验室建设方案</p>
              <span>Laboratory construction scheme</span>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="img_box_list three" @click="goHref('/sm_laboratory')" v-if="user_id">
            <div class="img_box_list_text">
              <p>智能实验室</p>
              <span>Intelligent laboratory</span>
            </div>
          </div>
          <div class="img_box_list three" v-if="user_id==null" @click="show_login()">
            <div class="img_box_list_text">
              <p>智能实验室</p>
              <span>Intelligent laboratory</span>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 20px">
      <!-- 新闻资讯 -->
      <div class="new_box pd_space">
        <div class="content_title">
          <div class="title_left fl">
            <p>新闻资讯</p>
            <span>NEWS</span>
          </div>
          <div class="title_right fr" @click="goHref('/news?tabindex=' + 1)">
            <span>更多资讯></span>
          </div>
        </div>
        <div class="new_content clear">
          <el-row :gutter="5">
            <el-col v-for="(item, index) in newList" :key="index" :span="index == 0 ? 12 : 6">
              <div
                class="new_item new_margin_bottom"
                @click="gotoDetalis(item.article_id, item.type)"
              >
                <img :src="item.thumb" />
                <div class="mask">
                  <p>{{ item.title }}</p>
                  <p>{{ item.title }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <img class="us_img" v-show="active == 1" :src="srce" />
      <div class="box_rtop" v-if="top">
        <div class="rtop_list" @mouseenter="showActive(1)" @mouseleave="showActive(0)">
          <img v-show="active == 0" src="../../assets/img/zb_icon_wx_n.png" />
          <img v-show="active == 1" src="../../assets/img/zb_icon_wx_s.png" />

          <p v-show="active == 0">关注我们</p>
          <p v-show="active == 1" class="n1">关注我们</p>
        </div>
        <div
          class="rtop_list"
          @mouseenter="showActive1(1)"
          @mouseleave="showActive1(0)"
          @click="goHref('/UserCenter?control=' + 10)"
        >
          <img v-show="active1 == 0" src="../../assets/img/zb_icon_zx_n.png" />
          <img v-show="active1 == 1" src="../../assets/img/zb_icon_zx_s.png" />
          <p v-show="active1 == 0">在线咨询</p>
          <p v-show="active1 == 1" class="n1">在线咨询</p>
        </div>
        <div
          class="rtop_list"
          @mouseenter="showActive2(1)"
          @mouseleave="showActive2(0)"
          @click="goHref('/shopCart')"
        >
          <img v-show="active2 == 0" src="../../assets/img/zb_icon_gwc_n.png" />
          <img v-show="active2 == 1" src="../../assets/img/zb_icon_gwc_s.png" />
          <p v-show="active2 == 0">购物车</p>
          <p v-show="active2 == 1" class="n1">购物车</p>
        </div>
        <div
          class="rtop_list"
          @mouseenter="showActive3(1)"
          @mouseleave="showActive3(0)"
          @click="gotoTop"
        >
          <img v-show="active3 == 0" src="../../assets/img/zb_icon_fhdb_n.png" />
          <img v-show="active3 == 1" src="../../assets/img/zb_icon_fhdb_s.png" />
          <p v-show="active3 == 0">返回顶部</p>
          <p v-show="active3 == 1" class="n1">返回顶部</p>
        </div>
      </div>
      <!-- 仪器、耗材采购 -->
      <div class="purchase_box pd_space">
        <div class="content_title">
          <div class="title_left fl">
            <p>仪器、耗材采购</p>
            <span>Purchase of instruments and consumables</span>
          </div>
          <div class="title_right fr" @click="goto()">
            <span>更多产品></span>
          </div>
        </div>
        <div class="purchase_tap clear">
          <el-row :gutter="20">
            <el-col v-for="(item, index) in classifyList" :key="index" :sm="6" :md="4" :lg="3">
              <span
                @click="chose_classify(item.id)"
                :class="[classify_id == item.id ? 'active' : '']"
              >{{ item.name }}</span>
            </el-col>
          </el-row>
        </div>
        <div class="purchase_content clear">
          <el-row>
            <el-col class="purchase_item" :span="6" v-for="(item, index) in goodsList" :key="index">
              <div v-if="index == 0">
                <div class="purchase_item_first">
                  <h3>{{ goodsName.name }}</h3>
                  <hr align="left" />
                  <p
                    class="three-letter"
                    v-if="goodsName.category_remark != null"
                  >{{ goodsName.category_remark }}</p>
                  <p v-else>暂无简介</p>
                </div>
                <div class="m1" v-if="goodsList.length < 1">暂无数据</div>
              </div>
              <div v-if="index > 0" @click="goHref('/commodityDetails?id=' + item.goods_id)">
                <img :src="item.original_img" />
                <div class="purchase_name one-letter">{{ item.goods_name }}</div>
                <div class="purchase_price">
                  <span class="purchase_price_now">¥{{ item.shop_price }}</span>
                  <span class="purchase_price_del">¥{{ item.market_price }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 仪器知识 -->
      <div class="knowledge_box">
        <div class="content_title pd_space">
          <div class="title_left fl">
            <p>仪器知识</p>
            <span>Instrument knowledge</span>
          </div>
          <div class="title_right fr" @click="goHref('/news?tabindex=' + 2)">
            <span>查看更多></span>
          </div>
        </div>
        <div class="knowledge_content clear">
          <el-row :gutter="20">
            <el-col :span="6" v-for="(item, index) in instrumentList" :key="index">
              <div class="knowledge_item fl" @click="gotodetails(item.article_id)">
                <img :src="item.thumb" />
                <p class="one-letter">{{ item.title }}</p>
                <span>{{ item.add_time }}</span>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 行业招标信息 -->
      <div class="information_box pd_space">
        <div class="content_title">
          <div class="title_left fl">
            <p>行业招投标信息</p>
            <span>Industry bidding information</span>
          </div>
          <div class="title_right fr" @click="goHref('/industry')">
            <span>查看更多></span>
          </div>
        </div>
        <div class="information_content clear">
          <el-row :gutter="50">
            <el-col :span="12" :offset="0" v-for="(item, index) in bdList" :key="index">
              <div class="information_item" @click="showPop(item.id)">
                <div class="information_item_top">
                  <div>
                    <img src="../../assets/img/icon_pdf_s.png" alt />
                    <p class="two-letter">{{ item.title }}</p>
                  </div>
                  <!-- <a href="javascript:;">下载</a> -->
                </div>
                <div class="information_item_bottom clear">
                  <span>{{ item.publishdate }}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <van-popup v-model="show" class="pop">
      <div class="pop_top">
        <div class="pop_left">
          <img class="fl" src="../../assets/img/icon_pdf_s.png" />
          <p class="one-letter fl">{{ list.title }}</p>
        </div>
        <img class="img2 fr" @click="show = false" src="../../assets/img/pop_but_gb.png" />
      </div>
      <div class="pop_content">{{ list.source }}</div>
      <div v-html="list.content" class="pop_bottom"></div>
    </van-popup>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import {
  bidList,
  newList,
  instrumentList,
  bidinfo,
  Index_classify,
  Index_goods,
  Company,
} from "../../api/index";
export default {
  components: { Top, Bottom },
  data() {
    return {
      top: false,
      active: 0,
      active1: 0,
      active2: 0,
      active3: 0,
      p: 1,
      bdList: [],
      newList: [],
      instrumentList: [],
      show: false,
      list: "",
      classifyList: [],
      classify_id: "",
      goodsList: [],
      goodsName: "",
      code: "",
      srce: "",
      user_id: "",
    };
  },
  created() {
    this.getbidList(); //招标列表
    this.getNewList();
    this.getinstrumentList();
    this.getList(); //仪器采购分类
    this.Companye();
    this.user_id = localStorage.getItem("user_id");
  },
  watch: {
    // this.user_id = localStorage.getItem("user_id");
  },
  mounted() {
    var _this = this;
    window.addEventListener("scroll", function () {
      //监听滚动
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 1200) {
        //如果当前滚动位置大于您的所需要达到的高度则显示置顶元素，反之不显示
        _this.top = true;
      } else {
        _this.top = false;
      }
    });
  },
  methods: {
    show_login() {
      this.$message("请登录后查看");
    },
    Companye() {
      Company().then((res) => {
        this.srce = res.data.value;
      });
    },
    goto() {
      localStorage.setItem("name", "仪器列表");
      this.$router.push({
        path: "/Purchase",
      });
    },
    //采购列表
    getclassify_list() {
      Index_goods({ id: this.classify_id }).then((res) => {
        if (res.status == 1) {
          this.goodsList = res.data.goods;
          this.goodsName = res.data.category_name;
        }
      });
    },
    chose_classify(id) {
      this.classify_id = id;
      this.getclassify_list();
    },
    //
    getList() {
      Index_classify({}).then((res) => {
        this.classifyList = res.data;
        this.classify_id = res.data[0].id;
        this.getclassify_list();
      });
    },
    showPop(id) {
      bidinfo({ id: id }).then((res) => {
        if (res.status == 1) {
          this.show = true;
          this.list = res.data;
        }
      });
    },
    //知识详情
    gotodetails(id) {
      this.$router.push({
        path: "/NewsDetail",
        query: { id: id, isvideo: 1, types: 2 },
      });
    },
    //跳转新闻详情
    gotoDetalis(id, isvideo) {
      this.$router.push({
        path: "/NewsDetail",
        query: { id: id, isvideo: isvideo, types: 1 },
      });
    },
    //仪器知识列表
    getinstrumentList() {
      instrumentList({}).then((res) => {
        this.instrumentList = res.data;
      });
    },

    //咨询列表
    getNewList() {
      newList({}).then((res) => {
        this.newList = res.data;
      });
    },
    //招标列表
    getbidList() {
      bidList({ p: this.p }).then((res) => {
        if (res.status == 1) {
          this.bdList = res.data;
          console.log(res);
        }
      });
    },
    //返回顶部
    gotoTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 8);
    },
    showActive(index) {
      this.active = index;
    },
    showActive1(index) {
      this.active1 = index;
    },
    showActive2(index) {
      this.active2 = index;
    },
    showActive3(index) {
      this.active3 = index;
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>


<style lang="scss" scoped>
img {
  object-fit: cover;
}
// .pd_space{
//   padding-top: 20px;
// }
.content_title {
  padding-top: 30px;
}
.clear {
  clear: both;
}
.container {
  // width: 1800px;
  min-width: 1440px;
  overflow-x: hidden;
}
.img_box {
  width: 100%;
  height: 650px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.img_box_list_box {
  position: relative;
  // top: -110px;
  margin-top: -100px;
  // background: #000;
  width: 80%;
  height: 80px;
  left: 20%;
}
.img_box_list {
  background: url("../../assets/img/sy_img_yqwx.png") no-repeat;
  background-size: cover;
  background-position: -15px 0;
  width: 100%;
  height: 80px;
  padding-left: 90px;
  padding-top: 15px;
  cursor: pointer;
  border-radius: 6px;
}
.img_box_list.two {
  background: url("../../assets/img/sy_img_sysfa.png") no-repeat;
  background-size: cover;
  background-position: -15px 0;
}
.img_box_list.three {
  background: url("../../assets/img/sy_img_sysjs.png") no-repeat;
  background-size: cover;
  background-position: -15px 0;
}

p {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
span {
  font-size: 13px;
  color: #c1c1c1;
}

// 新闻
.new_box {
  // height: 1017px;
}
.new_content {
  padding-top: 27px;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
}
.new_content .new_item {
  width: 100%;
  height: 395px;
  float: left;
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.55) 100%
    );
  }
}
.new_content .new_item img {
  width: 100%;
  height: 395px;
  background-color: #999999;
}
.new_content .new_item p {
  color: #ffffff;
  font-size: 22px;
  font-weight: normal;
  padding: 0 52px 0 22px;
  position: absolute;
  bottom: 34px;
}
// .new_content .new_item:nth-child(1) {
//   width: 49.2%;
// }
// .new_content .new_item:nth-child(1) img {
//   width: 100%;
//   object-fit: cover;
// }
// .new_content .new_item:nth-child(1) p {
//   padding: 0 54px 0 30px;
// }
// .new_content .new_item:nth-child(1) p:nth-child(2) {
//   bottom: 75px;
// }
// .new_content .new_item:nth-child(1) p:nth-child(3) {
//   font-size: 16px;
//   bottom: 20px;
// }
// .new_content .new_item:nth-child(1) span {
//   color: #ffffff;
//   font-size: 16px;
//   padding: 0 54px 0 30px;
// }
.new_margin_top {
  margin-top: 5px;
}
.new_margin_bottom {
  margin-bottom: 5px;
}
.new_margin_left {
  margin-left: 0.2%;
}
// .new_margin_right {
//   margin-right: 0.2%;
// }
//仪器、耗材采购
.purchase_box {
  height: 949px;
}
.purchase_tap {
  padding-top: 68px;
  width: 100%;
}
.purchase_tap span {
  cursor: pointer;
  color: #999999;
  font-size: 22px;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  display: inline-block;
  // margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.purchase_tap span.active {
  background-color: #408df7;
  border: 1px solid #408df7;
  color: #ffffff;
}
// .purchase_tap span:first-child {
//   margin-left: 0;
// }
// .purchase_tap span:last-child {
//   margin-right: 0;
// }
.purchase_content {
  padding-top: 60px;
}
.purchase_item {
  // width: 100%;
  height: 300px;
  padding: 0 16px 26px;
  float: left;
  // img {
  //   //  object-fit: cover;
  // }
}
.purchase_item:nth-child(2),
.purchase_item:nth-child(4),
.purchase_item:nth-child(5),
.purchase_item:nth-child(7) {
  background-color: #f9f9f9;
}
.purchase_item:nth-child(1) {
  background-color: #408df7;
}
.purchase_item_first {
  color: #ffffff;
  padding: 55px;
  width: 100%;
  line-height: 31px;
}
.purchase_item_first h3 {
  color: #ffffff;
  font-size: 24px;
}
.purchase_item_first hr {
  width: 38px;
  height: 3px;
  background-color: #ffffff;
}
.purchase_item_first p {
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}
.purchase_item img {
  width: 100%;
  height: 200px;
  padding: 0 15%;
}
.purchase_item .purchase_name {
  text-align: center;
  font-size: 20px;
  color: #333333;
  line-height: 26px;
}
.purchase_item .purchase_price {
  text-align: center;
}
.purchase_item .purchase_price .purchase_price_now {
  font-size: 25px;
  color: #ff0000;
  line-height: 33px;
  margin-right: 17px;
}
.purchase_item .purchase_price .purchase_price_del {
  font-size: 20px;
  color: #999999;
  line-height: 26px;
  text-decoration: line-through;
}

.knowledge_content {
  margin: 0 auto;
  width: 98%;
}
.knowledge_item {
  width: 100%;
  height: 380px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
  margin: 27px 0;
  cursor: pointer;
  p {
    margin-bottom: 24px;
  }
}
.knowledge_item img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}
.knowledge_item p,
.knowledge_item span {
  padding-left: 20px;
}
//行业投标信息
.information_box {
  width: 100%;
}
.information_content {
  width: 100%;
  // height: 620px;
  padding: 30px 0;
  border-top: 1px solid #eeeeee;
}
.information_item {
  width: 100%;
  height: 110px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  margin-right: 100px;
  cursor: pointer;
}
.information_item:nth-of-type(2n) {
  margin: 0;
}
.information_item_top {
  height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.information_item_top div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.information_item_top div img {
  width: 24px;
  height: 28px;
}
.information_item_top div p {
  max-width: 550px;
  color: #333333;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  margin-left: 17px;
}
.information_item_top a {
  width: 98.88px;
  height: 35px;
  line-height: 30px;
  font-size: 16px;
  color: #408df7;
  border: 2px solid #408df7;
  background-color: #ebf3ff;
  text-align: center;
}
.information_item_bottom {
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 41px;
}
.information_item_bottom span {
  color: #999999;
  font-size: 14px;
  line-height: 26px;
}
//每栏内容头部
.title_left p {
  font-weight: normal;
  font-size: 38px;
  margin-bottom: 10px;
}
.title_left span {
  font-size: #999999;
}
.title_right {
  padding-top: 20px;
}
.title_right span {
  display: inline-block;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  font-size: 20px;
  // padding: 12px 28px;
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  // margin-top: 23px;
  cursor: pointer;
}
.box_rtop {
  position: fixed;
  right: 70px;
  bottom: 100px;
  top: 100px;
  width: 75px;
  height: 350px;
  z-index: 10;
}
.rtop_list {
  width: 75px;
  height: 75px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  img {
    width: 28px;
    height: 28px;
    margin: 15px 0 5px;
  }
  p {
    font-size: 12px;
    color: #999999;
    font-weight: 400;
  }
}
.n1 {
  color: #408df7 !important;
}
.us_img {
  position: fixed;
  right: 150px;
  bottom: 100px;
  top: 95px;
  width: 104px;
  height: 100px;
  opacity: 1;
}
.pop {
  width: 1000px;
  height: 600px;
  background: #ffffff;
  border: 1px solid #707070;
  padding: 40px 30px 30px;
  text-align: inherit !important;
  overflow: hidden;
  img {
    cursor: pointer;
  }
}
.pop_top {
  height: 30px;
}
.pop_left {
  float: left;
  padding-left: 150px;
  width: 100%;
  p {
    font-size: 25px;
    color: #333333;
    font-weight: bold;
    margin-left: 5px;
    max-width: 700px;
  }
}
.img2 {
  margin-top: -30px;
}
.pop_content {
  margin: 20px 0;
  font-size: 16px;
  color: #333333;
  text-align: center;
}
.pop_bottom {
  width: 100%;
  height: 440px;
  overflow-y: auto;
  padding-bottom: 100px;
}
</style>
