<template>
  <div class="container">
    <Top></Top>
    <div class="content-box mg_space_260">
      <div class="top-left">
        <div class="top-title one-letter">
          {{ article.title }}
        </div>
        <div class="top-desc">
          <p>
            <img src="../../assets/img/xwxq_icon_sj.png" alt="" /><span
              >发布时间：{{ article.add_time }}</span
            >
          </p>
          <p>
            <img src="../../assets/img/xwxq_icon_zz.png" alt="" /><span
              >来自：{{ article.author }}</span
            >
          </p>
          <p>
            <img src="../../assets/img/xwxq_icon_lll.png" alt="" /><span
              >浏览量：{{ article.click }}</span
            >
          </p>
          <p v-if="isvideo == 1">新闻</p>
          <p v-if="isvideo == 2">视频</p>
        </div>
      </div>
      <div class="top-right">
        <div class="like-box e7" @click="chose_dz" v-if="status == 1">
          <img src="../../assets/img/xwxq_top_dz_s.png" />
          <p style="color: #ffffff">{{ article.praise }}</p>
        </div>
        <div class="like-box" @click="chose_dz" v-if="status == 2">
          <img src="../../assets/img/xwxq_top_dz_n.png" />
          <p>{{ article.praise }}</p>
        </div>
        <div class="collect-box e7" v-if="status1 == 1" @click="chose_sc">
          <img src="../../assets/img/xwxq_top_sc_n.png" />
          <p style="color: #ffffff">{{ article.collection_num }}</p>
        </div>
        <div class="collect-box" v-if="status1 == 2" @click="chose_sc">
          <img src="../../assets/img/xwxq_top_sc_s.png" />
          <p>{{ article.collection_num }}</p>
        </div>
      </div>
      <div class="content clear" v-if="isvideo == 1">
        <div v-html="article.content"></div>
      </div>
      <div class="content clear" v-else>
        <video autoplay="autoplay" controls="controls" :src="article.video_url">
          暂无视频
        </video>
      </div>
    </div>
    <div class="comment-box">
      <div class="comment-top">参与评论</div>
      <div class="comment-input">
        <textarea
          cols="30"
          rows="10"
          placeholder="说点什么吧..."
          v-model="content"
        ></textarea>
        <div class="comment-user">
          <div>
            <img v-if="token" :src="User.head_pic" />
            <img v-else src="../../assets/img/head.png" />
          </div>
          <span>{{ User.user_name }}</span>
          <p @click="btn()">提交评论</p>
        </div>
      </div>
    </div>
    <div class="history-box mg_space_260">
      <div class="history-title" v-if="types == 1">
        评论区({{ comment_num ? comment_num : 0 }})
      </div>
      <div class="history-title" v-if="types == 2">
        评论区({{ commentList.count }})
      </div>
      <div class="history-content">
        <div v-if="types == 1" style="height: 100%">
          <div class="m1" v-if="comment.length < 1">暂无评论</div>
          <div
            class="content-item"
            v-for="(item, index) in comment"
            :key="index"
          >
            <div class="user-info">
              <img :src="item.head_pic" alt="" />
            </div>
            <div class="user-comment">
              <div class="user_top fl">
                <p>{{ item.nickname }}</p>
                <span>{{ item.add_time }}</span>
              </div>
              <div class="fl comment-content">
                <span class="fl">{{ item.content }}</span>
                <div class="comment-like">
                  <img
                    @click="comment_dz(item.id)"
                    v-if="item.type == 2"
                    src="../../assets/img/xwxq_dz_n.png"
                  />
                  <img
                    v-if="item.type == 1"
                    src="../../assets/img/xwxq_dz_s.png"
                  />
                  <p v-if="item.comment_praise != 0">
                    ({{ item.comment_praise }})
                  </p>
                  <p v-if="item.comment_praise == 0"></p>
                </div>
              </div>
            </div>
          </div>
          <van-pagination
            v-if="comment_num"
            v-model="p"
            :total-items="comment_num"
            class="block fl"
            @change="getList()"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
        <div v-if="types == 2">
          <div class="m1" v-if="commentList.article_commentlist.length < 1">
            暂无评论
          </div>
          <div
            class="content-item"
            v-for="(item, index) in commentList.article_commentlist"
            :key="index"
          >
            <div class="user-info">
              <img :src="item.head_pic" alt="" />
            </div>
            <div class="user-comment">
              <div class="user_top">
                <p class="fl">{{ item.user_name }}</p>
                <span class="fl">{{ item.add_time }}</span>
              </div>
              <div class="comment-content">
                <span class="fl">{{ item.content }}</span>
                <div class="comment-like">
                  <img
                    @click="comment_dz(item.comment_id)"
                    v-if="item.is_praise == 0"
                    src="../../assets/img/xwxq_dz_n.png"
                  />
                  <img
                    @click="comment_dz(item.comment_id)"
                    v-if="item.is_praise == 1"
                    src="../../assets/img/xwxq_dz_s.png"
                  />
                  <p v-if="item.comment_praise != 0">
                    ({{ item.comment_praise }})
                  </p>
                  <p v-if="item.comment_praise == 0"></p>
                </div>
              </div>
            </div>
          </div>
          <van-pagination
            v-model="page"
            :total-items="commentList.count"
            class="block fl"
            @change="getCommentList()"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import {
  newinfo,
  newinfo_dz,
  newinfo_sc,
  knowledge_dz,
  knowledge_sc,
  UseInfo,
  comment,
  comment_dz,
  active_comment,
  commentList,
  commentListDZ,
} from "../../api/index";
export default {
  components: { Top, Bottom },
  data() {
    return {
      isvideo: "",
      token: "",
      p: 1,
      types: "",
      article: "",
      comment: [], //评论列表
      status: "", //点赞、未点赞
      status1: "", //收藏、未收藏
      User: "",
      content: "",
      page: 1,
      commentList: [], //评论列表
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.user_id = localStorage.getItem("user_id");
    this.isvideo = this.$route.query.isvideo;
    this.types = this.$route.query.types; //1是新闻  2是知识
    this.id = this.$route.query.id;
    this.getList();
    this.getCommentList();
    if (this.token) {
      this.getUserInfo();
    } else {
      return;
    }
  },
  methods: {
    //评论的点赞
    comment_dz(id) {
      if (this.types == 1) {
        comment_dz({ token: this.token, id: id }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            this.getList();
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        });
      } else {
        commentListDZ({ token: this.token, comment_id: id }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getCommentList();
          } else if (res.status == 0) {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //发布评论
    btn() {
      if (this.types == 1) {
        comment({
          token: this.token,
          content: this.content,
          article_id: this.id,
        }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            this.getList();
            this.content = "";
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        });
      } else if (this.types == 2) {
        active_comment({
          token: this.token,
          content: this.content,
          article_id: this.id,
          parent_id: 0,
        }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.getCommentList();
            this.content = "";
          } else if (res.status == 0) {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //知识评论的列表
    getCommentList() {
      commentList({
        article_id: this.id,
        token: this.token,
        p: this.page,
      }).then((res) => {
        if (res.status == 1) {
          this.commentList = res.result;
        }
      });
    },
    getUserInfo() {
      UseInfo({ token: this.token }).then((res) => {
        this.User = res.result;
      });
    },
    //收藏
    chose_sc() {
      if (this.types == 1) {
        newinfo_sc({ token: this.token, article_id: this.id }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        });
      } else {
        knowledge_sc({ token: this.token, article_id: this.id }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        });
      }
    },
    //点赞
    chose_dz() {
      if (this.types == 1) {
        newinfo_dz({ token: this.token, article_id: this.id }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        });
      } else if (this.types == 2) {
        knowledge_dz({ token: this.token, article_id: this.id }).then((res) => {
          if (res.status == 0) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1000);
          } else if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            setTimeout(() => {
              this.getList();
            }, 1000);
          }
        });
      }
    },
    getList() {
      if (this.types == 1) {
        this.type = 2; //2是知识
      } else if (this.types == 2) {
        this.type = 1; //1是新闻
      }
      newinfo({
        article_id: this.id,
        p: this.p,
        user_id: this.user_id,
        type: this.type,
      }).then((res) => {
        if (res.status == 1) {
          this.article = res.data.article;
          this.status = res.data.article.dz_type;
          this.status1 = res.data.article.sc_type;
          this.comment = res.data.comment ? res.data.comment : "";
          this.comment_num = res.data.count;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  // min-width: 1800px;
  background-color: #fcfcfc;
}
//详情内容
.content-box {
  min-height: 500px;
  background-color: #ffffff;
  border-radius: 5px;
  margin-top: 30px;
  padding: 0 15%;
  padding-top: 10px;
  .top-left {
    float: left;
    padding-bottom: 15px;
    .top-title {
      width: 100%;
      height: 40px;
      font-size: 30px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 40px;
      color: #333333;
    }
    .top-desc {
      padding: 15px 0 0;
      p {
        float: left;
        padding: 0 30px;
        border-left: 1px solid #707070;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        img {
          vertical-align: middle;
          margin-right: 5px;
        }
        span {
          width: 152px;
          height: 19px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #333333;
          opacity: 1;
        }
      }
      p:nth-child(1) {
        padding-left: 0;
        border-left: none;
      }
      p:nth-child(1) img {
        width: 20.35px;
        height: 20.35px;
      }
      p:nth-child(2) img {
        width: 17.24px;
        height: 19.26px;
      }
      p:nth-child(3) img {
        width: 19.61px;
        height: 13.48px;
      }
    }
  }
  .top-right {
    float: right;
    div {
      float: left;
      width: 106px;
      height: 66px;
      border-radius: 33px;
      border: 1px solid #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-left: 20px;

      p {
        color: #408df7;
        font-size: 14px;
      }
    }
    div:nth-child(2) {
      margin-left: 10px;
    }
    .like-box {
      cursor: pointer;
      img {
        width: 24px;
        height: 23px;
      }
    }
    .collect-box {
      cursor: pointer;
      img {
        width: 25px;
        height: 24px;
      }
    }
  }
  .content {
    border-top: 1px solid #f3f3f3;
    padding: 30px 0;
    video {
      width: 100%;
      min-height: 200px;
    }
  }
}
//参与评论
.comment-box {
  margin: 10px 15%;
  background-color: #ffffff;
  border-radius: 5px;
  .comment-top {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 29px;
    color: #333333;
    margin-bottom: 25px;
  }
  .comment-input {
    background-color: #eeeeee;
    padding-bottom: 5px;
    textarea {
      width: 99%;
      margin: 0.5%;
      padding: 25px;
    }
    .comment-user {
      height: 75px;
      line-height: 75px;
      div {
        float: left;
        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          margin: 15px 0 0 20px;
          object-fit: cover;
        }
      }
      span {
        margin-left: 10px;
        font-size: 18px;
        color: #333333;
        font-family: Microsoft YaHei;
      }
      p {
        float: right;
        width: 90px;
        height: 40px;
        background: #408df7;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        float: right;
        margin-right: 20px;
        cursor: pointer;
      }
    }
  }
}
//评论区
.history-box {
  margin-bottom: 50px;
  padding: 30px 15%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  .history-title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 29px;
    color: #333333;
    margin: 30px 0;
  }
  .user_top {
    float: left;
    height: 50px;
    line-height: 50px;
    p {
      font-size: 18px;
      color: #333333;
      float: left;
      margin-right: 10px;
    }
    span {
      font-size: 14px;
      color: #999999;
      float: left;
    }
  }
  .history-content {
    .content-item {
      height: 142px;
      width: 100%;
      margin-bottom: 5px;
      .user-info {
        width: 55px;
        height: 100%;
        float: left;
        img {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          object-fit: cover;
        }
        p {
          margin-left: 10px;
          float: left;
        }
      }
      .user-comment {
        border-bottom: 1px solid #f3f3f3;
        margin-left: 60px;
        height: 142px;
        .comment-content {
          width: 100%;
          float: left;
          span {
            font-size: 18px;
            color: #333333;
          }
        }
        .comment-like {
          float: right;
          img {
            float: left;
            width: 23.86px;
            height: 23.06px;
            margin: 0 5px;
            cursor: pointer;
          }
          p {
            float: left;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 21px;
            color: #999999;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
.e7 {
  background: #408df7;
}
.q1 {
  margin-left: 15px !important;
}
.block {
  margin: 40px 45%;
}
</style>