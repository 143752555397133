/*
<template>
  <div class="content-right fl">
    <div class="content-right-tab">
      <p
        :class="{ active: activeTab == 'current' }"
        @click="toggleActiveTab('current', 0)"
      >
        当前仪器
      </p>
      <p
        :class="{ active: activeTab == 'maintain' }"
        @click="toggleActiveTab('maintain', 1)"
      >
        需保养仪器
      </p>
      <p
        :class="{ active: activeTab == 'repair' }"
        @click="toggleActiveTab('repair', 2)"
      >
        需维修仪器
      </p>
    </div>
    <div class="content-current clear" v-if="activeTab == 'current'">
      <div class="instrument-num">
        <p>
          共计设备：<span>{{ Instrument.length }}</span>
        </p>
      </div>
      <div id="allwidth" class="instrument-box clear">
        <div class="add-instrument fl" @click="show_card()">
          <img src="../../assets/img/grzx_but_tj.png" alt="" />
          <p>添加仪器</p>
        </div>
        <div
          class="huadong"
          id="classifyScroll"
          :style="{width:allwidth-205+'px'}"
        >
          <van-icon
            name="arrow"
            class="rightIcon"
            v-show="showRightIcon"
            @click="rightClick"
            v-if="yiru"
            :style="{left:clivWidth-40+'px'}"
          />
          <van-icon
            name="arrow-left"
            class="leftIcon"
            v-show="showLeftIcon"
            @click="leftClick"
            v-if="yiru"
          />
          <div
            class="instrument-list fl classifys"
            :style="{ width: scrollerHeight }"
          >
            <div
              class="list-item"
              v-for="(item, index) in Instrument"
              :key="index"
              :id="i == index ? 'colore' : ''"
              @click="qiehuan(index)"
            >
              <img class="instrument-img" :src="item.instrument_pic" alt="" />
              <p class="instrument-name">{{ item.instrument_name }}</p>
              <p class="begin-time">启用时间：{{ item.start_time }}</p>
              <div class="property-box">
                <p class="property-title">属性</p>
                <p class="property-value">
                  <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                      {{ state[item.is_status]
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(itemm, indexx) in state"
                        :key="indexx"
                        @click.native="equipment_state(index, indexx)"
                        >{{ itemm }}</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="instrument-detail clear" v-if="bigstate" v-bind="bigstate">
        <img :src="bigstate.instrument_pic" alt="" />
        <div class="instrument-info">
          <div class="info-item">
            <p>仪器：</p>
            <p>{{ bigstate.instrument_name }}</p>
          </div>
          <div class="info-item">
            <p>品牌：</p>
            <p>{{ bigstate.instrument_brand }}</p>
          </div>
          <div class="info-item">
            <p>购买时间：</p>
            <p>{{ bigstate.purchase_time }}</p>
          </div>
          <div class="info-item">
            <p>启用时间：</p>
            <p>{{ bigstate.start_time }}</p>
          </div>
          <div class="info-item">
            <p>采购途径：</p>
            <p>{{ bigstate.procurement }}</p>
          </div>
          <div class="info-item-bottom clear">
            <div class="bottom-left">
              <p>使用频率：</p>
              <p>{{ bigstate.use_frequency }}</p>
            </div>
            <div class="bottom-right">
              <p>属性：</p>
              <p>
                <span class="bottom-right-span">
                  {{ state[bigstate.is_status] }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="record-box clear">
        <el-tabs v-model="activeRecord" @tab-click="yqstate">
          <el-tab-pane label="维修记录" name="repair">
            <div class="record-title">
              <p>时间</p>
              <p>工程师</p>
              <p>订单状态</p>
            </div>
            <p class="corder" v-if="!wbrecord.length">暂无维修记录</p>
            <div
              class="record-item"
              v-for="(item, index) in wbrecord"
              :key="index"
            >
              <p>{{ item.end_time }}</p>
              <p>{{ item.eengineer_name }}</p>
              <p>{{ order_status[item.order_status-1] }}</p>
              <div class="clear"></div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="保养记录" name="maintain">
            <div class="record-title">
              <p>时间</p>
              <p>工程师</p>
              <p>订单状态</p>
            </div>
            <p class="corder" v-if="!wbrecord.length">暂无保养记录</p>
            <div
              class="record-item"
              v-for="(item, index) in wbrecord"
              :key="index"
            >
              <p>{{ item.end_time }}</p>
              <p>{{ item.eengineer_name }}</p>
              <p>{{ order_status[item.order_status-1] }}</p>
              <div class="clear"></div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <div class="content-maintain" v-if="activeTab == 'maintain'">
      <div class="maintain-box">
        <p class="maintain-box-span" v-if="!Instrument">暂无数据</p>
        <div
          class="maintain-item"
          v-for="(item, index) in Instrument"
          :key="index"
        >
          <img :src="item.instrument_pic" alt="" />
          <div class="maintain-info">
            <p>仪器：{{ item.instrument_name }}</p>
            <p>品牌：{{ item.instrument_brand }}</p>
            <p>购买时间：{{ item.purchase_time }}</p>
            <p>启用时间：{{ item.start_time }}</p>
            <p>采购途径：{{ item.procurement }}</p>
            <p>使用频率：{{ item.use_frequency }}</p>
            <p class="maintain-btn" @click="gouHerf(1, item)">去保养</p>
          </div>
        </div>
      </div>
      <div class="maintain-page" v-if="listnum>10">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listnum"
          @current-change="changee"
        >
        </el-pagination>
      </div>
    </div>
    <div class="content-repair" v-if="activeTab == 'repair'">
      <div class="maintain-box">
        <p class="maintain-box-span" v-if="!Instrument">暂无数据</p>
        <div
          class="maintain-item"
          v-for="(item, index) in Instrument"
          :key="index"
        >
          <img :src="item.instrument_pic" alt="" />
          <div class="maintain-info">
            <p>仪器：{{ item.instrument_name }}</p>
            <p>品牌：{{ item.instrument_brand }}</p>
            <p>购买时间：{{ item.purchase_time }}</p>
            <p>启用时间：{{ item.start_time }}</p>
            <p>采购途径：{{ item.procurement }}</p>
            <p>使用频率：{{ item.use_frequency }}</p>
            <p class="maintain-btn" @click="gouHerf(2, item)">去维修</p>
          </div>
        </div>
      </div>
      <div class="maintain-page" v-if="listnum>10">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listnum"
          @current-change="changee"
        >
        </el-pagination>
      </div>
    </div>

    <van-popup v-model="show" class="pop">
      <img
        class="pop_img1 fr"
        src="../../assets/img/pop_but_gb.png"
        @click="shows"
        v-if="show"
      />
      <div class="pop-title">
        <p>添加仪器</p>
        <p>
          <!-- <el-upload
            class="upload-demo"
            action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/Company/analysis"
            name="file_url"
            :data="{ token: this.token, type: 1 }"
            accept=".xls,.xlsx"
            multiple
            :on-success="chenggong"
            :show-file-list="false"
            :limit="1"
          > -->
          <el-upload
            class="upload-demo"
            action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/Company/analysis"
            name="file_url"
            :data="{ token: this.token, type: 1 }"
            accept=".xls,.xlsx"
            multiple
            :on-success="chenggong"
            :show-file-list="false"
            :limit="1"
          >
            <el-button size="small" type="primary">
              <img
                class="frrr"
                src="../../assets/img/kc_icon_dr_n.png"
                alt=""
              />
              导入
            </el-button>
          </el-upload>
        </p>
        <p @click="xiazmoban">下载模版</p>
      </div>

      <div class="pop-div2">
        <p class="pop-div2-img">仪器照片</p>
        <van-uploader
          :after-read="upLoad"
          accept="image/*"
          name="file"
          :max-count="1"
          class="fr q2 widtt"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <img v-else class="phice" src="../../assets/img/pj_but_tjts.png" />
        </van-uploader>
        <div class="pop-instrument-name">
          <p>仪器名称</p>
          <input
            type="text"
            v-model="instrument_name"
            placeholder="请填写仪器名称"
          />
        </div>
        <div class="pop-instrument-name">
          <p>品牌</p>
          <input
            type="text"
            v-model="instrument_brand"
            placeholder="请填写品牌"
          />
        </div>
        <div class="pop-instrument-name fl">
          <p>仪器序列号</p>
          <input
            type="text"
            @click="state1"
            v-model="instrument_num"
            placeholder="请填写仪器序列号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>型号</p>
          <input
            type="text"
            v-model="instrument_size"
            placeholder="请填写型号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>仪器类型</p>
          <div @click="state2">
            <input
              type="text"
              v-model="instrument_cat"
              placeholder="请选择仪器类型"
              id="in"
              disabled="disabled"
            />
            <div
              class="zhansh"
              :style="{ width: wid }"
              v-if="zhansh"
              @mouseenter="enterr()"
              @mouseleave="leavee()"
            >
              <div
                class="fenlei"
                v-for="(item, index) in fenlei"
                :key="index"
                @click="neirong(item)"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pop-instrument-name fl">
          <p>采购时间</p>
          <div class="block">
            <el-date-picker
              v-model="purchase_time"
              type="date"
              placeholder="请填写采购时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="pop-instrument-name">
          <p>采购金额</p>
          <input
            type="text"
            v-model="purchase_money"
            onkeyup="value=value.replace(/[^\d.]/g,'')"
            placeholder="请填写采购金额"
          />
        </div>
        <div class="pop-instrument-name">
          <p>启用时间</p>
          <div class="block">
            <el-date-picker
              v-model="start_time"
              type="date"
              placeholder="请填写启用时间"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="pop-instrument-name fl">
          <p>采购途径</p>
          <input
            type="text"
            v-model="procurement"
            placeholder="请填写采购途径"
          />
        </div>
        <div class="pop-instrument-name">
          <p>使用频率</p>
          <input
            type="text"
            v-model="use_frequency"
            placeholder="请填写使用频率"
          />
        </div>
        <div class="pop-instrument-name">
          <p>使用者</p>
          <input
            type="text"
            v-model="instrument_user"
            placeholder="请填写使用者"
          />
        </div>
        <div class="pop-instrument-name fl">
          <p>存放实验室</p>
          <input
            type="text"
            v-model="lab_room"
            placeholder="请填写存放实验室"
          />
        </div>
        <div class="pop-instrument-name">
          <p>房间号</p>
          <input
            type="text"
            v-model="lab_room_num"
            placeholder="请填写房间号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>管理人</p>
          <input type="text" v-model="lab_respon" placeholder="请填写管理人" />
        </div>
        <div class="add-content-div" @click="add">
          <el-button class="add-content" type="text">添加</el-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  userinstrument_list,
  records,
  add_instrument,
  template,
  instrument_status,
} from "../../api/User";
// import XLSX from "xlsx";
export default {
  data() {
    return {
      activeTab: "current",
      activeRecord: "repair",
      token: "",
      index: 0, //全部、待维修、待保养
      p:1,
      Instrument: [], //获取参数
      state: ["正常使用", "待保养", "待维修"], //仪器状态
      bigstate: {}, //仪器详情大图
      wbrecord: [], //仪器维修保养记录
      order_status: ["已提交", "已接单", "已拒绝", "已完成", "已评价"],
      show: false,
      i: 1, //选中商品边框变蓝
      instrument_name: "", //仪器名称
      instrument_brand: "", //仪器品牌
      instrument_num: "", //仪器序列号
      instrument_size: "", //型号
      instrument_cat: "", //仪器类型
      purchase_time: "", //采购时间
      purchase_money: "", //采购金额
      start_time: "", //开始时间
      procurement: "", //采购途径
      use_frequency: "", //使用频率
      instrument_user: "", //使用者
      lab_room: "", //实验室
      lab_room_num: "", //实验室房间号
      lab_respon: "", //管理人
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0,
      imageUrl: "", //上传图片地址
      yiru: false,
      zhansh: false,
      fenlei: ["教学", "科研", "分析检测", "其他"],
      showRightIcon: true,
      listnum:0,
      lastItemOffsetLeft:'',
      allwidth:''
    };
  },
  computed: {
    showLeftIcon() {
      return this.clickNum > 0;
    },
    scrollerHeight: function () {
      return this.Instrument.length * 212 + "px";
    },
    wid() {
      return this.fenlei.length * 51 + "px";
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.UserList(0);
    // this.alwidth()
  },
  mounted(){
    this.alwidth()
  },
  methods: {
    alwidth(){
      this.allwidth = document.getElementById('allwidth').clientWidth;
      console.log(this.allwidth)
    },
    //切换 当前 需保养 需维修
    toggleActiveTab(activeTab, is_status) {
      this.Instrument = {};
      this.index = is_status;
      if (activeTab != this.activeTab) {
        this.activeTab = activeTab;
      }
      this.UserList();
    },
    //获取参数
    UserList(i) {
      const data = {
        token: this.token,
        is_status: this.index,
        p:this.p
      };
      userinstrument_list(data).then((res) => {
        console.log(res.result.list)
        if(res.result.list!=undefined){
            this.Instrument = res.result.list;
        }
        // console.log(res.result,'result');
        this.listnum = res.result&&res.result.count?res.result.count:0
        if (res.result.count<10) {
          document.getElementsByClassName('maintain-page').display = 'none'
        }
        if (i == 0 && this.Instrument!=undefined) {
          this.qiehuan(i);
        }
      });
    },
    //切换当前设备大图
    qiehuan(i) {
      this.i = i;
      this.bigstate = this.Instrument[i];
      this.activeRecord = "repair";
      this.record(1);
    },
    //维修/保养
    record(num) {
      const data = {
        id: this.Instrument[this.i].id,
        instrument_dispose: num,
        p: 1,
      };
      records(data).then((res) => {
        this.wbrecord = res.result.orderlist;
      });
    },
    //显示隐藏添加图片弹窗
    show_card() {
      (this.instrument_name = ""),
        (this.instrument_brand = ""),
        (this.instrument_num = ""),
        (this.instrument_size = ""),
        (this.instrument_cat = ""),
        (this.purchase_time = ""),
        (this.purchase_money = ""),
        (this.start_time = ""),
        (this.procurement = ""),
        (this.use_frequency = ""),
        (this.instrument_user = ""),
        (this.lab_room = ""),
        (this.lab_room_num = ""),
        (this.lab_respon = ""),
        (this.imageUrl = "");
      this.show = true;
    },
    //添加设备参数
    add() {
      if (!this.imageUrl) {
        return this.$message.error('请上传仪器照片！');
      }
      if (!this.instrument_name) {
        return this.$message.error('请填写仪器名称！');
      }
      if (!this.instrument_brand) {
        return this.$message.error('请填写仪器品牌！');
      }
      if (!this.instrument_brand) {
        return this.$message.error('请填写仪器品牌！');
      }
      if (!this.instrument_num) {
        return this.$message.error('请填写仪器序列号！');
      }
      if (!this.instrument_size) {
        return this.$message.error('请填写仪器型号！');
      }
      if (!this.instrument_cat) {
        return this.$message.error('请选择仪器类型！');
      }
      if (!this.purchase_time) {
        return this.$message.error('请选择采购时间！');
      }
      if (!this.purchase_money) {
        return this.$message.error('请填写采购金额！');
      }
      if (!this.start_time) {
        return this.$message.error('请选择启用时间！');
      }
      if (!this.procurement) {
        return this.$message.error('请填写采购途径！');
      }
      if (!this.use_frequency) {
        return this.$message.error('请填写使用频率！');
      }
      if (!this.instrument_user) {
        return this.$message.error('请填写使用者！');
      }
      if (!this.lab_room) {
        return this.$message.error('请填写存放实验室！');
      }
      if (!this.lab_room_num) {
        return this.$message.error('请填写房间号！');
      }
      if (!this.lab_respon) {
        return this.$message.error('请填写管理人！');
      }
      const data = {
        token: this.token,
        instrument_name: this.instrument_name,
        instrument_brand: this.instrument_brand,
        instrument_num: this.instrument_num,
        instrument_size: this.instrument_size,
        instrument_cat: this.instrument_cat,
        purchase_time: this.purchase_time,
        purchase_money: this.purchase_money,
        start_time: this.start_time,
        procurement: this.procurement,
        use_frequency: this.use_frequency,
        instrument_user: this.instrument_user,
        lab_room: this.lab_room,
        lab_room_num: this.lab_room_num,
        lab_respon: this.lab_respon,
        instrument_pic: this.imageUrl,
      };
      add_instrument(data).then((res) => {
        if (res.status == 1) {
          this.show = false;
          this.UserList();
          this.$alert(res.msg, "提示", {
            confirmButtonText: "确定",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    state1() {
      this.code = false;
    },
    state2() {
      this.type = false;
      this.zhansh = true;
    },
    // 点击右箭头（左侧滚动）
    rightClick() {
      // 获取最后一个元素距离左侧的距离
      this.lastItemOffsetLeft = document.getElementsByClassName("list-item")[
          this.Instrument.length - 1
        ].offsetLeft;
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.Instrument.length) {
        // 获取当前元素宽度
        let width =
          document.querySelectorAll(".list-item")[this.clickNum].offsetWidth +
          10;
        // 获取可视区域宽度
        const lookWidth = document.getElementById("classifyScroll").clientWidth;
        // 如果最后一个元素距离左侧的距离大于可视区域的宽度，表示最后一个元素没有出现，执行滚动效果

        if (this.lastItemOffsetLeft > lookWidth) {
          // 公示：滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName("classifys")[0].style.marginLeft = `${
            -width + this.lastLeft
          }px`;
          console.log(this.lastLeft)
          this.lastLeft = -width + this.lastLeft;
          // 点击次数+1
          this.clickNum += 1;
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum;

          if (this.clickNum > this.Instrument.length - 5) {
            this.showRightIcon = false;
            return;
          }
        }
        this.showRightIcon = this.lastItemOffsetLeft > lookWidth + width;
      }
      // if (this.allwidth>this.lastItemOffsetLeft) {
      //   let jul = 200-(this.allwidth-this.lastItemOffsetLeft)
      //   document.getElementsByClassName("classifys")[0].style.marginLeft = `${
      //       this.lastLeft+jul
      //     }px`;
      //   this.clickNum += 1;
      //   this.showRightIcon = true;
      //   this.yiru = true;
      //   return
      // }
    },
    // 点击左箭头（右侧滚动）
    leftClick() {
      if (this.clickNum > 0) {
        // 获取当前元素宽度
        let width =
          document.querySelectorAll(".list-item")[this.clickNum - 1]
            .offsetWidth + 10;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        document.getElementsByClassName("classifys")[0].style.marginLeft = `${
          this.lastLeft + width
        }px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-1
        this.clickNum--;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      }
    },
    upLoad(file) {
      const formData = new FormData();
      formData.append("img_file", file.file);
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic",
          formData,
          config
        )
        .then((res) => {
          this.imageUrl = res.data.result.url;
        });
    },
    shows() {
      this.imageUrl = "";
      return (this.show = false);
    },
    enter() {
      this.yiru = true
      console.log(this.clickNum)
      this.lastItemOffsetLeft = document.getElementsByClassName("list-item")[
          this.Instrument.length - 1
        ].offsetLeft;
      this.clivWidth = document.getElementById('classifyScroll').clientWidth;
      if (this.allwidth-280<this.lastItemOffsetLeft) {
        console.log(666)
        this.showRightIcon = true;
        this.yiru = true;
      }
      
      // if (this.Instrument.length < 4) {
      //   this.showRightIcon = false;
      // } else {
      //   this.showRightIcon = true;
      // }
    },
    leave() {
      this.yiru = false;
    },

    //下载模版
    xiazmoban() {
      const data = {
        type: 5,
      };
      template(data).then((res) => {
        const url = "http://" + res.data;
        window.open(url);
      });
    },
    enterr() {
      this.zhansh = true;
    },
    leavee() {
      this.zhansh = false;
    },
    neirong(item) {
      this.instrument_cat = item;
    },
    chenggong(response, file, fileList) {
      this.$message({
        message: "批量导入成功！",
        type: "success",
      });
    },
    yqstate(tab) {
      if (tab.name == "repair") {
        this.record(1);
      } else {
        this.record(2);
      }
    },
    equipment_state(index, status) {
      this.bigstate.is_status = status;
      var id = this.Instrument[index].id;
      var data = {
        id,
        status,
      };
      instrument_status(data).then((res) => {
        if (res.status == 1) {
          this.$message.success(res.info);
          this.UserList();
        }
      });
    },
    gouHerf(index, item) {
      if (index == 1) {
        this.$router.push({
          name: "Maintain",
          params: {
            index: 1,
            item: item,
          },
        });
      } else {
        this.$router.push({
          name: "Maintain",
          params: {
            index: 2,
            item: item,
          },
        });
      }
    },
    changee(index){
      this.p = index
      this.UserList();
    }
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-right-tab {
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #eeeeee;
    p {
      cursor: pointer;
      width: 90px;
      height: 70px;
      line-height: 70px;
      text-align: center;
      margin: 0 35px;
      float: left;
    }
    p.active::after {
      background-color: #408df7;
      width: 42px;
      height: 2px;
      content: " ";
      display: inline-block;
      position: relative;
      top: -58%;
      left: 0;
    }
  }
  .content-current {
    padding: 40px 30px;
    .instrument-num {
      p {
        float: right;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding: 15px 0;
        color: #333333;
        span {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #408df7;
        }
      }
    }
    .instrument-box {
      padding: 15px 0 30px 0;
      .add-instrument {
        width: 200px;
        height: 300px;
        background: #f5f5f5;
        border: 1px solid #cccccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          padding: 20px;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
        }
      }
      .instrument-list {
        .list-item {
          float: left;
          width: 200px;
          height: 300px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          padding: 10px;
          margin: 0 5px;
          .instrument-img {
            width: 180px;
            height: 180px;
            object-fit: cover;
          }
          .instrument-name {
            height: 50px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 22px;
          }
          .category {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 22px;
            color: #333333;
          }
          .begin-time {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 26px;
            color: #999999;
          }
          .property-box {
            border-top: 1px solid #eeeeee;
            .property-title {
              float: left;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 26px;
              color: #999999;
            }
            .property-value {
              float: right;
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 26px;
              color: #999999;
              img {
                margin-left: 5px;
                width: 10px;
                height: 5px;
              }
            }
          }
        }
        .list-item:hover {
          border: 1px solid #408df7;
        }
      }
    }
    .instrument-detail {
      padding: 30px 0;
      border-top: 1px solid #eeeeee;
      img {
        float: left;
        width: 300px;
        height: 300px;
        object-fit: cover;
      }
      .instrument-info {
        float: left;
        margin-left: 80px;
        .info-item {
          height: 50px;
          p {
            float: left;
          }
          p:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 28px;
            line-height: 50px;
            color: #999999;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 28px;
            line-height: 50px;
            color: #333333;
          }
        }
        .info-item-bottom {
          width: 100%;
          .bottom-left {
            width: 324px;
            float: left;
          }
          .bottom-right {
            float: right;
            p {
              position: relative;
              span {
                float: left;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
                margin-right: 5px;
              }
              img {
                float: right;
                width: 10px;
                height: 5px;
                position: absolute;
                top: 50%;
              }
            }
          }
          p {
            float: left;
          }
          p:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 28px;
            line-height: 50px;
            color: #999999;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 28px;
            line-height: 50px;
            color: #333333;
            .bottom-right-span {
              margin-left: 195px;
            }
          }
        }
      }
    }
    .record-box {
      padding: 30px 0;

      .record-title {
        height: 50px;
        background-color: #efefef;
        padding-left: 17px;
        margin: 5px 0;
        text-align: center;
        p:nth-child(1) {
          width: 15%;
        }
        p:nth-child(2) {
          width: 70%;
        }
        p:nth-child(3) {
          width: 15%;
        }
        p {
          float: left;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 50px;
          color: #333333;
        }
      }
      .record-item {
        border-bottom: 1px solid #eeeeee;
        padding: 20xp 0 15px 0;
        text-align: center;
        padding-left: 17px;
        p:nth-child(1) {
          width: 15%;
          line-height: 56px;
        }
        p:nth-child(2) {
          width: 70%;
          line-height: 56px;
        }
        p:nth-child(3) {
          width: 15%;
          line-height: 56px;
        }
        p {
          float: left;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 28px;
          color: #333333;
        }
      }
    }
  }
  .content-maintain,
  .content-repair {
    float: left;
    padding: 25px;
    width: 100%;
    .maintain-box {
      // width: 99%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: flex-start;
      flex-wrap: wrap;
      .maintain-item:nth-child(odd) {
        margin: 1% 1% 1% 0;
      }
      .maintain-item:nth-child(even) {
        margin: 1% 0 1% 1%;
      }
      .maintain-item {
        width: 48%;
        height: 260px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        padding: 15px;
        img {
          margin-right: 20px;
          width: 45%;
          max-width: 223px;
          height: 223px;
          float: left;
        }
        .maintain-info {
          float: left;
          width: 50%;
          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 28px;
            color: #333333;
            white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;
          }
          p.maintain-btn {
            width: 100px;
            margin-top: 10px;
            cursor: pointer;
            height: 45px;
            border: 2px solid #408df7;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 45px;
            text-align: center;
            color: #408df7;
          }
        }
      }
    }
  }
  .maintain-page {
    margin: 40px 0;
    display: flex;
    justify-content: center;
  }
}
.corder {
  text-align: center;
}
.maintain-box-span {
  width: 100%;
  text-align: center;
}
.pop {
  z-index: 2000 !important;
  width: 1200px;
  height: 864px;
  padding: 0 40px;
  border-radius: 5px;
  .pop_img1 {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .pop_img1:hover {
    cursor: pointer;
  }
  .pop-title {
    height: 45px;
    width: 100%;
    margin-top: 43px;
    p:nth-child(1) {
      float: left;
      width: 88px;
      height: 29px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #333333;
      opacity: 1;
    }
    p:nth-child(2) {
      input {
        display: none;
      }
      text-align: center;
      float: right;
      width: 93px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 45px;
      color: #408df7;
      opacity: 1;

      .frrr {
        margin-bottom: -2px;
        line-height: 45px;
        width: 17px;
        height: 16px;
        opacity: 1;
      }
    }
    p:nth-child(3) {
      text-align: center;
      float: right;
      width: 93px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 45px;
      color: #408df7;
      opacity: 1;
      margin-right: 20px;
    }
    p:nth-child(3):hover {
      cursor: pointer;
    }
  }
  .pop-title p:nth-child(2):hover {
    cursor: pointer;
  }
  .pop-div2 {
    margin-top: 60px;
    height: 100px;
    .pop-div2-img {
      float: left;
      margin-left: 16px;
      width: 64px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 100px;
      color: #333333;
      opacity: 1;
      margin-right: 101px;
    }
    .phice {
      float: left;
      width: 150px;
      height: 100px;
    }
    .pop-instrument-name {
      float: left;
      height: 100px;
      width: 331px;
      margin-left: 46px;
      margin-bottom: 26px;
      input {
        width: 100%;
        height: 55px;
        background: #ffffff;
        border: 1px solid #cccccc;
        opacity: 1;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 55px;
        opacity: 1;
        padding-left: 16px;
      }
      p {
        float: left;
        width: 45%;
        margin-left: 16px;
        margin-top: 4px;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        float: left;
        color: red;
      }
    }
    .fl {
      margin-left: 0px;
    }
    .add-content-div {
      width: 100%;
      float: left;
      text-align: center;
      .add-content {
        margin: 0 auto;
        width: 300px;
        height: 60px;
        background: #408df7;
        opacity: 1;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #ffffff;
        opacity: 1;
        border-radius: 3px;
      }
    }
    .add-content-div:hover {
      cursor: pointer;
    }
  }
}
/deep/.el-carousel__container button {
  border-radius: 0;
}
/deep/.el-carousel__arrow--left {
  left: 5px;
}
/deep/.el-carousel__arrow--right {
  right: 9px;
}
.huadong {
  float: left;
  // width: 840px;
  overflow-y: hidden;
  position: relative;
}
// 这里是用的scss
@function px($px) {
  @return $px * 2px;
}
#classifyScroll {
  // width: 80%;
  overflow: hidden;
  transition: all 0.3s;
  &.active {
    transition: all 0.3s;
    width: 90%;
    margin: 0 auto;
  }
  .classifys {
    // 注意一下，这里没有用position: relative，，而图标用了position:absolute。因为这个东西是准备做组件的，我把相对定位写在父级的css标签上了。
    transition: all 0.3s;
    display: flex;
    align-items: center;
    .rightIcon {
      position: absolute;
      right: px(-5);
      bottom: 47%;
      transform: translate(0, 50%);
      z-index: 9;
    }
    .leftIcon {
      position: absolute;
      left: px(-5);
      bottom: 47%;
      transform: translate(0, 50%);
      z-index: 9;
    }
    &-item {
      font-size: px(11);
      font-weight: 400;
      color: #ffffff;
      line-height: px(16);
      // margin-right: px(52);
      padding-right: px(52);
      white-space: nowrap;
    }
  }
}

.van-icon-arrow-left::before {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  // position: absolute;
  left: 5px;
  top: 130px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}
.van-icon-arrow::before {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  // position: absolute;
  // right: -836px;
  top: 130px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}
/deep/.van-icon {
  position: absolute;
  z-index: 10;
}
#colore {
  border: 1px solid #408df7;
}
/deep/.van-uploader {
  float: left;
}
#in {
  width: 331px;
}
.el {
  width: 30px;
  div {
    height: 400px;
    overflow: scroll;
    overflow: hidden;
  }
}
.zhansh {
  overflow: hidden;
  padding: 0 20px;
  background: #ffffff;
  position: relative;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  z-index: 2111;
}
.fenlei {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  span {
    margin-left: 20px;
  }
}
/deep/.el-button--primary {
  color: #408df7;
  border-color: #fff;
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--primary:hover {
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--small,
.el-button--small.is-round {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  width: 89px;
  height: 45px;
  border-radius: 7px;
  margin-top: -2px;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-button--primary:focus {
  background: rgba(0, 0, 0, 0);
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-message--success {
  z-index: 10000;
}
/deep/.van-overlay {
  z-index: 200 !important;
}
.avatar {
  width: 150px;
  object-fit: cover;
  height: 100px;
}

/deep/.el-input--suffix .el-input__inner {
  padding-right: 30px;
  height: 55px !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  cursor: pointer;
  width: 100%;
}
.widtt {
  width: 150px;
}
.el-dropdown-link {
  font-size: 12px;
  color: #408df7;
}
.property-value {
  cursor: pointer;
}
.van-icon-arrow {
  // position: absolute;
  z-index: 10;
  width: 40px;
  height: 40px;
  top: 40%;
  background: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;

}
.van-icon-arrow-left {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  // position: absolute;
  left: 6px;
  top: 40%;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  z-index: 10;
}
#classifyScroll{
  overflow-x: scroll;
}
</style>
