<template>
  <div class="content-right fl">

    <div class="content-title">
      <p>我的工程师</p>
    </div>
        <div v-if="list.length<1" class="m1">暂无工程师</div>
    <div class="engineer-num">
      <p>
        共计工程师：<span>{{ list.length }}</span>
      </p>
    </div>
    <div
      class="engineer-box clear"
      id="classifyScroll"
    >
      <van-icon
        name="arrow"
        class="rightIcon"
        v-show="Instrumente"
        @click="rightClick"
        v-if="yiru"
      />
      <van-icon
        name="arrow-left"
        class="leftIcon"
        v-show="showLeftIcon"
        @click="leftClick"
        v-if="yiru"
      />
      <div class="classifys" :style="{ width: scrollerHeight }">
        <div
          class="enginner-item"
          v-for="(item, index) in list"
          :key="index"
          :class="[en_id == item.engineer_id ? 't9' : '']"
          @click="chose(item.engineer_id)"
        >
          <img :src="item.head_pic" alt="" />
          <div class="enginner-card">
            <div class="name-mobile">
              <p class="fl">{{ item.user_name }}</p>
              <p class="fr">{{ item.mobile }}</p>
              <div class="clear"></div>
            </div>
            <el-divider></el-divider>
            <div class="service-praise clear">
              <p class="fl">成功服务：{{ item.servecount }}次</p>
              <p class="fr">好评率：{{item.rate}}%</p>
            </div>
            <p class="major clear">{{item.serve_item}}</p>
            <el-divider></el-divider>
            <p class="company">{{ Info.company_name  }}</p>
          </div>
        </div>
      </div>
      <!-- <div class="clear"></div> -->
    </div>
    <el-divider class="clear"></el-divider>
    <div class="engineer-detail clear" v-if="list.length">
      <img :src="Info.head_pic" alt="" />
      <div class="engineer-info">
        <div class="info-item">
          <div class="fl">
            <p class="fl">姓名：</p>
            <p class="fl">{{ Info.user_name }}</p>
          </div>
          <div class="fr">
            <p class="fl">手机号：</p>
            <p class="fl">{{ Info.mobile }}</p>
          </div>
        </div>
        <div class="info-item clear">
          <div class="fl">
            <p class="fl">单位：</p>
            <p class="fl">{{ Info.company_name }}</p>
          </div>
          <div class="fr">
            <p class="fl">服务次数：</p>
            <p class="fl">{{ Info.servecount }} 次</p>
          </div>
        </div>
        <div class="info-item clear">
          <!-- <div>
            <p class="fl">地址：</p>
            <p class="fl">{{ Info.user_address }}</p>
          </div> -->
        </div>
        <div class="info-item clear">
          <!-- <div class="fl">
            <p class="fl">座机号：</p>
            <p class="fl">{{ Info.mobile }}</p>
          </div> -->
          
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="record-title clear" v-if="list.length">
      <p>仪器</p>
      <p>时间</p>
      <p>服务类型</p>
    </div>
    <div
      class="record-item clear"
      v-for="(item, index) in orderList"
      :key="index"
      
    >
      <div class="pic-name fl">
        <img :src="item.pic" alt="" />
        <p>{{ item.instrument_name }}</p>
      </div>
      <p class="service-time fl">{{ item.add_time }}</p>
      <p class="service-type fl"  v-if="item.instrument_dispose==1">仪器维修</p>
      <p class="service-type fl"  v-if="item.instrument_dispose==2">仪器保养</p>
      <p class="service-type fl"  v-if="item.instrument_dispose==3">安装调试</p>
      <p class="service-type fl"  v-if="item.instrument_dispose==4" >我的实验室</p>
      <p class="service-type fl"  v-if="item.instrument_dispose==5">我的实验室方案</p>
      <div class="clear"></div>
    </div>
  </div>
</template>
<script>
import { my_engineer, gcs_info } from "../../api/User";
export default {
  data() {
    return {
      token: "",
      p: 1,
      list: [],
      yiru: false,
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0,
      showRightIcon: true,
      en_id: "",
      Info: "",
      orderList: [],
    };
  },
  computed: {
    showLeftIcon() {
      return this.clickNum > 0;
    },
    Instrumente() {
      if (this.list.length > 3) {
        return true;
      }
      return false;
    },
    scrollerHeight: function () {
      return this.list.length * 360 + "px";
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
    this.getInfo(); //工程师详情
  },
  methods: {
    getInfo() {
      gcs_info({ engineer_id: this.en_id }).then((res) => {
        if (res.status == 1) {
          this.orderList = res.result.orderlist;
          // console.log(res)
          this.Info = res.result.userinfo;
        }
      });
    },

    chose(id) {
      this.en_id = id;
      this.getInfo();
    },
    rightClick() {
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.list.length - 1) {
        // 获取当前元素宽度
        let width =
          document.querySelectorAll(".enginner-item")[this.clickNum]
            .offsetWidth + 10;
        // 获取最后一个元素距离左侧的距离
        let lastItemOffsetLeft = document.getElementsByClassName(
          "enginner-item"
        )[this.list.length - 1].offsetLeft;
        // 获取可视区域宽度
        const lookWidth = document.getElementById("classifyScroll").clientWidth;
        // 如果最后一个元素距离左侧的距离大于可视区域的宽度，表示最后一个元素没有出现，执行滚动效果
        if (lastItemOffsetLeft > lookWidth) {
          // 公示：滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName("classifys")[0].style.marginLeft = `${
            -width + this.lastLeft
          }px`;
          this.lastLeft = -width + this.lastLeft;
          // 点击次数+1
          this.clickNum += 1;
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum;
          if (this.clickNum > this.list.length) {
            this.showRightIcon = false;
            return;
          }
        }
        this.showRightIcon = lastItemOffsetLeft > lookWidth + width;
      }
    },
    // 点击左箭头（右侧滚动）
    leftClick() {
      if (this.clickNum > 0) {
        // 获取当前元素宽度
        let width =
          document.querySelectorAll(".enginner-item")[this.clickNum - 1]
            .offsetWidth + 10;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        document.getElementsByClassName("classifys")[0].style.marginLeft = `${
          this.lastLeft + width
        }px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-1
        this.clickNum--;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      }
    },
    enter() {
      this.yiru = true;
    },
    leave() {
      this.yiru = false;
    },

    getList() {
      my_engineer({ token: this.token, p: this.p }).then((res) => {
        if (res.status == 1) {
          this.list = res.result;
          this.en_id = res.result[0].engineer_id;
           this.getInfo();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  margin-bottom: 20px;
  background-color: #ffffff;
  width: 100%;
  padding: 30px;
  .content-title {
    p {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #333333;
    }
  }
  .engineer-num {
    p {
      float: right;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      span {
        color: #408df7;
      }
    }
  }
  .enginner-item:nth-of-type(2n + 1) {
    margin: 0;
  }
  .engineer-box {
    height: 200px;
    padding: 20px 0;
    width: 100%;
    overflow-y: hidden;
    overflow-x: scroll;
    float: left;
    .enginner-item {
      float: left;
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 20px 15px;
      width: 350px;
      height: 160px;
      margin: 0 10px 20px 0;
      .el-divider--horizontal {
        clear: both;
        margin: 8px 0;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        float: left;
        margin-right: 10px;
      }
      .enginner-card {
        float: left;
        width: 65%;
        .name-mobile {
          p {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 21px;
            color: #333333;
          }
        }
        .service-praise {
          p {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 16px;
            color: #333333;
          }
        }
        .major {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #999999;
        }
        .company {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 16px;
          color: #999999;
        }
      }
    }
  }
  .engineer-detail {
    padding: 20px 0;
    img {
      float: left;
      width: 150px;
      height: 150px;
      border-radius: 50%;
      margin-top: 3%;
    }
    .engineer-info {
      margin-top: 50px;
      float: right;
      width: 75%;
      .info-item {
        div:nth-child(2) {
          margin-right: 10%;
        }
        div {
          height: 50px;
          p:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 50px;
            color: #807575;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 50px;
            color: #333333;
          }
        }
      }
    }
  }
  .record-title {
    height: 50px;
    background-color: #efefef;
    padding: 0 50px;
    margin: 10px 0;
    p {
      float: left;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      color: #333333;
    }
    p:nth-child(1) {
      width: 40%;
    }
    p:nth-child(2) {
      width: 30%;
    }
    p:nth-child(3) {
      width: 30%;
    }
  }
  .record-item {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    color: #333333;
    padding: 10px 15px;
    border-bottom: 1px solid #eeeeee;
    .pic-name {
      width: 40%;
      img {
        float: left;
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      p {
        float: left;
        width: 60%;
        margin-left: 10px;
      }
    }
    .service-time {
      width: 30%;
    }
    .service-type {
      width: 30%;
    }
  }
}
#classifyScroll {
  // overflow: hidden;
  transition: all 0.3s;
  &.active {
    transition: all 0.3s;
    width: 90%;
    margin: 0 auto;
  }
  .classifys {
    overflow: hidden;
    float: left;
    // 注意一下，这里没有用position: relative，，而图标用了position:absolute。因为这个东西是准备做组件的，我把相对定位写在父级的css标签上了。
    transition: all 0.3s;
    .rightIcon {
      position: absolute;
      right: 0;
      bottom: 47%;
      transform: translate(0, 50%);
      z-index: 9;
    }
    .leftIcon {
      position: absolute;
      left: px(-5);
      bottom: 47%;
      transform: translate(0, 50%);
      z-index: 9;
    }
    &-item {
      font-size: px(11);
      font-weight: 400;
      color: #ffffff;
      line-height: px(16);
      // margin-right: px(52);
      padding-right: px(52);
      white-space: nowrap;
    }
  }
}
.van-icon-arrow-left::before {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 50px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}
.van-icon-arrow::before {
  width: 40px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -1024px;
  top: 55px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
}
/deep/.van-icon {
  position: absolute;
  z-index: 10;
}
#colore {
  border: 1px solid #408df7;
}
/deep/.van-uploader {
  float: left;
}
#in {
  width: 331px;
}
.el {
  width: 30px;
  div {
    height: 400px;
    overflow: scroll;
    overflow: hidden;
  }
}
.zhansh {
  overflow: hidden;
  padding: 0 20px;
  background: #ffffff;
  position: relative;
  border: 1px solid #e6e6e6;
  background: #ffffff;
}
.fenlei {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  span {
    margin-left: 20px;
  }
}
/deep/.el-button--primary {
  color: #408df7;
  border-color: #fff;
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--primary:hover {
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--small,
.el-button--small.is-round {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  width: 89px;
  height: 45px;
  border-radius: 7px;
  margin-top: -2px;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-button--primary:focus {
  background: rgba(0, 0, 0, 0);
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-message--success {
  z-index: 10000000;
}
/deep/.van-overlay {
  z-index: 200 !important;
}
.t9 {
  border: 1px solid #408df7 !important;
}
</style>