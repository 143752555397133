import { post } from "../util/index";

//我的同学、学生列表(1)
export function Friendlist(query) {
  return post("UserInstrument/pc_userFriendList", query);
}

//搜索同学、学生列表(1)
export function Search(query) {
  return post("UserInstrument/search_user", query);
}

//添加同学、学生(1)
export function addFriend(query) {
  return post("UserInstrument/addUserFriend", query);
}
//删除同学、学生(1)
export function delFriend(query) {
  return post("UserInstrument/delUserFriend", query);
}
//单位资料修改(1)
export function upUnit(query) {
  return post("User/upUserUnit", query);
}
//我的地址列表(1)
export function addressList(query) {
  return post("User/getAddressList", query);
}
//添加地址列表(1)
export function add_ressList(query) {
  return post("User/addHanziAddress", query);
}
//删除地址列表(1)
export function del_ressList(query) {
  return post("User/del_address", query);
}

//设置默认地址列表(1)
export function set_ress(query) {
  return post("User/setDefaultAddress", query);
}

//编辑地址，地址详情（1）
export function redact_ress(query) {
  return post("User/useraddressinfo", query);
}

//用户仪器列表
export function userinstrument_list(query) {
  return post("InstrumentData/userinstrument_list", query);
}

//仪器维修保养记录
export function records(query) {
  return post("UserInstrument/userOrderinstrument", query);
}

//用户仪器添加/修改
export function add_instrument(query) {
  return post("InstrumentData/userinstrument_add", query);
}

//我的收藏列表（1）
export function sc_list(query) {
  return post("User/sc_list", query);
}

//我的收藏取消（1）
export function sc_qx(query) {
  return post("User/cancel_sc", query);
}
//我的订单删除（1）
export function del_order(query) {
  return post("user/order_del", query);
}

//我的订单取消（1）
export function qu_order(query) {
  return post("user/cancelOrder", query);
}

//订单详情（1）
export function order_Details(query) {
  return post("user/getOrderDetail", query);
}
//确认收货（1）
export function orderConfirm(query) {
  return post("User/orderConfirm", query);
}
//物流信息（1）
export function wl_info(query) {
  return post("User/getExpressInfo", query);
}
//我的工程师（1）
export function my_engineer(query) {
  return post("InstrumentData/user_engineer", query);
}
//评论（1）
export function PL(query) {
  return post("user/add_comment", query);
}

//会员专属商品（1）
export function vipGoods(query) {
  return post("Goods/pc_goodsFine", query);
}
//优惠券（1）
export function getCouponList(query) {
  return post("user/getCouponList", query);
}
//我的单位（1）
export function my_company(query) {
  return post("user/my_company", query);
}

//服务订单详情（1）
export function fw_Info(query) {
  return post("InstrumentData/order_instrument_info", query);
}
//工程师评价（1）
export function pj(query) {
    return post("InstrumentData/instrumentComment", query)
}
//工程师详情（1）
export function gcs_info(query) {
    return post("InstrumentData/user_engineerinfo", query)
}

//库存添加（1）
export function add(query) {
    return post("Company/addinventory", query)
}
//服务订单删除（1）
export function del(query) {
    return post("InstrumentData/deleteorder", query)
}

//我的库存（1）
export function kc(query) {
    return post("Company/userInventoryList", query)
}

//我的库存（1）
export function lose(query) {
  return post("Company/reduce", query)
}

//我的库存模板（1）
export function board(query) {
  return post("Index/template", query)
}

//我的库存导出（1）
export function derive(query) {
  return post("Company/exportInstrument", query)
}

//合同下载（1）
export function contract(query) {
  return post("InstrumentData/template_contract", query)
}










//工程师端
//单子列表（1）
export function monad(query) {
  return post("Engineer/pc_order_instrument_list", query);
}
//单子详情（1）
export function monad_info(query) {
  return post("Engineer/order_instrument_info", query);
}
//添加仪器下载模版
export function template(query) {
  return post("Index/template", query);
}

//仪器管理-二级商品分类
export function goodsCategoryList(query) {
  return post("Goods/goodsCategoryList", query);
}

//仪器管家-三级分类
export function goodsSecAndThirdCategoryList(query) {
  return post("Goods/goodsSecAndThirdCategoryList", query);
}
//取消订单
export function cancel(query) {
  return post("Engineer/order_instrument_status", query);
  
}

//客户列表
export function kh_list(query) {
  return post("Engineer/engineer_client", query);
}
//工程师报价
export function quote(query) {
  return post("InstrumentData/engineer_quote", query);
}

//用户同意/拒绝报价
export function baojia(query) {
    return post('InstrumentData/user_quote', query)
}

//工程师新人专享
export function card(query) {
    return post('user/pcgetCouponList', query)
}

//工程师领取优惠券
export function ling(query) {
    return post('UserInstrument/couponSave', query)
}

//工程师服务历史
export function list(query) {
    return post('Engineer/pc_order_instrument_engineer', query)
}

//仪器管理-状态
export function instrument_status(query){
  return post('UserInstrument/instrument_status',query)
}