<template>
  <div>
    <Top></Top>
    <div class="img_box">
      <div class="img_text">
        <img src="../../assets/img/sysjs_icon.png" />
        <p>智能实验室</p>
        <p class="a2">Laboratory construction scheme</p>
        <img class="a3" src="../../assets/img/top_img_x.png" />
        <div>
          基于物联传感+信息化+大数据，
          提供高端分析仪器仪表、信息化软件、运维服务、
          运营服务、检测服务、咨询服务及环境治理装备等创新产品和业务组合。
        </div>
      </div>
    </div>
    <!-- 内容 -->
    <div class="pd_space_260">
      <div class="content">
        <el-row :gutter="20">
          <el-col :span="6">
            <div class="main clear">
              <div class="left-menu fl">
                <el-menu
                  default-active="1"
                  text-color="#999999"
                  active-text-color="#408DF7"
                  :unique-opened="true"
                >
                  <el-submenu index="1">
                    <template slot="title">
                      <span>实验室管理</span>
                    </template>
                    <el-menu-item index="1-1" @click="chose(1)">数据</el-menu-item>
                    <el-menu-item index="1-2" @click="chose(2)">控制台</el-menu-item>
                  </el-submenu>
                  <el-submenu index="2">
                    <template slot="title">
                      <span>数据报告</span>
                    </template>
                    <el-menu-item index="2-1" @click="chose(3)">数据报告</el-menu-item>
                  </el-submenu>
                </el-menu>
              </div>
            </div>
          </el-col>
          <!-- 右边框 -->
          <el-col :span="18">
            <div v-show="classact == 1" class="box1">
              <div class="box1_1">
                <p>空气质量检测</p>
                <div class="box1_item_box">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <div class="box1_item fl">
                        <img class="img1 fl" src="../../assets/img/sys_icon_wd.png" />
                        <div class="box1_item_title fl">温度</div>
                        <img class="img2 fl" src="../../assets/img/sys_img_xg_b.png" />
                        <div class="box1_item_text fl" style="width:45%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.temperature}}°</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="box1_item fl">
                        <img class="img1 fl" src="../../assets/img/sys_icon_sd.png" />
                        <div class="box1_item_title fl">湿度</div>
                        <img class="img2 fl" src="../../assets/img/sys_img_xg_b.png" />
                        <div class="box1_item_text fl" style="width:45%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.humidity}}°</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="box1_item fl">
                        <img class="img1 fl" src="../../assets/img/sys_icon_jq.png" />
                        <div class="box1_item_title fl">甲醛</div>
                        <img class="img2 fl" src="../../assets/img/sys_img_xg_b.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.ch2o}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <div class="box1_item_list_box">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <div class="box1_item_list fl">
                        <span class="fl">CO2</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.co2}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="box1_item_list fl">
                        <span class="fl">TVOC</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.tvoc}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="box1_item_list fl">
                        <span class="fl">PM2.5</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.pm25}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div class="box1_item_list fl">
                        <span class="fl">PM10</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in areaList" :key="index">
                              <div>{{item.pm10}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="box1_1" style="margin-top: 10px; height: 270px">
                <p>电力</p>
                <div class="electricity_box">
                  <el-row :gutter="10">
                    <el-col :span="6">
                      <div @click="lineVoltage()" class="box1_item_list fl">
                        <span class="fl">电压</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in falList" :key="index">
                              <div>{{item.voltage}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div @click="lineCurrent()" class="box1_item_list fl">
                        <span class="fl">电流</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in falList" :key="index">
                              <div>{{item.current}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div @click="lineElectricPower()" class="box1_item_list fl">
                        <span class="fl">功率</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in falList" :key="index">
                              <div>{{item.electricpower}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="6">
                      <div @click="lineElectricity()" class="box1_item_list fl">
                        <span class="fl">电能</span>
                        <img class="fl" src="../../assets/img/sys_img_xg.png" />
                        <div class="box1_item_text fl" style="width:30%">
                          <el-carousel
                            style="height:100px;"
                            direction="vertical"
                            indicator-position="none"
                            :autoplay="true"
                          >
                            <el-carousel-item v-for="(item,index) in falList" :key="index">
                              <div>{{item.electricity}}</div>
                            </el-carousel-item>
                          </el-carousel>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="box1_1" style="margin-top: 10px; height: 294px">
                <p>门禁</p>
                <div class="entrance">
                  <el-row :gutter="10">
                    <el-col :span="8">
                      <div class="entrance_list fl">
                        <img src="../../assets/img/syssj_icon_kg.png" />
                        <div>
                          <span>开关总数量</span>
                          <ul>888</ul>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="entrance_list1 fl">
                        <div class="left fl">
                          <span>正常</span>
                        </div>
                        <div class="content1 fl">
                          <div>数量</div>
                          <span>999</span>
                        </div>
                        <div class="right fl">
                          <div>占比</div>
                          <span>99%</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="8">
                      <div class="entrance_list1 fr" style="padding-right:20px">
                        <div class="left fl" style="background:#FF0000">
                          <span>异常</span>
                        </div>
                        <div class="content1 fl">
                          <div>数量</div>
                          <span>999</span>
                        </div>
                        <div class="right fl">
                          <div>占比</div>
                          <span>99%</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="box1_1" style="margin-top: 10px; height: 294px">
                <p>摄像头</p>

                <el-col :span="8" class="fl">
                  <EZUIKitJs />
                </el-col>
                <el-col :span="8" class="fl">
                  <EZUIKitJs1 />
                </el-col>
                <el-col :span="8" class="fl">
                  <EZUIKitJs2 />
                </el-col>
              </div>
            </div>

            <div v-show="classact == 2" class="box2">
              <div class="box2_top">
                <p class="fl">控制台</p>
                <div class="fr">保存设置</div>
              </div>
              <div class="box2_content">
                <el-row :gutter="40">
                  <el-col :span="12">
                    <div class="box2_content_left fl">
                      <div class="fl">我的门禁</div>
                      <el-switch
                        class="switch_lead"
                        v-model="value1"
                        active-color="#13ce66"
                        inactive-color="#ebebec"
                        inactive-text="已关闭"
                        active-text="已打开"
                      ></el-switch>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="box2_content_left fl">
                      <div class="fl">我的门禁</div>
                      <el-switch
                        class="switch_lead"
                        v-model="value2"
                        active-color="#13ce66"
                        inactive-color="#ebebec"
                        inactive-text="已关闭"
                        active-text="已打开"
                      ></el-switch>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-show="classact == 3" class="box3">
              <div class="box3_text">选择类型</div>
              <!-- <div class="pull-down" @click="select">
                <p class="fl">{{ selectName }}</p>
                <img class="fr" src="../../assets/img/but_xl_h_n.png" />
              </div>-->

              <el-select v-model="value" placeholder="请选择">
                <el-option
                  v-for="item in pull"
                  :key="item.lab_id"
                  :label="item.lab_title"
                  :value="item.lab_id"
                ></el-option>
              </el-select>

              <div class="synopsis"></div>
              <div class="download" @click="btn()">下载</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <el-dialog title="电压检测数据" :visible.sync="voltageVisible" width="80%" center>
      <div v-if="voltageVisible" id="line-voltage"></div>
    </el-dialog>
    <el-dialog title="电流检测数据" :visible.sync="currentVisible" width="80%" center>
      <div v-if="currentVisible" id="line-current"></div>
    </el-dialog>
    <el-dialog title="功率检测数据" :visible.sync="electricPowerVisible" width="80%" center>
      <div v-if="electricPowerVisible" id="line-electricPower"></div>
    </el-dialog>
    <el-dialog title="电能检测数据" :visible.sync="electricityVisible" width="80%" center>
      <div v-if="electricityVisible" id="line-electricity"></div>
    </el-dialog>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import { Line } from "@antv/g2plot";
import EZUIKitJs from "../../components/EZUIKitJs";
import EZUIKitJs1 from "../../components/EZUIKitJs1";
import EZUIKitJs2 from "../../components/EZUIKitJs2";
// import ViewItemSmall from "./view-item1";
// import ViewItemBig from "./view-item2";
import {
  Voltage,
  Current,
  ElectricPower,
  Electricity,
  getFacility,
  airData,
  storeList,
  storeNew,
} from "../../api/company";
export default {
  components: { Top, Bottom, EZUIKitJs, EZUIKitJs1, EZUIKitJs2 },
  data() {
    return {
      classact: 1,
      pull: [],
      pull_list: false,
      selss: 0,
      selectName: "",
      value2: true,
      value1: true,
      voltageVisible: false,
      currentVisible: false,
      electricPowerVisible: false,
      electricityVisible: false,
      token: "",
      falList: [],
      areaList: "", //空气检测数量
      user_id: "",
      value: "",
    };
  },
  created() {
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    this.selectName = this.pull[0].name;
  },
  mounted() {
    this.getCil();
    this.getarea();
    this.getStore();
  },
  methods: {
    btn() {
      console.log(this.value);
      storeNew({ lab_id: this.value }).then((res) => {
        window.open(res.result);
      });
    },
    getStore() {
      storeList({}).then((res) => {
        this.pull = res.result;
        console.log(res);
      });
    },
    //空气检测数据
    getarea() {
      airData({ user_id: this.user_id }).then((res) => {
        this.areaList = res.data;
      });
    },
    getCil() {
      getFacility({ user_id: this.user_id }).then((res) => {
        this.falList = res.data;
      });
    },
    chose(num) {
      this.classact = num;
    },
    select() {
      this.pull_list = !this.pull_list;
    },
    select1(index, name) {
      this.selss = index;
      this.selectName = name;
      this.pull_list = false;
    },

    lineVoltage() {
      this.voltageVisible = true;
      Voltage({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-voltage", {
            data: res.data,
            xField: "day",
            yField: "voltage",
            seriesField: "name",
            xAxis: {
              tickCount: 10,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineCurrent() {
      this.currentVisible = true;
      Current({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-current", {
            data: res.data,
            xField: "day",
            yField: "current",
            seriesField: "name",
            xAxis: {
              tickCount: 10,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineElectricPower() {
      this.electricPowerVisible = true;
      ElectricPower({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-electricPower", {
            data: res.data,
            xField: "day",
            yField: "electricpower",
            seriesField: "name",
            xAxis: {
              tickCount: 5,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineElectricity() {
      this.electricityVisible = true;
      Electricity({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-electricity", {
            data: res.data,
            xField: "day",
            yField: "electricity",
            seriesField: "name",
            xAxis: {
              tickCount: 5,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.img_box {
  width: 100%;
  height: 400px;
  background: url("../../assets/img/sysjz_top_img.png");
  position: relative;
}
.pd_space_260 {
  padding: 0 5%;
}
.img_text {
  position: absolute;
  top: 70px;
  width: 50%;
  height: 10px;
  text-align: center;
  left: 25%;
  right: 25%;
  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px 0 5px;
  }
  .a2 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }
  .a3 {
    margin: 30px 0 40px;
  }
  div {
    font-size: 18px;
    color: #ffffff;
  }
}
//
.content {
  width: 100%;
  height: 100%;
  padding: 20px 0 130px;
}
.main {
  width: 100%;
  .left-menu {
    width: 100%;
    // width: 300px;
  }
}
.el-submenu:nth-last-of-type(1) {
  padding: 0 !important;
}
.el-submenu {
  padding-bottom: 10px !important;
  // border-bottom:1px solid  #eeeeee;
}
.el-menu {
  border: none;
}
.el-menu-item {
  border: 0.5px solid #eeeeee !important;
  padding-left: 20px !important;
}

.el-submenu /deep/.el-submenu__title {
  height: 60px !important;
  border: 1px solid #eeeeee !important;
}
// 内容
.box3 {
  width: 100%;
  height: 510px;
  // margin-left: 310px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  text-align: center;
}
.box3_text {
  font-size: 22px;
  color: #333333;
  font-weight: bold;
  padding-top: 35px;
}
.pull-down {
  width: 490px;
  height: 60px;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 18px;
  color: #333333;
  line-height: 60px;
  margin: 30px auto;
  padding: 0 20px;
  cursor: pointer;
  img {
    width: 15px;
    height: 8px;
    margin-top: 25px;
  }
}
.pill_list {
  width: 490px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #cccccc;
  margin: -30px auto;
  border-radius: 5px;
  z-index: 99;
  p {
    font-size: 16px;
    color: #333333;
    background: #e2e2e2;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
}
.a1 {
  background: #cccccc !important;
}
.synopsis {
  font-size: 14px;
  color: #999999;
  text-align: center;
  max-width: 490px;
  margin: 50px auto;
}
.download {
  font-size: 16px;
  color: #ffffff;
  width: 300px;
  height: 60px;
  background: #408df7;
  margin: 30px auto 0;
  line-height: 60px;
}
// 2
.box2 {
  width: 100%;
  height: 510px;
  // margin-left: 310px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  padding: 0 45px;
}
.box2_top {
  height: 94px;
  border-bottom: 1px solid #eeeeee;
  line-height: 94px;
  margin-bottom: 20px;
  p {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
  }
  div {
    font-size: 16px;
    color: #408df7;
    width: 100px;
    height: 45px;
    border: 2px solid #408df7;
    border-radius: 5px;
    line-height: 45px;
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
  }
}
.box2_content_left {
  width: 100%;
  height: 94px;
  line-height: 94px;
  border-bottom: 1px solid #eeeeee;
  div {
    font-size: 18px;
    color: #333333;
  }
}
.switch_lead {
  float: right;
  margin-top: 35px;
}

.switch_lead .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}
/*打开时文字位置设置*/
.switch_lead .el-switch__label--right {
  z-index: 1;
}
/* 调整打开时文字的显示位子 */
.switch_lead .el-switch__label--right span {
  position: absolute;
  right: 55px;
  top: 5px;
  color: #ff0000;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
}
/*关闭时文字位置设置*/
.switch_lead .el-switch__label--left {
  z-index: 1;
}
/* 调整关闭时文字的显示位子 */
.switch_lead .el-switch__label--left span {
  margin-left: -50px;
  color: #999999;
  font-size: 14px;
}
/*显示文字*/
.switch_lead .el-switch__label.is-active {
  display: block;
}
/* 调整按钮的宽度 */
.switch_lead.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 45px !important;
  margin: 0;
}
// 1111
.box1 {
  // width: 1090px;
  height: 100%;
  // margin-left: 310px;
}

.box1_1 {
  width: 100%;
  height: 100%;
  padding: 0 46px 0 40px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  p {
    font-size: 22px;
    color: #333333;
    font-weight: bold;
    height: 94px;
    line-height: 94px;
    border-bottom: 1px solid #eeeeee;
  }
}
.box1_item {
  // margin-right: 20px;
  width: 100%;
  height: 125px;
  background: url("../../assets/img/syssj_bg_b.png") no-repeat;
  // background-repeat: 100% 100%;
  background-size: 100% 100%;
  text-align: center;
  line-height: 125px;
  padding: 0 7%;
}
.box1_item_title {
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
}
.box1_item_text {
  font-size: 36px;
  color: #ffffff;
  font-weight: bold;
}
.img1 {
  // width: 30px;
  // height: 45px;
  margin: 45px 10px 0 0;
}
.img2 {
  margin: 50px 20px 0;
}
.box1_item_box {
  padding: 32px 0 25px 0;
  height: 150px;
}
.box1_item_list_box {
  margin: 25px 0 35px 0;
  height: 90px;
}
.box1_item:nth-of-type(3) {
  margin-right: 0;
}
.box1_item_list:nth-of-type(4) {
  margin: 0;
}
.box1_item_list {
  width: 100%;
  height: 90px;
  background: url("../../assets/img/syssj_bg_s.png") no-repeat;
  background-size: 100% 100%;
  line-height: 90px;
  text-align: center;
  padding: 0 10%;
  cursor: pointer;
  span {
    font-size: 16px;
    color: #ffffff;
    width: 30%;
  }
  div {
    // width: 30%;
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
  }
  img {
    margin: 35px 10% 0;
  }
}
.electricity_box {
  padding: 42px 0 0 0;
}
.entrance {
  margin-top: 30px;
  width: 100%;
  height: 125px;
  border-radius: 15px;
  background: url("../../assets/img/syssj_kg_bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px 50px;
}
.entrance_list {
  img {
    float: left;
  }
  div {
    float: left;
    margin-left: 25px;
    color: #ffffff;
    span {
      font-size: 15px;
    }
    ul {
      font-size: 16px;
    }
  }
}
.entrance_list1 {
  .left {
    width: 50px;
    height: 50px;
    background: #00ff3c;
    border-radius: 50%;
    opacity: 0.38;
    text-align: center;
    span {
      font-size: 16px;
      color: #ffffff;

      line-height: 50px;
      opacity: 1 !important;
    }
  }
}
.content1 {
  margin-left: 20px;
  height: 50px;
  color: #ffffff;
  div {
    font-size: 15px;
    margin-bottom: 5px;
  }
  span {
    font-size: 16px;
  }
}
.right {
  margin-left: 50px;
  height: 50px;
  color: #ffffff;
  div {
    font-size: 15px;
    margin-bottom: 5px;
  }
  span {
    font-size: 16px;
  }
}
// /deep/.el-carousel__item{
//   // overflow: visible !important;
// }
.block {
  width: 220px;
  height: 180px;
}
.el-select {
  width: 50%;
  margin-top: 20px;
}
</style>
