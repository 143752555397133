<template>
  <div class="content-right fl">
    <div class="right-top">
      <p class="fl">地址管理</p>
      <p class="fr" @click="showArea()">添加新地址</p>
      <div class="clear"></div>
    </div>
    <el-divider></el-divider>
    <div class="right-main">
      <div class="main-top">
        <p>收货人</p>
        <p>地址</p>
        <p>电话</p>
        <p>操作</p>
      </div>
      <div class="m1" v-if="list.length < 1">暂无地址</div>
      <div class="main-item" v-for="(item, index) in list" :key="index">
        <p class="one-letter">{{ item.consignee }}</p>
        <p>
          {{ item.province_name }}{{ item.city_name }}{{ item.district_name
          }}{{ item.address }}
        </p>
        <p>{{ item.mobile }}</p>
        <div class="item-operation fl">
          <div class="operation-left fl">
            <p class="fl" @click="showArea1(item.address_id)">修改</p>
            <p class="fl">|</p>
            <p class="fl" @click="que_del(item.address_id, item.is_default)">删除</p>
          </div>
          <!-- <p
            class="set-default fr"
            @click="set(item.address_id)"
            v-if="item.is_default == 0"
          >
            设为默认
          </p> -->
          <p class="set-default fr active" v-if="item.is_default == 1">默认地址</p>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <van-popup v-model="show" class="pop">
      <img src="../../assets/img/pop_but_gb.png" @click="show = false" class="close" />
      <div class="list_box_top">
        <p v-if="rise">修改地址</p>
        <p v-if="rise == false">新增地址</p>
        <div @click="chose">
          <img v-if="type == 0" src="../../assets/img/but_xz_n.png" />
          <img v-if="type == 1" src="../../assets/img/but_xz_s.png" />
          <span>设为默认</span>
        </div>
      </div>
      <div class="list">
        <p>收货人</p>
        <input placeholder="填写收货人" v-model="name" />
      </div>
      <div class="list">
        <p>联系电话</p>
        <input placeholder="填写联系电话" v-model="phone" />
      </div>
      <div class="list">
        <p>城市</p>
        <div class="area_box fr" v-if="unit_city.length < 1">
          <el-cascader
            class="a7 fr"
            :options="options"
            ref="cascaderAddr"
            placeholder="请选择"
            v-model="unit_city"
            @change="handleChange"
          >
          </el-cascader>
        </div>
        <div class="area_box fr" v-else>
          <el-cascader
            class="a7 fr"
            :options="options"
            ref="cascaderAddr"
            :placeholder="unit_city"
            v-model="unit_city"
            @change="handleChange"
          >
          </el-cascader>
        </div>
        <!-- <input placeholder="填写收货人" /> -->
      </div>
      <div class="list1">
        <p>详细地址</p>
        <textarea placeholder="填写详细地址" v-model="site" />
      </div>
      <div class="sub" v-if="rise == false" @click="add(0)">保存新地址</div>
      <div class="sub" v-if="rise == true" @click="add(1)">修改地址</div>
    </van-popup>
  </div>
</template>
<script>
import {
  addressList,
  del_ressList,
  add_ressList,
  set_ress,
  redact_ress,
} from "../../api/User";
let pcas = require("../../assets/js/area.json");
export default {
  data() {
    return {
      token: "",
      list: [], //地址列表
      show: false,
      options: pcas,
      type: 0,
      province: "",
      district: "",
      city: "",

      name: "",
      site: "",
      phone: "",
      unit_city: [],
      rise: false,
      id: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
  },
  methods: {
    set(id) {
      this.id = id;
      set_ress({ token: this.token, address_id: this.id }).then((res) => {
        if (res.status == 1) {
          // this.$message({
          //   message: res.msg,
          //   type: "success",
          // });
          setTimeout(() => {
            this.getList();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        }
      });
    },
    //添加新地址
    add(index) {
      if (index == 0) {
        let data = {
          token: this.token,
          consignee: this.name,
          province_name: this.province,
          city_name: this.city,
          district_name: this.district,
          address: this.site,
          mobile: this.phone,
          is_default: this.type ? 1 : 0,
        };
        add_ressList(data).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.name = "";
            this.unit_city = "";
            this.province = "";
            this.city = "";
            this.district = "";
            this.site = "";
            this.phone = "";
            this.type = 0;
            setTimeout(() => {
              this.show = false;
              this.getList();
            }, 1000);
          } else if (res.status == -1) {
            this.$message.error(res.msg);
          }
        });
      } else {
        let data = {
          address_id: this.id,
          token: this.token,
          consignee: this.name,
          province_name: this.province,
          city_name: this.city,
          district_name: this.district,
          address: this.site,
          mobile: this.phone,
          is_default: this.type,
        };
        add_ressList(data).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            if (this.type == 1) {
              // console.log(this.id);
              this.set(this.id);
              this.show = false;
            }
            this.name = "";
            this.unit_city = "";
            this.province = "";
            this.city = "";
            this.district = "";
            this.site = "";
            this.phone = "";
            this.type = 0;
            this.show = false;
            // setTimeout(() => {
            //   this.show = false;
            //   this.getList();
            // }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //修改地址弹框
    showArea1(id) {
      this.show = true;
      this.rise = true;
      this.id = id;
      this.redact_ress(id); //修改地址
    },
    //修改地址的地址详情
    redact_ress(id) {
      redact_ress({ token: this.token, address_id: id }).then((res) => {
        if (res.status == 1) {
          this.name = res.result.consignee;
          this.unit_city = [
            res.result.province_name + res.result.city_name + res.result.district_name,
          ];
          this.province = res.result.province_name;
          this.city = res.result.province_name;
          this.district = res.result.province_name;
          this.site = res.result.address;
          this.phone = res.result.mobile;
          this.type = res.result.is_default;
        }
      });
    },
    chose() {
      this.type = !this.type;
    },
    //删除地址
    que_del(id, stat) {
      // if (stat == 1) {
      //   this.$message({
      //     type: "error",
      //     message: "您不能删除默认地址!",
      //   });
      //   return;
      // }
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // that.$message({
          //   showClose: true,
          //   type: "success",
          //   message: "删除成功!",
          // });
          setTimeout(() => {
            this.del(id);
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除地址
    del(id) {
      del_ressList({ token: this.token, address_id: id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getList();
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        }
      });
    },
    //选择省市区
    handleChange(e, form, thsAreaCode) {
      thsAreaCode = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels; // 注意2： 获取label值
      this.province = thsAreaCode[0];
      this.city = thsAreaCode[1];
      this.district = thsAreaCode[2];
    },
    showArea() {
      this.rise = false;
      this.name = "";
      this.unit_city = "";
      this.province = "";
      this.city = "";
      this.district = "";
      this.site = "";
      this.phone = "";
      this.type = 0;
      this.show = true;
    },
    getList() {
      addressList({ token: this.token }).then((res) => {
        this.list = res.result;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  padding: 25px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  .right-top {
    p:nth-child(1) {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 45px;
      color: #333333;
    }
    p:nth-child(2) {
      width: 127px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 45px;
      text-align: center;
      color: #408df7;
      cursor: pointer;
    }
  }
  .right-main {
    .main-top {
      height: 50px;
      background-color: #efefef;
      p {
        float: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
      }
      p:nth-child(1) {
        width: 10%;
        text-align: center;
      }
      p:nth-child(2) {
        width: 40%;
      }
      p:nth-child(3) {
        width: 20%;
      }
      p:nth-child(4) {
        width: 30%;
      }
    }
    .main-item {
      padding: 26px 0;
      border-bottom: 1px solid #eeeeee;
      p {
        float: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 40px;
        color: #333333;
      }
      p:nth-child(1) {
        width: 10%;
        text-align: center;
      }
      p:nth-child(2) {
        width: 40%;
      }
      p:nth-child(3) {
        width: 20%;
      }
      .item-operation {
        width: 30%;
        .operation-left {
          width: 50%;
          p {
            width: 30%;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            font-weight: 400;
            line-height: 40px;
            color: #333333;
            cursor: pointer;
          }
          p:nth-child(2) {
            width: 10%;
          }
        }
        .set-default {
          width: 105px;
          height: 40px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 40px;
          text-align: center;
          color: #999999;
          cursor: pointer;
        }
        .set-default.active {
          width: 105px;
          height: 40px;
          background: rgba(64, 141, 247, 0.1);
          border: 2px solid #408df7;
          text-align: center;
          border-radius: 5px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 40px;
          color: #408df7;
          cursor: pointer;
        }
      }
    }
  }
}
.pop {
  width: 380px;
  height: 600px;
  padding: 30px;
}
.close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.list_box_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-top: 30px;
  float: left;
  p {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    float: left;
  }
  div {
    cursor: pointer;
    float: right;
    img {
      width: 13px;
      height: 13px;
    }
    span {
      margin-left: 10px;
      font-size: 14px;
      color: #999999;
      // float: left;
    }
  }
}
.list {
  height: 70px;
  width: 100%;
  line-height: 70px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  input {
    height: 69px;

    float: right;
    text-align: right;
  }
}
.list1 {
  width: 100%;
  height: 170px;
  padding-top: 30px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  textarea {
    position: absolute;
    bottom: 135px;
    left: 30px;
    width: 80%;
    height: 100px;
  }
}
.sub {
  cursor: pointer;
  margin-top: 20px;
  float: left;
  padding: 15px 120px;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
}
.area_box /deep/ .el-input__inner {
  width: 200px !important;
  color: #333333 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 48px;
}
.a7 {
  height: 70px;
  line-height: 70px;
}
</style>
