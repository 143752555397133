<template>
  <div class="content-right fl">
    <div class="content-title fl">
      <p class="fl" @click="goBack()">服务订单 ></p>
      <p class="fl">订单详情</p>
    </div>
    <div class="content-main">
      <div class="order-sn-status">
        <p class="item-title fl" v-if="Info.instrument_dispose == 1">
          仪器维修
        </p>
        <p class="item-title fl" v-if="Info.instrument_dispose == 2">
          仪器保养
        </p>
        <p class="item-title fl" v-if="Info.instrument_dispose == 3">
          安装调试
        </p>
        <p class="item-title fl" v-if="Info.instrument_dispose == 4">
          我的实验室
        </p>
        <p class="item-title fl" v-if="Info.instrument_dispose == 5">
          我的实验室方案
        </p>
        <p class="fr" v-if="stat == 1 && stats == 0">已提交</p>
        <p class="fr" v-if="stat == 1 && stats == 1">工程师已报价</p>
        <p class="fr" v-if="stat == 2 && stats == 2 && statz == 0">同意报价</p>
        <p class="fr" v-if="stat == 4 && stats == 2 && statz == 0">待支付</p>
        <p class="fr" v-if="stat == 2 && statz == 1">已支付</p>
        <p class="fr" v-if="stat == 5">已评价</p>
        <p class="fr" v-if="stat == 6">已取消</p>
        <p class="fr" v-if="stat == 3">工程师已拒绝</p>
        <p class="fr" v-if="stat == 1 && stats == 3">拒绝报价</p>
        <div class="clear"></div>
        <p class="time">
          <span>创建时间：{{ Info.add_time }}</span
          ><span>接单时间：{{ Info.end_time }}</span
          ><span>结束时间：{{ Info.pay_time }}</span>
        </p>
      </div>
      <el-divider></el-divider>
      <div v-if="Info.instrument_dispose == 1" style="height: 100%">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器维修</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_name }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器品牌</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_brand }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">故障说明</p>
          </div>
          <div class="item-right fl guzhang">
            <p>{{ Info.instrument_malfunction }}</p>
          </div>
        </div>

        <div class="content-item" v-if="Info.maintain_pic">
          <div class="item-left fl">
            <p class="item-title">图片</p>
          </div>
          <div class="item-right fl">
            <p>
              <img
                class="r8"
                v-for="(item, index) in Info.maintain_pic"
                :key="index"
                :src="item"
                @click="getBigImg(item)"
              />
            </p>
          </div>
        </div>
        <div
          class="content-item"
          v-if="Info.maintain_video"
          style="margin-top: 40px"
        >
          <div class="item-left fl">
            <p class="item-title">视频</p>
          </div>
          <div class="item-right fl">
            <p>
              <a :href="Info.maintain_video" target="view_window" class="t10 fl"
                ><video :src="Info.maintain_video" class="t8 fl"></video
              ></a>
            </p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div v-if="Info.instrument_dispose == 2">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器保养</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_name }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">保养方案</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.maintain_plan }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器品牌</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_brand }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">保养说明</p>
          </div>
          <div class="item-right fl guzhang">
            <p>{{ Info.maintain_desc }}</p>
          </div>
        </div>
        <div class="content-item" v-if="Info.maintain_pic">
          <div class="item-left fl">
            <p class="item-title">图片</p>
          </div>
          <div class="item-right fl">
            <p>
              <img
                class="r8"
                v-for="(item, index) in Info.maintain_pic"
                :key="index"
                :src="item"
                @click="getBigImg(item)"
              />
            </p>
          </div>
        </div>
        <div
          class="content-item"
          v-if="Info.maintain_video"
          style="margin-top: 40px"
        >
          <div class="item-left fl">
            <p class="item-title">视频</p>
          </div>
          <div class="item-right fl">
            <p>
              <a :href="Info.maintain_video" target="view_window" class="t10 fl"
                ><video :src="Info.maintain_video" class="t8 fl"></video
              ></a>
            </p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div v-if="Info.instrument_dispose == 3">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器名称</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_name }}</p>
          </div>
        </div>
        <div class="content-item" v-if="Info.instrument_size">
          <div class="item-left fl">
            <p class="item-title">仪器型号</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.instrument_size }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">仪器品牌</p>
          </div>
          <div class="item-right fl">
            <p>{{ Info.install_seller }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">安装说明</p>
          </div>
          <div class="item-right fl guzhang">
            <p>{{ Info.install_desc }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">现场情况说明</p>
          </div>
          <div class="item-right fl guzhang">
            <p>{{ Info.install_condition_desc }}</p>
          </div>
        </div>
        <div class="content-item" v-if="Info.install_pic">
          <div class="item-left fl">
            <p class="item-title">图片</p>
          </div>
          <div class="item-right fl">
            <p>
              <img
                class="r8"
                v-for="(item, index) in Info.install_pic"
                :key="index"
                @click="getBigImg(item)"
                :src="item"
              />
            </p>
          </div>
        </div>
        <div
          class="content-item"
          v-if="Info.maintain_video"
          style="margin-top: 40px"
        >
          <div class="item-left fl">
            <p class="item-title">视频</p>
          </div>
          <div class="item-right fl">
            <p>
              <a :href="Info.maintain_video" target="view_window" class="t10 fl"
                ><video :src="Info.maintain_video" class="t8 fl"></video
              ></a>
            </p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div v-if="Info.instrument_dispose == 4">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">管理需求</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.sy_user }}</p>
          </div>
        </div>
      </div>
      <div v-if="Info.instrument_dispose == 5">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">整体布局规划设计</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.lab_project }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">家具工程</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.lab_furniture }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">通风工程</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.lab_ventilate }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">装修工程</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.lab_decoration }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">供气工程</p>
          </div>
          <div class="item-right fl">
            <p class="t9">{{ Info.lab_air }}</p>
          </div>
        </div>
      </div>

      <!-- 
      <div class="content-item">
        <div class="item-left">
          <p class="item-title">专业服务项目</p>
        </div>
        <div class="item-right">
          <p>超声波清洗器维修，本生灯维修；</p>
        </div>
      </div>
      <div class="content-item">
        <div class="item-left">
          <p class="item-title">历史评价</p>
        </div>
        <div class="item-right">
          <p>好评88次，一般23次，差评1次</p>
        </div>
      </div> -->

      <el-divider></el-divider>
      <div class="content-item" v-if="Info.engineer_name">
        <div class="item-left fl">
          <p class="item-title">工程师信息</p>
        </div>
        <div class="item-right fl">
          <img class="q7 fl" :src="Info.head_pic" />
          <p class="fl" style="margin-left: 10px">{{ Info.engineer_name }}</p>
          <p class="fl" style="margin-left: 10px">{{ Info.mobile }}</p>
        </div>
      </div>
      <div class="content-item" v-if="Info.head_pic">
        <div class="item-left fl">
          <p class="item-title">成功服务</p>
        </div>
        <div class="item-right fl">
          <p>
            <span>{{ Info.serve_num }}</span
            >次
          </p>
        </div>
      </div>
      <div class="content-item" v-if="Info.serve_item">
        <div class="item-left fl">
          <p class="item-title">专业服务项目</p>
        </div>
        <div class="item-right fl">
          <p>
            <span>{{ Info.serve_item }}</span>
          </p>
        </div>
      </div>
      <div class="content-item" v-if="Info.head_pic">
        <div class="item-left fl">
          <p class="item-title">历史评级</p>
        </div>
        <div class="item-right fl">
          <p>
            <span>
              好评{{ Info.good_ping }}次，一般{{ Info.ordinary_ping }}次，差评{{
                Info.bad_ping
              }}次
            </span>
          </p>
        </div>
      </div>
      <div v-if="stats == 0 && stat == 1">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">工程师未报价,请耐心等候</p>
          </div>
        </div>
      </div>
      <div v-if="stat == 2 && statz == 0 && stats == 1">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">待付款</p>
          </div>
        </div>
      </div>
      <div v-if="stat == 1 && stats == 3">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">您已拒绝报价</p>
          </div>
        </div>
      </div>
      <div v-if="stat == 1 && stats == 1">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">工程师已报价</p>
          </div>
        </div>
      </div>
      <div v-if="stats == 2 && stat == 2">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">已接受报价,等待工程师确认</p>
          </div>
        </div>
      </div>
      <div v-if="stats == 2 && stat == 4">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">工程师已确认，等待付款</p>
          </div>
        </div>
      </div>
      <!-- <div v-if="stat == 4 && stats==2 && statz == 0">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">工程师已报价，等待付款</p>
          </div>
        </div>
      </div> -->
      <div v-if="stats == 1">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">报价金额</p>
          </div>
          <div class="item-right fl">
            <p class="fl">￥{{ Info.order_price }}</p>
          </div>
        </div>
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">工程师备注</p>
          </div>
          <div class="item-right fl beizhu">
            <p class="fl">{{ Info.quote_content }}</p>
          </div>
        </div>
      </div>

      <!-- <div v-if="Info.order_report == 2 && Info.order_status == 5">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">已完成</p>
          </div>
        </div>
      </div> -->
      <div v-if="Info.order_status == 5">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">已评价</p>
          </div>
        </div>
      </div>
      <div v-if="Info.order_status == 3">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">工程师拒绝接单，等待后台重新分配工程师</p>
          </div>
        </div>
      </div>
      <div v-if="Info.order_status == 6">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">进度</p>
          </div>
          <div class="item-right fl">
            <p class="fl">已取消</p>
          </div>
        </div>
      </div>
      <div v-if="Info.order_report == 2">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">报价金额</p>
          </div>
          <div class="item-right fl">
            <p class="fl">¥{{ Info.order_price }}</p>
          </div>
        </div>
      </div>
      <div v-if="Info.order_report == 2">
        <div class="content-item">
          <div class="item-left fl">
            <p class="item-title">工程师备注</p>
          </div>
          <div class="item-right fl">
            <p class="fl">{{ Info.quote_content }}</p>
          </div>
        </div>
      </div>

      <el-divider></el-divider>
      <div v-if="Info.order_report == 1" class="u9 fl" @click="chose(0)">
        拒绝报价
      </div>
      <div v-if="Info.order_report == 1" class="u9 fr" @click="chose(1)">
        同意报价
      </div>
      <div
        v-if="Info.order_report == 3 && Info.order_status != 6"
        class="u9 fl"
        @click="qu()"
      >
        取消订单
      </div>

      <div
        class="order-btn"
        v-if="stat == 4 && statz == 1 && stats == 2"
        @click="goto(Info)"
      >
        <p class="fr">去评价</p>
        <div class="clear"></div>
      </div>
    </div>
    <div class="big-img" v-if="openBig" @click="closeBig">
      <img :src="nowImg" alt="" />
    </div>
  </div>
</template>
<script>
import { fw_Info, baojia, cancel } from "../../api/User";
import { browse } from "../../api/index";
export default {
  data() {
    return {
      id: "",
      Info: {},
      type: "",
      openBig: false,
      stat: "1",
      statz: "",
      stats: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.id = localStorage.getItem("Fw_id");
    this.getList();
    // this.getBrowse(); //订单的浏览
  },
  methods: {
    getBrowse() {
      // browse({ token: this.token, order_id: this.id, type: 2 }).then((res) => {
      //   console.log(res);
      // });
    },
    closeBig() {
      this.openBig = false;
    },
    getBigImg(path) {
      this.nowImg = path;
      this.openBig = true;
    },
    goto(item) {
      this.$emit("change6");
      let data = JSON.stringify(item);
      localStorage.setItem("data", data);
    },
    //取消订单
    qu() {
      cancel({ id: this.id, order_status: 6 }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.goBack();
          }, 1000);
        }
      });
    },
    //拒绝/同意订单
    chose(index) {
      if (index == 0) {
        this.type = 3;
      } else {
        this.type = 2;
      }
      baojia({ id: this.id, order_report: this.type }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.goBack();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        }
      });
    },
    getList() {
      fw_Info({ id: this.id }).then((res) => {
        if (res.status == 1) {
          this.Info = res.result;
          this.stat = res.result.order_status;
          this.statz = res.result.pay_status;
          this.stats = res.result.order_report;
          // console.log(this.stat)
          // console.log(res);
        }
      });
    },
    //跳转商品订单
    goBack() {
      this.$emit("change1");
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-title {
    width: 100%;
    padding: 0 115px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      cursor: pointer;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
  .content-main {
    padding: 100px 115px;
    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #333333;
    }
    p.item-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
    }
    .el-divider--horizontal {
      margin: 20px 0;
    }
    .order-sn-status {
      p:nth-child(2) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 28px;
        color: #408df7;
      }
    }
    .order-time {
      height: 50px;
      p {
        margin-right: 50px;
        line-height: 50px;
      }
    }
    .info-title {
      height: 40px;
      background: #efefef;
      padding: 0 30px;
      p {
        line-height: 40px;
      }
      p:nth-child(1) {
        width: 50%;
      }
      p:nth-child(2) {
        width: 25%;
        text-align: center;
      }
      p:nth-child(3) {
        width: 25%;
        text-align: right;
      }
    }
    .order-btn {
      cursor: pointer;
      p {
        width: 100px;
        height: 45px;
        border: 2px solid #eeeeee;
        opacity: 1;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 45px;
        text-align: center;
        color: #333333;
        opacity: 1;
      }
    }
  }
}
.content-item {
  margin-top: 24px;
  height: 80px;
  width: 100%;
  line-height: 80px;
}
.item-left {
  min-width: 124px;
  height: 80px;
  line-height: 80px;
  p {
    margin-top: 20px;
  }
}
.item-right {
  img {
    object-fit: cover;
    width: 80px;
    height: 80px;
  }
  p {
    margin-top: 20px;
    display: -webkit-box;
    word-break: break-all;
    -webkit-box-orient: vertical;
  }
  p:first-of-type {
    font-size: 16px;
    color: #333333;
    // margin: 20px 30px 0 20px;
  }
  p:nth-of-type(2) {
    font-size: 16px;
  }
}
.q7 {
  width: 80px !important;
  height: 80px !important;
  border-radius: 50%;
}
.r8 {
  margin-right: 10px;
  height: 100px;
}
.t10 {
  height: 100px;
  margin-right: 10px;
}
.t8 {
  margin-top: -20px;

  height: 100px;
}
.t9 {
  margin: 0 !important;
  margin-top: 20px !important;
}
.u9 {
  font-size: 16px;
  color: #ffffff;
  background: #408df7;
  padding: 15px 50px;
  cursor: pointer;
}
.beizhu {
  margin-bottom: 10px;
}
.time {
  margin-top: 10px;
  span {
    margin-right: 53px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 28px;
    color: #333333;
    opacity: 1;
  }
}
.guzhang {
  margin-top: -40px !important;
  margin-bottom: 10px !important;
}
</style>