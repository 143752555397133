import {
    post
} from "../util/index";
//单位列表(1)
export function companylist(query) {
    return post("Company/companyList", query);
}
// 获取手机验证码(1)
export function getcode(query) {
    return post("Company/smsCode", query);
}
// 获取单位职务(1)
export function getJob(query) {
    return post("Company/companyPositionList", query);
}
// 注册(1)
export function register(query) {
    return post("Company/register", query);
}
// 登录(1)
export function login(query) {
    return post("Company/login", query);
}
//图片上传
export function picUp(query) {
    return post("InstrumentData/instrument_pic", query);
}
//招标信息（1）
export function bidList(query) {
    return post("Index/bidsList", query);
}
//首页咨询列表（1）
export function newList(query) {
    return post("Index/getEssay", query);
}
//首页仪器知识列表（1）
export function instrumentList(query) {
    return post("Index/getArticle", query);
}

//招标信息详情（1）
export function bidinfo(query) {
    return post("Index/bidsInfo", query);
}
//关于我们（1）
export function about(query) {
    return post("Article/about_user", query);
}
//资讯分类（1）
export function new_classify(query) {
    return post("Article/article_cats", query);
}
//资讯列表（1）
export function new_classifyList(query) {
    return post("Article/article_lists", query);
}

//资讯详情（1）
export function newinfo(query) {
    return post("Article/essay_info", query);
}
//资讯详情-点赞（1）
export function newinfo_dz(query) {
    return post("Article/essay_dz", query);
}
//资讯详情-收藏（1）
export function newinfo_sc(query) {
    return post("Article/essay_sc", query);
}
//知识详情-点赞（1）
export function knowledge_dz(query) {
    return post("Article/give_praise", query);
}
//知识详情-收藏（1）
export function knowledge_sc(query) {
    return post("Article/article_sc", query);
}

//个人信息（1）
export function UseInfo(query) {
    return post("UserInstrument/userInfo", query);
}
//个人资料修改（1）
export function UpUser(query) {
    return post("User/upUser", query);
}

//客服信息（1）
export function service(query) {
    return post("UserInstrument/service_user", query);
}
//意见反馈（1）
export function feedback(query) {
    return post("Article/instrument_inquiry", query);
}

// 
//资讯发布评论（1）
export function comment(query) {
    return post("Article/essay_comment", query);
}
//资讯的评论点赞(1)
export function comment_dz(query) {
    return post("Article/comment_dz", query);
}
//活动发布评论(1)
export function active_comment(query) {
    return post("Article/article_comment", query);
}
//知识评论列表(1)
export function commentList(query) {
    return post("Article/article_commentlist", query);
}

//知识评论点赞(1)
export function commentListDZ (query) {
    return post("Article/give_comment_praise", query);
}
//首页仪器采购分类(1)
export function  Index_classify(query) {
    return post("Index/category_list", query);
}

//首页仪器采购列表(1)
export function  Index_goods(query) {
    return post("Index/goods_list", query);
}

//首页实验室建设
export function construction(query) {
    return post("InstrumentCat/butler_service",query)
}
//实验室建设文章分类
export function article(query){
    return post("Article/article_list",query)
}

//实验室建设提交
export function construction_submission(query){
    return post('InstrumentCat/add_service',query)
}
//微信 登录
export function wxLogin(query){
    return post('user/pcwxlogin',query)
}
//获取联系我们数据
export function contact_us(query){
    return post('UserInstrument/service_user',query)
}
//仪器服务添加 维修、保养、调试
export function order_instrument(query){
    return post('InstrumentData/order_instrument',query)
}
//工程进度 维修、保养、调试
export function order_instrument_list(query){
    return post('InstrumentData/order_instrument_list_pc',query)
}
//保养方案
export function instrument_cat(query){
    return post('InstrumentCat/instrument_cat',query)
}
//工程师列表 分页
export function instrument_engineer(query){
    return post('InstrumentData/instrument_engineer',query)
}
//工程师列表 不分页
export function instrument_engineers(query){
    return post('InstrumentData/instrument_engineers',query)
}
//我的仪器列表分页
export function userinstrument_list(query){
    return post('InstrumentData/userinstrument_list',query)
}
//我的仪器列表不分页
export function myInstrument(query){
    return post('Company/myInstrument',query)
}
//仪器导入模板
export function template(query){
    return post('Index/template',query)
}
//安装调试维修保养提交信息
export function instrument_order(query){
    return post("InstrumentData/instrument_order",query)
}
//安装调试维修保养多选提交信息
export function instrument_orders(query){
    return post("InstrumentData/instrument_orders",query)
}
//等级说明（1）
export function level_info(query){
    return post("UserInstrument/user_level",query)
}

//个人档案（1）
export function user_file (query){
    return post("UserInstrument/userInstrument",query)
}

//完善资料（1）
export function  perfect(query){
    return post("Company/pc_perfectInformation",query)
}

//工程师服务历史
export function engineer_client_info(query){
    return post("InstrumentData/engineer_client_info",query)
}

//工程师详细信息
export function instrument_engineerinfo(query){
    return post("InstrumentData/instrument_engineerinfo",query)
}

//订单的浏览
export function browse(query){
    return post("Engineer/order_record",query)
}

//二维码
export function Company(query){
    return post("Company/eweim",query)
}

//咨讯展示
export function articleUser(query){
    return post("company/articleUser",query)
}

//监控URL
export function URL(query){
    return post("Index/getVideoUrl",query)
}
//监控token
export function Token(query){
    return post("Index/getVideoToken",query)
}

