<template>
  <div class="container">
    <Top ref="child"></Top>
    <div class="content-box mg_space">
      <div class="content-left fl">
        <div class="left-top">
          <div class="user-pic">
            <img :src="User.head_pic" alt="" />
          </div>
          <div class="user-name">
            <p>{{ User.user_name }}</p>
          </div>
          <div class="profession"></div>
          <div class="evaluate">
            <p>好评{{ User.good_ping }}次</p>
            <p>中评{{ User.ordinary_ping }}次</p>
            <p>差评{{ User.bad_ping }}次</p>
            <div class="clear"></div>
          </div>
          <div class="out-login clear" @click="out()">退出登录</div>
        </div>
        <div class="left-bottom clear">
          <div
            class="bottom-item"
            :class="{ active: control == 1 }"
            @click="changeMenu(1)"
          >
            <p :class="[User.record_status == 0 ? 'active' : '']">我要接单</p>
          </div>
          <div
            class="bottom-item"
            :class="{ active: control == 2 }"
            @click="changeMenu(2)"
          >
            <p>我的客户</p>
          </div>
          <div
            class="bottom-item"
            :class="{ active: control == 3 }"
            @click="changeMenu(3)"
          >
            <p>新人专享</p>
          </div>
          <div
            class="bottom-item"
            :class="{ active: control == 4 }"
            @click="changeMenu(4)"
          >
            <p>我的收藏</p>
          </div>
          <div
            class="bottom-item"
            :class="{ active: control == 5 }"
            @click="changeMenu(5)"
          >
            <p>联系客服</p>
          </div>
        </div>
      </div>
      <Order v-if="control == 1 && order_control == 1"></Order>
      <OrderDetail v-if="control == 1 && order_control == 2"></OrderDetail>
      <Customer v-if="control == 2"></Customer>
      <NewExclusive v-if="control == 3"></NewExclusive>
      <MyCollection v-if="control == 4"></MyCollection>
      <Service v-if="control == 5"></Service>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import Order from "./order"; //我要接单
import OrderDetail from "./orderDetail"; //我要接单
import Customer from "./customer"; //我的客户
import NewExclusive from "./newExclusive"; //新人专享
import MyCollection from "./myCollection"; //我的收藏
import Service from "./service"; //联系客服
import { UseInfo, user_file, browse } from "../../api/index";
export default {
  data() {
    return {
      control: 2,
      order_control: 1,
      User: "",
      token: "",
      File: "",
    };
  },
  components: {
    Top,
    Bottom,
    Order,
    OrderDetail,
    Customer,
    NewExclusive,
    MyCollection,
    Service,
  },
  watch: {
    $route(to, from) {
      this.control = this.$route.query.control;
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.control = this.$route.query.control;
    this.getUserInfo();
    this.getInfo();
  },
  methods: {
    getInfo() {
      user_file({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.File = res.result;
        } else {
          this.$message("未登录，请登录");
          setTimeout(() => {
            localStorage.removeItem("token");
            this.$router.replace("/");
          }, 1000);
        }
      });
    },

    chose1() {
      this.control = 1;
      this.order_control = 1;
    },
    chose() {
      this.control = 1;
      this.order_control = 2;
    },
    //个人信息
    getUserInfo() {
      UseInfo({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.User = res.result;
        }
      });
    },
    //退出登录
    out() {
      let that = this;
      this.$confirm("即将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已退出!",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("level");
          setTimeout(() => {
            that.$router.replace("/");
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消退出",
          });
        });
    },
    //切换 左侧菜单
    changeMenu(control) {
      if (control != this.control) {
        this.control = control;
      }
      if (this.control == 1) {
        this.order_control = 1;
        browse({ type:2,token: this.token}).then((res) => {
          this.getUserInfo();
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  background-color: #fcfcfc;
  min-width: 1440px;
}
// @media only screen and (min-width: 1440px) {
// }
.content-box {
  margin: 20px auto;
  width: 1120px;
}
.content-left {
  .left-top {
    background-color: #ffffff;
    width: 260px;
    height: 280px;
    padding: 20px;
    .user-pic {
      width: 100%;
      img {
        width: 85px;
        height: 85px;
        border-radius: 50%;
        margin: 10px 67px;
      }
    }
    .user-name {
      p {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        text-align: center;
        color: #333333;
        padding: 10px 0;
      }
    }
    .profession {
      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-align: center;
        color: #333333;
        padding: 10px 0;
      }
    }
    .evaluate {
      border-top: 1px solid #eeeeee;
      border-bottom: 1px solid #eeeeee;
      p {
        float: left;
        width: 33%;

        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #999999;
      }
    }
    .user-mobile {
      padding: 10px 0;
      p,
      img {
        float: left;
      }
      .mobile {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 19px;
        color: #999999;
      }
      .user-vip {
        img {
          width: 26px;
          height: 24px;
          margin: 0 5px;
        }
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 21px;
          color: #333333;
        }
      }
    }
    .out-login {
      text-align: center;
      padding: 10px 0;
      font-size: 14px;
      cursor: pointer;
      line-height: 19px;
      color: #408df7;
    }
  }
  .left-bottom {
    margin: 20px 0 40px 0;
    background-color: #ffffff;
    .bottom-item {
      cursor: pointer;
      width: 260px;
      height: 55px;
      p {
        height: 55px;
        padding-left: 40px;
        font-size: 16px;
        line-height: 55px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      p.active::after {
        background-color: #ff0000;
        width: 6px;
        height: 6px;
        content: " ";
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: -8%;
        left: -35%;
      }
    }
    div.active {
      background-color: #f2f2f2;
    }
    div.active::after {
      background-color: #408df7;
      width: 4px;
      height: 28px;
      content: " ";
      display: inline-block;
      position: relative;
      bottom: 40px;
      left: 0;
    }
  }
}
</style>