<template>
  <el-row :gutter="20" class="container">
    <el-col :span="14">
      <div class="air-wrap">
        <p class="title">仪器总数</p>
        <el-row :gutter="50" class="air-content">
          <el-col :span="6">
            <div class="air-item">
              <div class="air-item-left green">优</div>
              <span class="content-num">0</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="air-item">
              <div class="air-item-left blue">良</div>
              <span class="content-num">0</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="air-item">
              <div class="air-item-left gray">正常</div>
              <span class="content-num">{{toalList.air.normal}}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="air-item">
              <div class="air-item-left red">不正常</div>
              <span class="content-num">{{toalList.air.unusual}}</span>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="12">
          <div class="dianli">
            <view-item-small :type="1" :canvasId="'dianli'" :state="1"></view-item-small>
          </div>
          <div class="shexiang">
            <view-item-small :type="2" :canvasId="'shexiang'" :state="2"></view-item-small>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="work">
            <view-item-small :type="3" :canvasId="'work1'" :state="3"></view-item-small>
            <div
              style="width: 380px;margin:40px 0;height: 0px;border-bottom: 1px dashed  rgba(255, 255, 255, 0.27058823529411763);"
            />
            <view-item-small :type="4" :canvasId="'work2'" :state="4"></view-item-small>
          </div>
        </el-col>
      </el-row>
    </el-col>
    <el-col :span="10">
      <div class="data-wrap">
        <div class="data-content">
          <p class="title">空气检测数据</p>
          <el-row :gutter="8">
            <el-col :span="8">
              <div class="data-content-item" @click="Temperature()">
                <img class="icon" src="../../../assets/img/icon_wendu.png" />
                <span class="label">温度</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.temperature}}°</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="data-content-item" @click="Humidity()">
                <img class="icon" src="../../../assets/img/icon_shidu.png" />
                <span class="label">湿度</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.humidity}}°</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="data-content-item" @click="Formaldehyde()">
                <img class="icon" src="../../../assets/img/icon_jiaquan.png" />
                <span class="label">甲醛</span>
                <img class="line" src="../../../assets/img/img_divide.png" />

                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.ch2o}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="10" style="margin-top: 15px">
            <el-col :span="6">
              <div class="data-content-item" @click="getCo2()">
                <span class="label">CO2</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.co2}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="data-content-item" @click="gettvoc()">
                <span class="label">TVOC</span>
                <img class="line" src="../../../assets/img/img_divide.png" />

                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.tvoc}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="data-content-item" @click="getPM25()">
                <span class="label">PM2.5</span>
                <img class="line" src="../../../assets/img/img_divide.png" />

                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.pm25}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="data-content-item" @click="getPM()">
                <span class="label">PM10</span>
                <img class="line" src="../../../assets/img/img_divide.png" />

                <span class="cont" style="width:35%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in areaList" :key="index">
                      <div>{{item.pm10}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
          </el-row>

          <p class="title">电力检测数据</p>
          <el-row :gutter="10">
            <el-col :span="6">
              <div @click="lineVoltage()" class="data-content-item">
                <span class="label">电压</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont2" style="width:50%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in falList" :key="index">
                      <div>{{item.voltage}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="lineCurrent()" class="data-content-item">
                <span class="label">电流</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont2" style="width:50%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in falList" :key="index">
                      <div>{{item.current}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="lineElectricPower()" class="data-content-item">
                <span class="label">功率</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont2" style="width:50%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in falList" :key="index">
                      <div>{{item.electricpower}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
            <el-col :span="6">
              <div @click="lineElectricity()" class="data-content-item">
                <span class="label">电能</span>
                <img class="line" src="../../../assets/img/img_divide.png" />
                <span class="cont2" style="width:50%;height:20px">
                  <el-carousel
                    height="30px"
                    direction="vertical"
                    indicator-position="none"
                    :autoplay="true"
                  >
                    <el-carousel-item v-for="(item,index) in falList" :key="index">
                      <div>{{item.electricity}}</div>
                    </el-carousel-item>
                  </el-carousel>
                </span>
              </div>
            </el-col>
          </el-row>

          <p class="title">门禁数据</p>
          <div class="menjin">
            <el-row>
              <el-col :span="8" class="menjin-box">
                <img src="../../../assets/img/icon_switch.png" />
                <div class="menjin-content">
                  <span class="menjin-content-title">开关总数量</span>
                  <span class="menjin-content-num">8888</span>
                </div>
              </el-col>
              <el-col :span="8" class="menjin-box">
                <div class="menjin-sign green">正常</div>
                <div class="menjin-content">
                  <span class="menjin-content-title">数量</span>
                  <span class="menjin-content-num">8888</span>
                </div>
                <div class="menjin-content">
                  <span class="menjin-content-title">占比</span>
                  <span class="menjin-content-num">8888</span>
                </div>
              </el-col>
              <el-col :span="8" class="menjin-box">
                <div class="menjin-sign red">异常</div>
                <div class="menjin-content">
                  <span class="menjin-content-title">数量</span>
                  <span class="menjin-content-num">8888</span>
                </div>
                <div class="menjin-content">
                  <span class="menjin-content-title">占比</span>
                  <span class="menjin-content-num">8888</span>
                </div>
              </el-col>
            </el-row>
          </div>

          <p class="title">摄像头数据</p>
          <el-row :gutter="10">
            <el-col :span="8" class="fl">
              <div class="block">
                <EZUIKitJs />
              </div>
            </el-col>
            <el-col :span="8" class="fl">
              <div class="block">
                <EZUIKitJs1 />
              </div>
            </el-col>
            <el-col :span="8" class="fl">
              <div class="block">
                <EZUIKitJs2 />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div></div>
    </el-col>

    <el-dialog title="电压检测数据" :visible.sync="voltageVisible" width="80%" center>
      <div v-if="voltageVisible" id="line-voltage"></div>
    </el-dialog>
    <el-dialog title="电流检测数据" :visible.sync="currentVisible" width="80%" center>
      <div v-if="currentVisible" id="line-current"></div>
    </el-dialog>
    <el-dialog title="功率检测数据" :visible.sync="electricPowerVisible" width="80%" center>
      <div v-if="electricPowerVisible" id="line-electricPower"></div>
    </el-dialog>
    <el-dialog title="电能检测数据" :visible.sync="electricityVisible" width="80%" center>
      <div v-if="electricityVisible" id="line-electricity"></div>
    </el-dialog>
    <!--甲醛  -->
    <el-dialog title="甲醛检测数据" :visible.sync="formaldehyde" width="80%" center>
      <div v-if="formaldehyde" id="formaldehyde"></div>
    </el-dialog>
    <!--湿度 -->
    <el-dialog title="湿度检测数据" :visible.sync="humidity" width="80%" center>
      <div v-if="humidity" id="humidity"></div>
    </el-dialog>
    <!--温度 -->
    <el-dialog title="温度检测数据" :visible.sync="temperature" width="80%" center>
      <div v-if="temperature" id="temperature"></div>
    </el-dialog>
    <!--co2 -->
    <el-dialog title="CO2检测数据" :visible.sync="CO2" width="80%" center>
      <div v-if="CO2" id="CO2"></div>
    </el-dialog>
    <!--PM10 -->
    <el-dialog title="PM10检测数据" :visible.sync="PM10" width="80%" center>
      <div v-if="PM10" id="PM10"></div>
    </el-dialog>
    <!--PM25 -->
    <el-dialog title="PM2.5检测数据" :visible.sync="PM25" width="80%" center>
      <div v-if="PM25" id="PM25"></div>
    </el-dialog>
    <!--tvoc -->
    <el-dialog title="TVOC检测数据" :visible.sync="tvoc" width="80%" center>
      <div v-if="tvoc" id="tvoc"></div>
    </el-dialog>
  </el-row>
</template>

<script>
import { Line } from "@antv/g2plot";
import ViewItemSmall from "./view-item1";
import EZUIKitJs from "../../../components/EZUIKitJs";
import EZUIKitJs1 from "../../../components/EZUIKitJs1";
import EZUIKitJs2 from "../../../components/EZUIKitJs2";
import {
  Voltage,
  Current,
  ElectricPower,
  Electricity,
  formaldehyde,
  humidity,
  temperature,
  CO2,
  PM10,
  PM25,
  tvoc,
  airData,
  getFacility,
  collect,
} from "../../../api/company";
export default {
  components: { ViewItemSmall, EZUIKitJs, EZUIKitJs1, EZUIKitJs2 },
  data() {
    return {
      voltageVisible: false,
      currentVisible: false,
      electricPowerVisible: false,
      electricityVisible: false,
      formaldehyde: false,
      humidity: false,
      temperature: false,
      CO2: false,
      PM10: false,
      PM25: false,
      tvoc: false,
      token: "",
      areaList: "", //空气检测数量
      falList: [],
      toalList: [], //汇总
    };
  },
  mounted() {
    this.token = localStorage.getItem("token");
    this.use_id = localStorage.getItem("user_id");
    this.getarea();
    this.getCil();
    this.getToal();
    // document.getElementsByClassName('view1-title').innerHTML = '付款期'
  },
  created() {},
  methods: {
    //汇总
    getToal() {
      collect({}).then((res) => {
        this.toalList = res.data;
      });
    },
    getCil() {
      getFacility({user_id:this.use_id}).then((res) => {
        this.falList = res.data;
      });
    },

    //空气检测数据
    getarea() {
      airData({user_id:this.use_id}).then((res) => {
        this.areaList = res.data;
      });
    },
    gettvoc() {
      this.tvoc = true;
      tvoc({}).then((res) => {
        const line = new Line("tvoc", {
          data: res.data,
          xField: "day",
          yField: "tvoc",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    getPM25() {
      this.PM25 = true;
      PM25({}).then((res) => {
        const line = new Line("PM25", {
          data: res.data,
          xField: "day",
          yField: "pm25",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    getPM() {
      this.PM10 = true;
      PM10({}).then((res) => {
        const line = new Line("PM10", {
          data: res.data,
          xField: "day",
          yField: "pm10",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    getCo2() {
      this.CO2 = true;
      CO2({}).then((res) => {
        const line = new Line("CO2", {
          data: res.data,
          xField: "day",
          yField: "co2",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    Temperature() {
      this.temperature = true;
      temperature({}).then((res) => {
        const line = new Line("temperature", {
          data: res.data,
          xField: "day",
          yField: "temperature",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    Humidity() {
      this.humidity = true;
      humidity({}).then((res) => {
        const line = new Line("humidity", {
          data: res.data,
          xField: "day",
          yField: "humidity",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    Formaldehyde() {
      this.formaldehyde = true;
      formaldehyde({}).then((res) => {
        const line = new Line("formaldehyde", {
          data: res.data,
          xField: "day",
          yField: "ch2o",
          seriesField: "name",
          xAxis: {
            tickCount: 10,
          },
          slider: {
            start: 0.9,
            end: 1,
          },
        });
        line.render();
      });
    },
    lineVoltage() {
      this.voltageVisible = true;
      Voltage({ token: this.token }).then((res) => {
        if (res.status == 1) {
          const line = new Line("line-voltage", {
            data: res.data,
            xField: "day",
            yField: "voltage",
            seriesField: "name",
            xAxis: {
              tickCount: 10,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineCurrent() {
      this.currentVisible = true;
      Current({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-current", {
            data: res.data,
            xField: "day",
            yField: "current",
            seriesField: "name",
            xAxis: {
              tickCount: 10,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineElectricPower() {
      this.electricPowerVisible = true;
      ElectricPower({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-electricPower", {
            data: res.data,
            xField: "day",
            yField: "electricpower",
            seriesField: "name",
            xAxis: {
              tickCount: 5,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
    lineElectricity() {
      this.electricityVisible = true;
      Electricity({ token: this.token }).then((res) => {
        if (res.status == 1) {
          //   let arr=[]
          //   arr.push(res.data.)
          const line = new Line("line-electricity", {
            data: res.data,
            xField: "day",
            yField: "electricity",
            seriesField: "name",
            xAxis: {
              tickCount: 5,
            },
            slider: {
              start: 0.6,
              end: 1,
            },
          });
          line.render();
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.block {
  width: 220px;
  height: 180px;
}
.menjin {
  background: url("../../../assets/img/menjin_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 20px 3%;
  &-sign {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    text-align: center;
    flex: none;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
  }

  &-box {
    display: flex;
    align-items: center;
  }
  img {
    width: 48px;
    height: 48px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    margin: 0 3%;
    &-title {
      font-size: 15px;
      color: #fff;
      font-weight: bold;
    }
    &-num {
      font-size: 16px;
      font-weight: bold;
      color: #47e6ff;
    }
  }
}
.data-wrap {
  background: url("../../../assets/img/data4_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 20px 2%;
  .data-content {
    background: rgba(6, 102, 232, 0.08);
    padding: 0 20px 2%;
    &-item {
      background: url("../../../assets/img/wendu_bg_1.png") no-repeat;
      background-size: 100% 100%;
      width: 100%;
      padding: 17px 7%;
      display: flex;
      align-items: center;
      cursor: pointer;
      .icon {
        width: 40px;
        height: 40px;
      }
      .line {
        width: 20px;
        height: 20px;
      }
      .label {
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
      .cont {
        font-size: 16px;
        color: #47e6ff;
        font-weight: bold;
      }
      .cont2 {
        font-size: 16px;
        color: #47e6ff;
        font-weight: bold;
      }
    }
  }
}
.shexiang {
  background: url("../../../assets/img/shexiang_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 30px;
  margin-top: 20px;
}
.work {
  background: url("../../../assets/img/work_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 30px;
}
.dianli {
  background: url("../../../assets/img/dianli_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 30px;
}
.air-content {
  .air-item {
    display: flex;
    align-items: center;
    height: 90px;
    background: linear-gradient(
      92deg,
      rgba(10, 84, 234, 0.32) 0%,
      rgba(11, 28, 71, 0.32) 100%
    );
    &-left {
      width: 67px;
      height: 61px;
      line-height: 61px;
      text-align: center;
      font-size: 15px;
      font-weight: bold;
      color: #fff;
      border-radius: 0px 15px 15px 0px;
      margin-right: 8px;
    }
    .green {
      background: rgba(0, 255, 60, 0.38);
    }
    .red {
      background: rgba(255, 0, 0, 0.38);
    }
    .blue {
      background: rgba(22, 118, 254, 0.38);
    }
    .gray {
      background: rgba(172, 176, 182, 0.38);
    }
  }
}
.green {
  background: rgba(0, 255, 60, 0.38);
}
.red {
  background: rgba(255, 0, 0, 0.38);
}
.air-wrap {
  background: url("../../../assets/img/air_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  padding: 0 30px 30px;
}
.container {
  margin-top: 50px;
}

.content {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}
.content-title {
  font-size: 15;
  color: #fff;
}
.content-num {
  font-size: 22px;
  font-weight: bold;
  color: #00c0ff;
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 20px 0 15px;
}
</style>