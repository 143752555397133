<template>
  <div>
    <el-row :gutter="20" class="seek">
      <el-col :span="6" :offset="0">
        <img class="img1 fl" src="../assets/img/sc_ss_yqgj.png" />
      </el-col>
      <el-col :span="10" :offset="0">
        <div class="seek_input fl">
          <input type="text" placeholder="请输入搜索关键词" v-model="content" />
          <p @click="sou">搜索</p>
        </div>
      </el-col>
      <el-col :span="8" :offset="0">
        <div class="seek_right fl">
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <div
                class="gwc fr"
                @click="showCart()"
                :class="[border_blue ? 'r7' : '']"
              >
                <img src="../assets/img/sc_top_gwc.png" alt="" />
                <span>购物车</span>
                <div class="dot">
                  <span>{{ list.number ? list.number : 0 }}</span>
                </div>
                <!-- 购车  弹框 -->
                <div v-show="show1" class="pop2">
                  <div class="pop2_list_box">
                    <div
                      class="pop2_list"
                      v-for="(item, index) in list.cat"
                      :key="index"
                    >
                      <img :src="item.image" />
                      <div class="pop2_list_right fl">
                        <span class="two-lettef two-letter">{{
                          item.goods_name
                        }}</span>
                        <div class="pop2_list_num">
                          <p>¥{{ item.goods_price }}</p>
                          <span>x{{ item.goods_num }}</span>
                          <div @click="delCart(item.id)">删除</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pop2_bottom">
                    <div class="pop2_bottom_list">
                      <div class="pop_bottom_left">
                        <div>
                          <p>{{ list.number ? list.number : 0 }}</p>
                          件商品
                        </div>
                        <div>
                          共计：¥
                          <ol>
                            {{
                              list.price ? list.price : 0
                            }}
                          </ol>
                        </div>
                      </div>
                      <span class="gwc-text" @click="goHref()"
                        >去购物车结算</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12" :offset="0">
              <div
                class="cp fr"
                @click="showPK()"
                :class="[border_blue1 ? 'r7' : '']"
              >
                <img src="../assets/img/sc_top_pk.png" alt="" />
                <span>产品PK</span>
                <div class="dot">
                  <span v-if="pkList">{{
                    pkList.length ? pkList.length : 0
                  }}</span>
                </div>
                <div v-show="show2" class="pop3">
                  <div class="pop3_left">
                    <div
                      class="pop3_list_box"
                      v-for="(item, index) in pkList"
                      :key="index"
                    >
                      <img class="fl" :src="item.original_img" />
                      <div class="fl">
                        <p class="two-letter">{{ item.goods_name }}</p>
                        <span @click="delPK(item.id)">删除</span>
                      </div>
                    </div>
                  </div>
                  <div class="pop_right fr">
                    <div @click="duiBI()">对比</div>
                    <p @click="qingkong(pkList.id)">清空对比栏</p>
                  </div>
                  <div class="close" @click.native="colse1">隐藏</div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Cart_List, pkList, delpk, removePk, delCart } from "../api/goods";
export default {
  data() {
    return {
      token: "",
      show: false,
      show1: false, //购物车弹框
      border_blue: false,
      show2: false, //PK弹框
      border_blue1: false,
      list: [],
      content: "",
      p: 1,
      pkList: [],
      id: [],
      PKId: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList(); //购物车列表
    this.getPklist(); //PK列表
  },
  methods: {
    goHref() {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else if (this.list.cat.length < 1) {
        this.$message("购物车暂无结算商品");
      } else if (this.list.cat.length > 0) {
        this.$router.replace({ path: "shopCart" });
      }
    },
    duiBI() {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else if (this.PKId == "") {
        this.$message("暂无对比商品");
      } else {
        this.$router.push({
          path: "/Comparison",
          query: {
            id: this.PKId,
          },
        });
      }
    },
    //删除购物车列表
    delCart(id) {
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });

          that.delCartList(id);
          setTimeout(() => {
            that.getList();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delCartList(id) {
      delCart({ ids: id }).then((res) => {});
    },
    qingkong(id) {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else if (id == "") {
        this.$message("暂无需清空商品");
      } else {
        let that = this;
        this.$confirm("即将清空, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that.$message({
              showClose: true,
              type: "success",
              message: "已清空!",
            });
            that.qingK();
            setTimeout(() => {
              that.getPklist();
            }, 100);
          })
          .catch(() => {
            that.$message({
              showClose: true,
              type: "info",
              message: "已取消清空",
            });
          });
      }
    },
    qingK() {
      removePk({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.$router.go(0);
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //删除pk
    delPK(id) {
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });

          that.getDel(id);
          setTimeout(() => {
            that.getPklist();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getDel(id) {
      delpk({ id: id }).then((res) => {
        if (res.status == 1) {
          // this.$message({
          //   message: res.info,
          //   type: "success",
          // });
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //PK列表
    getPklist() {
      pkList({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.pkList = res.data.data;
          this.PKId = res.data.id;
        }
      });
    },
    //搜索
    sou() {
      if (this.content == "") {
        this.$message.error("搜索内容不能为空！");
        return;
      }
      this.$router.push({
        name: "Purchase",
        params: {
          text: this.content,
          
        },
      });
      // localStorage.setItem("name", this.content);
      // this.$router.push({
      //   path: "./Purchase",
      //   query:{
      //     type:2,
      //   }
      // });
      // List({ token: this.token, type: 2, name: this.content, p: this.p }).then(
      //   (res) => {
      //     if (res.status == 1) {
      //       let list = JSON.stringify(res.data.data);
      //       localStorage.setItem("list", list);
      //       localStorage.setItem("name", this.content);
      //       this.$router.push({
      //         path: "./purchase",
      //       });
      //     }
      //   }
      // );
    },
    //购物车列表
    getList() {
      Cart_List({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
        }
      });
    },
    colse1() {
      this.show2 = false;
      this.border_blue1 = false;
    },
    showPK() {
      this.show1 = false;
      this.border_blue = false;
      this.show2 = !this.show2;
      this.border_blue1 = !this.border_blue1;
      this.getPklist();
    },
    showCart() {
      this.show2 = false;
      this.border_blue1 = false;
      this.show1 = !this.show1;
      this.border_blue = !this.border_blue;
    },
  },
};
</script>

<style lang="scss" scoped>
.seek_right {
  width: 100%;
}
.seek {
  width: 100%;
  height: 80px;
  padding: 0 260px;
  background: #ffffff;
  .gwc,
  .cp {
    width: 120px;
    height: 45px;
    background: #f9f9f9;
    border: 1px solid #eeeeee;
    position: relative;
    margin-top: 20px;
    padding: 12px 0 0 20px;
    cursor: pointer;
    .dot {
      width: 37px;
      height: 20px;
      background: #ff0000;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 11px;
      position: absolute;
      right: 0px;
      top: -10px;
      text-align: center;
      span {
        width: 16px;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        margin-left: 8px;
        color: #ffffff;
      }
    }
    img {
      width: 19px;
      height: 18px;
      margin: 2px 8px 0 0;
      float: left;
    }
    span {
      font-size: 16px;
      float: left;
      font-weight: 400;
      color: #333333;
    }
  }
  .gwc {
    margin-right: 20px;
  }
}
.seek_input {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  margin-right: 20px;
  input {
    width: 80%;
    height: 45px;
    background: #ffffff;
    border: 1px solid #408df7;
    padding-left: 10px;
    float: left;
  }
  p {
    float: left;
    color: #ffffff;
    font-size: 16px;
    width: 20%;
    height: 45px;
    background: #408df7;
    border: 1px solid #408df7;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
  }
}
.img1 {
  width: 150px;
  height: 34px;
  margin-top: 24px;
  // padding: 24px 100px 0 24px;
}
.pop {
  z-index: 2;
  position: absolute;
  width: 1151px;
  height: 500px;
  background: #ffffff;
  top: 220px;
  left: 508px;
  padding: 20px 55px;
  overflow-y: auto;
}
.pop_list {
  border-bottom: 1px solid #eeeeee;
  float: left;
  width: 100%;
  padding: 24px 0;

  .pop_list_content {
    display: inline-block;
    padding-left: 10px;
    width: 80%;
    margin-top: -20px;
    span {
      font-size: 16px;
      color: #666666;
      margin: 0 24px 10px 0;
    }
  }
}
.pop_list_text {
  p {
    max-width: 200px;
    text-align: right;
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }
}
.pop_img1 {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.t8 {
  position: absolute;
  bottom: 10px;
  p {
    margin-right: 40px;
  }
}
.pop2 {
  height: 400px;
  width: 260px;
  position: absolute;
  top: 45px;
  right: 0;
  // background: #cccccc;
  z-index: 10;
}
.r7 {
  border: 1px solid #408df7 !important;
}
.pop2_list_box {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
  background: #ffffff;
}
.pop2_list {
  width: 100%;
  position: relative;
  height: 80px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eeeeee;
  img {
    width: 60px !important;
    height: 60px !important;
    object-fit: cover;
    float: left;
  }
}
.pop2_list_right {
  max-width: 150px;
  margin-left: 10px;
  span {
    font-size: 12px;
    color: #333333;
  }
}
.pop2_list_num {
  font-size: 12px;
  width: 70%;
  position: absolute;
  top: 45px;
  line-height: 30px;
  p {
    color: #ff0000;
    float: left;
  }
  span {
    float: left;
    margin-left: 20px;
  }
  div {
    color: #cccccc;
    float: right;
    cursor: pointer;
  }
}
.pop2_bottom {
  // position: absolute;
  // bottom: 0;
  padding: 18px 20px 18px 10px;
  width: 100%;
  height: 75px;
  background: #f3f3f3;
  span.gwc-text {
    float: right;
    color: #ffffff;
    font-size: 14px;
    width: 110px;
    height: 40px;
    background: #408df7;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.pop_bottom_left {
  float: left;
  div {
    font-size: 14px;
    color: #333333;
    width: 100%;
    // max-width: 150px;
    p {
      float: left;
      color: #408df7;
      font-weight: bold;
    }
    ol {
      float: right;
    }
  }
}
// pk弹框
.pop3 {
  z-index: 10;
  position: absolute;
  top: 45px;
  left: -1150px;
  width: 1400px;
  height: 200px;
  background: #ffffff;
  padding: 30px 0;
}
.close {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.pop3_left {
  float: left;
  width: 1200px;
  height: 140px;
}
.pop3_list_box {
  float: left;
  width: 33%;
  height: 100%;
  padding: 20px;
  border-right: 1px solid #eeeeee;
  position: relative;
  img {
    width: 100px;
    height: 100px;
  }
  div {
    margin-left: 15px;
    p {
      margin-top: 10px;
      font-size: 14px;
      color: #333333;
      max-width: 230px;
    }
    span {
      font-size: 12px;
      color: #cccccc;
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
  }
}
.pop_right {
  text-align: center;
  width: 200px;
  height: 140px;
  div {
    width: 80px;
    height: 35px;
    background: #408df7;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin: 40px auto 20px;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    color: #408df7;
    cursor: pointer;
  }
}
</style>
