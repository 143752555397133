import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index";
import News from "../views/information/news"; //新闻列表
import Zixun from "../views/information/zixun";
import NewsDetail from "../views/information/newsDetail"; //新闻资讯视频详情
import Industry from "../views/information/industry"; //行业招投标信息
import Maintain from "../views/steward/maintain"; //维修维护
import laboratory from "../views/laboratory/index"; //实验室解决方案
import sm_laboratory from "../views/sm_laboratory/index"; //智能实验室
import UserCenter from "../views/user_center/index"; //个人中心
import shopCart from "../views/procurement/shoppingCart"; //购物车
import about_us from "../views/home/about_us"; //
import Enginner_center from "../views/enginner_center/index"; //工程师端
import Homepage from "../views/procurement/homepage"; //采购服务
import Purchase from "../views/procurement/purchase"; //商品列表
import Purchases from "../views/procurement/purchases"; //商品列表
import CommodityDetails from "../views/procurement/commodityDetails"; //商品详情
import Comparison from "../views/procurement/comparison"; //PK对比页面
import TheOrder from "../views/procurement/theOrder" //结算页
import OrderInfo from "../views/procurement/orderInfo" //querendingdan
import nullBack from "../views/laboratory/nullBack"

// 大屏数据展示
import DataPreview from "../views/datapreview/datapreview"
import DataPreviewAdmin from "../views/datapreviewadmin/datapreviewadmin"

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const routes = [{
  path: "/",
  component: Home,
  meta: {
    keepAlive: false
  }
},
{
  path: "/news",
  component: News,
  meta: {
    keepAlive: false
  }
},
{
  path: "/zixun",
  component: Zixun,
  meta: {
    keepAlive: false
  }
},
{
  path: "/newsDetail",
  component: NewsDetail,
  meta: {
    keepAlive: false
  }
},
{
  path: "/industry",
  component: Industry,
  meta: {
    keepAlive: false
  }
},
{
  path: "/maintain",
  component: Maintain,
  name: 'Maintain',
  hidden: true,
  meta: {
    keepAlive: false
  }
},

{
  path: "/laboratory",
  component: laboratory,
  meta: {
    keepAlive: false
  }
},
{
  path: "/sm_laboratory",
  component: sm_laboratory,
  meta: {
    keepAlive: false
  }
},
{
  path: "/UserCenter",
  component: UserCenter,
  meta: {
    keepAlive: false
  }
},
{
  path: "/shopCart",
  component: shopCart,
  meta: {
    keepAlive: false
  }
},
{
  path: "/about_us",
  component: about_us,
  meta: {
    keepAlive: false
  }
},
{
  path: "/EnginnerCenter",
  component: Enginner_center,
  meta: {
    keepAlive: false
  }
},
{
  path: "/homepage",
  component: Homepage,
},
{
  path: "/purchase",
  name: 'Purchase',
  hidden: true,
  component: Purchase,
  meta: {
    keepAlive: false
  }
},
{
  path: "/purchases",
  name: 'Purchases',
  hidden: true,
  component: Purchases,
  meta: {
    keepAlive: false
  }
},
{
  path: "/commodityDetails",
  component: CommodityDetails,
},
{
  path: "/comparison",
  component: Comparison,
},
{
  path: "/theOrder",
  component: TheOrder
},
{
  path: "/OrderInfo",
  component: OrderInfo
},
{
  path: "/nullBack",
  component: nullBack
}, {
  path: "/datapreview",
  component: DataPreview,
  meta: {
    keepAlive: false
  }
}, {
  path: "/datapreviewadmin",
  component: DataPreviewAdmin,
  meta: {
    keepAlive: false
  }
},
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;