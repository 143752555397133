<template>
  <div class="content-right fl">
    <div class="contact-way">
      <div class="way-inbox">
        <img
          class="telphone-pic"
          src="../../assets/img/lxwm_icon_dh.png"
          alt=""
        />
        <p>免费电话</p>
        <p class="shadow-box"></p>
        <p>{{ list.plane_tel }}</p>
      </div>
      <div class="way-inbox">
        <img
          class="mobile-pic"
          src="../../assets/img/lxwm_icon_sj.png"
          alt=""
        />
        <p>手机号</p>
        <p class="shadow-box"></p>
        <p>{{ list.mobile }}</p>
      </div>
      <div class="way-inbox">
        <img class="qq-pic" src="../../assets/img/lxwm_icon_qq.png" alt="" />
        <p>QQ</p>
        <p class="shadow-box"></p>
        <p>{{ list.qq }}</p>
      </div>
      <div class="way-inbox">
        <img
          class="service-pic"
          src="../../assets/img/lxwm_icon_kf.png"
          alt=""
        />
        <p>人工客服</p>
      </div>
      <div class="way-inbox">
        <img
          class="wx-com-pic"
          src="../../assets/img/lxwm_icon_qywx.png"
          alt=""
        />
        <p>企业微信</p>
        <img class="wx-pic" :src="srce" alt="" />
      </div>
    </div>
    <div class="feedback-input">
      <p class="input-title">意见反馈</p>
      <el-input type="textarea" class="fankui" v-model="content" :maxlength="200"  placeholder="请输入意见反馈"></el-input>
      <div class="num">{{ content.length }}/200</div>
      <p class="input-submit" @click="sub">提交</p>
    </div>
    <div class="clear"></div>
    <!-- <div class="feedback-record">
      <p class="record-title">反馈回复</p>
      <div class="record-item">
        <div class="item-content">
          <div class="record-left">
            <p>2020-02-06</p>
          </div>
          <div class="record-right">
            <p>我的意见</p>
            <p>
              能够自我调节的机器在古代就有，而反馈的概念是在十八世纪的英国进入经济理论领域，但是当时并没有人把它看作一个普适的抽象概念，所以并未为其命名。
            </p>
          </div>
        </div>
        <div class="item-content">
          <p class="record-left">2020-02-06</p>
          <div class="record-right">
            <p>仪器管家回复</p>
            <p>
              能够自我调节的机器在古代就有，而反馈的概念是在十八世纪的英国进入经济理论领域，但是当时并没有人把它看作一个普适的抽象概念，所以并未为其命名。
            </p>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import { service, feedback, Company} from "../../api/index";
export default {
  data() {
    return {
      list: "",
      content: "",
      srce:''
    };
  },
  watch:{
    content(newVal,oldVal){
      if (this.content.length>=200) {
        this.$message("最大输入200字！");
      }
    }
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getInfo();
    this.Companye()
  },
  methods: {
    Companye(){
      Company().then(res=>{
        // console.log(res.data.value)
        this.srce = res.data.value
      })
    },
    sub() {
      if (this.content == "") {
        this.$message("请输入您要反馈的内容");
      } else{
        feedback({ token: this.token, inquiry_desc: this.content }).then(
          (res) => {
            if (res.status == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              setTimeout(() => {
                this.content = "";
              }, 1000);
            } else if (res.status == 0) {
              this.$message.error(res.msg);
            }
          }
        );
      }
    },
    getInfo() {
      service({}).then((res) => {
        this.list = res.result;
        // console.log(res);
      });
    },
    huidiao(){
      // console.log(2234243234)
    }
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  width: 100%;
  .contact-way {
    display: flex;
    flex: row;
    justify-content: space-between;
    .way-inbox {
      width: 19%;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px;
      p {
        margin: 5px 0;
      }
      .shadow-box {
        width: 32px;
        height: 6px;
        background: #eeeeee;
        opacity: 1;
        border-radius: 3px;
      }
      .telphone-pic {
        width: 23px;
        height: 25px;
      }
      .mobile-pic {
        width: 20px;
        height: 28px;
      }
      .qq-pic {
        width: 31px;
        height: 31px;
      }
      .service-pic {
        width: 28px;
        height: 28px;
      }
      .wx-com-pic {
        width: 27px;
        height: 26px;
      }
      .wx-pic {
        width: 88px;
        height: 88px;
      }
    }
  }
  .feedback-input {
    margin: 10px 0;
    width: 100%;
    background-color: #ffffff;
    padding: 30px 30px 30px 50px;
    position: relative;
    .input-title {
      height: 50px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      color: #333333;
    }
    .fankui {
      /deep/ textarea{
      height: 140px;
      border: 1px solid #eeeeee;
      width: 100%;
      padding: 20px;
      margin: 10px 0;
    }}
    .input-submit {
      cursor: pointer;
      width: 185px;
      height: 45px;
      background: #408df7;
      line-height: 45px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-align: center;
      color: #ffffff;
    }
  }
  .feedback-record {
    padding: 30px;
    background-color: #ffffff;
    .record-title {
      height: 50px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      color: #333333;
    }
    .record-item {
      padding: 35px 0 55px;
      border-top: 1px solid #eeeeee;
      .item-content {
        display: flex;
        flex-direction: row;
        padding: 10px 15px;
        .record-left {
          padding: 5px 0;
          width: 20%;
        }
        .record-right {
          width: 80%;
          p:nth-child(1) {
            padding: 5px 0;
          }
          p:nth-child(2) {
            padding: 10px 0;
          }
        }
      }
      .item-content:nth-child(2) {
        background: #f9f9f9;
        .record-right {
          p:nth-child(1) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #408df7;
          }
        }
      }
    }
  }
}
.num {
  position: absolute;
  right: 50px;
  bottom: 105px;
}
</style>