<template>
  <div class="bottom_box clear">
    <footer>
      <div class="bottom">
        <img class="bottom_logo fl" src="../assets/img/yqgj_top_logo.png" />
        <div class="bottom_conter_box fl">
          <p class="bule_text" @click="goHref('/about_us')">关于我们</p>
          <p class="bule_text" @click="goHref('/Homepage')">产品中心</p>
          <p class="bule_text" @click="goHref('/news?tabindex=' + 1)">
            新闻资讯
          </p>
          <p class="bule_text" @click="goHref('/UserCenter?control=' + 10)">
            联系我们
          </p>
          <a href="https://beian.miit.gov.cn/" target="view_window"
            >© 版权所有 贵州众创仪云科技有限公司 黔ICP备19001820号-1</a
          >
        </div>
        <img class="bottom_logo1 fr" src="../assets/img/555.jpg" />
      </div>
    </footer>
  </div>
  <!-- <div class="bottom_box clear">
    <div class="bottom">
      <el-row :gutter="20">
        <el-col :span="12" :offset="0"></el-col>
        <el-col :span="12" :offset="0"></el-col>
        <el-col :span="12" :offset="0"></el-col>
      </el-row>
    </div>
  </div> -->
</template>

<script>
import { Company } from "../api/index";
export default {
  data() {
    return {
      srce: "",
    };
  },
  created() {
    this.Companye();
  },
  methods: {
    goHref(path) {
      this.$router.push(path);
    },
    Companye() {
      Company().then((res) => {
        this.srce = res.data.value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom_box {
  width: 100%;
  background: #f5f5f5;
  height: 220px;
  position: relative;
  bottom: 0;
  // margin-top: 20%;
  .bottom {
    position: absolute;
    bottom: 0;
    // left: 20%;
    // right: 20%;
    min-width: 1440px;
    max-width: 1440px;
    margin: 0 auto;
    left: 0;
    right: 0;
    padding: 0 160px;
    height: 220px;
  }
}
.bottom_conter_box {
  width: 63%;
  height: 64px;
  padding: 0 120px;
  border-left: 1px solid #c1c1c1;
  border-right: 1px solid #c1c1c1;
  margin: 10% 60px 0;
}
.bottom_logo {
  width: 160px;
  height: 50px;
  margin-top: 10%;
}
.bottom_logo1 {
  object-fit: contain;
  width: 120px;
  height: 120px;
  margin-top: 6%;
}
.bottom_conter_box p {
  font-size: 16px;
  color: #999999;
  float: left;
  line-height: 16px;
  // margin-right: 55px;
  width: 25%;
  cursor: pointer;
  text-align: center;
}
.bule_text:hover {
  color: #408df7;
}
.bottom_conter_box a {
  font-size: 14px;
  color: #bbbbbb;
  line-height: 64px;
  display: inline-block;
  width: 100%;
  text-align: center;
}
</style>