<template>
  <div class="content">
    <div class="mobile">
      <img src="../../assets/img/lxwm_icon_dh.png" alt="" />
      <p v-if="!diaoyong">电话报装</p>
      <p v-else>{{diaoyong}}</p>
      <p class="shadow-box"></p>
      <p>{{serveInfo.plane_tel}}</p>
    </div>
    <div class="wechat">
      <div class="wechat-left">
        <img src="../../assets/img/lxwm_icon_qywx.png" alt="" />
        <p>企业微信</p>
        <p class="shadow-box"></p>
        <p>扫描右侧二维码<br />添加企业微信</p>
      </div>
      <div class="wechat-right">
        <img v-if="serveInfo.eweim" :src="serveInfo.eweim" alt="" />
        <img v-else :src="srce" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import { service,Company} from "../../api/index";
export default {
  data() {
    return {
      serveInfo:[],
      srce:''
    };
  },
  created(){
    this.getContactUs();
    this.Companye()
  },
  props:{
    diaoyong:String
  },
  methods:{
    Companye(){
      Company().then(res=>{
        // console.log(res.data.value)
        this.srce = res.data.value
      })
    },
    //获取联系我们客服信息
    getContactUs() {
      service({}).then((res) => {
        if (res.status == 1) {
          this.serveInfo = res.result;
        }
      });
    },
  }
};
</script>
<style lang="scss" scoped>
  .content {
    min-height: 381px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    float: right;
    width: 78%;
    margin-left: 2%;
    padding: 30px;
    .mobile {
      width: 50%;
      float: left;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 110px 0;
      img {
        width: 27.26px;
        height: 29.17px;
      }
      p {
        margin: 10px;
      }
      p:nth-child(2) {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 26px;
        color: #333333;
      }
      p:nth-child(4) {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 29px;
        color: #333333;
      }
    }
    .wechat {
      width: 50%;
      float: right;
      padding: 110px;
      .wechat-left {
        float: left;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 37.1px;
          height: 35.04px;
        }
        p {
          margin: 10px;
          text-align: center;
        }
        p:nth-child(2) {
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 26px;
          color: #333333;
        }
        p:nth-child(4) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #333333;
        }
      }
      .wechat-right {
        float: right;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
    .shadow-box {
      width: 32px;
      height: 6px;
      background: #eeeeee;
      opacity: 1;
      border-radius: 3px;
    }
  }
</style>