import { render, staticRenderFns } from "./preview2.vue?vue&type=template&id=db2812f4&scoped=true&"
import script from "./preview2.vue?vue&type=script&lang=js&"
export * from "./preview2.vue?vue&type=script&lang=js&"
import style0 from "./preview2.vue?vue&type=style&index=0&id=db2812f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db2812f4",
  null
  
)

export default component.exports