<template>
  <div class="content-right fl">
    <div class="title">
      <p
        class="collect-title fl"
        :class="[tabIndex == 0 ? 'blue' : '']"
        @click="chose(0)"
      >
        资讯收藏
      </p>
      <p
        class="collect-title fl"
        :class="[tabIndex == 1 ? 'blue' : '']"
        @click="chose(1)"
      >
        仪器知识收藏
      </p>
      <p
        class="collect-title fl"
        :class="[tabIndex == 2 ? 'blue' : '']"
        @click="chose(2)"
      >
        商品收藏
      </p>
    </div>

    <el-divider></el-divider>
    <div class="collect-box fl">
      <div v-if="list.length < 1" class="m1">暂无收藏</div>
      <div v-if="tabIndex == 1 || tabIndex == 0">
        <div
          class="collect-item"
          v-for="(item, index) in list"
          :key="index"
          @click="goto(item.essay_id,item.article_id, item.is_value)"
        >
          <img :src="item.thumb" alt="" />
          <p class="name one-letter">{{ item.title }}</p>
          <div class="item-bottom">
            <p class="fl">{{ item.add_time }}</p>
            <p class="fl">{{ item.cat_name }}</p>
            <p class="fr" @click="qu(item.article_id)">取消收藏</p>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div v-if="tabIndex == 2">
        <div
          class="collect-item"
          v-for="(item, index) in list"
          :key="index"
          @click="goHref('/CommodityDetails?id=' + item.goods_id)"
        >
          <img :src="item.original_img" alt="" />
          <p class="name one-letter">{{ item.goods_name }}</p>
          <div class="item-bottom">
            <!-- <p class="fl">{{ item.add_time }}</p> -->
            <p class="fl" style="color: #ff0000">￥{{ item.shop_price }}</p>
            <p
              class="fr"
              style="color: #ff0000"
              @click.stop="qu(item.goods_id)"
            >
              取消收藏
            </p>
            <div class="clear"></div>
          </div>
        </div>
      </div>
      <div class="clear"></div>
    </div>
    <div class="collect-page fl" v-if="num > 0">
      <van-pagination
        v-model="p"
        :total-items="num"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { sc_list, sc_qx } from "../../api/User";
export default {
  data() {
    return {
      token: "",
      p: 1,
      tabIndex: 0,
      type: 1,
      list: [],
      arr: "",
      types: "",
      num:''
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
  },
  methods: {
    goto(id1,id, isvideo) {

      if (this.tabIndex ==0) {
        if (isvideo == 2) {
          this.types = 2;
        } else {
          this.types = 1;
        }
        this.$router.push({
          path: "/NewsDetail",
          query: {
            id: id1,
            types: 1,
            isvideo: this.types,
          },
        });
      } else{
        this.$router.push({
          path: "/NewsDetail",
          query: {
            id: id,
            types: 2,
              isvideo: 1,
          },
        });
      }
    },
    qu(id) {
      let that = this;
      this.$confirm("即将取消收藏, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已取消收藏!",
          });
          this.qu_list(id);
          setTimeout(() => {
            this.getList();
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消",
          });
        });
    },
    qu_list(id) {
      if (this.tabIndex == 0) {
        this.arr = 1;
      } else if (this.tabIndex == 1) {
        this.arr = 2;
      } else if (this.tabIndex == 2) {
        this.arr = 3;
      }
      sc_qx({ token: this.token, type: this.arr, id: id }).then((res) => {
      });
    },
    getList() {
      sc_list({ token: this.token, p: this.p, type: this.type }).then((res) => {
        if (res.status == 1) {
          // console.log(res)
          this.list = res.data.info;
          this.num = res.data.count
        }
      });
    },
    chose(index) {
      this.list = []
      this.tabIndex = index;

      if (index == 0) {
        this.type = 1;
      } else if (index == 1) {
        this.type = 2;
      } else if (index == 2) {
        this.type = 3;
      }
      this.p = 1;
      this.getList();
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;

  .collect-box {
    width: 100%;
    height: 100%;
    .collect-item {
      margin: 1%;
      float: left;
      width: 31%;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 5px;
      img {
        width: 100%;
        height: 195px;
        border-radius: 5px 5px 0 0;
        object-fit: cover;
      }
      p.name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
        padding: 5px 15px;
      }
      .item-bottom {
        padding: 10px 15px 15px;
        p {
          cursor: pointer;
        }
        p:nth-child(1),
        p:nth-child(2) {
          margin-right: 20px;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #999999;
        }
        p:nth-child(3) {
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 24px;
          color: #ff0000;
          cursor: pointer;
        }
      }
    }
    .collect-item.active {
      border-bottom: 3px solid #408df7;
      p.name {
        color: #408df7;
      }
    }
  }
  .collect-page {
    margin: 40px 400px;
  }
}
.title {
  width: 100%;
  height: 90px;
  p {
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    padding: 30px 30px 50px 30px;
    cursor: pointer;
  }
}
.blue {
  border-bottom: 4px solid #408df7;
  color: #408df7 !important;
}
.block {
  margin: 0 auto;
}
</style>