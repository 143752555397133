<template>
  <div class="wrap">
    <div class="head-bg">
      <img class="head-title" src="../../assets/img/head_img_title2.png" />
    </div>
    <div class="bg1">
      <span>业务数据</span>
      <div style="margin-top: 10px">
        <p>执行中</p>
        <ul>{{total.order.execution}}</ul>
      </div>
      <div class="bg2">
        <p>验收中</p>
        <ul>{{total.order.check}}</ul>
      </div>
      <div class="bg3">
        <p>已完成</p>
        <ul>{{total.order.finish}}</ul>
      </div>
    </div>
    <div class="bg4">
      <span>采购数据</span>
      <div style="margin-top: 10px">
        <p>耗材采购</p>
        <ul>{{total.goods.consumable}}</ul>
      </div>
      <div class="bg5">
        <p>仪器采购</p>
        <ul>{{total.goods.instrument}}</ul>
      </div>
      <div class="bg6">
        <p>物联网设备采购</p>
        <ul>{{total.goods.facility}}</ul>
      </div>
    </div>
    <div class="bg7">
      <div class="bg7_top">
        <div class="top_left fl">客户数据</div>
        <div class="top-right fr">
          <!-- <div class="box fl">
            <img class="fl" src="../../assets/img/icon_hangye.png" />
            <p class="fl">按行业</p>
          </div> -->
          <img class="fl box_img" src="../../assets/img/icon_locate.png" />
          <el-select
            class="box"
            v-model="value1"
            :placeholder="options[zuobiao]"
            @change="chose"
            style="position: relative;"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item"
              :value="item"
              @click.native="zuobiao = index"
            ></el-option>
          </el-select>
          <!-- <div class="box fl">
            <img class="fl" src="../../assets/img/icon_locate.png" />
            <p class="fl">
              山东省
              <img
                src="../../assets/img/navnar_icon_back.png"
                style="float:right;width:10px;height:10px"
              />
            </p>
          </div>-->
        </div>
      </div>
      <div class="border"></div>
      <div class="bg7_list">
        <div v-if="city.cityUserCount==null" style="color:#fff">暂无数据~！</div>
        <div class="list fl" v-for="item in city.cityUserCount" :key="item">
          <p class="fl">{{item.name}}</p>
          <span class="fl">{{item.user_count}}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <img
        class="fl"
        style=" position: absolute;top: 25px;left:10px"
        src="../../assets/img/icon_locate.png"
      />
      <el-select
        class="box fl"
        v-model="value"
        :placeholder="options[zuobiao]"
        @change="chose1"
        style="position: relative;"
      >
        <el-option
          v-for="(item, index) in options"
          :key="index"
          :label="item"
          :value="item"
          @click.native="zuobiao = index"
        ></el-option>
      </el-select>
      <div class="content_right">
        <p class="fl">仪器总数量(累计):</p>
        <div>
          <span>{{city.instrumentCount}}</span>
        </div>
        <ul>2020-02-02 15:12:13</ul>
      </div>
    </div>
    <div class="city_box">
      <p>{{name?name:'北京市'}}</p>
      <div>
        执行中：
        <span>{{city.order.execution}}</span>
      </div>
      <div>
        验收中：
        <span>{{city.order.check}}</span>
      </div>
      <div>
        已完成：
        <span>{{city.order.finish}}</span>
      </div>
    </div>
    <Map @getCity="getCity"></Map>
    <Right></Right>
  </div>
</template>

<script>
import Right from "../../components/data_right";
import Map from "../../components/map";
import { clientCity, admin_toal } from "../../api/company";
export default {
  components: { Right, Map },
  data() {
    return {
      city: "",
      total: "",
      options: [
        "北京市",
        "天津市",
        "上海市",
        "重庆市",
        "河北省",
        "山西省",
        "辽宁省",
        "吉林省",
        "黑龙江省",
        "江苏省",
        "浙江省",
        "安徽省",
        "福建省",
        "江西省",
        "山东省",
        "河南省",
        "湖北省",
        "湖南省",
        "广东省",
        "海南省",
        "四川省",
        "贵州省",
        "云南省",
        "陕西省",
        "甘肃省",
        "青海省",
        "广西壮族自治区",
        "西藏自治区",
        "宁夏回族自治区",
        "新疆维吾尔自治区",
        "内蒙古自治区",
        "香港特别行政区",
        "澳门特别行政区",
        "台湾省",
      ],
      zuobiao: 0,
      zuobiao1: 0,
    };
  },
  created() {},
  mounted() {
    this.getCity("北京省");
    this.getToal();
  },
  methods: {
    chose(value) {
      this.getCity(value);
    },
    chose1(value) {
      // this.name = value;
      this.getCity(value);
    },
    getToal() {
      admin_toal({}).then((res) => {
        this.total = res.data;
      });
    },
    getCity(value) {
      this.name = value
      clientCity({ invoice_province: value }).then((res) => {
        this.city = res.data;
        console.log(this.city);
      });
    },
  },
};
</script>

<style lang='scss' scoped>
/deep/.l7-bottom {
  display: none;
}
.map-wrap {
  width: 815px;
  height: 600px;
}
.wrap {
  background: url("../../assets/img/bg_1.png");
  background-size: 100% 100%;
  width: 100%;
  min-height: 1500px;
}
.head-bg {
  // position: fixed;
  // left: 0;
  // top: 0;
  background: url("../../assets/img/head_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 82px;
  text-align: center;
  .head-title {
    margin-top: 25px;
    width: 312px;
    height: 32px;
  }
}
.bg1 {
  position: absolute;
  left: 60px;
  top: 100px;
  background: url("../../assets/img/yewu_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 20%;
  height: 350px;
  padding: 20px;
  span {
    font-size: 16px;
    color: #ffffff;
  }
  div {
    background: url("../../assets/img/yewu_img_1.png") no-repeat;
    height: 80px;
    margin-bottom: 20px;
    padding: 10px 20px;
    p {
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    ul {
      font-size: 22px;
      color: #00c0ff;
      font-weight: bold;
    }
  }
}
.bg2 {
  background: url("../../assets/img/yewu_img_2.png") no-repeat !important;
}
.bg3 {
  background: url("../../assets/img/yewu_img_3.png") no-repeat !important;
}
.bg4 {
  position: absolute;
  left: 60px;
  top: 600px;
  background: url("../../assets/img/yewu_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 20%;
  height: 350px;
  padding: 20px;
  span {
    font-size: 16px;
    color: #ffffff;
  }
  div {
    background: url("../../assets/img/caigou_img_1.png") no-repeat;
    height: 80px;
    margin-bottom: 20px;
    padding: 10px 20px;
    p {
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 10px;
    }
    ul {
      font-size: 22px;
      color: #00c0ff;
      font-weight: bold;
    }
  }
}
.bg5 {
  background: url("../../assets/img/caigou_img_2.png") no-repeat !important;
}
.bg6 {
  background: url("../../assets/img/caigou_img_3.png") no-repeat !important;
}
.bg7 {
  position: absolute;
  left: 60px;
  top: 980px;
  width: 40%;
  min-height: 360px;
  background: url("../../assets/img/kehudata_bg_1.png") no-repeat;
  background-size: 100% 100%;
  padding: 0 10px;
}
.top_left {
  font-size: 16px;
  color: #ffffff;
  line-height: 80px;
}
.bg7_top {
  padding: 0 20px;
  height: 80px;
}
.border {
  border-bottom: 0.5px dashed #ffffff;
  opacity: 0.3;
}
.box {
  cursor: pointer;
  width: 110px;
  height: 35px;
  background: rgba(255, 255, 255, 0.11);
  opacity: 1;
  border-radius: 18px;
  margin: 20px 10px 0 0;
  img {
    margin: 5px 5px 0 10px;
  }
  p {
    font-size: 14px;
    color: #ffffff;
    line-height: 35px;
  }
}
.bg7_list {
  padding: 20px;
}
.list {
  min-width: 20%;
  margin-bottom: 10px;
  p {
    font-size: 16px;
    color: #ffffff;
    margin-right: 8px;
  }
  span {
    font-size: 16px;
    color: #00c0ff;
  }
}
.content {
  z-index: 10;
  width: 40%;
  position: absolute;
  top: 150px;
  left: 25%;
  height: 70px;
}
.content_right {
  float: left;
  margin-left: 20px;

  p {
    font-size: 14px;
    color: #ffffff;
    margin: 20px 10px 0 0;
  }
  ul {
    font-size: 14px;
    color: #ffffff;
    opacity: 0.5;
    position: absolute;
    bottom: -25%;
    right: 35%;
  }
  div {
    float: left;
    width: 40px;
    height: 60px;
    background: #0a54ea;
    line-height: 60px;
    text-align: center;
    span {
      font-size: 50px;
      color: #fff;
    }
  }
}
.city_box {
  width: 145px;
  height: 145px;
  border-radius: 5px;
  background: rgba(0, 126, 255, 0.23);
  position: absolute;
  padding: 5px 16px 0;
  top: 90%;
  right: 29%;
  p {
    text-align: center;
    font-size: 16px;
    color: #ffe3ae;
    border-bottom: 1px solid #fff;
    line-height: 30px;
    margin-bottom: 5px;
  }
  div {
    height: 26px;
    font-size: 14px;
    color: #fff;
    span {
      color: #47e6ff !important;
    }
  }
}
.el-select /deep/ .el-input--suffix .el-input__inner {
  cursor: pointer;
  width: 110px;
  height: 35px;
  background: rgba(255, 255, 255, 0.11);
  opacity: 1;
  border-radius: 18px;
  border: none;
  color: #fff;
  padding: 0 30px;
}
.box_img {
  position: absolute;
  top: 25px;
  right: 20%;
}
.el-select /deep/ .el-input__suffix{
  top:5px;
  right: 10px;
}
</style>