<template>
  <div class="content-right fl">
    <div class="content-title fl">
      <p class="fl" @click="goBack()">商品订单</p>
      <p class="fl" @click="goDetails()">> 订单详情</p>
      <p class="fl">> 详情</p>
    </div>
    <div class="content-main">
      <div class="goods-box">
        <div class="goods-item">
          <div class="goods-info fl">
            <img class="fl" :src="item.original_img" alt="" />
            <div class="name-type-place fl">
              <p class="two-letter">{{ item.goods_name }}</p>
              <p>型号：{{ item.goods_sn }}</p>
              <p>产地：{{ item.goods_site }}</p>
            </div>
          </div>
          <div class="goods-price fl">
            <p>¥{{ item.market_price }}</p>
            <p>¥{{ item.goods_price }}</p>
          </div>
          <div class="goods-num fl">
            <p>X{{ item.goods_num }}</p>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <div class="comment-content">
        <div class="content-left fl">
          <div class="comment-item">
            <p class="fl">商品评价</p>
            <div class="star-box fl">
              <van-rate
                v-model="score"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change()"
              />
            </div>
            <p class="fl">{{ score }}星</p>
            <div class="clear"></div>
          </div>
          <div class="comment-item">
            <p class="fl">响应速度</p>
            <div class="star-box fl">
              <van-rate
                v-model="score1"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change1()"
              />
            </div>
            <p class="fl">{{ score1 }}星</p>
            <div class="clear"></div>
          </div>
          <div class="comment-item">
            <p class="fl">物流服务</p>
            <div class="star-box fl">
              <van-rate
                v-model="score2"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change2()"
              />
            </div>
            <p class="fl">{{ score2 }}星</p>
            <div class="clear"></div>
          </div>
          <p>评价说明：4-5星为好评，3星为一般，1-2星为差评</p>
        </div>
        <div class="content-right fl">
          <p>评价商品</p>
          <textarea placeholder="请输入评价内容" v-model="content"></textarea>
        </div>
        <div class="clear"></div>
      </div>
      <div class="upload-header">
        <p class="fl">晒图片</p>
        <p class="">最多上传9张图片</p>
        <div class="clear"></div>
      </div>
      <div class="upload-box">
        <el-upload
          :on-success="qiandiao"
          action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic"
          name="img_file"
          accept="image/*"
          :multiple="true"
          class="e5 fl"
        >
          <img class="upload-pic" src="../../assets/img/pj_but_tjts.png" />
        </el-upload>
        <div class="upload-item1 fl" v-for="(item, index) in Img" :key="index">
          <img class="upload-pic fl" :src="item" />
          <img
            class="upload-del"
            src="../../assets/img/sc_img_but_sc.png"
            @click="del(index)"
          />
          <!-- <div class="clear"></div> -->
        </div>
      </div>
      <p class="upload-text">晒出您的照片，让更多用户看到吧~</p>
      <el-divider></el-divider>

      <p class="comment-btn" @click="sub()">提交评价</p>
    </div>
  </div>
</template>
<script>
import { PL } from "../../api/User";
export default {
  data() {
    return {
      score: 5, //商品评价
      score1: 5, //响应速度
      score2: 5, //物流服务
      item: "",
      imgurl2: require("../../assets/img/icon_pj_s.png"),
      imgurl1: require("../../assets/img/icon_pj_n.png"),
      upIndex: 0,
      content: "",
      Img: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.item = JSON.parse(localStorage.getItem("data"));
    // console.log(this.item);
  },
  methods: {
    //提交评价
    sub() {
      let data = {
        token: this.token,
        goods_id: this.item.goods_id,
        spec_key: this.item.spec_key,
        order_id: this.item.order_id,
        service_rank: this.score,
        deliver_rank: this.score1,
        goods_rank: this.score2,
        content: this.content,
        imgs: this.Img,
      };
      // console.log(data);
      PL(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.goBack();
          }, 1000);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    del(index) {
      // console.log(this.Img);
      this.Img.splice(index, 1);
    },

    //上传图片
    qiandiao(response, file, fileList) {
      if (fileList.length > 9) {
        this.$message("最多可上传9张");
        this.Img.push(response.result.url);
        this.Img.splice(9, this.Img.length - 9);
      } else {
        this.Img.push(response.result.url);
      }
    },
    change() {
      // console.log(this.score);
    },
    change1() {
      // console.log(this.score1);
    },
    change2() {
      // console.log(this.score2);
    },
    //商品详情
    goDetails() {
      this.$emit('change');
    },
    //跳转商品订单
    goBack() {
      this.$emit('change1');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-title {
    width: 100%;
    padding-left: 115px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      cursor: pointer;
    }
  }
  .content-main {
    padding: 100px 115px;
    .goods-box {
      .goods-item {
        background: #ffffff;
        border: 1px solid #eeeeee;
        padding: 20px 30px;
        .goods-info {
          width: 50%;
          img {
            width: 120px;
            height: 100px;
          }
          .name-type-place {
            width: 60%;
            margin-left: 20px;
            p {
              font-weight: 400;
              line-height: 20px;
            }
            p:nth-child(1) {
              font-size: 14px;
              color: #333333;
              margin-bottom: 8px;
            }
            p:nth-child(2),
            p:nth-child(3) {
              font-size: 13px;
              color: #999999;
            }
          }
        }
        .goods-price {
          width: 35%;
          text-align: center;
          p {
            font-size: 14px;
            color: #333333;
          }
        }
        .goods-price p:nth-of-type(1) {
          color: #999999;
          text-decoration: line-through;
        }
        .goods-num {
          width: 15%;
          text-align: center;
        }
      }
    }
    .comment-content {
      padding: 20px 0;
      .content-left {
        width: 40%;
        .comment-item {
          border-bottom: 1px solid #eeeeee;
          p:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
            line-height: 70px;
            color: #333333;
            margin-right: 30px;
          }
          .star-box {
            height: 70px;
            margin-right: 20px;
            img {
              width: 26px;
              height: 25px;
              position: relative;
              top: 30%;
              margin: 0 5px;
            }
          }
          p:nth-child(3) {
            font-size: 16px;
            font-weight: bold;
            line-height: 70px;
            color: #408df7;
          }
        }
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 32px;
          color: #999999;
        }
      }
      .content-right {
        width: 58%;
        padding-left: 80px;
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 60px;
          color: #333333;
        }
        textarea {
          width: 100%;
          height: 160px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          border-radius: 5px;
          padding: 15px;
        }
      }
    }
    .upload-header {
      p:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
        margin-right: 40px;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #ff0000;
      }
    }
    .upload-box {
      height: 80px;
      .upload-item {
        background: url("../../assets/img/pj_but_tjts.png") no-repeat;

        margin-right: 10px;
        .upload-pic {
          width: 76px;
          height: 76px;
          position: relative;
        }
        .upload-del {
          cursor: pointer;
          width: 30px;
          height: 20px;
          position: absolute;
          left: 46px;
        }
      }
    }
    .upload-text {
      margin-top: 10px;
      font-size: 12px;
      color: #999999;
    }
    .comment-btn {
      width: 210px;
      height: 50px;
      background: #408df7;
      font-size: 16px;
      font-weight: 400;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  }
}
.van-rate /deep/ .van-icon__image {
  width: 26px;
  height: 25px;
  margin: 20px 5px;
}
.van-rate /deep/ .van-rate__item:not(:last-child) {
  padding-right: 17px;
}
.upload-item1 {
  height: 80px;
  margin: 0 5px;
  .upload-pic {
    // position: relative;
    width: 76px;
    height: 76px;
    object-fit: cover;
  }
  .upload-del {
    width: 30px;
    height: 20px;
    margin-left: -30px;
  }
}
.e5 /deep/ .el-upload--picture-card {
  float: left;
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  margin-right: 10px !important;
}
.e5 /deep/ .el-upload-list--picture-card {
  margin-left: 80px !important;
}
.e5 /deep/ .el-upload-list {
  display: none !important;
}
.e5 /deep/ .el-upload-list__item-status-label {
  display: none !important;
}
.e5 /deep/.el-upload-list__item-actions {
  margin-left: 100px !important;
}
.e5 /deep/.el-upload-list__item {
  width: 76px !important;
  height: 76px !important;
  img {
    object-fit: cover;
  }
}
</style>