<template>
    <div>
        <Top></Top>
        <div class="complateeee">
        <p class="titleeee">{{content.title}}</p>
        <div v-html="content.content"></div>
        </div>
        <Bottom></Bottom>
    </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import {
  articleUser
} from "../../api/index";
export default {
    components: { Top, Bottom },
   data() {
      return {
         content:''
      }
   },
   created(){
    this.canshu()
   },
   methods:{
       canshu(){
           articleUser().then(res=>{
               this.content = res.data
           })
       }
   }
}
</script>
<style lang='scss'>
   .complateeee{
       padding: 20px 20%;
       min-height: 650px;
       .titleeee{
           font-size: 20px;
           text-align: center;
           font-weight: 700;
           margin-bottom: 10px;
       }
   }
</style>