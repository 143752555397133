<template>
  <div class="content-right">
    <div class="content-main">
      <div class="task-coupon">
        <p class="task-title">新人专享</p>
        <el-divider></el-divider>
        <el-row :gutter="20">
          <el-col
            :span="8"
            :offset="0"
            v-for="(item, index) in list"
            :key="index"
          >
            <div
              class="task-item"
              :class="[
                item.display == 2 ? 'unfinished' : '',
                item.is_receive == 1 ? 'received' : '',
              ]"
            >
              <p class="task-item-title">{{ item.description }}</p>
              <div class="coupon">
                <div class="item-left fl">
                  <p>
                    <span>￥</span><span>{{ item.money }}</span>
                  </p>
                  <p>满{{ item.condition }}元可用</p>
                </div>
                <div class="item-right fl">
                  <p>{{ item.name }}</p>
                  <p>{{ item.use_start_time }}-{{ item.use_end_time }}</p>
                </div>
                <div class="clear"></div>
              </div>
              <div class="clear"></div>
              <p class="task-btn" @click="ling(item.id)">立即领取</p>
            </div>
          </el-col>
        </el-row>

        <div class="clear"></div>
      </div>
      <!-- unfinished //未完成样式 received已领取 -->
      <div class="my-coupon">
        <p class="coupon-title">我的代金券</p>
        <el-divider></el-divider>
        <div class="coupon-box">
          <div class="coupon-item" v-for="(item, index) in lists" :key="index">
            <div class="item-left fl">
              <p>
                <span>￥</span><span>{{ item.money }}</span>
              </p>
              <p>满{{ item.condition }}元可用</p>
            </div>
            <div class="item-right fl">
              <p>{{ item.name }}</p>
              <p>{{ item.use_start_time }}-{{ item.use_end_time }}</p>
            </div>
            <div class="clear"></div>
          </div>

          <div class="clear"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { card, ling } from "../../api/User";
export default {
  data() {
    return {
      token: "",
      list: [], //全部
      list2: [], //已领取的
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
  },
  methods: {
    ling(id) {
      ling({ id: id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.getList();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    getList() {
      card({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.list = res.data.coupon_list;
          this.lists = res.data.coupon_lists;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 270px;
  min-width: 1130px;
  // max-width: 80%;
  margin-bottom: 20px;
  .content-main {
    .task-coupon {
      overflow: hidden;
      padding: 0 30px 30px;
      background-color: #ffffff;
      margin-bottom: 10px;
      .task-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
      }
      .task-item {
        width: 100%;
        float: left;
        // margin: 1% 0;
        .task-item-title {
          font-size: 16px;
          font-weight: 400;
          line-height: 50px;
          color: #333333;
          padding-left: 20px;
        }
        .task-item-title::after {
          background-color: #408df7;
          width: 6px;
          height: 6px;
          content: " ";
          border-radius: 50%;
          display: inline-block;
          position: relative;
          top: 10%;
          left: -70%;
        }
        .coupon {
          width: 100%;
          height: 104px;
          background: url("../../assets/img/gcs_yhq_bg_ylq.png");
          background-size: 100% 100%;
          border-radius: 5px;
          float: left;
          .item-left {
            width: 41%;
            padding: 20px 10px 10px 33px;
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              line-height: 28px;
              color: #ffffff;
            }
            p:nth-child(1) {
              span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                color: #ffffff;
              }
              span:nth-child(2) {
                font-size: 30px;
                font-weight: bold;
                line-height: 28px;
                color: #ffffff;
              }
            }
          }
          .item-right {
            width: 59%;
            padding: 25px 15px;
            p {
              width: 80%;
            }
            p:nth-child(1) {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #999999;
              padding-bottom: 10px;
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #999999;
            }
          }
        }
        .task-btn {
          cursor: pointer;
          width: 95px;
          height: 34px;
          background: #ffffff;
          border: 1px solid #408df7;
          opacity: 1;
          border-radius: 3px;
          font-size: 16px;
          font-weight: 400;
          line-height: 34px;
          text-align: center;
          color: #408df7;
          margin-top: 25px;
        }
      }

      .task-item.unfinished {
        .task-item-title::after {
          background-color: #eeeeee;
        }
        .coupon {
          background: url("../../assets/img/gcs_yhq_bg_wlq.png");
          background-size: 100% 100%;
        }
        .task-btn {
          color: #aaaaaa;
          border: 1px solid #eeeeee;
        }
      }
      .task-item.received {
        .task-item-title::after {
          background-color: #eeeeee;
        }
        .coupon {
          background: url("../../assets/img/gcs_yhq_bg_wlq.png");
          background-size: 100% 100%;
        }
        .task-btn {
          color: #aaaaaa;
          border: none;
        }
      }
      div.task-item:nth-child(2n) {
        margin: 1% 15px;
      }
    }
    .my-coupon {
      padding: 0 30px 30px;
      background-color: #ffffff;
      .coupon-title {
        font-size: 16px;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
      }
      .coupon-box {
        .coupon-item {
          width: 32%;
          height: 104px;
          background: url("../../assets/img/gcs_yhq_bg_ylq.png");
          background-size: cover;
          margin: 1% 2% 1% 0;
          border-radius: 5px;
          float: left;
          .item-left {
            width: 41%;
            padding: 20px 10px 10px 33px;
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              line-height: 28px;
              color: #ffffff;
            }
            p:nth-child(1) {
              span:nth-child(1) {
                font-size: 14px;
                font-weight: 400;
                line-height: 28px;
                color: #ffffff;
              }
              span:nth-child(2) {
                font-size: 30px;
                font-weight: bold;
                line-height: 28px;
                color: #ffffff;
              }
            }
          }
          .item-right {
            width: 59%;
            padding: 25px 15px;
            p {
              width: 80%;
            }
            p:nth-child(1) {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #dbb461;
              padding-bottom: 10px;
            }
            p:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              color: #999999;
            }
          }
        }
        .coupon-item:nth-child(3n) {
          margin-right: 0;
        }
      }
    }
    .el-divider--horizontal {
      margin: 20px 0;
      background: 0 0;
      border-top: 1px dashed #e8eaec;
    }
  }
}
</style>