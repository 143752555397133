<template>
  <div class="top_box">
    <children ref="child"></children>
    <div class="top fl no_blue">
      <el-row :gutter="0">
        <el-col :span="4" :offset="0">
          <img class="fl top1" src="../assets/img/yqgj_top_logo.png" @click="goHref('/')" />
        </el-col>
        <el-col :span="20" :offset="0">
          <div class="top_right fr">
            <p @click="goHref('/')" class="blue_text text-center">首页</p>

            <p>
              <el-dropdown>
                <span class="el-dropdown-link">管家服务</span>
                <el-dropdown-menu slot="dropdown" placement="bottom" class="steward_box">
                  <div class="steward_left fl">
                    <div>维修维护</div>
                    <p @click="goHref('/Maintain')">安装调试</p>
                    <p @click="goHref('/Maintain')">故障维修</p>
                    <p @click="goHref('/Maintain')">仪器保养</p>
                  </div>
                  <div class="steward_content fl">
                    <div>实验室建设方案</div>
                    <p @click="goHref('/laboratory')">方案获取</p>
                    <p @click="goHref('/laboratory')">业务简介</p>
                  </div>
                  <div class="steward_right fl" v-if="user_id">
                    <div>智能实验室</div>
                    <p @click="goHref('/sm_laboratory')">实验室管理</p>
                    <p @click="goHref('/sm_laboratory')">数据报告</p>
                  </div>
                  <div class="steward_right fl" v-if="user_id==null">
                    <div>智能实验室</div>
                    <p @click="show_login()">实验室管理</p>
                    <p @click="show_login()">数据报告</p>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </p>
            <p>
              <el-dropdown>
                <span class="el-dropdown-link">仪器资讯</span>
                <el-dropdown-menu slot="dropdown" placement="bottom">
                  <el-dropdown-item @click.native="goHref('/news?tabindex=' + 1)">新闻资讯</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/news?tabindex=' + 2)">仪器知识</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/Industry')">行业招投标信息</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/zixun')">
                    {{
                    ZXZS.info
                    }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </p>
            <p @click="goHref('/Homepage')" class="blue_text">采购服务</p>
            <p v-if="use_id" @click="goHref('/datapreview')" class="blue_text text-center">数据展示</p>
            <p v-if="user_id==null" @click="show_login()" class="blue_text text-center">数据展示</p>
            <!-- <p class="blue_text">数据展示</p> -->
            <p @click="goHref('/about_us')" class="blue_text">关于我们</p>
            <p @click="pop_login" v-if="token == '' || token == undefined || token == null">登录/注册</p>
            <div class="fr" v-if="level == 2">
              <!-- <p
                @click="pop_login"
                v-if="token == '' || token == undefined || token == null"
              >
                登录/注册
              </p>-->
              <el-dropdown>
                <img class="w1" v-if="token" :src="head_pic" />
                <el-dropdown-menu class="head_list">
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=1', 1)">用户信息</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=2', 2)">仪器管理</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=3', 3)">我的工程师</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=4', 4)">我的订单</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=5', 5)">我的库存</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=6', 6)">会员中心</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=7', 7)">我的单位</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/UserCenter?control=10', 10)">联系客服</el-dropdown-item>
                  <el-dropdown-item @click.native="close_out">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div class="fr" v-if="level == 1">
              <el-dropdown class="my-el-dropdown">
                <img class="w1" v-if="token" :src="head_pic" />
                <el-dropdown-menu class="head_list">
                  <el-dropdown-item @click.native="goHref('/EnginnerCenter?control=' + 1)">我要接单</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/EnginnerCenter?control=' + 2)">我的客户</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/EnginnerCenter?control=' + 3)">新人专享</el-dropdown-item>
                  <el-dropdown-item @click.native="goHref('/EnginnerCenter?control=' + 5)">联系客服</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--登录弹框 -->
    <van-popup v-model="show1" class="pop">
      <div class="qr_code" id v-show="qr_code">
        <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close8()" />
        <dl>微信登录</dl>
        <div class="pop_img2" id="qrcode" @click="chose_phone">
          <wxlogin
            appid="wxdb9fcea99c981de2"
            :scope="'snsapi_login'"
            :theme="'black'"
            :redirect_uri="encodeURIComponent('https://www.yqgjy.com/#/')"
            :href="'data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5zdGF0dXMuc3RhdHVzX2Jyb3dzZXIgewogIGRpc3BsYXk6IG5vbmU7Cn0KLmltcG93ZXJCb3ggLnFyY29kZSB7CiAgYm9yZGVyOiBub25lOwogIHdpZHRoOiAxNTBweDsKICBoZWlnaHQ6MTUwcHg7Cn0KaWZyYW1lewogaGVpZ2h0OjE2MHB4ICFpbXBvcnRhbnQ7Cn0KLmltcG93ZXJCb3ggLnN0YXR1c3sKICBkaXNwbGF5OiBub25lCn0KLmluZm97CiAgcG9zaXRpb246IGFic29sdXRlOwogIHRvcDo1MHB4OwogIGJhY2tncm91bmQ6I2ZmZmZmZjsKICB6LWluZGV4OjEwOwp9'"
            rel="external nofollow"
          ></wxlogin>
        </div>
        <div class="pop_bottom_login">
          <p>请使用微信扫描二维码登录“仪器管家”</p>
          <div class="phone" @click="chose_phone">手机验证码一键登录></div>
        </div>
      </div>
      <!--未注册提示 -->
      <div class="not_register" v-show="not_register">
        <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close9" />
        <dl>未注册账户</dl>
        <img class="not_img" src="../assets/img/dl_img_zl.png" />
        <div>请先注册账户，并且完善信心才能 登录仪器管家</div>
        <p @click="chose_register()">去注册></p>
      </div>
      <!-- 选择用户登录 -->
      <div class="user_box" v-show="user">
        <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close1" />
        <div class="user fl" @click="pt_login">
          <img class="user1 fl" src="../assets/img/dl_js_icon_ptjs.png" />
          <p class="fl">我是用户</p>
          <img class="fr" src="../assets/img/icon_xyb_b.png" />
        </div>
        <div class="user user2 fl" @click="gcs_login">
          <img class="user1 fl" src="../assets/img/dl_js_icon_gcs.png" />
          <p class="fl">我是工程师</p>
          <img class="fr" src="../assets/img/icon_xyb_b.png" />
        </div>
      </div>
      <!-- 普通用户登录 -->
      <div class="pt_login_box" v-show="pt_box">
        <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close2" />
        <div class="pt_login">
          <div class="pt_login_top">
            <p class="fl">用户登录</p>
            <div class="fr" @click="chose_gcs">工程师登录></div>
          </div>
          <input class="phone_input" placeholder="请输入手机号" v-model="phone" />
          <div class="ver_code fl">
            <input class="fl" placeholder="请输入验证码" v-model="pt_logCode" />
            <p class="fl" @click="getCode(2)">{{ regCodeTip }}</p>
          </div>
          <div class="btn fl" @click="go_ptlogin">登录</div>
          <div class="pt_bottom fl">
            <p class="fl">还没账号?</p>
            <span class="fl" @click="register_pt">完善用户信息</span>
            <div class="pt_right fr" @click="chose_wx">
              <img class="fl" src="../assets/img/dl_icon_wx_s.png" />
              <div class="fl">微信登录</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 工程师 -->
      <div class="pt_login_box" v-show="gcs_box">
        <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close3" />
        <div class="pt_login">
          <div class="pt_login_top">
            <p class="fl">工程师登录</p>
            <div class="fr" @click="chose_pt">用户登录></div>
          </div>
          <input class="phone_input" placeholder="请输入手机号" v-model="phone" />
          <div class="ver_code fl">
            <input class="fl" placeholder="请输入验证码" v-model="gcs_logCode" />
            <p class="fl" @click="getCode(4)">{{ regCodeTip }}</p>
          </div>
          <div class="btn fl" @click="go_gcslogin">登录</div>
          <div class="pt_bottom fl">
            <p class="fl">还没账号?</p>
            <span class="fl" @click="register_gcs">完善工程师信息</span>
            <div class="pt_right fr" @click="chose_wx">
              <img class="fl" src="../assets/img/dl_icon_wx_s.png" />
              <div class="fl">微信登录</div>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!-- 普通用户注册 -->
    <div class="login_pt" v-show="pt_sign">
      <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close4" />
      <div class="login_pt_box">
        <p>完善用户信息</p>
        <input class="input" placeholder="您的姓名" v-model="pt_name" />
        <input class="input" placeholder="请填写手机号" v-model="phone" />
        <div class="code_box fl">
          <input class="fl" placeholder="请填写验证码" v-model="pt_code" />
          <div class="fr" @click="getCode(1)">{{ regCodeTip }}</div>
        </div>
        <input class="input" placeholder="请填写邮箱" v-model="pt_mail" />
        <el-select v-model="name" placeholder="请选择" class="units" @change="chose_list">
          <el-option v-for="item in companylist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="name1" placeholder="请选择" class="units" @change="chose_list1">
          <el-option v-for="item in Joblist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="btn btn1" @click="btn1">确认</div>
      </div>
    </div>
    <!-- 工程师注册 -->
    <div class="login_pt gcs" v-show="gcs_sign">
      <img class="pop_img1 fr" src="../assets/img/pop_but_gb.png" @click="close5" />
      <div class="login_pt_box">
        <p>完善工程师信息</p>
        <input class="input" placeholder="您的姓名" v-model="gcs_name" />
        <input class="input" placeholder="请填写手机号" v-model="phone" />
        <div class="code_box fl">
          <input class="fl" placeholder="请填写验证码" v-model="gcs_code" />
          <div class="fr" @click="getCode(3)">{{ regCodeTip }}</div>
        </div>
        <div class="certificate_box fl">
          <div class="certificate fl">
            <span>资质证书</span>
            <div>没有证书可以不上传</div>
          </div>
          <van-uploader :after-read="upLoad" :max-count="1" class="fr q2">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <img v-else class="fr" src="../assets/img/but_sctup_dl.png" />
          </van-uploader>
        </div>
        <input class="input" placeholder="请填写邮箱" v-model="gcs_mail" />
        <el-select v-model="name" placeholder="请选择" class="units" @change="chose_list">
          <el-option v-for="item in companylist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-select v-model="name1" placeholder="请选择" class="units" @change="chose_list1">
          <el-option v-for="item in Joblist" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="btn btn1" @click="btn2">确认</div>
      </div>
    </div>
    <div class="box_box" v-if="none"></div>
  </div>
</template>

<script>
import {
  companylist,
  getcode,
  getJob,
  login,
  wxLogin,
  perfect,
  UseInfo,
  articleUser,
} from "../api/index";
import { List } from "../api/goods";
import wxlogin from "vue-wxlogin";
import { mapState } from "vuex";
export default {
  name: "child",
  components: { wxlogin },
  data() {
    return {
      none: false,
      text: "", //搜索内容
      // show: false, //管家服务弹框
      show1: false, //登录弹框\
      qr_code: true,
      type: "", //验证码类型
      name: "", //单位名称
      companylist: [], //单位列表
      companylist_id: "", //单位名称id
      name1: "", //职位名称
      Joblist: [], //职位列表
      job_id: "", //职务id
      user: false, //选择用户登录
      pt_box: false, //普通用户登录
      pt_sign: false, //普通用户注册
      regCodeFlag: true,
      regTimeCount: null, //验证码部分
      phone: "", //普通用户
      pt_name: "",
      pt_code: "",
      pt_mail: "",
      regCodeTip: "发送验证码",
      pt_logCode: "",
      gcs_box: false, //工程师登录
      gcs_sign: false, //工程师注册
      not_register: false, //未注册提示
      gcs_logCode: "",
      gcs_name: "",
      gcs_code: "",
      gcs_mail: "",
      imageUrl: "", //资质图片地址
      head_pic: "",
      level: "", //等级
      code: "",
      p: 1,
      mold: "",
      token: "",
      user_id: "",
      state: true,
      timer: null,
      User: "",
      ZXZS: "",
    };
  },
  created() {
    this.zixunText();
    this.getToken();
    this.head_pic = localStorage.getItem("head_pic");
    this.level = localStorage.getItem("level");
    this.getcompanList(); //单位列表
    var url = location.href;
    console.log(url);
    // this.daoji();
    // this.getUserInfo();
    setInterval(() => {
      if (url != location.href) {
        url = location.href;
        var temp1 = url.split("?");
        var pram = temp1[1];
        var keyValue = pram.split("&");
        var obj = {};
        for (var i = 0; i < keyValue.length; i++) {
          var item = keyValue[i].split("=");
          var key = item[0];
          var value = item[1];
          obj[key] = value;
        }
        console.log(obj.code);
        this.code = obj.code;
        if (this.code) {
          this.WX();
        } else {
          return;
        }
      }
    }, 150);
  },

  methods: {
    WX() {
      wxLogin({ code: this.code }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("level", res.data.is_identity);
          localStorage.setItem("phone", res.data.mobile);
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("user_id", res.data.user_id);
          this.user_id = res.data.user_id;
          this.getToken();
          setTimeout(() => {
            this.show1 = false;
            this.$router.go(0);
          }, 1000);
        } else if (res.status == 2) {
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("level", res.data.is_identity);
          localStorage.setItem("phone", res.data.mobile);
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("user_id", res.data.user_id);
          this.user_id = res.data.user_id;
          this.getToken();
          //未完善资料  弹出用户端
          this.$message(res.info);
          this.qr_code = false;
          this.not_register = true;
        } else if (res.status == 0) {
          this.$message(res.info);
        }
      });
    },
    // daoji() {
    //   if (this.token) {
    //     this.timer = setInterval(() => {
    //       this.getUserInfo();
    //     }, 300000);
    //   }
    // },
    // getUserInfo() {
    //   UseInfo({ token: this.token }).then((res) => {
    //     if (res.status == 1) {
    //       this.User = res.result;
    //     }
    //   });
    // },
    //搜索
    sou() {
      List({ token: this.token, type: 2, name: this.text, p: this.p }).then(
        (res) => {
          if (res.status == 1) {
            let list = JSON.stringify(res.data.data);
            localStorage.setItem("list", list);
            localStorage.setItem("name", this.text);
            this.$router.push({
              path: "./purchase",
            });
          }
        }
      );
    },
    show_login() {
      this.$message("请登录后查看");
    },
    getToken() {
      this.token = localStorage.getItem("token");
      this.user_id = localStorage.getItem("user_id");
    },
    //退出登录
    close_out() {
      let that = this;
      this.$confirm("即将退出登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已退出!",
          });
          localStorage.removeItem("token");
          localStorage.removeItem("level");
          localStorage.removeItem("user_id");
          that.getToken();
          setTimeout(() => {
            that.$router.go(0);
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消退出",
          });
        });
    },
    //工程师登录
    go_gcslogin() {
      login({ mobile: this.phone, type: 1, code: this.gcs_logCode }).then(
        (res) => {
          if (res.status == 1) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("level", res.data.is_identity);
            localStorage.setItem("phone", res.data.mobile);
            localStorage.setItem("head_pic", res.data.head_pic);
            localStorage.setItem("user_id", res.data.user_id);
            this.getToken();
            this.$message({
              message: res.info,
              type: "success",
            });
            setTimeout(() => {
              this.close3();
              this.$router.go(0);
            }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        }
      );
    },
    //普通用户登录
    go_ptlogin() {
      login({ mobile: this.phone, type: 2, code: this.pt_logCode }).then(
        (res) => {
          if (res.status == 1) {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("level", res.data.is_identity);
            localStorage.setItem("phone", res.data.mobile);
            localStorage.setItem("head_pic", res.data.head_pic);
            localStorage.setItem("user_id", res.data.user_id);
            this.$message({
              message: res.info,
              type: "success",
            });

            this.getToken();
            setTimeout(() => {
              this.close2();
              this.$router.go(0);
            }, 1000);
          } else if (res.status == 0) {
            this.$message.error(res.info);
          }
        }
      );
    },
    close8() {
      this.show1 = false;
      this.$router.go(0);
    },
    //完善资料
    btn1() {
      let data = {
        user_id: this.user_id,
        user_name: this.pt_name,
        mobile: this.phone,
        code: this.pt_code,
        email: this.pt_mail,
        is_identity: 2,
        company_id: this.companylist_id,
        position_id: this.job_id,
      };
      perfect(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          setTimeout(() => {
            this.close4();
            this.close8();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //资质上传
    upLoad(file) {
      const formData = new FormData();
      formData.append("img_file", file.file);
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic",
          formData,
          config
        )
        .then((res) => {
          if (res.data.status == 1) {
            this.imageUrl = res.data.result.url;
          }
        });
    },
    //完善资料
    btn2() {
      let data = {
        user_name: this.gcs_name,
        user_id: this.user_id,
        mobile: this.phone,
        code: this.gcs_code,
        email: this.gcs_mail,
        is_identity: 1,
        company_id: this.companylist_id,
        position_id: this.job_id,
        zlcred: this.imageUrl,
      };
      perfect(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          setTimeout(() => {
            this.close5();
            this.close8();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    chose_list1(id) {
      this.job_id = id;
    },
    chose_list(id) {
      this.companylist_id = id;
      this.getJoblist();
    },
    //普通注册-发送验证码
    getCode(num) {
      if (this.state) {
        this.state = false;
        if (num == 1) {
          this.type = 6;
        } else if (num == 2) {
          this.type = 4;
        } else if (num == 3) {
          this.type = 5;
        } else if (num == 4) {
          this.type = 3;
        }
        getcode({ mobile: this.phone, type: this.type }).then((res) => {
          if (res.status == 0) {
            this.$message.error(res.info);
            this.state = true;
          } else if (res.status == 1) {
            this.$message({
              message: res.info,
              type: "success",
            });
            //获取验证码
            if (this.regCodeFlag) {
              this.regCodeFlag = false;
              let time = 60;
              this.regTimeCount = setInterval(() => {
                time--;
                if (time > 0) {
                  this.regCodeTip = time + "s";
                } else {
                  this.state = true;
                  this.regCodeTip = "获取验证码";
                  this.regCodeFlag = true;
                  clearInterval(this.regTimeCount);
                }
              }, 1000);
            }
          }
        });
      } else {
        return;
      }
      // 1普通注册--2普通登录
    },
    //单位职务
    getJoblist() {
      getJob({ company_id: this.companylist_id }).then((res) => {
        if (res.status == 1) {
          this.Joblist = res.data;
        }
      });
    },
    //单位列表
    getcompanList() {
      companylist({}).then((res) => {
        if (res.status == 1) {
          this.companylist = res.data;
        }
      });
    },

    close9() {
      this.show1 = false;
      this.not_register = false;
      this.qr_code = true;
    },
    //关闭
    close1() {
      this.user = false;
      this.qr_code = true;
      this.show1 = false;
    },
    close2() {
      this.none = false;
      this.pt_box = false;
      this.qr_code = true;
      this.show1 = false;
    },

    close3() {
      this.none = false;
      this.gcs_box = false;
      this.qr_code = true;
      this.show1 = false;
    },
    close4() {
      this.none = false;
      this.pt_sign = false;
      this.pt_box = false;
      this.qr_code = true;
      this.show1 = false;
    },
    close5() {
      this.none = false;
      this.gcs_sign = false;
      this.gcs_box = false;
      this.qr_code = true;
      this.show1 = false;
    },
    //工程师注册
    register_gcs() {
      this.none = true;
      this.gcs_sign = true;
      this.show1 = false;
    },
    //普通注册
    register_pt() {
      this.none = true;
      this.pt_sign = true;
      this.show1 = false;
    },
    //跳转选择用户
    chose_register() {
      this.user = true;
      this.not_register = false;
    },
    //切换手机号登录
    chose_phone() {
      (this.qr_code = false), (this.user = true);
    },
    //普通用户登录
    pt_login() {
      this.user = false;
      this.pt_box = true;
    },
    chose_pt() {
      this.pt_box = true;
      this.gcs_box = false;
    },
    //工程师登录
    gcs_login() {
      this.gcs_box = true;
      this.user = false;
    },
    chose_gcs() {
      this.gcs_box = true;
      this.pt_box = false;
    },
    //微信登录
    chose_wx() {
      this.qr_code = true;
      this.pt_box = false;
      this.gcs_box = false;
    },

    //登录弹框
    pop_login() {
      this.show1 = true;
    },
    goHref(path, index) {
      this.count = index;
      this.$router.push(path);
    },
    //咨讯展示
    zixunText() {
      articleUser().then((res) => {
        this.ZXZS = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#qrcode {
  height: 160px !important;
}
.top_box {
  padding: 0 160px;
  height: 100px;
  background: #ffffff;
}
.top {
  width: 100%;
  height: 100px;
  line-height: 100px;
}
.top1 {
  width: 157px;
  height: 48px;
  margin-top: 40px;
  cursor: pointer;
}
.top_conter {
  width: 38%;
  height: 55px;
  background: #ffffff;
  margin: 30px 0 0 60px;
}
.top_right {
  font-size: 14px;
  color: #333333;
  p {
    float: left;
    padding-left: 40px;
    cursor: pointer;
    :hover {
      color: #408df7;
    }
  }
}
.blue_text:hover {
  color: #408df7;
}
.top_conter1 {
  cursor: pointer;
  width: 60px;
  height: 53px;
  background: #408df7;
  img {
    margin: 20px 20px 0 0;
  }
}
.top_input {
  margin-left: 10px;
  height: 50px;
  width: 80%;
}
.steward_box {
  padding: 20px 25px;
  height: 140px;
  background: #ffffff;
  border-radius: 3px;
  // position: absolute;
  // top: 125px;
  // right: 400px;
  // z-index: 999;
  div {
    font-size: 14px;
    color: #333333;
    margin-bottom: 15px;
  }
  p {
    cursor: pointer;
  }
}
.steward_left {
  p {
    font-size: 12px;
    color: #999999;
    margin-bottom: 8px;
  }
}
.steward_content {
  padding-left: 40px;
  p {
    font-size: 12px;
    color: #999999;
    margin-bottom: 8px;
  }
}
.steward_right {
  padding-left: 20px;
  p {
    font-size: 12px;
    color: #999999;
    margin-bottom: 8px;
  }
}
.all {
  width: 96px;
  height: 55px;
  background: #f9f9f9;
  text-align: center;
  border: 1px solid #eeeeee;
  margin-top: 30px;
  margin-left: 50px;
  cursor: pointer;
  p {
    font-size: 14px;
    color: #333333;
    font-weight: bold;
    line-height: 55px;
  }
}
.steward_box /deep/ p :active {
  color: #408df7;
}

.pop {
  z-index: 2000 !important;
  width: 380px;
  height: 340px;
  background: #ffffff;
  border-radius: 6px;
  text-align: center;
  padding: 10px 10px;
  img {
    cursor: pointer;
  }
  dl {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    padding-top: 40px;
  }
  p {
    font-size: 14px;
    color: #333333;
  }
  .phone {
    font-size: 12px;
    color: #408df7;
    margin-top: 20px;
    cursor: pointer;
    z-index: 999999;
  }
  .pop_img2 {
    margin: 10px 0 20px 0;
  }
}

.qr_code {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.user_box {
  width: 100%;
  height: 100%;
}
.user {
  width: 300px;
  height: 70px;
  background: url("../assets/img/dl_js_img_ptyh.png") no-repeat;
  border-radius: 5px;
  padding: 0 22px;
  line-height: 70px;
  text-align: center;
  margin: 80px 0 0 30px;
  cursor: pointer;
  img {
    margin-top: 23px;
  }
  p {
    font-size: 16px;
    color: #ffffff;
    margin-left: 20px;
  }
}
.user2 {
  background: url("../assets/img/dl_js_img_gcs.png") no-repeat;
  margin-top: 28px;
}
// 普通用户
.pt_login_box {
  width: 100%;
  height: 100%;
}
.pt_login {
  padding: 0 30px;
}
.pt_login_top {
  padding-top: 30px;
  p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  div {
    cursor: pointer;
    font-size: 12px;
    color: #408df7;
  }
}
.phone_input {
  width: 300px;
  height: 45px;
  border: 1px solid #cccccc;
  margin-top: 25px;
  padding-left: 20px;
}
.ver_code {
  width: 100%;
  margin-top: 20px;
  input {
    width: 190px;
    height: 45px;
    border: 1px solid #cccccc;
    padding-left: 20px;
  }
  p {
    cursor: pointer;
    margin-left: 10px;
    width: 100px;
    height: 45px;
    line-height: 45px;
    background: #408df7;
    font-size: 14px;
    color: #ffffff;
  }
}
.btn {
  margin-top: 20px;
  width: 300px;
  height: 45px;
  background: #408df7;
  color: #ffffff;
  font-size: 14px;
  line-height: 45px;
  cursor: pointer;
  text-align: center;
}
.pt_bottom {
  width: 100%;
  margin-top: 20px;
  p {
    font-size: 14px;
    color: #999999;
  }
  span {
    cursor: pointer;
    color: #408df7;
  }
}
.pt_right {
  width: 90px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  img {
    margin: 8px 2px 0 10px;
  }
  div {
    font-size: 12px;
    color: #333333;
  }
}
.pop_img1 {
  cursor: pointer;
}
// 注册弹框
.login_pt {
  position: fixed;
  top: 20%;
  left: 40%;
  width: 380px;
  height: 600px;
  background: #ffffff;
  border-radius: 6px;
  // text-align: center;
  padding: 10px 10px;
  z-index: 99;
}
.login_pt_box {
  padding: 30px 30px 0;
  p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 25px;
  }
  .input {
    width: 300px;
    height: 45px;
    border: 1px solid #cccccc;
    margin-bottom: 20px;
    padding-left: 20px;
  }
}
.code_box {
  width: 300px;
  height: 45px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  padding: 0 20px;
  line-height: 45px;
  input {
    height: 43px;
  }
  div {
    cursor: pointer;
    font-size: 14px;
    color: #408df7;
  }
}
.units {
  width: 300px;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  line-height: 45px;
  padding: 0 20px;
  color: #999999;
  padding: 0 5px;
  /deep/.el-input__inner {
    height: 45px !important;
    border: none !important;
    color: #999999 !important;
    border: none !important;
  }
}
.el-input::-webkit-scrollbar-thumb {
  border-radius: 0 !important;
}

.el-icon-arrow-down {
  line-height: 45px !important;
}
// .el-dropdown-menu {
//   width: 300px;
// }
.btn1 {
  margin: 0;
}
.gcs {
  height: 705px;
}
.certificate_box {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  img {
    cursor: pointer;
  }
}
.certificate {
  height: 100px;
  padding: 26px 0 0 20px;
  span {
    font-size: 14px;
    color: #333333;
  }
  div {
    margin-top: 10px;
    font-size: 12px;
    color: #999999;
  }
}
.el-upload {
  width: 150px;
  height: 100px;
  line-height: 100px;
  float: right;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.avatar {
  width: 152px;
  height: 103px;
  border-radius: 5px;
  object-fit: cover;
}
.w1 {
  float: right;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 25px 0 0 50px;
  cursor: pointer;
  object-fit: cover;
}

.head_list {
  width: 120px !important;
  background: #ffffff;
  border-radius: 3px;
  // left: 1650px !important;
}
.pop_bottom_login {
  position: absolute;
  left: 70px;
}
.van-overlay {
  z-index: 1999 !important;
}
.not_img {
  margin: 35px 0;
}
.not_register {
  div {
    font-size: 14px;
    color: #333333;
    text-align: center;
    max-width: 210px;
    margin: 0 auto 24px;
  }
  p {
    cursor: pointer;
    font-size: 12px;
    color: #408df7;
  }
}

.steward_box {
  padding: 20px 25px;
  height: 140px;
  background: #ffffff;
  border-radius: 3px;
  min-width: 342px;
}
.el-dropdown-link {
  color: #333;
}
/deep/.el-dropdown {
  height: 60px;
}
.my-el-dropdown {
  height: 70px;
}
</style>