<template>
  <div class="view1" v-loading="loading">
    <p class="view1-title" v-if="type==1">电力数据</p>
    <p class="view1-title" v-if="type==2">摄像头数据</p>
    <p class="view1-title" v-if="type==3">门禁数据</p>
    <p class="view1-title" v-if="type==4">工作总量数据</p>
    <p class="view1-title" v-if="type==5">免费质保期</p>
    <p class="view1-title" v-if="type==6">付费期</p>
    <el-row :gutter="20">
      <el-col :span="12">
        <div class="view1-item">
          <p class="view1-title" v-if="type==5||type==6">维修统计(次)</p>
          <p class="view1-title" v-else>正常工作数量</p>
          <span class="content-num" v-if="type==1">{{toalList.electricity.normal}}</span>
          <span class="content-num" v-if="type==2">{{toalList.video.normal}}</span>
          <span class="content-num" v-if="type==3">0</span>
          <span class="content-num" v-if="type==4">{{toalList.all.normal}}</span>
          <span class="content-num" v-if="type==5">{{list.freeCharge.maintainCount}}</span>
          <span class="content-num" v-if="type==6">{{list.free.maintainCount}}</span>
        </div>
        <div class="view1-item">
          <p class="view1-title" v-if="type==5 ||type==6">维护统计(次)</p>
          <p class="view1-title" v-else>异常工作数量</p>
          <span class="content-num" v-if="type==1">{{toalList.electricity.unusual}}</span>
          <span class="content-num" v-if="type==2">{{toalList.video.unusual}}</span>
          <span class="content-num" v-if="type==3">0</span>
          <span class="content-num" v-if="type==4">{{toalList.all.unusual}}</span>
          <span class="content-num" v-if="type==5">{{list.freeCharge.upkeepCount}}</span>
          <span class="content-num" v-if="type==6">{{list.free.upkeepCount}}</span>
        </div>
      </el-col>
      <el-col :span="12" class="box" v-if="type==1">
        <el-progress
          stroke-width="22"
          color="#0A54EA"
          type="circle"
          width="160"
          :percentage="parseInt(toalList.electricity.normal/(toalList.electricity.unusual+toalList.electricity.normal)*100)"
        ></el-progress>
      </el-col>
      <el-col :span="12" class="box" v-if="type==4">
        <el-progress
          stroke-width="22"
          color="#0A54EA"
          type="circle"
          width="160"
          :percentage="parseInt(toalList.all.normal/(toalList.all.unusual+toalList.all.normal)*100)"
        ></el-progress>
      </el-col>
      <el-col :span="12" class="box" v-if="type==2">
        <el-progress
          stroke-width="22"
          color="#0A54EA"
          type="circle"
          width="160"
          :percentage="parseInt(toalList.video.normal/(toalList.video.unusual+toalList.video.normal)*100)"
        ></el-progress>
      </el-col>
      <el-col :span="12" class="box" v-if="type==5">
        <el-progress
          stroke-width="22"
          color="#0A54EA"
          type="circle"
          width="160"
          :percentage="parseInt(list.freeCharge.maintainCount/(list.freeCharge.maintainCount+list.freeCharge.upkeepCount)*100)"
        ></el-progress>
      </el-col>
      <el-col :span="12" class="box" v-if="type==6">
        <el-progress
          stroke-width="22"
          color="#0A54EA"
          type="circle"
          width="160"
          :percentage="parseInt(list.free.maintainCount/(list.free.maintainCount+list.free.upkeepCount)*100)"
        ></el-progress>
      </el-col>

      <!-- <el-col :span="12">
        <div :id="canvasId1"></div>
      </el-col>
      <el-col :span="12">
        <div :id="canvasId"></div>
      </el-col>
      <el-col :span="12">
        <div :id="canvasId"></div>
      </el-col>-->
      <!-- <el-col :span="12">
        <div :id="canvasId"></div>
      </el-col>-->
    </el-row>
  </div>
</template>

<script>
import { RingProgress } from "@antv/g2plot";
import { collect, client } from "../../../api/company";
export default {
  props: ["type", "canvasId", "state"],
  data() {
    return {
      stat: ["免费质保期", "付费期"],
      toalList: "",
      loading: true,
      list: "",
    };
  },
  created() {
    this.getToal();
    this.getList();
  },
  mounted() {
    this.chart();
  },
  methods: {
    chart() {
      const ringProgress = new RingProgress(this.canvasId, {
        height: 215,
        width: 215,
        autoFit: true,
        percent: 1,
        color: ["#0A54EA", "#062D97"],
      });
      ringProgress.render();
    },
    //汇总
    getToal() {
      collect({}).then((res) => {
        this.toalList = res.data;
        this.loading = false;
      });
    },
    getList() {
      client({}).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-num {
  font-size: 25px;
  font-weight: bold;
  line-height: 26px;
  color: #47e6ff;
}
.view1 {
  &-title {
    font-size: 14px;
    color: #fff;
  }
  &-item {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    margin-top: 20px;
    background: linear-gradient(
      92deg,
      rgba(10, 84, 234, 0.32) 0%,
      rgba(11, 28, 71, 0.32) 100%
    );
  }
}

.box /deep/ svg path:first-child {
  stroke: #062d97;
}
</style>