<template>
  <div class="complate">
    <Header></Header>
    <!-- 面包屑 -->
    <div class="place">
      <span>当前位置：</span>
      <span>采购服务></span>
      <span>购物车></span>
      <span>结算页</span>
    </div>
    <div class="quanb">
      <!-- 地址信息 -->
      <div class="address">
        <span class="people">收货人信息</span>
        <button class="news" @click="showArea">新增收货地址</button>
        <div class="information" v-if="list.addressList != null">
          <input type="checkbox" checked="checked" name="" />
          <span class="name" v-if="show_address">
            {{ list.addressList.consignee }},{{ list.addressList.mobile }},{{
              list.addressList.province_name
            }},{{ list.addressList.city_name }},{{
              list.addressList.district_name
            }},{{ list.addressList.address }}
          </span>
          <span class="name" v-else>
            {{ NewadressList.consignee }},{{ NewadressList.mobile }},{{
              NewadressList.province_name
            }},{{ NewadressList.city_name }},{{
              NewadressList.district_name
            }},{{ NewadressList.address }}
          </span>
        </div>
        <div class="information" v-else>暂无收货地址</div>
        <button class="more" v-popover:popover2>
          <span>更多地址</span><img src="../../assets/img/xl_h.png" />
        </button>
        <el-popover ref="popover2" placement="bottom-start" trigger="click">
          <div class="pop1">
            <div
              class="adree_list"
              v-for="(item, index) in adressList"
              :key="index"
            >
              <div class="fl" @click="chose_aderess(item, item.address_id)">
                <img :src="address_id == item.address_id ? imgUrl2 : imgUrl1" />
              </div>
              <p>
                {{ item.consignee }},{{ item.mobile }},{{
                  item.province_name
                }},{{ item.city_name }},{{ item.district_name }},{{
                  item.address
                }}
              </p>
            </div>
          </div>
        </el-popover>
      </div>
      <!-- 配送方式 -->
      <div class="way">
        <span>配送方式</span>
        <div>
          <input
            type="radio"
            checked="checked"
            name="1"
            @click="chose_dis(1)"
          />
          快递运输
          <input class="btn1" type="radio" name="1" @click="chose_dis(2)" />
          物流
        </div>
      </div>
      <!-- 留言 -->
      <div class="message">
        <p>给商家留言</p>
        <div>
          <input
            v-model="nameText"
            maxlength="100"
            placeholder="输入您的留言内容"
          />
          <span></span>
        </div>
      </div>
      <!-- 发票 -->
      <div class="invoice">
        <p>发票</p>
        <div>
          <input type="radio" name="invoice" @click="chose_bill(1)" />
          <span>普通发票</span>
          <input type="radio" name="invoice" @click="chose_bill(2)" />
          <span>增值税发票</span>
          <input
            type="radio"
            checked="checked"
            name="invoice"
            @click="chose_bill(3)"
          />
          <span>不开发票</span>
        </div>
        <div class="invoice_number" v-show="tabIndex == 1">
          <input v-model="unit" disabled />

          <span @click="show1 = true">修改</span>
        </div>
        <div class="invoice_number" v-show="tabIndex == 2">
          <input v-model="unit"  disabled/>
          <span @click="show2 = true">修改</span>
        </div>
      </div>
      <div class="shopping_form">
        <div class="list_head">
          <span class="goods">商品</span>
          <span class="the_price">价格</span>
          <span class="number">数量</span>
          <span class="reporter">小记</span>
        </div>
        <div
          class="shopping"
          v-for="(item, index) in list.cartList"
          :key="index"
        >
          <img :src="item.original_img" />
          <div class="shopp_name two-letter">
            <span>{{ item.goods_name }}</span>
          </div>
          <span class="shopp_money">¥{{ item.goods_price }}</span>
          <div class="num">{{ item.goods_num }}</div>
          <span class="shopp_reporter"
            >¥{{ (item.goods_num * item.goods_price) | numFilter }}</span
          >
        </div>
        <!-- 优惠券 -->

        <div class="coup_text">使用优惠券</div>
        <div class="m1" v-if="list.couponList < 1">暂无可使用的优惠券</div>
        <div class="coup_list_box" v-if="list.couponList">
          <div
            class="coupon-item"
            v-for="(item, index) in list.couponList"
            :key="index"
            @click="chose_coupon(index, item.id, item.money)"
          >
            <div class="item-left fl">
              <p>满减券</p>
              <p>
                <span>￥</span><span>{{ item.money }}</span>
              </p>
              <p>满{{ item.condition }}元可用</p>
            </div>
            <div class="item-right fl">
              <p>{{ item.name }}</p>
              <p>{{ item.use_start_time }}-{{ item.use_end_time }}</p>
            </div>
            <img
              class="e9"
              src="../../assets/img/order_icon_quan_selected.png"
              v-if="selectNum == index"
            />
            <div class="clear"></div>
          </div>
          <div class="clear"></div>
        </div>

        <!-- 商品计算 -->
        <div class="the_freight">
          <div class="top">
            <span
              ><span class="money"
                ><span>{{ list.totalPrice.num }}</span
                >件商品，总商品金额：
              </span> </span
            ><span>￥{{ order_info.goodsFee }}</span>
          </div>
          <div class="bot">
            <span class="money">运费：</span
            ><span>￥{{ order_info.postFee }}</span>
          </div>
          <div class="bot">
            <span class="money">优惠券：</span
            ><span>￥{{ money ? money : 0 }}</span>
          </div>
        </div>
        <div class="total_price">
          <p>
            应付金额：<span>¥{{ order_info.payables }}</span>
          </p>
        </div>
        <!-- 提交订单 -->
        <div class="the_order">
          <button class="bt1" @click="goHref('/UserCenter?control=' + 10)">
            联系客服
          </button>
          <button class="bt2" @click="btn()">提交订单</button>
          <button class="bt3"><a :href="contract">合同下载</a></button>
        </div>
      </div>
    </div>

    <van-popup v-model="show" class="pop">
      <img
        src="../../assets/img/pop_but_gb.png"
        @click="close()"
        class="close"
      />
      <div class="list_box_top">
        <p>新增地址</p>
        <div @click="chose">
          <img v-if="type1 == 0" src="../../assets/img/but_xz_n.png" />
          <img v-if="type1 == 1" src="../../assets/img/but_xz_s.png" />
          <span>设为默认</span>
        </div>
      </div>
      <div class="list">
        <p>收货人</p>
        <input placeholder="填写收货人" v-model="name" />
      </div>
      <div class="list">
        <p>联系电话</p>
        <input placeholder="填写联系电话" v-model="phone" />
      </div>
      <div class="list">
        <p>城市</p>
        <div class="area_box fr">
          <el-cascader
            :options="options"
            ref="cascaderAddr"
            placeholder="请选择"
            v-model="unit_city"
            @change="handleChange"
          >
          </el-cascader>
        </div>
      </div>
      <div class="list1">
        <p>详细地址</p>
        <textarea placeholder="填写详细地址" v-model="site" />
      </div>
      <div class="sub" @click="add()">保存新地址</div>
    </van-popup>
    <!-- 普通发票 -->
    <van-popup v-model="show1" class="pop2">
      <img
        src="../../assets/img/pop_but_gb.png"
        @click="show1 = false"
        class="close"
      />
      <div class="top">普通发票</div>
      <div class="personage">
        <input type="radio" name="1" checked="param1" @click="chose_per(1)" />
        个人
        <input
          class="btn1"
          type="radio"
          name="1"
          checked="param2"
          @click="chose_per(2)"
        />
        公司
      </div>
      <div class="pop_list">
        <p>单位名称</p>
        <input placeholder="请输入单位名称" v-model="company" />
      </div>
      <div class="pop_list" v-if="param2 == 1">
        <p>纳税识别号</p>
        <input placeholder="请填纳税识别号" v-model="code" />
      </div>
      <div class="notice">*个人发票不用填写纳税识别号</div>
      <div class="btn" @click="que_pt()">确定</div>
    </van-popup>
    <!-- 增值税发票 -->
    <van-popup v-model="show2" class="pop3">
      <img
        src="../../assets/img/pop_but_gb.png"
        @click="show2 = false"
        class="close"
      />
      <div class="pop3_box">
        <div class="top">增值税发票</div>
        <div class="personage">
          <input type="radio" name="1" checked="param1" @click="chose_per(1)" />
          个人
          <input
            class="btn1"
            type="radio"
            name="1"
            checked="param2"
            @click="chose_per(2)"
          />
          公司
        </div>
        <div class="pop_list">
          <p>单位名称</p>
          <input
            placeholder="请输入单位名称"
            v-model="company"
            disabled="disabled"
          />
        </div>
        <div class="pop_list" v-if="param2 == 1">
          <p>纳税识别号</p>
          <input placeholder="请填纳税识别号" v-model="code" />
        </div>
        <div class="pop_list1">
          <p>单位地址</p>
          <textarea placeholder="请填写单位地址" v-model="address_dz" />
        </div>
        <div class="pop_list">
          <p>单位开户行</p>
          <input placeholder="请填写单位开户行" v-model="bank" />
        </div>
        <div class="pop_list">
          <p>开户账号</p>
          <input placeholder="请填写开户账号" v-model="bank_code" />
        </div>
        <div class="pop_list1">
          <p>收件人地址</p>
          <textarea placeholder="请填写收件人地址" v-model="address_sh" />
        </div>
        <div class="pop_list">
          <p>收件人</p>
          <input placeholder="请填写收件人" v-model="username" />
        </div>
        <div class="pop_list">
          <p>电话</p>
          <input placeholder="请填写电话" v-model="mobile" />
        </div>
      </div>
      <div class="btn" @click="que_zz()">确定</div>
    </van-popup>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { subOrder, Subrder, use_coupon } from "../../api/goods";
import { addressList, add_ressList, contract, upUnit } from "../../api/User";
let pcas = require("../../assets/js/area.json");
export default {
  components: { Header, Bottom },
  data() {
    return {
      token: "",
      imgUrl1: require("../../assets/img/but_xz_n.png"),
      imgUrl2: require("../../assets/img/but_xz_s.png"),
      show: false,
      type1: 0,
      list: {},
      adressList: [], //我的地址列表
      options: pcas,
      province: "",
      district: "",
      city: "",
      nameText: "", //商家留言
      name: "",
      site: "",
      phone: "",
      unit_city: [],
      address_id: "", //地址ID
      show_address: true,
      NewadressList: [], //选择其他收货地址
      checkIdArr: [],
      checkIndex: [],
      allFlag: false,
      show1: false, //普通发票
      show2: false, //增值税发票
      tabIndex: "",
      pop2: false,
      invoice_type: 0, //发票类型
      is_showcode: 1, //配送方式
      invoice_user_type: 1, //个人1 还是 公司2
      company: "", //单位名称
      code: "", //纳税识别号
      address_dz: "", //单位地址
      bank: "", //开户行
      bank_code: "", //开户账号
      address_sh: "", //发票收货人地址
      username: "", //
      mobile: "",
      unit: "", //单位和编码
      submit_order: "submit_order",
      order_info: "",
      type: "",
      coupon_id: "",
      money: "",
      contract: "",
      param1: 0,
      param2: 1,
      selectNum: -1,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    (this.type = this.$route.query.type), 
    this.getOrder(); //订单详情  //type1立即购买的type2  购物车进来的

    this.getadressList(); //我的地址列表
    this.getcontract();
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  methods: {
    getcontract() {
      contract({}).then((res) => {
        this.contract = res.result.tem_pic;
      });
    },
    chose_coupon(index, id, money) {
      this.coupon_id = id;
      this.money = money;
      if (this.selectNum === index) {
        this.selectNum = -1;
        this.money = 0;
        this.coupon_id = "";
        this.getOrder1();
      } else {
        this.selectNum = index;
        if (this.type == 1) {
          use_coupon({
            token: this.token,
            is_buy_now: 1,
            coupon_id: this.coupon_id,
            goods_price: this.list.totalPrice.total_fee,
          }).then((res) => {
            if (res.status == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getOrder1();
            } else {
              this.selectNum = -1;
              this.$message.error(res.msg);
              this.coupon_id = "";
              this.money = 0;
            }
          });
        } else {
          use_coupon({
            token: this.token,
            coupon_id: this.coupon_id,
            goods_price: this.list.totalPrice.total_fee,
          }).then((res) => {
            if (res.status == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.getOrder1();
            } else {
              this.selectNum = -1;
              this.$message.error(res.msg);
              this.coupon_id = "";
              this.money = 0;
            }
          });
        }
      }
    },

    // },
    getOrder1() {
      if (this.type == 1) {
        Subrder({
          token: this.token,
          address_id: this.address_id,
          is_buy_now: 1,
          coupon_id: this.coupon_id,
        }).then((res) => {
          if (res.status == 1) {
            this.order_info = res.result;
          } else if (res.status == -1) {
            this.$message.error(res.msg);
          }
        });
      } else {
        Subrder({
          token: this.token,
          address_id: this.address_id,
          coupon_id: this.coupon_id,
        }).then((res) => {
          if (res.status == 1) {
            this.order_info = res.result;
          } else if (res.status == -1) {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //提交订单
    btn() {
      if (this.type == 1) {
        let data = {
          is_buy_now: 1,
          token: this.token,
          address_id: this.address_id,
          act: this.submit_order,
          user_note: this.nameText,
          coupon_id: this.coupon_id,
          invoice_register_address: this.address_sh,
          invoice_mobile: this.mobile,
          invoice_deposit_bank: this.bank,
          invoice_taxpayer_code: this.code,
          invoice_bank_account: this.bank_code,
          invoice_type: this.invoice_type,
          invoice_user_type: this.invoice_user_type,
          invoice_user_address: this.address_dz,
          invoice_user_name: this.username,
          is_showcode: this.is_showcode,
        };
        Subrder(data).then((res) => {
          if (res.status == 1) {
            this.$router.replace({
              path: "/OrderInfo",
              query: {
                id: res.result,
                order_amount: res.order_amount,
                type: 2,
              },
            });
          } else if (res.status == -1) {
            this.$message.error(res.msg);
          }
        });
      } else {
        let data = {
          // is_buy_now: 1,
          token: this.token,
          address_id: this.address_id,
          act: this.submit_order,
          user_note: this.nameText,
          coupon_id: this.coupon_id,
          invoice_register_address: this.address_sh,
          invoice_mobile: this.mobile,
          invoice_deposit_bank: this.bank,
          invoice_taxpayer_code: this.code,
          invoice_bank_account: this.bank_code,
          invoice_type: this.invoice_type,
          invoice_user_type: this.invoice_user_type,
          invoice_user_address: this.address_dz,
          invoice_user_name: this.username,
          is_showcode: this.is_showcode,
        };
        Subrder(data).then((res) => {
          if (res.status == 1) {
            this.$router.replace({
              path: "/OrderInfo",
              query: {
                id: res.result,
                order_amount: res.order_amount,
                type: 2,
              },
            });
          } else if (res.status == -1) {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //增值税发票确认
    que_zz() {
      if (this.param2 == 1) {
        this.unit = this.company + "," + this.code;
      } else {
        this.unit = this.company;
      }
      this.show2 = false;
      this.Unit();
    },
    Unit() {
      let data = {
        token: this.token,
        unit_name: this.company,
        address: this.address_dz,
        plane_tel: this.mobile,
        invoice_taxpayer_code: this.code,
        invoice_deposit_bank: this.bank,
        invoice_bank_account: this.bank_code,
        invoice_user_address: this.address_sh,
        invoice_user_name: this.username,
      };
      upUnit(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //普通发票确认
    que_pt() {
      if (this.param2 == 1) {
        this.unit = this.company + "," + this.code;
      } else {
        this.unit = this.company;
      }

      this.show1 = false;
    },
    //个人还是公司
    chose_per(index) {
      if (index == 2) {
        this.invoice_user_type = 1;
        this.param1 = 0;
        this.param2 = 1;
      } else {
        this.invoice_user_type = 2;
        this.param1 = 1;
        this.param2 = 0;
      }
    },
    //选择配送方式
    chose_dis(index) {
      if (index == 1) {
        this.is_showcode = 1;
      } else {
        this.is_showcode = 2;
      }
    },
    //发票类型
    chose_bill(index) {
      this.tabIndex = index;
      if (this.tabIndex == 1) {
        this.invoice_type = 1;
        this.param2 = 1;
      } else if (this.tabIndex == 2) {
        this.invoice_type = 2;
        this.param2 = 1;
      } else if (this.tabIndex == 3) {
        this.invoice_type = 0;
        
      }
    },
    //选择其他地址
    chose_aderess(item, id) {
      this.address_id = id;
      this.show_address = false;
      this.popover2 = false;
      this.NewadressList = item;
    },
    //添加新地址
    add() {
      let data = {
        token: this.token,
        consignee: this.name,
        province_name: this.province,
        city_name: this.city,
        district_name: this.district,
        address: this.site,
        mobile: this.phone,
        is_default: this.type,
      };
      add_ressList(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.show = false;
            this.getadressList();
            location.reload();
          }, 1000);
        } else if (res.status == -1) {
          this.$message.error(res.msg);
        }
      });
    },
    //选择省市区
    handleChange(e, form, thsAreaCode) {
      thsAreaCode = this.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels; // 注意2： 获取label值
      this.province = thsAreaCode[0];
      this.city = thsAreaCode[1];
      this.district = thsAreaCode[2];
    },
    chose() {
      this.type1 = !this.type1;
    },
    close() {
      this.show = false;
    },
    //新增地址弹框
    showArea() {
      this.show = true;
    },
    //地址列表
    getadressList() {
      addressList({ token: this.token }).then((res) => {
        if (res.status == 1) {
          let list = res.result;
          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              if (this.allFlag) {
                list[i].selected = true;
              } else list[i].selected = false; //false 未选中 true 选中
            }
          }
          this.adressList = res.result;
        } else if (res.status == 0) {
          this.$message("请添加收货地址");
          setTimeout(() => {
            this.show = true;
          }, 1000);
        }
      });
    },
    goHref(path) {
      this.$router.push(path);
    },
    getOrder() {
      if (this.type == 1) {
        subOrder({ token: this.token, is_buy_now: 1 }).then((res) => {
          if (res.status == 1) {
            this.list = res.result;
            this.address_id = res.result.addressList.address_id;
            this.company = this.list.userInfo.company_name;
            this.code = this.list.userInfo.invoice_taxpayer_code;
            this.address_dz = this.list.userInfo.address; //单位地址
            this.bank = this.list.userInfo.invoice_deposit_bank; //开户行
            this.bank_code = this.list.userInfo.invoice_bank_account; //开户账号
            this.address_sh = this.list.userInfo.invoice_user_address; //发票收货地址
            this.username = this.list.userInfo.invoice_user_name; //发票收件人
            this.mobile = this.list.userInfo.mobile; //发票收件人手机号
            this.unit =
              this.list.userInfo.company_name +
              "," +
              this.list.userInfo.invoice_taxpayer_code;
            this.getOrder1();
          }
        });
      } else {
        subOrder({ token: this.token }).then((res) => {
          if (res.status == 1) {
            this.list = res.result;
            this.address_id = res.result.addressList.address_id;
            this.company = this.list.userInfo.company_name;
            this.code = this.list.userInfo.invoice_taxpayer_code;
            this.address_dz = this.list.userInfo.address; //单位地址
            this.bank = this.list.userInfo.invoice_deposit_bank; //开户行
            this.bank_code = this.list.userInfo.invoice_bank_account; //开户账号
            this.address_sh = this.list.userInfo.invoice_user_address; //发票收货地址
            this.username = this.list.userInfo.invoice_user_name; //发票收件人
            this.mobile = this.list.userInfo.mobile; //发票收件人手机号
            this.unit =
              this.list.userInfo.company_name +
              "," +
              this.list.userInfo.invoice_taxpayer_code;
            this.getOrder1();
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.place {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100%;
  padding: 0 14%;
  height: 50px;
  background: #f3f3f3;
  line-height: 50px;
  span {
    margin-left: 5px;
  }
}
.quanb {
  width: 100%;
  padding: 0 14%;
  padding-top: 44px;
  .address {
    width: 100%;
    height: 130px;
    border-bottom: 1px solid #eeeeee;
    .people {
      float: left;
      width: 50%;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 26px;
      color: #333333;
      opacity: 1;
      margin-bottom: 33px;
    }
    .news {
      float: right;
      right: 0;
      height: 21px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 26px;
      color: #408df7;
      opacity: 1;
      background: rgba(0, 0, 0, 0);
    }
  }
  .information {
    width: 100%;
    height: 20px;
    float: left;
    padding: 0 10px;
    margin-bottom: 18px;
    input {
      margin-right: 20px;
      margin-top: -3px;
    }
  }
  .more {
    margin-left: 20px;
    float: left;
    background: rgba(0, 0, 0, 0);
    span {
      margin-right: 5px;
      width: 64px;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 26px;
      color: #999999;
      opacity: 1;
    }
  }
  .way {
    height: 115px;
    width: 100%;
    padding-top: 47px;
    border-bottom: 1px solid #eeeeee;
    span {
      float: left;
      width: 11%;
      height: 21px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 26px;
      color: #333333;
      opacity: 1;
    }
    div {
      float: left;
      margin-top: 3px;
    }
    input {
      margin-right: 5px;
      margin-top: -3px;
    }
    input:hover {
      cursor: pointer;
    }
    .btn1 {
      margin-left: 60px;
    }
  }
  .message {
    width: 100%;
    height: 105px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 26px;
    color: #333333;
    opacity: 1;
    border-bottom: 1px solid #eeeeee;
    p {
      width: 11%;
      float: left;
      line-height: 105px;
    }
    div {
      width: 89%;
      height: 105px;
      position: relative;
      float: left;
      input {
        margin-top: 30px;
        float: left;
        width: 100%;
        height: 50px;
        border: 1px solid #eeeeee;
        border-radius: 5px;
        padding-left: 20px;
        font-size: 14px;
        padding-right: 80px;
      }
      span {
        float: right;
        position: absolute;
        right: 20px;
        top: 40%;
        width: 39px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        opacity: 1;
      }
    }
  }
  .invoice {
    width: 100%;
    height: 120px;
    padding-top: 39px;
    p {
      float: left;
      width: 11%;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 40px;
      color: #333333;
      opacity: 1;
    }
    div {
      // width: 30%;
      float: left;
      span {
        margin: 0 50px 0 5px;
        margin-top: 20px;
        line-height: 40px;
      }
    }
    .invoice_number {
      width: 59%;
      float: right;
      background: #f3f8ff;
      height: 38px;
      input {
        background: #f3f8ff;
        width: 90%;
        height: 38px;
        padding-left: 10px;
        font-size: 14px;
        font-weight: 400;
        color: #408df7;
      }
      span {
        margin: 0;
        height: 38px;
        font-size: 14px;
        color: #333333;
        float: right;
        padding-right: 20px;
        cursor: pointer;
      }
    }
  }
  .shopping_form {
    width: 100%;
    height: 100%;
    .list_head {
      width: 100%;
      height: 42px;
      background: #f9f9f9;
      margin-bottom: 20px;
      position: relative;
    }
    span {
      width: 0px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    button {
      background: rgba(0, 0, 0, 0);
    }
    .goods {
      display: inline-block;
      width: 10%;
      margin-right: 30%;
      position: absolute;
      left: 10%;
      text-align: center;
    }
    .the_price {
      width: 10%;
      display: inline-block;
      position: absolute;
      left: 50%;
      line-height: 42px;
    }
    .number {
      width: 10%;
      display: inline-block;
      position: absolute;
      left: 70%;
      line-height: 42px;
    }
    .reporter {
      width: 10%;
      position: absolute;
      display: inline-block;
      left: 90%;
      line-height: 42px;
    }
  }
  .the_freight {
    width: 100%;
    padding-top: 14px;
    div {
      margin-bottom: 10px;
      text-align: right;
      padding-right: 40px;
      span {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #333333;
        opacity: 1;
      }
      .money {
        margin-right: 40px;
        span {
          color: #408df7;
        }
      }
    }
  }
  .shopping {
    width: 100%;
    padding: 30px 0 30px 30px;
    height: 160px;
    position: relative;
    margin-bottom: 20px;
    border: 1px solid #eeeeee;
    .choose {
      top: 40%;
      position: absolute;
    }
    img {
      width: 120px;
      height: 100px;
      position: absolute;
      top: 17%;
      left: 9%;
    }
    .shopp_name {
      float: left;
      max-width: 20%;
      font-size: 14px;
      color: #333333;
      position: absolute;
      left: 25%;
      top: 17%;
    }
    .shopp_money {
      width: 10%;
      text-align: center;
      position: absolute;
      left: 46%;
      top: 16%;
      font-size: 14px;
      font-family: Microsoft YaHei;
    }
    .num {
      position: absolute;
      width: 10%;
      left: 70%;
      top: 12%;
      input {
        width: 100%;
        height: 35px;
        text-align: center;
        border: 0px;
      }
    }
    .shopp_reporter {
      width: 10%;
      text-align: center;
      display: inline-block;
      position: absolute;
      right: 3%;
      top: 17%;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: bold;
    }
    .delete {
      width: 10%;
      position: absolute;
      display: inline-block;
      text-align: center;
      left: 90%;
      top: 17%;
    }
  }
  .total_price {
    width: 100%;
    height: 72px;
    background: #f9f9f9;
    text-align: center;
    p {
      float: right;
      line-height: 72px;
      font-size: 16px;
      margin-right: 40px;
      span {
        color: red;
        margin-left: 20px;
        font-size: 22px;
      }
    }
  }
  .the_order {
    width: 100%;
    height: 130px;
    position: relative;
    padding-bottom: 30px;
    .bt1 {
      position: absolute;
      left: 0;
      position: absolute;
      right: 200px;
      width: 179px;
      height: 60px;
      background: rgba(64, 141, 247, 0.23);
      border: 1px solid #408df7;
      opacity: 1;
      color: #408df7;
      top: 30px;
      font-size: 18px;
    }
    .bt2 {
      position: absolute;
      right: 0;
      width: 179px;
      height: 60px;
      background: #408df7;
      opacity: 1;
      font-size: 18px;
      color: #ffffff;
      top: 30px;
    }
    .bt3 {
      position: absolute;
      right: 200px;
      width: 179px;
      top: 30px;
      height: 60px;
      background: rgba(64, 141, 247, 0.23);
      border: 1px solid #408df7;
      opacity: 1;
      color: #408df7;
      font-size: 18px;
    }
  }
}

input[type="radio"]:hover {
  border-color: #408df7;
}
input[type="radio"] {
  background: url("../../assets/img/but_xz_n.png") no-repeat;
  width: 15px;
  height: 15px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
  position: relative;
  border-radius: 0px;
}
input[type="radio"]::before {
  position: absolute;
  top: 0%;
  left: 0%;
  background: #fff;
  width: 100%;
  border: 1px;
  height: 100%;
}
input[type="radio"]:checked::before {
  background: url("../../assets/img/but_xz_s.png") no-repeat;
  background-color: #fff;
  border: none;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  // border: 1px solid #408df7;
  // color: #408df7;
  font-size: 8px;
  font-weight: bold;
  border-radius: 0px;
}
// 地址选择框
.el-popover {
  position: absolute !important;
  left: 1500px !important;
}
.pop1 {
  min-width: 400px !important;
  padding: 20px 10px;
  height: 100%;
}
.adree_list {
  margin-bottom: 5px;
  div {
    img {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
.pop {
  width: 380px;
  height: 600px;
  padding: 30px;
}
.close {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.list_box_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  margin-top: 30px;
  float: left;
  p {
    font-size: 18px;
    color: #333333;
    font-weight: bold;
    float: left;
  }
  div {
    cursor: pointer;
    float: right;
    img {
      width: 13px;
      height: 13px;
    }
    span {
      margin-left: 10px;
      font-size: 14px;
      color: #999999;
      // float: left;
    }
  }
}
.list {
  height: 70px;
  width: 100%;
  line-height: 70px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  input {
    height: 69px;

    float: right;
    text-align: right;
  }
}
.list1 {
  width: 100%;
  height: 170px;
  padding-top: 30px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  textarea {
    position: absolute;
    bottom: 135px;
    left: 30px;
    width: 80%;
    height: 100px;
  }
}
.sub {
  cursor: pointer;
  margin-top: 20px;
  float: left;
  padding: 15px 120px;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
}
.area_box /deep/ .el-input__inner {
  width: 200px !important;
  color: #333333 !important;
  border-radius: 0 !important;
  border: none !important;
  height: 48px;
}
// 普通发票
.pop2 {
  width: 380px;
  height: 463px;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  padding: 0 30px;
}
.top {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding-top: 55px;
}
.personage {
  padding: 20px 0;
  input:nth-of-type(2) {
    margin-left: 20px;
  }
}
.pop_list {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  height: 70px;
  line-height: 70px;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  input {
    background: #ffffff;
    float: right;
    font-size: 16px;
    text-align: right;
    border-bottom: 1px solid #eeeeee;
    height: 70px;
  }
}
.pop_list1 {
  width: 100%;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    padding: 30px 0 10px 0;
  }
  textarea {
    width: 100%;
  }
}
.notice {
  font-size: 13px;
  color: #ff0000;
  margin-top: 30px;
  float: left;
}
.btn {
  position: absolute;
  bottom: 20px;
  width: 320px;
  height: 50px;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  line-height: 50px;
}
.pop3 {
  width: 380px;
  height: 800px;
  background: #ffffff;
  border-radius: 5px;
  padding: 0 30px;
  .top {
    padding-top: 20px !important;
  }
}
.coup_text {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin: 40px 0 20px 0;
}
.coup_list_box {
  height: 100%;
  padding: 26px 0 26px 26px;
  width: 100%;
  border: 1px solid #eeeeee;
}
.coupon-item {
  float: left;
  position: relative;
  width: 340px;
  height: 100px;
  background: url("../../assets/img/ptyh_yhq.png");
  background-size: cover;
  margin: 0 10px 10px 0;
  border-radius: 5px;
  .item-left {
    width: 41%;
    padding: 10px 10px 10px 33px;
    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-weight: 400;
      line-height: 28px;
      color: #ffffff;
    }
    p:nth-child(2) {
      span:nth-child(1) {
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
      }
      span:nth-child(2) {
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #ffffff;
      }
    }
  }
  .item-right {
    width: 59%;
    padding: 15px;
    p {
      width: 100%;
    }
    p:nth-child(1) {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #666666;
      padding-bottom: 10px;
    }
    p:nth-child(2) {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: #999999;
    }
  }
}
.e9 {
  position: absolute;
  right: 0;
}
</style>
