<template>
  <div class="complate">
    <Header></Header>
    <Tops ref="mychild"></Tops>
    <!-- 面包屑 -->
    <div class="place">
      <span>当前位置：</span>
      <span>采购服务></span>
      <span>对比结果</span>
    </div>

    <!-- 对比列表 -->
    <div class="mg_space_260">
      <div class="parameter">
        <div class="parameter_left">
          <p class="a1">仪器名称</p>
          <span v-for="(item, index) in list.goods_name" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_img">
          <p class="a1 a2">图片</p>
          <div v-for="(item, index) in list.original_img" :key="index">
            <img class="img1" :src="item" />
            <!-- <img class="img2" src="../../assets/img/pop_but_gb.png" @click="del()" /> -->
          </div>
        </div>
        <div class="parameter_left a3">
          <p class="a1">报价</p>
          <span v-for="(item, index) in list.shop_price" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">厂商</p>
          <span v-for="(item, index) in list.goods_manu" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">产地</p>
          <span v-for="(item, index) in list.goods_site" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">规格</p>
          <span v-for="(item, index) in list.goods_spec" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">电源</p>
          <span v-for="(item, index) in list.goods_elec" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3 shuoming" style="display:show"> 
          <p class="a1">产品说明</p>
          <div v-for="(item, index) in list.goods_explain" :key="index">
            <span >{{
            item
          }}</span>
          </div>
          
        </div>
        <!-- <div class="parameter_left a3">
          <p class="a1">转速范围</p>
          <span v-for="(item, index) in list.goods_rev" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">最大容量</p>
          <span v-for="(item, index) in list.goods_capacity" :key="index">{{
            item
          }}</span>
        </div>

        <div class="parameter_left a3">
          <p class="a1">光学系统</p>
          <span v-for="(item, index) in list.goods_optics" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">光谱宽带</p>
          <span v-for="(item, index) in list.goods_bandwidth" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">光度精度</p>
          <span v-for="(item, index) in list.goods_precision" :key="index">{{
            item
          }}</span>
        </div>
        <div class="parameter_left a3">
          <p class="a1">光学范围</p>
          <span v-for="(item, index) in list.goods_wave" :key="index">
            {{item}}
          </span>
        </div> -->
      </div>
    </div>
    <!-- 推荐仪器 -->
    <div class="mg_space_260">
      <div class="recommend">
        <div class="recommend_title">
          <span>推荐商品</span>
        </div>
        <div
          class="recommend_instruments"
          v-for="(item, index) in restGoodslist"
          :key="index"
        >
          <div>
            <div class="instrument">
              <img
                :src="item.original_img"
                @click="goHref('/CommodityDetails?id=' + item.goods_id)"
              />
              <p class="two-letter">{{ item.goods_name }}</p>
              <div>
                <p class="fl">促销价</p>
                <span>¥{{ item.shop_price }}</span>
                <ol class="blue" @click="funPk(item.goods_id, item.cat_id)">
                  加入PK
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mg_space_260">
      <div class="browse">
        <div class="browse_title">
          <span>浏览记录</span>
        </div>
        <div
          class="browse_instruments"
          v-for="(item, index) in goods_history"
          :key="index"
        >
          <div>
            <div class="instrument">
              <img
                :src="item.original_img"
                @click="goHref('/CommodityDetails?id=' + item.goods_id)"
              />
              <p class="two-letter">{{ item.goods_name }}</p>
              <div>
                <p class="fl">促销价</p>
                <span>¥{{ item.shop_price }}</span>
                <ol class="blue" @click="funPk(item.goods_id, item.cat_id)">
                  加入PK
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import Tops from "../../components/Tops";
import { PKInfo, addpk } from "../../api/goods";
export default {
  components: { Header, Bottom, Tops },
  data() {
    return {
      tiken: "",
      list: "",
      id: "",
      user_id: "",
      goods_history: [],
      restGoodslist: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.user_id = localStorage.getItem("user_id");
    this.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    //加入pk
    funPk(cat_id, id) {
      addpk({ token: this.token, goods_id: id, cat_id: cat_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.$refs.mychild.getPklist();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    getList() {
      PKInfo({
        token: this.token,
        goods_id: this.id,
        user_id: this.user_id,
      }).then((res) => {
        if (res.status == 1) {
          console.log(res)
          this.list = res.result.goods_info;
          this.goods_history = res.result.goods_history;
          this.restGoodslist = res.result.restGoodslist;
        }
      });
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.complate {
  background-color: #fcfcfc;
  min-width: 1024px;
}
.seek {
  width: 100%;
  height: 80px;
  padding: 0 10%;

  #logo {
    width: 157px;
    height: 33px;
    margin: 0 114px 0 35px;
  }
  input {
    width: 804px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #408df7;
    opacity: 1;
    padding-left: 10px;
  }
  .ss {
    color: #ffffff;
    font-size: 16px;
    width: 120px;
    min-width: 50px;
    height: 45px;
    background: #408df7;
    border: 1px solid #408df7;
    opacity: 1;
    margin-right: 30px;
  }
  .gwc,
  .cp {
    width: 160px;
    min-width: 90px;
    height: 45px;
    background: #f9f9f9;
    border: 1px solid #eeeeee;
    text-align: center;
    position: relative;
    div {
      width: 37px;
      height: 20px;
      background: #ff0000;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 11px;
      position: absolute;
      right: 0px;
      top: -10px;
      text-align: center;
      span {
        width: 16px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
        opacity: 1;
      }
    }
    img {
      width: 19px;
      height: 18px;
      margin-right: 9px;
    }
    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .gwc {
    margin-right: 20px;
  }
}
.place {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100%;
  padding: 0 10%;
  height: 50px;
  background: #f3f3f3;
  line-height: 50px;
  span {
    margin-left: 5px;
  }
}

.recommend,
.browse {
  margin-top: 30px;
  width: 100%;
  margin-bottom: 20px;
  height: 470px;
  position: relative;
  .recommend_title,
  .browse_title {
    width: 100%;
    height: 72px;
    background: #ffffff;
    padding-left: 40px;
    border-bottom: 1px solid #eeeeee;
    span {
      width: 88px;
      height: 29px;
      font-size: 22px;
      font-weight: bold;
      line-height: 72px;
      color: #333333;
      margin-right: 43px;
    }
  }
  .instrument:last-of-type {
    border: none !important;
  }
  .recommend_instruments,
  .browse_instruments {
    width: 100%;
    background: #ffffff;
    .instrument {
      float: left;
      width: 20%;
      height: 360px;
      padding: 16px 18px 0;
      background: #ffffff;
      border-right: 1px solid #eeeeee;
      img {
        width: 244px;
        height: 225px;

        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        max-width: 240px;
        font-weight: 400;
        color: #333333;
        opacity: 1;
        margin-bottom: 20px;
      }
      div {
        width: 18%;
        position: absolute;
        bottom: 50px;
        height: 22px;
        #money {
          color: #999999;
          font-size: 16px;
          float: left;
          margin: 0;
          margin-right: 20px;
        }
        span {
          float: left;
          color: red;
          font-size: 16px;
          margin-right: 20px;
          margin-left: 10px;
        }
        ol {
          cursor: pointer;
          float: right;
          width: 49px;
          text-align: center;
          height: 20px;
          border: 1px solid #bbbbbb;
          font-size: 11px;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
        }
      }
    }
  }
}
.browse {
  margin-bottom: 64px;
}
.parameter {
  width: 100%;
  margin-top: 20px;
  // height: 1250px;
  border-right: 1px solid #cccccc;
}
.a1 {
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding-right: 25px;
  text-align: right;
  border-right: 1px solid #cccccc;
  width: 200px;
}
.parameter_left {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border: 1px solid #cccccc;
  border-right: none;
  span {
    height: 80px;
    border-right: 1px solid #cccccc;
    font-size: 18px;
    color: #333333;
    width: 393px;
    float: left;
    padding-left: 50px;
  }
}
.parameter_left span:last-of-type {
  border-right: none;
}
.parameter_img div:last-of-type {
  border-right: none;
}
.parameter_img {
  height: 210px;
  border: 1px solid #cccccc;
  border-right: none;
  position: relative;
  .a2 {
    width: 200px;
    line-height: 200px;
    height: 210px;
    float: left;
  }
  div {
    width: 393px;
    border-right: 1px solid #cccccc;
    float: left;
    height: 210px;
    padding-left: 50px;
    .img1 {
      width: 160px;
      height: 160px;
    }
    .img2 {
      width: 20px;
      height: 20px;

      float: right;
      margin: 20px 20px 0 0;
    }
  }
}
.a3 {
  // border-top: none;
}
.blue:hover {
  background: #408df7 !important;
  color: #ffffff !important;
  border: none !important;
}
.shuoming{
  height: 160px;
  p{
    height: 160px;
  }
  div{
    width: 393px;
    border-right: 1px solid #cccccc;
    float: left;
    height: 160px;
    padding: 20px 10px; 
    span{
      // float: none;
      height: 20px;
      line-height: 20px;
      padding-left: 35px;
      width: 100%;
    }
  }
    div:last-of-type {
  border-right: none;
}
}
</style>