<template>
  <div class="container">
    <Top></Top>
    <div class="img_box">
      <div class="img_text">
        <img src="../../assets/img/yqwx_icon.png" />
        <p>维修维护</p>
        <p class="a2">Laboratory construction scheme</p>
        <img class="a1" src="../../assets/img/top_img_x.png" />
        <div>
          基于物联传感+信息化+大数据， 提供高端分析仪器仪表、信息化软件、运维服务、
          运营服务、检测服务、咨询服务及环境治理装备等创新产品和业务组合。
        </div>
      </div>
    </div>
    <div class="industry-tab mg_space clear">
      <el-row :gutter="70">
        <el-col :span="8"><p @click="goHref('/news?tabindex=' + 1)">新闻资讯</p></el-col>
        <el-col :span="8"><p @click="goHref('/news?tabindex=' + 2)">仪器知识</p></el-col>
        <el-col :span="8"><p @click="goHref('/news?tabindex=' + 2)">仪器知识</p></el-col>
      </el-row>
    </div>
    <div class="industry-type mg_space clear">
      <el-row :gutter="10">
        <el-col :span="20" class="type-box">
          <el-row>
            <el-col :span="3"
              ><p @click="chose(1)" :class="[type == 1 ? 'color' : '']">
                采购需求
              </p></el-col
            >
            <el-col :span="3"
              ><p @click="chose(2)" :class="[type == 2 ? 'color' : '']">
                采购公告
              </p></el-col
            >
            <el-col :span="3"
              ><p @click="chose(3)" :class="[type == 3 ? 'color' : '']">
                更正公告
              </p></el-col
            >
            <el-col :span="3"
              ><p @click="chose(4)" :class="[type == 4 ? 'color' : '']">
                废标公告
              </p></el-col
            >
            <el-col :span="4"
              ><p @click="chose(5)" :class="[type == 5 ? 'color' : '']">
                中标（成交）公告
              </p></el-col
            >
            <el-col :span="3"
              ><p @click="chose(6)" :class="[type == 6 ? 'color' : '']">
                单一来源公示
              </p></el-col
            >
            <el-col :span="5"
              ><p @click="chose(7)" :class="[type == 7 ? 'color' : '']">
                单一来源（成交）公告
              </p></el-col
            >
          </el-row>
        </el-col>
        <el-col :span="4" class="search-box">
          <input type="text" v-model="content" />
          <button @click="sou()">搜索</button>
        </el-col>
      </el-row>
    </div>
    <div class="industry-content mg_space clear">
      <div class="m1" v-if="bdList < 1">暂无信息~！</div>
      <el-row :gutter="30">
        <el-col
          :span="12"
          class="industry-item"
          v-for="(item, index) in bdList"
          :key="index"
        >
          <div class="industry-item-top" @click="showPop(item.id)">
            <div>
              <img src="../../assets/img/icon_pdf_s.png" alt="" />
              <p class="two-letter">{{ item.title }}</p>
            </div>
            <!-- <a href="javascript:;">下载</a> -->
          </div>
          <div class="industry-item-bottom clear">
            <span>{{ item.publishdate }}</span>
          </div>
        </el-col>
      </el-row>
      <div class="fenye">
        <van-pagination
          v-model="p"
          :total-items="200"
          :show-page-size="5"
          class="block fl"
          @change="gotopage"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <van-popup v-model="show" class="pop">
      <div class="pop_top">
        <div class="pop_left">
          <img class="fl" src="../../assets/img/icon_pdf_s.png" />
          <p class="one-letter fl">{{ list.title }}</p>
        </div>
        <img
          class="img2 fr"
          @click="show = false"
          src="../../assets/img/pop_but_gb.png"
        />
      </div>
      <div class="pop_content">{{ list.source }}</div>
      <div v-html="list.content" class="pop_bottom"></div>
    </van-popup>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import { bidList, bidinfo } from "../../api/index";
export default {
  components: { Top, Bottom },
  data() {
    return {
      p: 1,
      bdList: [],
      show: false,
      list: "",
      type: 1,
      content: "",
    };
  },
  created() {
    this.getbidList();
  },
  methods: {
    sou() {
      bidList({ keywords: this.content, p: this.p }).then((res) => {
        if (res.status == 1) {
          this.bdList = res.data;
        }
      });
    },
    chose(index) {
      this.type = index;
      this.getbidList();
    },
    showPop(id) {
      bidinfo({ id: id }).then((res) => {
        if (res.status == 1) {
          this.show = true;
          this.list = res.data;
        }
      });
    },
    gotopage() {
      this.getbidList();
    },
    //招标列表
    getbidList() {
      bidList({ p: this.p, type: this.type }).then((res) => {
        if (res.status == 1) {
          this.bdList = res.data;
        }
      });
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-width: 1440px;
  background-color: #fcfcfc;
}
.banner {
  width: 100%;
  height: 500px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  margin-bottom: 10px;
}
.industry-tab {
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fcfcfc;
  p {
    width: 240px;
    height: 60px;
    // margin: 0 40px;
    background-color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    font-weight: 400;
    line-height: 60px;
    text-align: center;
    color: #333333;
    border-radius: 5px;
  }
  .active {
    background-color: #408df7;
    color: #ffffff;
  }
}
.industry-type {
  border-top: 1px solid #eeeeee;
  padding: 30px 0;
  .type-box {
    float: left;
    p {
      height: 43px;
      line-height: 43px;
      padding: 0 15px;
      text-align: center;
      border-radius: 5px;
      background-color: #ffffff;
      float: left;
      // margin-right: 20px;
      cursor: pointer;
    }
  }
  .search-box {
    float: right;
    height: 45px;
    padding: 0 !important;
    border: 1px solid #eeeeee;
    border-radius: 5px;
    input {
      width: 75%;
      height: 45px;
    }
    button {
      width: 25%;
      height: 45px;
      background-color: #408df7;
      color: #ffffff;
      border-radius: 0 5px 5px 0;
    }
  }
}
//内容

.industry-content {
  position: relative;
  margin-top: 30px;
  min-height: 800px;
  background-color: #ffffff;
  padding: 30px;
  .industry-item {
    cursor: pointer;
    width: 46.5%;
    height: 110px;
    border-bottom: 1px solid #eeeeee;
    float: left;
    // margin-right: 90px;
    .industry-item-top {
      height: 70px;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      div {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        img {
          width: 24px;
          height: 28px;
        }
        p {
          color: #333333;
          font-size: 20px;
          line-height: 26px;
          font-weight: normal;
          margin-left: 17px;
          max-width: 470px;
        }
      }
      a {
        width: 98.88px;
        height: 35px;
        line-height: 30px;
        font-size: 16px;
        color: #408df7;
        border: 2px solid #408df7;
        background-color: #ebf3ff;
        text-align: center;
      }
    }
    .industry-item-bottom {
      height: 40px;
      display: table-cell;
      vertical-align: middle;
      padding-left: 41px;
      span {
        color: #999999;
        font-size: 14px;
        line-height: 26px;
      }
    }
  }
}
.industry-item:nth-of-type(2n) {
  margin: 0;
}
.block {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 300px;
  height: 100px;
  margin-top: 50px;
  margin-left: 38%;
  font-size: 20px;
}
.el-pagination {
  font-size: 20px !important;
}

.img_box {
  width: 100%;
  height: 400px;
  background: url("../../assets/img/sysjz_top_img.png");
  position: relative;
}
.img_text {
  position: absolute;
  top: 70px;
  width: 50%;
  height: 10px;
  text-align: center;
  left: 25%;
  right: 25%;
  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: bold;
    margin: 20px 0 5px;
  }
  .a2 {
    font-size: 20px;
    color: #ffffff;
    text-transform: uppercase;
    margin: 0;
  }
  .a1 {
    margin: 30px 0 40px;
  }
  div {
    font-size: 18px;
    color: #ffffff;
  }
}
.pop {
  width: 1000px;
  height: 600px;
  background: #ffffff;
  border: 1px solid #707070;
  padding: 40px 30px 30px;
  text-align: inherit !important;
  overflow: hidden;
  img {
    cursor: pointer;
  }
}
.pop_top {
  height: 30px;
}
.pop_left {
  float: left;
  padding-left: 150px;
  width: 100%;
  p {
    font-size: 25px;
    color: #333333;
    font-weight: bold;
    margin-left: 5px;
    max-width: 700px;
  }
}
.img2 {
  margin-top: -30px;
}
.pop_content {
  margin: 20px 0;
  font-size: 16px;
  color: #333333;
  text-align: center;
}
.pop_bottom {
  width: 100%;
  height: 440px;
  overflow-y: auto;
  padding-bottom: 100px;
}
.color {
  background: #408df7 !important;
  color: #ffffff !important;
}
.fenye {
  width: 100%;
  text-align: center;
}
</style>
