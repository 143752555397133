<template>
  <div style="background: #ffffff">
    <Header></Header>
    <Tops ref="mychild"></Tops>
    <div class="place fl">
      <span>当前位置：</span>
      <span>采购服务></span>
      <span class="title_name">{{ list.goods_name }}</span>
    </div>
    <div class="big-img" v-if="openBig" @click="closeBig">
      <img :src="nowImg" />
    </div>
    <!-- 商品 -->
    <div class="mg_space_260">
      <div class="goods">
        <div class="magnify fl">
          <div class="left_contaner">
            <div v-if="video_url"  id="videoPlay" class="video_box">
              <video
                controls
                controlslist="nodownload  noremoteplayback"
                ref="thisVideo"
                preload
                :src="video_url"
              ></video>
            </div>

            <div
              v-show="isShow"
              class="middle_img"
              @mouseover="boxMouseOver"
              @mouseleave="boxMouseLeave"
            >
              <img :src="middleImg" />
              <div class="search_img">
                <img src="../../assets/img/sc_top_ss.png" />
              </div>
              <div
                class="shade"
                @mouseover="shadeMouseOver"
                @mousemove="shadeMouseMove"
                ref="shade"
                v-show="isShade"
              ></div>
            </div>
            <div class="carousel">
              <div class="left_arrow arrow1" @click="leftArrowClick">
                <img
                  v-if="this.middleLeft != 0"
                  src="../../assets/img/spxq_zh_s.png"
                />
                <img v-else src="../../assets/img/spxq_zh_n.png" />
              </div>
              <div class="show_box">
                <ul class="picture_container" ref="middlePicture">
                  <li
                    class="picture_item"
                    v-for="(item, index) in pictureList"
                    :key="index"
                    @mouseover="tabPicture(item.image_url)"
                  >
                    <img :src="item.image_url" class="small_img" alt="" />
                  </li>
                </ul>
              </div>
              <div class="right_arrow arrow" @click="rightArrowClick">
                <img
                  v-if="pictureList.length > 5"
                  src="../../assets/img/spxq_yh_s.png"
                />
                <img
                  v-if="pictureList < 5"
                  src="../../assets/img/spxq_yh_n.png"
                />
              </div>
            </div>
          </div>
          <div class="right_contanier" v-show="isBig">
            <img :src="middleImg" ref="bigImg" class="big_img" alt="" />
          </div>
          <div class="gooods_left_bottom">
            <div @click.stop="chose_ss()">
              <img v-if="type == 0" src="../../assets/img/icon_pj_n.png" />
              <img v-if="type == 1" src="../../assets/img/icon_pj_s.png" />
              <p>收藏</p>
            </div>
            <div @click.stop="addPK(list.cat_id, list.goods_id)">
              <img src="../../assets/img/productdetail_icon_pk.png" />
              <p>加入PK</p>
            </div>
          </div>
        </div>

        <div class="goods_right fl">
          <div class="goods_right_text two-letter">{{ list.goods_name }}</div>
          <div class="goods_righe_mess clear">
            <div>
              型号:
              <p>{{ list.goods_sn }}</p>
            </div>
            <div>
              产地:
              <p>{{ list.goods_site }}</p>
            </div>
            <div>
              品牌:
              <p>{{ list.goods_brand }}</p>
            </div>
          </div>
          <div class="num">
            <div>促销价</div>
            <p>¥{{ Price }}</p>
          </div>
          <div class="num1">
            <div>价格</div>
            <p>¥{{ list.market_price }}</p>
          </div>

          <div class="num3">
            <div class="div">规格</div>
            <div class="specification">
              <div v-for="(item, index) in goodsSpecList" :key="index">
                <p class="one-letter">{{ item.spec_name }}</p>
                <span
                  v-for="(itn, ind) in item.item_list"
                  :key="ind"
                  @click="chose(index, itn.item_id, itn.item)"
                  :class="[priceKey[index] == itn.item_id ? 'blue' : '']"
                  >{{ itn.item }}</span
                >
              </div>
            </div>
          </div>
          <div class="num2">
            <div>数量</div>
            <van-stepper
              class="step"
              v-model="num"
              :min="min"
              :max="repertory"
              integer
              async-change
              @change="onChange()"
            />
            <p>
              库存<span>{{ repertory }}</span
              >件
            </p>
          </div>
          <div class="btn clear">
            <div @click="buy()">立即购买</div>
            <p @click="add()">加入购物车</p>
            <span @click="show_card()">生成报价单</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="mg_space_260">
      <div class="remmend">
        <p></p>
        <div>产品介绍</div>
      </div>
      <div class="remmend_text" v-html="list.goods_content"></div>
    </div>
    <!-- 售后政策 -->
    <div class="mg_space_260">
      <div class="remmend">
        <p></p>
        <div>产品描述</div>
      </div>
      <div class="remmend_text" v-html="list.goods_remark"></div>
    </div>
    <div class="mg_space_260">
      <div class="remmend1">
        <p></p>
        <div>商品推荐</div>
      </div>
      <div class="goods_list_box">
        <div
          class="goods_list fl"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <img :src="item.original_img" @click="GetInfo(item.goods_id)" />
          <div class="goods_list_text two-letter">{{ item.goods_name }}</div>
          <div class="goods_list_bottom">
            <p>促销价</p>
            <div>¥{{ item.shop_price }}</div>
            <span @click="addPK(item.cat_id, item.goods_id)">加入PK</span>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="show" class="pop">
      <img
        class="pop_img1 fr"
        src="../../assets/img/pop_but_gb.png"
        @click="show = false"
      />
      <div class="pop_title fl">{{ Card_goods.goods_name }}</div>
      <div class="wrapper fl">
        <div class="item fl">致</div>
        <div class="item fr">由</div>
        <div class="item">
          <div class="item-cell">
            To Name姓名：{{ userlist.user_name }} {{ userlist.mobile }}
          </div>
        </div>
        <div class="item">
          <div class="item-cell">From{{ Card_goods.goods_manu }}</div>
        </div>
        <div class="item">
          <div class="item-cell">
            To name of company公司名称：{{ userlist.unit_name }}
          </div>
        </div>
        <div class="item">
          <div class="item-cell">Name姓名：{{ Card_goods.goods_manu }}</div>
        </div>
        <div class="item">
          <div class="item-cell">Fax Net传真 : 8989898</div>
        </div>
        <div class="item">
          <div class="item-cell">Fax Net传真 : 8989898</div>
        </div>
        <div class="item">
          <div class="item-cell">Tetal Pages页数 : 3页</div>
        </div>
        <div class="item">
          <div class="item-cell">Date日期:{{ Card_goods.add_time }}</div>
        </div>

        <div class="big-item">仪器名称:{{ Card_goods.goods_name }}</div>
        <div class="big-item">
          {{ Card_goods.goods_sn }}
          {{ Card_goods.cat_name }}
          {{ Card_goods.shop_price }}
          {{ Card_goods.goods_manu }}
        </div>
      </div>
      <div class="pop_bottom fl">
        <p class="fl">产品明细</p>
        <p class="fl">产品说明</p>
        <p class="fl">技术参数</p>
      </div>
      <a :href="Url" class="btn1 fl">下载</a>
    </van-popup>
    <canvas></canvas>
    <Righter></Righter>
    <Bottom></Bottom>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Tops from "../../components/Tops";
import Bottom from "../../components/Bottom";
import Righter from "../../components/Righter";
import $ from "jquery";
import {
  goodsInfo,
  addCart,
  addpk,
  addView,
  goods_ss,
  goods_price,
} from "../../api/goods";
export default {
  components: { Header, Tops, Bottom, Righter },
  props: {
    middleImgWidth: {
      default: 400,
      type: Number,
    }, // 产品图片宽
    middleImgHeight: {
      default: 400,
      type: Number,
    }, // 产品图片高
    thumbnailHeight: {
      default: 60,
      type: Number,
    }, // 缩略图容器高度
    zoom: {
      default: 2, // 缩略比例,放大比例
      type: Number,
    },
  },
  data() {
    return {
      pictureList: [],
      middleImg: "", // 中图图片地址
      bigImg: "", // 大图图片地址
      isShade: false, // 控制阴影显示与否
      isBig: false, // 控制放大图显示与否
      initX: 0, // 初始clientX值
      initY: 0, // 初始clientY值
      leftX: 0, // 初始定位left
      topY: 0, // 初始定位top
      middleLeft: 0, // 当前放置小图盒子的定位left值,
      itemWidth: 60, // 缩略图每张的宽度
      token: "",
      id: "",
      list: "",
      goodsList: [],
      imgList: [],
      num: 1, //数量
      user_id: "",
      type: 0,
      show: false,
      Card: "",
      Card_goods: "",
      userlist: {},
      Url: "",
      Price: "", //促销价
      repertory: "", //库存 //规格的库存
      min: 1,
      goodsSpecPrice: [],
      goodsSpecList: [], //规格
      goods_ll: "",
      specId: [], //规格的K
      priceKey: [],
      goods: "",
      nowImg: "",
      openBig: false,
      video_url: "",
      isShow: "",
      coverUrl:'',
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.user_id = localStorage.getItem("user_id");
    this.id = this.$route.query.id;
    this.getGoodsInfo();
    this.addView(); //进去详情页添加浏览记录
    this.getShow();

    if (this.imgList && this.imgList.length) {
      this.pictureList = this.imgList;
    }
    this.itemWidth = (this.middleImgWidth - 50) / 5;
  },
  mounted() {
    this.$nextTick(() => {
      // 容器的高
      const imgWidth = this.middleImgHeight + this.thumbnailHeight + 20;
      // 设置容器宽高
      $(".magnify").css({
        width: this.middleImgWidth,
        height: imgWidth,
      });
      // 设置产品图宽高
      $(".middle_img").css({
        width: this.middleImgWidth,
        height: this.middleImgHeight,
      });
      // 设置移动阴影图宽高
      $(".middle_img .shade").css({
        width: this.middleImgWidth / this.zoom,
        height: this.middleImgHeight / this.zoom,
      });
      // 设置缩略图容器高
      $(".carousel").css({
        height: this.thumbnailHeight,
      });
      // 设置每个缩略图宽
      $(".picture_item").css({
        width: this.itemWidth,
      });
      // 设置放大后图片容器的宽高,left
      $(".right_contanier").css({
        left: this.middleImgWidth,
        width: imgWidth,
        height: imgWidth,
      });
      // 设置放大图片的宽高(图片的放大倍数)
      $(".right_contanier .big_img").css({
        width: imgWidth * this.zoom,
        height: imgWidth * this.zoom,
      });
    });
  },
  methods: {
    // 产品图片鼠标移入事件,显示阴影,显示大图
    boxMouseOver(e) {
      console.log(e);
      e.preventDefault();
      e.stopPropagation();
      this.isShade = true;
      this.isBig = true;
      // 计算阴影的位置
      let x = e.offsetX - $(".shade").width() / 2;
      let y = e.offsetY - $(".shade").height() / 2;
      let maxLeft = $(".middle_img").width() - $(".shade").width();
      let maxTop = $(".middle_img").height() - $(".shade").height();
      x = x <= 0 ? 0 : x;
      x = x >= maxLeft ? maxLeft : x;
      y = y <= 0 ? 0 : y;
      y = y >= maxTop ? maxTop : y;
      $(".shade").css({
        left: x,
        top: y,
      });
    },
    // 鼠标在阴影移动
    shadeMouseMove(e) {
      e.preventDefault();
      e.stopPropagation();

      //用页面x - 父盒子的offsetLeft - 父盒子的左边框宽度
      var x =
        this.getEventPage(e).pageX -
        $(".middle_img")[0].offsetParent.offsetLeft -
        $(".middle_img")[0].offsetParent.clientLeft;
      //用页面y - 父盒子的offsetTop - 父盒子的上边框宽度
      var y =
        this.getEventPage(e).pageY -
        $(".middle_img")[0].offsetParent.offsetTop -
        $(".middle_img")[0].offsetParent.clientTop;

      //让阴影的坐标居中
      x -= $(".shade").width() / 2;
      y -= $(".shade").height() / 2;

      // 移动边界限制
      let maxLeft = $(".middle_img").width() - $(".shade").width();
      let maxTop = $(".middle_img").height() - $(".shade").height();
      x = x <= 0 ? 0 : x;
      x = x >= maxLeft ? maxLeft : x;
      y = y <= 0 ? 0 : y;
      y = y >= maxTop ? maxTop : y;
      // 重新赋值当前的定位值
      $(".shade").css({
        left: x,
        top: y,
      });
      // 计算出实时的大图的定位,首先计算出比例
      // 比例为x:大图宽度/小图宽度 y: 大图高度/小图高度,将小图的定位乘以比例就是大图的定位
      const xRate = $(".big_img").width() / $(".middle_img").width();
      const yRate = $(".big_img").height() / $(".middle_img").height();
      $(".big_img").css({
        left: -x * xRate,
        top: -y * yRate,
      });
    },
    // 鼠标移入阴影,去除自定义事件
    shadeMouseOver(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    // 图片移出隐藏阴影和大图
    boxMouseLeave() {
      this.isShade = false;
      this.isBig = false;
    },
    // 切换图片
    tabPicture(item) {
      this.video_url = "";
      this.isShow = true;
      this.middleImg = item;
    },
    // 点击左边箭头
    leftArrowClick() {
      if (this.middleLeft < 0) {
        // 每次向右平移一个图片盒子的宽度
        this.middleLeft += this.itemWidth;
        $(".picture_container").animate(
          {
            left: this.middleLeft,
          },
          500
        );
      }
    },
    // 点击右边箭头
    rightArrowClick() {
      this.video_url = "";
      this.isShow = true;
      // 每次向左平移一个盒子的宽度,最多移动的宽度为(图片数组长度-4)*每张缩略图的宽度
      if (this.middleLeft > -this.itemWidth * (this.pictureList.length - 3)) {
        this.middleLeft -= this.itemWidth;
        $(".picture_container").animate(
          {
            left: this.middleLeft,
          },
          500
        );
      }
    },
    /**
     * 获取网页滚出去的距离（包括上面滚出去的部分和左边滚出去的部分）
     * @returns {{scrollTop: (Number|number), scrollLeft: (Number|number)}}
     */
    getPageScroll() {
      return {
        scrollTop:
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          0,
        scrollLeft:
          window.pageXOffset ||
          document.documentElement.scrollLeft ||
          document.body.scrollLeft ||
          0,
      };
    },

    /**
     * 获取事件对象点击的点，相对于文档左上角的坐标
     * @param e
     * @returns {{pageX: *, pageY: *}}
     */
    getEventPage(e) {
      return {
        pageX: e.clientX + this.getPageScroll().scrollLeft,
        pageY: e.clientY + this.getPageScroll().scrollTop,
      };
    },

    closeBig() {
      this.openBig = false;
    },
    getBigImg(path) {
      this.nowImg = path;
      this.openBig = true;
    },
    //
    chose(idx, id, name) {
      let priceKey = this.priceKey;
      let specname = this.specname;
      let index = idx;
      let itemId = id;
      let subspecname = name;
      priceKey.splice(index, 1, itemId);
      specname.splice(index, 1, subspecname);
      this.itemId = itemId;
      (this.priceKey = priceKey),
        (this.specId = priceKey.join("_")), //商品规格
        (this.specname = specname);
      let goodsSpecPrice = this.goodsSpecPrice;
      for (let i = 0; i < goodsSpecPrice.length; i++) {
        if (goodsSpecPrice[i].key == this.specId) {
          this.Price = goodsSpecPrice[i].price;
          this.repertory = goodsSpecPrice[i].store_count;
          if (this.repertory == 0) {
            this.min = 0;
            this.num = 0;
          } else {
            this.min = 1;
            this.num = 1;
          }
        }
      }
    },
    //展示报价单
    getShow() {
      goods_price({ token: this.token, goods_id: this.id }).then((res) => {
        if (res.status == 1) {
          this.Url = res.result.url;
          this.Card_goods = res.result.goodslist;
          this.userlist = res.result.userlist;
        }
      });
    },
    show_card() {
      this.show = true;
    },
    //收藏
    chose_ss() {
      goods_ss({ token: this.token, goods_id: this.id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.getGoodsInfo();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        } else if (res.status == -100) {
          this.$message("您还未登录，请登录后再来");
          setTimeout(() => {
            this.$router.replace({ path: "/" });
          }, 1000);
        }
      });
    },
    addView() {
      addView({ goods_id: this.id, user_id: this.user_id }).then((res) => {});
    },
    //加入pk
    addPK(cat_id, id) {
      addpk({ token: this.token, goods_id: id, cat_id: cat_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.$refs.mychild.getPklist();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        } else if (res.status == -100) {
          this.$message("您还未登录，请登录后再来");
          setTimeout(() => {
            this.$router.replace({ path: "/" });
          }, 1000);
        }
      });
    },
    GetInfo(id) {
      goodsInfo({ user_id: this.user_id, id: id }).then((res) => {
        if (res.status == 1) {
          this.list = res.result.goods;
          this.goodsList = res.result.recommend_goods; //推荐商品
          this.pictureList = res.result.gallery; //轮播图
          this.video_url = res.result.goods.video_url;
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          // 实现滚动效果
          const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 1);
        }
      });
    },
    //立即购买
    buy() {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else if (this.goods.spec_goods_price != null) {
        addCart({
          token: this.token,
          goods_id: this.id,
          goods_num: this.num,
          is_buy_now: 1,
          goods_spec: this.specId,
        }).then((res) => {
          if (res.status == 1) {
            setTimeout(() => {
              this.$router.push({
                path: "./TheOrder",
                query: {
                  type: 1,
                },
              });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.goods.spec_goods_price == null) {
        addCart({
          token: this.token,
          goods_id: this.id,
          is_buy_now: 1,
          goods_num: this.num,
        }).then((res) => {
          if (res.status == 1) {
            setTimeout(() => {
              this.$router.push({
                path: "./TheOrder",
                query: {
                  type: 1,
                },
              });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //加入购物车
    add() {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else if (this.goods.spec_goods_price != null) {
        let formData;
        formData = {
          token: this.token,
          goods_id: this.id,
          goods_num: this.num,
          goods_spec: this.specId,
        };
        addCart(formData).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$refs.mychild.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      } else if (this.goods.spec_goods_price == null) {
        addCart({
          token: this.token,
          goods_id: this.id,
          goods_num: this.num,
        }).then((res) => {
          if (res.status == 1) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.$refs.mychild.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //数量
    onChange() {},
    //商品详情
    getGoodsInfo() {
      goodsInfo({ user_id: this.user_id, id: this.id }).then((res) => {
        if (res.status == 1) {
          this.goods = res.result;
          this.list = res.result.goods;
          this.goodsList = res.result.recommend_goods; //推荐商品
          this.pictureList = res.result.gallery; //轮播图
          this.middleImg = this.pictureList[0].image_url;
          this.video_url = res.result.goods.video_url;
          if (this.video_url == "") {
            this.isShow = true;
          } else {
            this.isShow = false;
          }
          this.type = res.result.is_collect; //商品收藏
          this.repertory = this.list.store_count; //库存
          this.Price = this.list.shop_price; //价格
          let goodsSpecList = res.result.goods.goods_spec_list; //规格
          let goodsSpecPrice = res.result.spec_goods_price; //价格
          let priceKey = [];
          let specname = [];

          if (goodsSpecList.length > 0) {
            for (let i = 0; i < goodsSpecList.length; i++) {
              priceKey.push(goodsSpecList[i].item_list[0].item_id);
              specname.push(goodsSpecList[i].item_list[0].item);
            }
            this.goodsSpecList = goodsSpecList;
            this.goodsSpecPrice = goodsSpecPrice;
            this.priceKey = priceKey;
            this.specId = priceKey.join("_");
            this.specname = specname;
            for (let i = 0; i < goodsSpecPrice.length; i++) {
              if (goodsSpecPrice[i].key == this.specId) {
                this.Price = goodsSpecPrice[i].price;
                this.repertory = goodsSpecPrice[i].store_count;
                if (this.repertory == 0) {
                  this.min = 0;
                  this.num = 0;
                } else {
                  this.min = 1;
                  this.num = 1;
                }
              }
            }
          }
        }
      });
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style   lang="scss" scoped>
.magnify {
  position: relative;
}
.left_contaner {
  width: 100%;
  height: 100%;
}
.left_contaner .middle_img {
  border: 1px solid #ccc;
  box-sizing: border-box;
  position: relative;
}
.left_contaner .shade {
  background-color: rgba(135, 206, 235, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  cursor: move;
}
.left_contaner .middle_img img {
  width: 100%;
  height: 100%;
}
.left_contaner .carousel {
  width: 100%;
  margin-top: 20px;
  display: -webkit-flex;
}
.left_contaner .carousel .show_box {
  margin: 0 10px;
  width: 340px;
  overflow: hidden;
  position: relative;
}
.left_contaner .carousel .arrow {
  flex-basis: 25px;
  cursor: pointer;
}
.arrow1 {
  width: 25px;
  height: 60px;
  img {
    width: 20px;
    height: 35px;
    margin: 15px 10px 0 0;
  }
}
.arrow {
  width: 25px;
  height: 60px;
  img {
    width: 20px;
    height: 35px;
    margin: 15px 0 0 5px;
  }
}
.left_contaner .carousel .left_arrow {
  background-position: center center;
}
.left_contaner .carousel .right_arrow {
  background-position: center right;
}
.left_contaner .carousel .picture_container {
  width: 200%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
}
.left_contaner .picture_container .picture_item {
  width: 60px;
  height: 100%;
  float: left;
  margin-right: 5px;
  box-sizing: border-box;
}
.picture_item {
  border: 2px solid #ffffff;
}
.left_contaner .picture_container .picture_item:hover {
  border: 2px solid #408df7;
}
.left_contaner .picture_container .picture_item img {
  width: 100%;
  height: 100%;
}
.right_contanier {
  z-index: 999;
  overflow: hidden;
  position: absolute;
  top: 0;
  border: 1px solid #ccc;
}
.right_contanier .big_img {
  position: absolute;
  top: 0px;
  left: 0px;
}

.complate {
  background-color: #fcfcfc;
}
.place {
  font-size: 16px;
  width: 100%;
  height: 50px;
  background: #f3f3f3;
  line-height: 50px;
  padding: 0 10%;

  span {
    width: 7%;
    float: left;
    white-space: nowrap;
    // display: inline-block;
    margin-left: 5px;
  }
}
.product {
  padding: 0 10%;
  .product_left {
    width: 300px;
    height: 300px;
  }
}
.pic-box {
  width: 300px;
  height: 300px;
}
// 商品
.goods {
  margin-top: 70px;
  width: 100%;
  height: 600px;
  border-bottom: 1px solid #eeeeee;
  padding-top: 20px;
}
.goods_left {
  width: 400px;
}
.img1 {
  object-fit: cover;
  width: 400px;
  height: 400px;
}

.gooods_left_bottom {
  padding-top: 30px;
  float: left;
  width: 400px;
  div {
    float: left;
    margin-left: 65px;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    img {
      width: 21px;
      height: 20px;
      float: left;
      margin-top: 5px;
    }
    p {
      font-size: 19px;
      color: #333333;
      float: left;
    }
  }
}

.goods_right {
  height: 100%;
  // background: chartreuse;
  width: calc(100% - 460px);
  margin-left: 40px;
}
.goods_right_text {
  font-size: 30px;
  color: #333333;
}
.goods_righe_mess {
  margin-top: 30px;
  height: 120px;
  background: #f4f4f4;
  padding: 45px 0 45px 35px;
  div {
    font-size: 20px;
    color: #666666;
    float: left;
    margin-right: 100px;
    p {
      color: #333333;
      float: right;
    }
  }
}
.num {
  margin-top: 20px;
  max-width: 400px;
  height: 40px;
  line-height: 40px;
  div {
    font-size: 18px;
    color: #999999;
    float: left;
  }
  p {
    font-size: 26px;
    color: #ff0000;
    margin-left: 30px;
    float: left;
  }
}
.num1 {
  // margin-top: 20px;
  max-width: 400px;
  height: 40px;
  line-height: 40px;
  div {
    font-size: 18px;
    color: #999999;
    float: left;
  }
  p {
    font-size: 18px;
    color: #333333;
    margin-left: 50px;
    float: left;
    text-decoration: line-through;
  }
}
.num2 {
  // margin-top: 20px;
  height: 40px;
  line-height: 40px;
  div {
    font-size: 18px;
    color: #999999;
    float: left;
  }
  p {
    font-size: 18px;
    color: #999999;
    margin-left: 30px;
    float: left;
  }
}
.num3 {
  // margin-top: 20px;
  height: 60px;
  .div {
    font-size: 18px;
    color: #999999;
    float: left;
  }
}
.specification {
  float: left;
  height: 50px;
  line-height: 30px;
  padding-left: 50px;
  div {
    margin-right: 5px;
    font-size: 16px;
    color: #666666;
    height: 40px;
    float: left;
    p {
      min-width: 50px;
      float: left;
    }
    span {
      float: left;
      margin: 0 5px 0 0;
      padding: 2px 10px;
      border: 1px solid #cccccc;
      font-size: 16px;
      color: #666666;
      cursor: pointer;
    }
  }
}
.step {
  margin-left: 50px;
  border: 1px solid #cccccc;
  line-height: 0;
  /deep/ .van-stepper__input {
    color: #666666;
    width: 65px;
    height: 35px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background: #ffffff;
  }
  /deep/.van-stepper__minus--disabled {
    background: #ffffff;
    color: #a1a1ab;
  }
  /deep/.van-stepper__minus {
    background: #ffffff;
  }
  /deep/ .van-stepper__plus {
    background: #ffffff;
  }
  .num-box {
    border: 1px solid #ddd;
    border-radius: 3px;
  }
}
.btn {
  margin-top: 40px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  cursor: pointer;
  div {
    width: 30%;
    height: 50px;
    background: rgba(64, 141, 247, 0.23);
    border: 1px solid #408df7;
    color: #408df7;
    font-size: 16px;
    line-height: 50px;
    float: left;
    margin-right: 20px;
  }
  p {
    width: 30%;
    height: 50px;
    background: #408df7;
    color: #ffffff;
    font-size: 16px;
    line-height: 50px;
    float: left;
    margin-right: 20px;
  }
  span {
    width: 30%;
    height: 50px;
    background: rgba(59, 175, 52, 0.23);
    border: 1px solid #3baf34;
    color: #3baf34;
    font-size: 16px;
    line-height: 50px;
    float: left;
  }
}
.remmend {
  height: 120px;
  border-bottom: 1px solid #eeeeee;
  p {
    width: 7px;
    height: 38px;
    background: #408df7;
    float: left;
    margin: 40px 15px 0;
  }
  div {
    font-size: 30px;
    color: #333333;
    font-weight: bold;
    float: left;
    margin-top: 38px;
  }
}
.remmend1 {
  height: 120px;
  p {
    width: 7px;
    height: 38px;
    background: #408df7;
    float: left;
    margin: 40px 15px 0;
  }
  div {
    font-size: 30px;
    color: #333333;
    font-weight: bold;
    float: left;
    margin-top: 38px;
  }
}

.remmend_text {
  padding: 40px 0;
  border-bottom: 1px solid #eeeeee;
}
// 推荐商品
.goods_list_box {
  width: 100%;
  margin-bottom: 150px;
}
.goods_list {
  padding: 18px;
  width: 20%;
  height: 360px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  position: relative;
  img {
    width: 100%;
    height: 223px;
    object-fit: cover;
  }
}
.goods_list_text {
  font-size: 16px;
  color: #333333;
}
.goods_list_bottom {
  position: absolute;
  bottom: 10px;
  width: 85%;
  p {
    font-size: 16px;
    color: #999999;
    float: left;
  }
  div {
    font-size: 16px;
    color: #ff0000;
    margin-left: 8px;
    float: left;
  }
  span {
    cursor: pointer;
    float: right;
    font-size: 11px;
    color: #999999;
    width: 50px;
    height: 20px;
    border: 1px solid #bbbbbb;
    line-height: 20px;
    text-align: center;
  }
}
.goods_list_box {
  height: 360px;
}

.banner {
  height: 500px;
  float: left;
  width: 400px;
  overflow: hidden;
  position: relative;
}
img {
  object-fit: cover;
  cursor: pointer;
  width: 400px;
  height: 400px;
}
/deep/.swiper-slide-thumb-active img {
  border: 2px solid #408df7;
}

.img2 {
  float: left;
  margin: 25px 5px 0 0;
  img {
    width: 15px;
    height: 27px;
  }
}
.img3 {
  float: left;
  margin: 25px 0 0 0;
  img {
    width: 15px;
    height: 27px;
  }
}
.img-swiper {
  float: left;
  width: 360px;
  overflow: hidden;
  img {
    margin: 15px 10px 0 0;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  /deep/.swiper-slide-thumb-active img {
    border: 2px solid #408df7;
  }
}
.pop {
  width: 792px;
  height: 673px;
  background: #ffffff;
  border-radius: 5px;
  padding: 30px 96px;
}
.pop_img1 {
  width: 15px;
  height: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.pop_title {
  width: 100%;
  text-align: center;
  color: #333333;
  font-size: 30px;
  font-weight: bold;
  float: left;
  margin-bottom: 20px;
  // margin: 0  auto 20px;
}

.wrapper {
  width: 100%;
  border: 1px solid #cccccc;
}

.item {
  width: 50%;
  height: 55px;
  box-sizing: border-box;
  border-bottom: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: 10px 0 0 20px;
  font-size: 15px;
  color: #333;
  float: left;
}

.item:nth-of-type(even) {
  border-right: none;
}

.big-item:last-child {
  border: none;
}

.big-item {
  float: left;
  width: 100%;
  height: 54px;
  padding: 10px 0 0 20px;
  border-bottom: 1px solid #cccccc;
  font-size: 28rpx;
  color: #333;
  box-sizing: border-box;
}
.pop_bottom {
  width: 100%;
  text-align: center;
  p {
    font-size: 14px;
    color: #bbbbbb;
    margin: 20px 60px;
  }
}
.btn1 {
  width: 320px;
  height: 50px;
  background: #408df7;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 16px;
  margin: 40px 130px 0;
  cursor: pointer;
}
.blue {
  color: #408df7 !important ;
  border: 1px solid #408df7 !important;
}
.big-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 54, 54, 0.5);
  z-index: 999;
  img {
    width: 80%;
    height: 600px;
    margin-left: 10%;
    margin-top: 10%;
    object-fit: contain;
  }
}
.search_img {
  width: 30px;
  height: 30px;
  background: #e8e8e8;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px 0 0 5px;
  img {
    object-fit: cover;
    width: 20px !important;
    height: 20px !important;
  }
}
/deep/.title_name {
  width: 80% !important;
  overflow: hidden;
  height: 50px;
  /*设置文本框大小*/
  white-space: nowrap;
  /*设置内容不换行*/
  text-overflow: ellipsis;
  /*设置文字超出文本框的内容显示成...*/
  overflow: hidden;
  /*超出部分隐藏*/
}
.video_box {
  width: 400px;
  height: 400px;
  video {
    width: 100%;
    height: 100%;
  }
}
</style>
