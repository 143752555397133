import {
    post
} from "../util/index";
//采购服务 一级分类(1)
export function Category(query) {
    return post("Goods/goodsCategoryList", query);
}
//采购服务 二级分类(1)
export function Category_next(query) {
    return post("Goods/goodsSecAndThirdCategoryList", query);
}
//采购服务 轮播图(1)
export function banner(query) {
    return post("goods/goods_ad", query);
}
//采购服务 首页展示的商品(1)
export function goods(query) {
    return post("goods/goods_list", query);
}
//购物车列表(1)
export function CartList(query) {
    return post("Cart/cartList", query);
}

//删除购物车(1)
export function delCart(query) {
    return post("cart/delCart", query);
}
//商品详情(1)
export function goodsInfo(query) {
    return post("Goods/goodsInfoMiniApp", query);
}

//购物车列表(1)
export function Cart_List(query) {
    return post("cart/cart_list", query);
}

//列表(1)
export function List1(query) {
    return post("goods/goodsLists", query);
}
export function List(query){
    return post('goods/search_list',query)
}
//加入购物车(1)
export function addCart(query) {
    return post("Cart/pcaddCart", query);
}


//加入PK(1)
export function addpk(query) {
    return post("goods/add_pk", query);
}
//删除PK(1)
export function delpk(query) {
    return post("goods/pk_delete", query);
}
//清空PK(1)
export function removePk(query) {
    return post("goods/all_delete", query);
}
//PK列表(1)
export function pkList(query) {
    return post("goods/pk_list", query);
}
//PK对比详情(1)
export function PKInfo(query) {
    return post("UserInstrument/compareInstruemntInfo", query);
}
//添加浏览记录(1)
export function addView(query) {
    return post("goods/addView", query);
}


//商品价格(1)
export function goodsprice(query) {
    return post("cart/pcgetCartTotal", query);
}

//商品数量(1)
export function goodsNum(query) {
    return post("cart/editGoodsNum", query);
}


//确认订单(1)
export function subOrder(query) {
    return post("cart/cart2", query);
}

//提交订单(1)
export function Subrder(query) {
    return post("cart/cart3", query);
}

//使用优惠券(1)
export function use_coupon(query) {
    return post("Cart/getCoupon", query);
}
//支付订单页(1)
export function orderInfo(query) {
    return post("user/getOrderDetail", query);
}

//微信支付(1)
export function OrderPay(query) {
    return post("Wxpay/payment", query);
}
//我的订单(1)
export function myOrder(query) {
    return post("User/pcgetOrderList", query);
}
//商品收藏(1)
export function goods_ss(query) {
    return post("goods/pccollectGoods", query);
}

//报价单(1)
export function goods_price(query) {
    return post("UserInstrument/goods_price", query);
}
//服务订单详情
export function service_order(query){
    return post("InstrumentData/pc_order_instrument_list",query)
}
//删除订单
export function deleter(query){
    return post("api/user/order_del",query)
}

//商品订单
export function goods_Order(query){
    return post("User/pcgetOrderList",query)
}
//服务订单支付
export function OrderPay1(query){
    return post("Wxpay/fw_payment",query)
}
//服务订单详情
export function orderInfo1(query){
    return post("InstrumentData/order_instrument_info",query)
}

//订单状态(1)
export function order_status(query){
    return post("InstrumentData/getOrderStatus",query)
}

//全部商品列表(1)
export function moreList(query){
    return post("Goods/goods_lists",query)
}

//汇款信息(1)
export function remittance(query){
    return post("User/message",query)
}