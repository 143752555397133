<template>
  <div v-loading="loading">
    <div class="view1">
      <el-row :gutter="20">
        <el-col :span="12">
          <p class="view1-h5" v-if="type==1">可正常使用</p>
          <p class="view1-h5" v-if="type==2">维修中</p>
          <p class="view1-h5" v-if="type==3">待维修</p>
          <p class="view1-h5" v-if="type==4">已报废</p>
          <div class="view1-item">
            <p class="view1-title">总数量</p>
            <span class="content-num" v-if="type==1">{{list.status.use}}</span>
            <span class="content-num" v-if="type==2">{{list.status.repair}}</span>
            <span class="content-num" v-if="type==3">{{list.status.waitingRepair}}</span>
            <span class="content-num" v-if="type==4">0</span>
          </div>
          <div class="view1-item">
            <p class="view1-title">总占比</p>
            <span
              class="content-num"
              v-if="type==1"
            >{{parseInt(list.status.use/(list.status.use+list.status.repair+list.status.waitingRepair)*100)}}%</span>
            <span
              class="content-num"
              v-if="type==2"
            >{{parseInt(list.status.repair/(list.status.use+list.status.repair+list.status.waitingRepair)*100)}}%</span>
            <span
              class="content-num"
              v-if="type==3"
            >{{parseInt(list.status.waitingRepair/(list.status.use+list.status.repair+list.status.waitingRepair)*100)}}%</span>
            <span class="content-num" v-if="type==4">0%</span>
          </div>
        </el-col>
        <el-col :span="12" class="box" v-if="type==1">
          <el-progress
            stroke-width="22"
            color="#0A54EA"
            type="circle"
            width="160"
            :percentage="parseInt(list.status.use/(list.status.use+list.status.repair+list.status.waitingRepair)*100)"
          ></el-progress>
        </el-col>
        <el-col :span="12" class="box" v-if="type==2">
          <el-progress
            stroke-width="22"
            color="#0A54EA"
            type="circle"
            width="160"
            :percentage="parseInt(list.status.repair/(list.status.use+list.status.repair+list.status.waitingRepair)*100)"
          ></el-progress>
        </el-col>
        <el-col :span="12" class="box" v-if="type==3">
          <el-progress
            stroke-width="22"
            color="#0A54EA"
            type="circle"
            width="160"
            :percentage="parseInt(list.status.waitingRepair/(list.status.use+list.status.repair+list.status.waitingRepair)*100)"
          ></el-progress>
        </el-col>
        <!-- <el-col :span="12" class="box" v-if="type==4">
          <el-progress
            stroke-width="15"
            color="#0A54EA"
            type="circle"
            :percentage="parseInt(list.status.use/(list.instrument.count)*100)"
          ></el-progress>
        </el-col>-->
      </el-row>
    </div>
  </div>
</template>

<script>
// import { RingProgress } from "@antv/g2plot";
import { client } from "../../../api/company";
export default {
  props: ["type", "canvasId"],
  data() {
    return {
      loading: true,
      list: "",
    };
  },
  mounted() {
    this.getList();
    // const ringProgress = new RingProgress(this.canvasId, {
    //   height: 215,
    //   width: 215,
    //   autoFit: true,
    //   percent: 0.7,
    //   color: ["#0A54EA", "#062D97"],
    // });
    // ringProgress.render();
  },
  methods: {
    getList() {
      client({}).then((res) => {
        this.list = res.data;
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content-num {
  font-size: 25px;
  font-weight: bold;
  line-height: 26px;
  color: #47e6ff;
}
.view1 {
  background: rgba(6, 45, 151, 0.17);
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
  &-h5 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
  }
  &-title {
    font-size: 14px;
    color: #fff;
  }
  &-item {
    display: flex;
    flex-direction: column;
    padding: 15px 20px;
    margin-top: 10px;
  }
}
.box /deep/ svg path:first-child {
  stroke: #062d97;
}
</style>