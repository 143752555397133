<template>
  <div>
    <Header />
    <div class="top">收银台</div>
    <div class="content_box">
      <div class="content">
        <div class="content_top">
          <div class="content_left">
            <p>
              订单提交成功，请您尽快付款！订单号：<span>{{
                list.order_sn
              }}</span
              >。
            </p>
            <div>
              请您在<span>24小时内</span>
              完成支付，否则订单会被自动取消（以订单详情页为准）。
            </div>
          </div>
          <div class="content-right">
            <div v-if="type == 1">
              应付金额：<span>¥{{ list.order_price }}</span>
            </div>
            <div v-else>
              应付金额：<span>¥{{ list.order_amount }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content_bottom_box">
        <div class="content_bottom_left">
          <p>微信支付</p>
          <img :src="Url + imgUrl" />
          <div>请使用微信支付</div>
        </div>
        <div class="content_bottom_right fr">
          <div class="text">线下汇款<span>/其他方式</span></div>
          <div class="content_list">
            <p>单位名称</p>
            <span>{{ Info.message.value }}</span>
          </div>
          <div class="content_list">
            <p>银行卡号</p>
            <span>{{ Info.info.value }}</span>
          </div>
          <!-- <div class="btn">提交汇款信息</div> -->
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="30%" @close="guan()">
      <div class="box">
        <img src="../../assets/img/img_cg.png" />
        <p>支付完成</p>
        <div v-if="type == 1" class="price">
          <span>¥{{ list.order_price }}</span>
        </div>
        <div v-else class="price">
          <span>¥{{ list.order_amount }}</span>
        </div>
        <div class="wan">微信支付完成</div>
      </div>
    </el-dialog>
    <Bottom />
  </div>
</template>

<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import {
  orderInfo,
  OrderPay,
  OrderPay1,
  orderInfo1,
  order_status,
  remittance,
} from "../../api/goods";
export default {
  components: { Header, Bottom },
  data() {
    return {
      token: "",
      id: "",
      list: "",
      Url: "http://yiqiguanjia.pro6.liuniukeji.net/",
      imgUrl: "",
      type: "",
      dialogVisible: false,
      time: 0,
      Info: {},
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.id = this.$route.query.id;
    this.type = this.$route.query.type; //1是服务订单
    this.getInfo();
    this.getPay();
    this.remittance();
  },
  mounted() {
    this.timer = setInterval(this.getStatus, 1000);
  },
  methods: {
    guan() {
      if (this.type == 2) {
        this.$router.replace({
          path: "/Homepage",
        });
      } else {
        this.$router.replace({
          path: "/UserCenter",
          query: {
            control: 4,
          },
        });
      }
    },
    remittance() {
      remittance({}).then((res) => {
        this.Info = res.data;
      });
    },
    getStatus() {
      if (this.type == 1) {
        order_status({ order_id: this.id, type: 2 }).then((res) => {
          if (res.status == 1) {
            if (res.data == 1) {
              this.dialogVisible = true;
              clearInterval(this.timer);
            } else {
              return;
            }
          }
        });
      } else {
        order_status({ order_id: this.id, type: 1 }).then((res) => {
          if (res.status == 1) {
            if (res.data == 1) {
              this.dialogVisible = true;
              clearInterval(this.timer);
            } else {
              return;
            }
          }
        });
      }
    },
    getPay() {
      if (this.type == 1) {
        OrderPay1({ id: this.id }).then((res) => {
          if (res.status == 1) {
            this.imgUrl = res.data;
          }
        });
      } else {
        OrderPay({ order_id: this.id }).then((res) => {
          if (res.status == 1) {
            this.imgUrl = res.data;
          }
        });
      }
    },
    //获取商品金额和商品订单
    getInfo() {
      if (this.type == 1) {
        orderInfo1({ token: this.token, id: this.id }).then((res) => {
          if (res.status == 1) {
            this.list = res.result;
          }
        });
      } else {
        orderInfo({ token: this.token, id: this.id }).then((res) => {
          if (res.status == 1) {
            this.list = res.result;
          }
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.top {
  height: 50px;
  background: #f3f3f3;
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #333333;
  line-height: 50px;
  font-weight: bold;
}
.content {
  width: 100%;
  height: 130px;
}
.content_box {
  padding: 0 360px;
  background: #ffffff;
  height: 710px;
}
.content_top {
  height: 130px;
  border-bottom: 1px solid #eeeeee;
  padding-top: 44px;
}
.content_left {
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
  }
  div {
    font-size: 14px;
    color: #999999;
    span {
      color: #ff0000;
    }
  }
}
.content-right {
  float: right;
  padding-top: 20px 30px 0 0;
  font-size: 16px;
  color: #333333;
  span {
    font-size: 22px;
    color: #ff0000;
    font-weight: bold;
  }
}
.content_bottom_box {
  padding-top: 44px;
  height: 560px;
}
.content_bottom_left {
  float: left;
  height: 100%;
  p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
  }
  img {
    width: 200px;
    height: 200px;
    margin: 40px 0 10px;
  }
  div {
    font-size: 14px;
    color: #666666;
  }
}
.content_bottom_right {
  // padding-left: 400px;
  // float: left;
  padding: 0  50px 0;
}

.text {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  span {
    font-size: 16px;
    color: #999999;
    font-weight: 400;
  }
}
.content_list {
  height: 50px;
  line-height: 50px;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  span {
    margin-left: 10px;
    float: left;
  }
}
.btn {
  font-size: 18px;
  color: #ffffff;
  width: 179px;
  height: 60px;
  background: #408df7;
  text-align: center;
  line-height: 60px;
  float: left;
  margin: 30px 0 0 100px;
}
.box {
  text-align: center;
  p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    margin-top: 20px;
  }

  .wan {
    font-size: 14px;
    color: #999999;
  }
}
.price {
  font-size: 36px;
  color: #ff0000;
  font-weight: bold;
  margin: 40px 0 10px 0;
}
</style>
