<template>
  <div class="container">
    <!-- <div id="canvas"></div> -->
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="box1">
          <p class="title">仪器总数</p>
          <el-row :gutter="20">
            <el-col :span="12">
              <div class="item">
                <img class="icon" src="../../../assets/img/yiqi_icon_1.png" />
                <div class="content">
                  <span class="content-title">仪器总数</span>
                  <span class="content-num">{{list.instrument.count}}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="item">
                <img class="icon" src="../../../assets/img/yiqi_icon_2.png" />
                <div class="content">
                  <span class="content-title">在线数量</span>
                  <span class="content-num">{{list.instrument.onLineCount}}</span>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="box2">
          <p class="title">售后数据</p>
          <view-item-small :type="5" :canvasId="'canvas1'" :state="5"></view-item-small>
          <div
            style="width: 380px;margin:10px 0;height: 0px;border-bottom: 1px dashed  rgba(255, 255, 255, 0.27058823529411763);"
          />
          <view-item-small :type="6" :canvasId="'canvas2'" :state="6"></view-item-small>
        </div>
      </el-col>
      <el-col :span="16" class="box3">
        <p class="title">使用数量</p>
        <el-row :gutter="20">
          <el-col :span="8">
            <div class="top">
              <div class="top-cell">
                <div class="left">
                  <div class="top-cell-sign red">高</div>
                </div>
                <div class="content">
                  <span class="content-title">总数量</span>
                  <span class="content-num content-numA">{{list.use.tallCount}}</span>
                </div>
                <div class="content">
                  <span class="content-title">总占比</span>
                  <span class="content-num">{{list.use.tallProportion}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="top">
              <div class="top-cell">
                <div class="left">
                  <div class="top-cell-sign blue">中</div>
                </div>
                <div class="content">
                  <span class="content-title">总数量</span>
                  <span class="content-num content-numA">{{list.use.centreCount}}</span>
                </div>
                <div class="content">
                  <span class="content-title">总占比</span>
                  <span class="content-num">{{list.use.centreProportion}}</span>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="top">
              <div class="top-cell">
                <div class="left">
                  <div class="top-cell-sign gray">低</div>
                </div>
                <div class="content">
                  <span class="content-title">总数量</span>
                  <span class="content-num content-numA">{{list.use.lowCount}}</span>
                </div>
                <div class="content">
                  <span class="content-title">总占比</span>
                  <span class="content-num">{{list.use.lowProportion}}</span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <view-item-big :type="1" :canvasId="'canvasBig1'"></view-item-big>
            <view-item-big :type="2" :canvasId="'canvasBig2'"></view-item-big>
          </el-col>
          <el-col :span="12">
            <view-item-big :type="3" :canvasId="'canvasBig3'"></view-item-big>
            <view-item-big :type="4" :canvasId="'canvasBig4'"></view-item-big>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import ViewItemSmall from "./view-item1";

import { client } from "../../../api/company";
import ViewItemBig from "./view-item2";
export default {
  components: { ViewItemSmall, ViewItemBig },
  data() {
    return {
      list: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      client({}).then((res) => {
        this.list = res.data;
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.container {
  margin-top: 50px;
  // padding-right: 17px;
}
.top {
  display: flex;
  background: rgba(6, 45, 151, 0.29);
  border-radius: 10px;
  padding: 7%;
  &-cell {
    display: flex;
    align-items: center;
    &-sign {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 34px;
      color: #fff;
    }
  }
}
.red {
  background: rgba(255, 0, 0, 0.38);
}
.blue {
  background: rgba(10, 84, 234, 0.55);
}
.gray {
  background: rgba(153, 153, 153, 0.29);
}
.content {
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  margin-right: 2%;
  // padding-right: 20px;
  &-title {
    font-size: 15px;
    color: #fff;
  }
  &-num {
    font-size: 22px;
    font-weight: bold;
    color: #00c0ff;
    height: 25px;
  }
  &-numA {
    border-right: 1px solid #ffffff;
    // margin-right: 20px;
    padding-right: 50px;
    height: 25px;
  }
}
.title {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  padding: 20px 0 15px;
}
.box1 {
  background: url("../../../assets/img/yiqi_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  //   height: 250px;
  padding: 0 20px 35px;
  .item {
    background-color: rgba(6, 45, 151, 0.29);
    height: 140px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    // margin-right: 20px;
  }
  .icon {
    width: 79px;
    height: 79px;
  }
}
.box2 {
  background: url("../../../assets/img/shouhou_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  //   height: 650px;
  padding: 0 20px 35px;
  margin-top: 20px;
}
.box3 {
  background: url("../../../assets/img/shiyong_bg_1.png") no-repeat;
  background-size: 100% 100%;
  // width: 100%;
  //   height: 920px;
  padding: 0 20px 35px;
}
.left {
  margin-right: 20px;
  width: 30%;
}
</style>