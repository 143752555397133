<template>
  <div class="content">
    <div id="title-instrument" class="title-instrument">
      <div class="fl title-left">
        <p class="fl">仪器选择</p>
        <p class="fl">*可多选</p>
      </div>
      <div class="fr title-right">
        <div @click="sousuo" class="search-btn fr">
          <img src="../../assets/img/top_icon_ss.png" alt="" />
          <span>搜索</span>
        </div>
        <p class="fr tianjia" @click="add">添加仪器</p>
      </div>
    </div>
    <div id="select-instrument" class="select-instrument clear">
      <el-button type="text" @click="show4">
        <el-input
          id="xuanze_yiqi"
          disabled="disabled"
          suffix-icon="el-icon-arrow-down"
          v-model="instrument_name_str"
          placeholder="请选择仪器"
        ></el-input>
      </el-button>
      <el-collapse-transition>
        <div v-show="xianshicontent" class="huadong transition-box">
          <el-checkbox
            v-for="(item, index) in aaa"
            :key="index"
            @change="strcontent(item.content)"
            :label="item.content.instrument_name"
            :checked="toChildrenData.id==item.content.id"
            v-model="aaa[index].i"
          ></el-checkbox>
        </div>
      </el-collapse-transition>
    </div>
    <el-divider></el-divider>
    <div v-for="(item, index) in instrument" :key="index">
      <div v-if="instrument">
        <div class="title-input">
          <p>手动输入</p>
        </div>
        <div class="input-item">
          <div class="name-inout fl">
            <p><span>*</span>仪器名称</p>
            <input
              disabled="disabled"
              type="text"
              v-model="item.instrument_name"
              placeholder="请填写仪器名称"
            />
          </div>
          <div class="type-input fr">
            <p><span>*</span>仪器型号</p>
            <input
              disabled="disabled"
              type="text"
              v-model="instrument[index].instrument_size"
              placeholder="请填写仪器型号"
            />
          </div>
        </div>
        <div class="input-item">
          <div class="factory-input fl">
            <p><span>*</span>品牌</p>
            <input
              v-model="instrument[index].install_seller"
              type="text"
              placeholder="请填写品牌"
            />
          </div>
          <div class="model-input fr" :id="'inputb' + index">
            <p>
              <span>*</span>保养方案<span class="fenpei">固定方案</span
              ><input
                type="radio"
                :name="'xuan' + index"
                id="fenpei"
                checked
                @click="fenP(0, index)"
              /><span class="fenpei">自定义方案</span
              ><input
                type="radio"
                :name="'xuan' + index"
                id="fenpei"
                @click="fenP(1, index)"
              />
            </p>
            <div style="width: 100%" :id="'inputa' + index">
              <el-select
                class="baoyangf"
                v-model="instrument[index].maintain_plan"
                placeholder="请选择保养方案"
              >
                <el-option
                  v-for="(item, indexx) in schemeList"
                  :key="indexx"
                  :label="item.title"
                  :value="item.id"
                  @click.native="fanga(item.id, item.title, index)"
                ></el-option>
              </el-select>
            </div>
            <input
              v-model="instrument[index].maintain_plan"
              style="display: none"
              type="text"
              name=""
              :id="'inputc' + index"
              placeholder="请输入保养方案"
            />
          </div>
        </div>
        <div class="input-item clear">
          <div class="desc-input fl">
            <p>保养说明</p>
            <textarea
              v-model="instrument[index].install_desc"
              name=""
              id=""
              placeholder="请填写保养说明"
            ></textarea>
          </div>
          <div class="desc-input fr">
            <p>
              <span>*</span>工程师<span class="fenpei">自动分配</span
              ><input
                type="checkbox"
                name="fenpei"
                id="fenpei"
                @click="autoFp(index)"
              />
            </p>
            <div class="inputimg">
              <input
                @click="gcs(index)"
                class="el-icon-arrow-down"
                v-model="instrument[index].gcs_name"
                placeholder="请选择工程师"
                :id="'inputtt' + index"
              />
              <img
                class="imputimg"
                src="../../assets/img/but_xl_h_n.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="upload-pic clear">
          <div class="upload-title">
            <p class="fl">现场图片/视频</p>
            <p class="fl">*最多上传3张图片和一段视频</p>
          </div>
          <div class="img-box clear">
            <el-upload
              @click.native="tupian(index)"
              :before-upload="qiandiao"
              action="#"
              name="img_file"
              accept="image/*,video/*"
              list-type="picture-card"
              :limit="3"
            >
              <img src="../../assets/img/but_sctup_dl.png" alt="" />
            </el-upload>
            <div
              class="upload_pictures"
              v-for="(item2, index2) in instrument[index].imageUrl"
              :key="index2"
            >
              <img class="upload_pictures_img" :src="item2" alt="" />
              <img
                @click="delet(1, index2, index)"
                class="del"
                src="../../assets/img/sc_img_but_sc.png"
                alt=""
              />
            </div>
            <div
              class="upload_video"
              v-show="videoUrl"
              v-for="(item3, index3) in instrument[index].videoUrl"
              :key="index3"
            >
              <video autoplay muted class="video" :src="item3"></video>
              <img
                @click="delet(2, index3, index)"
                class="del"
                src="../../assets/img/sc_img_but_sc.png"
                alt=""
              />
            </div>
            <el-table
              element-loading-text="加载中..."
              v-loading="loading"
              v-if="index == img_index && loading"
              style="width: 150px; height: 100px"
            ></el-table>
          </div>
        </div>
        <br />
        <el-divider></el-divider>
      </div>
    </div>

    <div v-if="instrument.length" class="submit-btn">
      <p @click="submit">提交</p>
    </div>
    <div class="attention">
      <p>请仔细填写相关信息，工程师会在10分钟之内与您取得联系</p>
    </div>
    <el-dialog
      :visible="show"
      class="pop"
      custom-class="tianjiaa"
      :before-close="shows"
    >
      <img
        class="pop_img1 fr"
        src="../../assets/img/pop_but_gb.png"
        @click="shows"
        v-if="show"
      />
      <div class="pop-title">
        <p>添加仪器</p>
        <p>
          <!--<van-uploader :after-read="onLoad" accept=".xls,.xlsx" name="file" :max-count="1"  class="fr q2" id="xlf"> </van-uploader>
        -->
          <el-upload
            class="upload-demo"
            action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/Company/analysis"
            name="file_url"
            :data="{ token: this.token, type: 1 }"
            accept=".xls,.xlsx"
            multiple
            :on-success="chenggong"
            :show-file-list="false"
            :limit="1"
          >
            <el-button size="small" type="primary"
              ><img
                class="frrr"
                src="../../assets/img/kc_icon_dr_n.png"
                alt=""
              />
              导入
            </el-button>
          </el-upload>
        </p>
        <p @click="xiazmoban">下载模版</p>
      </div>

      <div class="pop-div2">
        <p class="pop-div2-img">仪器照片</p>
        <van-uploader
          :after-read="upLoad"
          accept="image/*"
          name="file"
          :max-count="1"
          class="q2 widtt"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <img v-else class="phice" src="../../assets/img/pj_but_tjts.png" />
        </van-uploader>
        <div class="pop-instrument-name">
          <p>仪器名称</p>
          <input
            type="text"
            v-model="instrument_name"
            placeholder="请填写仪器名称"
          />
        </div>
        <div class="pop-instrument-name">
          <p>品牌</p>
          <input
            type="text"
            v-model="instrument_brand"
            placeholder="请填写品牌"
          />
        </div>
        <div class="pop-instrument-name fl">
          <p>仪器序列号</p>
          <input
            type="text"
            @click="state1"
            v-model="instrument_num"
            placeholder="请填写仪器序列号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>型号</p>
          <input
            type="text"
            v-model="instrument_size"
            placeholder="请填写型号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>仪器类型</p>
          <div @click="state2">
            <input
              type="text"
              v-model="instrument_cat"
              placeholder="请选择仪器类型"
              id="in"
              disabled="disabled"
            />
            <div
              class="zhansh"
              :style="{ width: wid }"
              v-if="zhansh"
              @mouseenter="enterr(index)"
              @mouseleave="leavee()"
            >
              <div
                class="fenlei"
                v-for="(item, index) in fenlei"
                :key="index"
                @click="neirong(item)"
              >
                <span>{{ item }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pop-instrument-name fl">
          <p>采购时间</p>
          <div class="block">
            <el-date-picker
              v-model="purchase_time"
              type="date"
              placeholder="请填写采购时间"
              popper-class="riqi"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="pop-instrument-name">
          <p>采购金额</p>
          <input
            type="text"
            v-model="purchase_money"
            onkeyup="value=value.replace(/[^\d.]/g,'')"
            placeholder="请填写采购金额"
          />
        </div>
        <div class="pop-instrument-name">
          <p>启用时间</p>
          <div class="block">
            <el-date-picker
              v-model="start_time"
              type="date"
              placeholder="请填写启用时间"
              popper-class="riqi"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="pop-instrument-name fl">
          <p>采购途径</p>
          <input
            type="text"
            v-model="procurement"
            placeholder="请填写采购途径"
          />
        </div>
        <div class="pop-instrument-name">
          <p>使用频率</p>
          <input
            type="text"
            v-model="use_frequency"
            placeholder="请填写使用频率"
          />
        </div>
        <div class="pop-instrument-name">
          <p>使用者</p>
          <input
            type="text"
            v-model="instrument_user"
            placeholder="请填写使用者"
          />
        </div>
        <div class="pop-instrument-name fl">
          <p>存放实验室</p>
          <input
            type="text"
            v-model="lab_room"
            placeholder="请填写存放实验室"
          />
        </div>
        <div class="pop-instrument-name">
          <p>房间号</p>
          <input
            type="text"
            v-model="lab_room_num"
            placeholder="请填写房间号"
          />
        </div>
        <div class="pop-instrument-name">
          <p>管理人</p>
          <input type="text" v-model="lab_respon" placeholder="请填写管理人" />
        </div>
        <div class="add-content-div" @click="adde">
          <el-button class="add-content" type="text">添加</el-button>
        </div>
      </div>
    </el-dialog>
    <van-popup v-model="show2">
      <img
        class="closs"
        src="../../assets/img/pop_but_gb.png"
        @click="shows2"
      />
      <div class="search">
        <div class="title">
          <span>搜索</span>
          <img src="../../assets/img/yqgj_top_logo.png" alt="" />
        </div>
        <input
          v-model="sous_content"
          placeholder="请输入搜索内容"
          type="text"
        />
        <div v-if="search_data" class="search_content">
          <div id="search_content" :style="{ height: hei }">
            <p
              v-for="(item, index) in search_data"
              :key="index"
              @click="click_search(item)"
            >
              {{ item.instrument_name }}
            </p>
          </div>
        </div>
        <p class="no_data" v-if="!search_data" style="color: #999999">
          暂无数据
        </p>
        <button v-if="!search_data" @click="add">添加仪器</button>
      </div>
    </van-popup>
    <van-popup v-model="show3">
      <img
        class="closs"
        src="../../assets/img/pop_but_gb.png"
        @click="shows2"
      />
      <div class="successfully" v-if="status == 1">
        <img
          class="title_img top1"
          src="../../assets/img/img_cg.png"
          alt=""
        /><br />
        <span id="font_color1">提交成功</span><br />
        <span class="time">{{ "00:" + min + ":" + s }}</span>
        <p>规定时间内会有工程师与您联系</p>
        <button @click="shows2">返回 ></button>
      </div>
      <div class="successfully" v-else>
        <img
          class="title_img top2"
          src="../../assets/img/img_shib.png"
          alt=""
        /><br />
        <span id="font_color2">提交失败</span>
        <p>稍后再试或直接拨打客服热线</p>
      </div>
    </van-popup>

    <el-dialog
      title="选择工程师"
      :visible.sync="gcstc"
      width="1200px"
      custom-class="gongchegshi"
    >
      <img
        class="dialog-img"
        src="../../assets/img/pop_but_gb.png"
        alt=""
        @click="gcstc = false"
      />

      <div class="dialog-header">
        <span class="dialog-title">选择工程师</span>
        <div class="dialog-sorting">
          <span
            v-for="(item, index) in sorting"
            :key="index"
            @click="sortingi(index)"
            :class="index == sortindex - 1 ? 'dialog-sort-bul' : 'dialog-sort'"
            >{{ item }}</span
          >
        </div>
      </div>
      <div class="dialogAll">
        <div>
          <div
            :class="index == gcsind ? 'dialog-pop-bul' : 'dialog-pop'"
            v-for="(item, index) in engineerList"
            :key="index"
            :style="(index + 1) % 3 == 0 ? 'margin:10px 0 0 0' : ''"
            @click="gcsxz(index)"
            @dblclick="lishifw(item.user_id)"
          >
            <div class="dialog-pop-img">
              <img :src="item.head_pic" alt="" /> <br />
              <input
                type="radio"
                name="sorting"
                id=""
                :checked="index == gcsind"
              />
            </div>
            <div class="dialog-pop-content">
              <p class="name">
                <span>{{ item.user_name }}</span
                ><span>{{ item.mobile }}</span>
              </p>
              <el-divider></el-divider>
              <p class="fuw">
                <span>成功服务：{{ item.succcount }}次</span
                ><span>好评率：{{ item.rate }}%</span>
              </p>
              <span class="contente">{{ item.serve_item }}</span>
              <el-divider></el-divider>
              <p class="happ">
                <span>好评{{ item.good_ping }}次</span
                ><span>中评{{ item.ordinary_ping }}次</span
                ><span>差评{{ item.bad_ping }}次</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="but">
        <button @click="gcstj">确定</button>
      </div>
    </el-dialog>
    <van-popup v-model="dialogVisible2">
      <div class="vantc">
        <el-tabs v-model="activeName">
          <el-tab-pane label="个人信息" name="first">
            <img :src="gcsinformation.head_pic" alt="" />
            <p>
              <span>姓名：</span><span>{{ gcsinformation.user_name }}</span>
            </p>
            <p>
              <span>手机：</span><span>{{ gcsinformation.mobile }}</span>
            </p>
            <p>
              <span>微信：</span><span>{{ gcsinformation.user_name }}</span>
            </p>
            <p>
              <span>毕业学校：</span><span>{{ gcsinformation.school }}</span>
            </p>
            <p>
              <span>学历：</span><span>{{ gcsinformation.education }}</span>
            </p>
            <p>
              <span>毕业时间：</span><span>{{ gcsinformation.grad_time }}</span>
            </p>
            <p>
              <span>上班专业工作年限：</span
              ><span>{{ gcsinformation.work_time }}</span>
            </p>
          </el-tab-pane>
          <el-tab-pane label="历史服务" name="second">
            <div class="second_all">
              <div
                class="second_content"
                v-for="(item, index) in lsservice"
                :key="index"
              >
                <p>单位：{{ item.unit_name }}</p>
                <p>电话：{{ item.mobile }}</p>
                <p>维修：{{ item.malfunctioncount }}次</p>
                <p>保养：{{ item.plancount }}次</p>
                <p>安装调试：{{ item.installcount }}次</p>
                <p>实验室安全管理：{{ item.labroomcount }}次</p>
                <p>实验室方案：{{ item.labcount }}次</p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </van-popup>
  </div>
</template>
<script>
import {
  instrument_cat,
  order_instrument,
  instrument_engineers,
  myInstrument,
  instrument_order,
  templatee,
  instrument_orders,
  engineer_client_info,
  instrument_engineerinfo,
} from "../../api/index";
import {
  userinstrument_list,
  records,
  add_instrument,
  template,
} from "../../api/User";
export default {
  data() {
    return {
      token: "",
      schemeList: [],
      engineerList: [],
      instrumentList: [],
      user_id: 0,
      instrument_malfunction: "",
      maintain_plan: 0,
      maintain_desc: "",
      instrument_id: 0,
      maintain_pic: "",
      maintain_video: "",
      Instrument: {},
      name: "",
      fangan: "",
      xinghao: "",
      dialogImageUrl: [],
      dialogVisible: false,
      disabled: false,
      show: false,
      xianshicontent: false,
      instrument_name: "", //仪器名称
      instrument_brand: "", //品牌
      instrument_num: "", //仪器序列号
      instrument_size: "", //型号
      instrument_cat: "", //仪器类型
      purchase_time: "", //采购时间
      purchase_money: "", //采购金额
      start_time: "", //开始时间
      procurement: "", //采购途径
      use_frequency: "", //使用频率
      instrument_user: "", //使用者
      lab_room: "", //实验室
      lab_room_num: "", //实验室房间号
      lab_respon: "", //管理人
      zhansh: false,
      imageUrl: "", //上传图片地址
      fenlei: ["教学", "科研", "分析检测", "其他"],
      lianjie: "",
      imageUrl2: [], //现场图片
      videoUrl: [], //现场视频
      loading: false, //上传文件加载动画
      gcsid: "", //工程师id
      id: "", //用户仪器id
      reason_that: "", //原因说明
      show2: false,
      show3: false,
      add_button: false, //搜索-添加仪器
      imgurlStr: "", //拼接上传图片的url
      videourlStr: "", //拼接上传视频的url
      min: 14, //计时器分钟
      s: 59, //计时器秒
      timer: null, //计时器
      status: "", //提交状态
      sous_content: "", //搜索内容
      search_data: {},
      condition_desc: "", //现场说明
      instrument_manufacturers: "", //品牌
      instrument: [],
      instrument_name_str: "",
      str: { gcs_str: [], name_str: [], size_str: [] },
      img_index: "",
      dingss: true,
      gcstc: false,
      sorting: ["按位置排序", "按位星级排序", "综合排序"],
      sortindex: 1,
      gcsind: 0,
      listind: 0,
      dialogVisible2: false,
      activeName: "first",
      gcsinformation: {}, //工程师信息
      lsservice: [], //工程师历史服务
      autof: false, //工程师自动分配
      aaa: [],
    };
  },
  props: {
    toChildrenData: {},
  },
  created() {
    this.token = localStorage.getItem("token");
    if (this.toChildrenData.id) {
      this.strcontent(this.toChildrenData);
    }
    this.instrument_name_str = this.toChildrenData.instrument_name;
    this.getInstrumentCat();
    this.getInstrumentEngineers();
    this.getMyInstrument();
    this.jiance();
    this.UserList();
  },
  computed: {
    wid() {
      return this.fenlei.length * 51 + "px";
    },
    hei() {
      return this.search_data.length * 20 + "px";
    },
  },
  watch: {
    sous_content(curVal, oldVal) {
      // 实现input连续输入，只发一次请求
      clearTimeout(this.timeout);
      this.timer = setTimeout(() => {
        this.sousuo(curVal);
      }, 300);
    },
  },
  methods: {
    //检测登陆
    jiance() {},
    //获取保养方案
    getInstrumentCat() {
      instrument_cat({ cat_id: 7 }).then((res) => {
        if (res.status == 1) {
          this.schemeList = res.result;
        }
      });
    },
    fanga(id, fa, index) {
      this.instrument[index].maintain_plan = id;
      this.fangan = fa;
    },
    //获取工程师
    getInstrumentEngineers() {
      instrument_engineers({ type: this.sortindex }).then((res) => {
        if (res.status == 1) {
          this.engineerList = res.result;
        }
      });
    },
    //获取我的仪器列表
    getMyInstrument() {
      myInstrument({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.instrumentList = res.result;
        }
      });
    },
    //获取所有仪器
    UserList() {
      const data = {
        token: this.token,
        is_status: 0,
      };
      this.aaa = [];
      userinstrument_list(data).then((res) => {
        this.Instrument = res.result.list;
        for (var i in this.Instrument) {
          this.aaa.push({ i: false, content: this.Instrument[i] });
        }
      });
    },
    //选取仪器
    tianjia(item) {
      this.instrument_size = item.instrument_size;
      this.id = item.id;
      this.instrument_num = item.instrument_num;
      this.name = item.instrument_name;
      this.xinghao = item.instrument_brand;
    },
    //工程师
    gcs(index) {
      this.gcstc = true;
      this.gcsind = 0;
      this.listind = index;
      // this.instrument[index].gcs_name = name;
      // this.instrument[index].engineer_id = id;
    },
    //添加仪器
    add() {
      (this.instrument_name = ""),
        (this.instrument_brand = ""),
        (this.instrument_num = ""),
        (this.instrument_size = ""),
        (this.instrument_cat = ""),
        (this.purchase_time = ""),
        (this.purchase_money = ""),
        (this.start_time = ""),
        (this.procurement = ""),
        (this.use_frequency = ""),
        (this.instrument_user = ""),
        (this.lab_room = ""),
        (this.lab_room_num = ""),
        (this.lab_respon = ""),
        (this.imageUrl = "");
      this.show2 = false;
      this.show = true;
    },
    //下载模版
    xiazmoban() {
      const data = {
        type: 5,
      };
      template(data).then((res) => {
        const url = "http://" + res.data;
        window.open(url);
      });
    },
    //上传图片
    upLoad(file) {
      console.log(file)
      const formData = new FormData();
      formData.append("img_file", file.file);
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      this.axios
        .post(
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic",
          formData,
          config
        )
        .then((res) => {
          if (res.data.status == 1) {
            this.imageUrl = res.data.result.url;
          }
        });
    },
    state1() {
      this.code = false;
    },
    state2() {
      this.type = false;
      this.zhansh = true;
    },
    enterr() {
      this.zhansh = true;
    },
    leavee() {
      this.zhansh = false;
    },
    neirong(item) {
      this.instrument_cat = item;
    },
    adde() {
      var str = this.start_time;
      if (!this.imageUrl) {
        return this.$message.error("请上传仪器照片！");
      }
      if (!this.instrument_name) {
        return this.$message.error("请填写仪器名称！");
      }
      if (!this.instrument_brand) {
        return this.$message.error("请填写仪器品牌！");
      }
      if (!this.instrument_brand) {
        return this.$message.error("请填写仪器品牌！");
      }
      if (!this.instrument_num) {
        return this.$message.error("请填写仪器序列号！");
      }
      if (!this.instrument_size) {
        return this.$message.error("请填写仪器型号！");
      }
      if (!this.instrument_cat) {
        return this.$message.error("请选择仪器类型！");
      }
      if (!this.purchase_time) {
        return this.$message.error("请选择采购时间！");
      }
      if (!this.purchase_money) {
        return this.$message.error("请填写采购金额！");
      }
      if (!this.start_time) {
        return this.$message.error("请选择启用时间！");
      }
      if (!this.procurement) {
        return this.$message.error("请填写采购途径！");
      }
      if (!this.use_frequency) {
        return this.$message.error("请填写使用频率！");
      }
      if (!this.instrument_user) {
        return this.$message.error("请填写使用者！");
      }
      if (!this.lab_room) {
        return this.$message.error("请填写存放实验室！");
      }
      if (!this.lab_room_num) {
        return this.$message.error("请填写房间号！");
      }
      if (!this.lab_respon) {
        return this.$message.error("请填写管理人！");
      }
      if (this.token != "") {
        const data = {
          token: this.token,
          instrument_name: this.instrument_name,
          instrument_brand: this.instrument_brand,
          instrument_num: this.instrument_num,
          instrument_size: this.instrument_size,
          instrument_cat: this.instrument_cat,
          purchase_time: this.purchase_time,
          purchase_money: this.purchase_money,
          start_time: this.start_time,
          procurement: this.procurement,
          use_frequency: this.use_frequency,
          instrument_user: this.instrument_user,
          lab_room: this.lab_room,
          lab_room_num: this.lab_room_num,
          lab_respon: this.lab_respon,
          instrument_pic: this.imageUrl,
        };
        add_instrument(data).then((res) => {
          if (res.status == 1) {
            this.show = false;
            this.Instrument = "";
            this.UserList();
            this.$alert("添加成功", "提示", {
              confirmButtonText: "确定",
            });
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    //导入
    chenggong(response, file, fileList) {
      // this.show = false
      this.$message({
        message: "批量导入成功！",
        type: "success",
      });
      // this.$alert('这是一段内容', '标题名称', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${ action }`
      //     });
      //   }
      // });
      // const h = this.$createElement;

      //   this.$notify({
      //     title: '标题名称',
      //     message: h('i', { style: 'color: teal'}, '这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案这是提示文案')
      //   });
    },
    //显示隐藏添加图片弹窗
    show_card() {
      this.show = true;
    },
    shows() {
      this.show = false;
    },
    shows2() {
      this.show2 = false;
      this.show3 = false;
      clearInterval(this.timer);
    },
    qiandiao(file) {
      let type = file.type.split("/")[0];
      var a = this.instrument[this.img_index].imageUrl.length;
      var b = this.instrument[this.img_index].videoUrl.length;

      if (type == "image") {
        if (a < 3) {
          this.loading = true;
        } else {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "图片上传数量不能大于3！",
            type: "error",
          });
          return;
        }
        this.lianjie =
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic";
        const formData = new FormData();
        formData.append("img_file", file);
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };

        this.axios.post(this.lianjie, formData, config).then((res) => {
          if (this.instrument[this.img_index].imageUrl.length < 3) {
            this.instrument[this.img_index].imageUrl.push(res.data.result.url);
            this.instrument[this.img_index].maintain_pic = this.instrument[
              this.img_index
            ].imageUrl.join(",");
          } else {
            this.$message({
              showClose: true,
              message: "图片上传数量不能大于1！",
              type: "error",
            });
          }
          this.loading = false;
        });
      } else {
        if (b < 1) {
          this.loading = true;
        } else {
          this.loading = false;
          this.$message({
            showClose: true,
            message: "视频上传数量不能大于1！",
            type: "error",
          });
          return;
        }
        let video;
        this.lianjie =
          "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_video";
        const formData = new FormData();
        formData.append("img_file", file);
        const config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        this.axios.post(this.lianjie, formData, config).then((res) => {
          if (this.instrument[this.img_index].videoUrl.length < 1) {
            this.instrument[this.img_index].videoUrl.push(res.data.result.url);
            this.instrument[this.img_index].maintain_video = this.instrument[
              this.img_index
            ].videoUrl.join(",");
          } else {
            this.$message({
              showClose: true,
              message: "视频上传数量不能大于1！",
              type: "error",
            });
          }
          this.loading = false;
        });
      }
    },
    //点击上传图片中的删除按钮
    delet(index, i, num) {
      if (index == 1) {
        this.instrument[num].imageUrl.splice(i, 1);
      } else {
        this.instrument[num].videoUrl.splice(i, 1);
      }
    },
    //提交
    submit() {
      let params = {
        token: this.token,
        type: 2,
        instrument: JSON.stringify(this.instrument),
      };
      if (this.token) {
        for (var item in this.instrument) {
          if (!this.instrument[item].install_seller) {
            this.$message.error(
              "请填写 " + this.instrument[item].instrument_name + " 品牌！"
            );
            return;
          }
          if (!this.instrument[item].maintain_plan) {
            this.$message.error(
              "请选择 " + this.instrument[item].instrument_name + " 保养方案！"
            );
            return;
          }
          if (!this.instrument[item].engineer_id) {
            this.$message.error(
              "请选择 " + this.instrument[item].instrument_name + " 工程师！"
            );
            return;
          }
          if (
            !this.instrument[item].maintain_pic &&
            !this.instrument[item].maintain_video
          ) {
            this.$message.error(
              "请上传 " + this.instrument[item].instrument_name + " 图片！"
            );
            return;
          }
        }
        instrument_orders(params).then((res) => {
          this.status = res.status;
          if (res.status == 1) {
            this.instrument = [];
            this.Instrument = {};
            this.str.name_str = [];
            this.instrument_name_str = "";
            this.show3 = true;
            this.daoji();
            this.$parent.state();
          } else {
            this.$message.error(res.msg || res.info);
          }
        });
      }
    },
    //搜索
    sousuo() {
      this.show2 = true;
      let data = {
        token: this.token,
        keyword: this.sous_content,
      };
      userinstrument_list(data).then(
        (res) => (this.search_data = res.result.list)
      );
    },
    //提交后倒计时
    daoji() {
      if (this.dingss) {
        this.dingss = false;
        this.timer = setInterval(() => {
          if (this.min == 0 && this.s == 0) {
            clearInterval(this.timer);
            this.dingss = true;
          } else {
            this.jishi();
          }
          if (this.show3 == false) {
            clearInterval(this.timer);
            this.s = 59;
            this.min = 14;
          }
        }, 1000);
      }
    },
    jishi() {
      this.s -= 1;
      if (this.s == 0 && this.min > 0) {
        this.min -= 1;
        this.s = 59;
      } else if (this.min == 0 && this.s == 0) {
        this.show3 = false;
      }
    },
    //搜索内容点击事件
    click_search(item) {
      this.show2 = false;
      // this.yiqiid = item.id
      this.strcontent(item);
      for (var s in this.aaa) {
        if (item.id == this.aaa[s].content.id) {
          this.aaa[s].i = true;
        }
      }
    },
    show4() {
      if (!this.Instrument.length) {
        this.UserList();
      }
      this.xianshicontent = !this.xianshicontent;
    },
    strcontent(item) {
      var data = {
        instrument_id: item.id,
        instrument_name: item.instrument_name,
        instrument_size: item.instrument_size,
        instrument_brand: item.instrument_brand,
        maintain_pic: "",
        maintain_video: "",
        engineer_id: this.gcsid, //工程师id
        install_desc: "", //保养说明
        maintain_plan: "",
        maintain_plan_type: 0, //固定方案/自定义方案
        gcs_name: "",
        imageUrl: [],
        videoUrl: [],
      };
      for (var ite in this.instrument) {
        if (this.instrument[ite].instrument_id == item.id) {
          this.instrument.splice(ite, 1);
          this.str.name_str.splice(ite, 1);
          this.str.size_str.splice(ite, 1);
          this.instrument_name_str = this.str.name_str.join(",");
          return;
        }
      }
      this.instrument.push(data);
      this.str.name_str.push(item.instrument_name);
      this.instrument_name_str = this.str.name_str.join(",");
    },
    tupian(index) {
      this.img_index = index;
    },
    sortingi(index) {
      this.sortindex = index + 1;
      this.getInstrumentEngineers();
    },
    gcsxz(index) {
      this.gcsind = index;
    },
    gcstj() {
      this.gcstc = false;
      this.instrument[this.listind].gcs_name = this.engineerList[
        this.gcsind
      ].user_name;
      this.instrument[this.listind].engineer_id = this.engineerList[
        this.gcsind
      ].user_id;
    },
    lishifw(id) {
      this.dialogVisible2 = true;
      var data = {
        engineer_id: id,
        p: 1,
      };
      //工程师历史服务
      engineer_client_info(data).then((res) => {
        this.lsservice = res.result;
      });
      //工程师详细信息
      instrument_engineerinfo({ user_id: id }).then((res) => {
        this.gcsinformation = res.result;
      });
    },
    //自动分配工程师
    autoFp(index) {
      this.autof = !this.autof;
      if (this.autof) {
        document.getElementById("inputtt" + index).disabled = "disabled";
        var num = Math.floor(Math.random() * this.engineerList.length);
        this.instrument[index].gcs_name = this.engineerList[num].user_name;
        this.instrument[index].engineer_id = this.engineerList[num].user_id;
      } else {
        document.getElementById("inputtt" + index).disabled = "";
      }
    },
    fenP(num, index) {
      this.instrument[index].maintain_plan = "";
      this.instrument[index].maintain_plan_type = num;
      if (num == 0) {
        document.getElementById("inputc" + index).style.display = "none";
        document.getElementById("inputa" + index).style.display = "";
      } else {
        document.getElementById("inputa" + index).style.display = "none";
        document.getElementById("inputc" + index).style.display = "";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  min-height: 1000px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  float: right;
  width: 78%;
  margin-left: 2%;
  margin-bottom: 20px;
  padding: 30px;
  .title-instrument {
    height: 45px;
    .title-left {
      p:nth-child(1) {
        font-size: 22px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 29px;
        color: #333333;
        margin-right: 15px;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #ff0000;
      }
    }
    .title-right {
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #408df7;
        width: 100px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border: 2px solid #408df7;
        opacity: 1;
        border-radius: 5px;
      }
      .search-btn {
        margin-left: 20px;
        width: 100px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #408df7;
        color: #ffffff;
        border-radius: 5px;
        img {
          margin-right: 10px;
          width: 20px;
          height: 19px;
          margin-bottom: -5px;
        }
      }
      .search-btn:hover {
        cursor: pointer;
      }
    }
  }
  .select-instrument {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 24px;
    .el-select {
      width: 100%;
    }
  }
  .title-input {
    p {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #333333;
      margin-right: 15px;
    }
  }
  .input-item {
    div {
      width: 50%;
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        width: 97%;
        line-height: 21px;
        color: #333333;
        margin: 15px 0;
        span {
          color: #ff0000;
          font-size: 16px;
          line-height: 21px;
        }
        .fenpei {
          float: right;
          font-family: Microsoft YaHei;
          color: #333;
        }
        .fenpei:nth-child(4) {
          display: inline-block;
          margin-right: 10px;
        }
        #fenpei {
          width: 13px;
          height: 13px;
          float: right;
          margin-top: 5px;
          margin-right: 5px;
          padding: 0;
          border-radius: 0;
        }
      }
    }
    input {
      width: 97%;
      height: 55px;
      background: #ffffff;
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 15px;
    }
    div {
      input {
        width: 97%;
        height: 55px;
        background: #ffffff;
        border: 1px solid #cccccc;
        border-radius: 5px;
        padding: 15px;
      }
    }
    textarea {
      width: 97%;
      height: 200px;
      background: #ffffff;
      border: 1px solid #cccccc;
      opacity: 1;
      border-radius: 5px;
      padding: 15px;
    }
  }
  .upload-pic {
    .upload-title {
      padding: 15px 0;
      p:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 21px;
        color: #333333;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 16px;
        color: #ff0000;
        padding: 0 15px;
      }
    }
    .img-box {
      width: 100%;
      height: 100px;
      margin-top: 30px;
      div {
        height: 100px;
        float: left;
        margin-right: 14px;
        img {
          float: left;
          object-fit: cover;
        }
      }
      img {
        width: auto;
        height: 100px;
      }
    }
  }
  .submit-btn {
    p {
      width: 300px;
      height: 60px;
      background: #408df7;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 60px;
      text-align: center;
      color: #ffffff;
      border-radius: 5px;
      margin: 15px 0;
    }
    p:hover {
      cursor: pointer;
    }
  }
  .attention {
    margin-top: 24px;
    p {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #999999;
    }
  }
}
/deep/.el-upload--picture-card {
  float: left;
  margin-right: 10px;
  width: 150px;
  height: 100px;
  line-height: 100px;
  border: none;
}
/deep/.el-upload-list__item {
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #c0ccda;
  border-radius: 6px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 150px;
  height: 100px;
  margin: 15px 8px 8px 0;
  display: inline-block;
}
.pop {
  // height: 1000px;
  margin: 0 auto;
  border-radius: 5px;
  .pop_img1 {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .pop_img1:hover {
    cursor: pointer;
  }
  .pop-title {
    height: 45px;
    width: 100%;
    margin-top: 43px;
    p:nth-child(1) {
      float: left;
      width: 88px;
      height: 29px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 29px;
      color: #333333;
      opacity: 1;
    }
    p:nth-child(2) {
      input {
        display: none;
      }
      text-align: center;
      float: right;
      width: 93px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 45px;
      color: #408df7;
      opacity: 1;

      .frrr {
        margin-bottom: -2px;
        line-height: 45px;
        width: 17px;
        height: 16px;
        opacity: 1;
      }
    }
    p:nth-child(3) {
      text-align: center;
      float: right;
      width: 93px;
      height: 45px;
      border: 2px solid #408df7;
      opacity: 1;
      border-radius: 5px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 45px;
      color: #408df7;
      opacity: 1;
      margin-right: 20px;
    }
    p:nth-child(3):hover {
      cursor: pointer;
    }
  }
  .pop-title p:nth-child(2):hover {
    cursor: pointer;
  }
  .pop-div2 {
    margin-top: 60px;
    height: 100px;
    .pop-div2-img {
      float: left;
      margin-left: 16px;
      width: 64px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 100px;
      color: #333333;
      opacity: 1;
      margin-right: 101px;
    }
    .phice {
      float: left;
      width: 150px;
      height: 100px;
    }
    .pop-instrument-name {
      float: left;
      height: 100px;
      width: 331px;
      margin-left: 46px;
      margin-bottom: 26px;
      input {
        width: 100%;
        height: 55px;
        background: #ffffff;
        border: 1px solid #cccccc;
        opacity: 1;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 55px;
        opacity: 1;
        padding-left: 16px;
      }
      p {
        float: left;
        width: 45%;
        margin-left: 16px;
        margin-top: 4px;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        float: left;
        color: red;
      }
    }
    .fl {
      margin-left: 0px;
    }
    .add-content-div {
      width: 100%;
      float: left;
      text-align: center;
      .add-content {
        margin: 0 auto;
        width: 300px;
        height: 60px;
        background: #408df7;
        opacity: 1;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #ffffff;
        opacity: 1;
        border-radius: 3px;
      }
    }
    .add-content-div:hover {
      cursor: pointer;
    }
  }
}
/deep/.el-button--primary {
  color: #408df7;
  border-color: #fff;
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--primary:hover {
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  width: 80px;
}
/deep/.el-button--small,
.el-button--small.is-round {
  padding: 0;
  font-size: 16px;
  font-weight: 400;
  width: 89px;
  height: 45px;
  border-radius: 7px;
  margin-top: -2px;
  border-color: rgba(0, 0, 0, 0);
}
/deep/.el-button--primary:focus {
  background: rgba(0, 0, 0, 0);
  color: #408df7;
  border-color: rgba(0, 0, 0, 0);
}
.van-overlay {
  z-index: 200 !important;
}
.van-uploader {
  float: left;
}
.tianjia {
  cursor: pointer;
}
.avatar {
  width: 150px;
  object-fit: cover;
  height: 100px;
}
/deep/.img-box img {
  float: left;
  position: relative;
}
/deep/.el-upload-list--picture-card {
  display: none;
}
.video {
  width: 150px !important;
  height: 100px;
  object-fit: cover;
  margin-right: 0;
}
.upload_pictures,
.upload_video {
  position: relative;
  .upload_pictures_img {
    width: 150px !important;
    object-fit: cover;
    margin-right: 0 !important;
  }

  .del {
    position: absolute;
    height: 20px !important;
    width: 30px !important;
    top: 0px;
    right: 0px;
    z-index: 100;
    margin-right: 0;
  }
  .del {
    cursor: pointer;
  }
}

.search {
  width: 1200px;
  height: 515px;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  padding: 0 175px;
  .no_data {
    margin: 10px 0;
    text-align: center;
  }
  .title {
    margin-top: 79px;
    height: 50px;
    width: 100%;
    margin-bottom: 20px;
    span {
      float: left;
      width: 44px;
      height: 29px;
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      color: #333333;
      opacity: 1;
    }
    img {
      float: right;
      width: 158px;
      height: 49px;
      border: 1px solid rgba(0, 0, 0, 0);
      opacity: 1;
    }
  }
  input {
    width: 100%;
    height: 60px;
    background: #ffffff;
    opacity: 1;
    border-bottom: 1px solid #006efc;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    opacity: 1;
    margin-bottom: 30px;
  }
  .search_content {
    margin-bottom: 38px;
    height: 300px;
    overflow-y: scroll;
    p {
      margin: 5px 0;
    }
    p:hover {
      cursor: pointer;
    }
  }
  button {
    width: 180px;
    height: 45px;
    border: 2px solid #408df7;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #408df7;
    opacity: 1;
    background: #fff;
  }
}
.closs {
  position: absolute;
  right: 10px;
  top: 10px;
}
.closs:hover {
  cursor: pointer;
}
.successfully {
  text-align: center;
  width: 380px;
  height: 340px;
  background: #ffffff;
  opacity: 1;
  border-radius: 5px;
  .title_img {
    width: 56px;
    height: 56px;
    opacity: 1;
  }
  .top1 {
    margin-top: 60px;
  }
  .top2 {
    margin-top: 84px;
    width: 86px;
    height: 86px;
  }
  #font_color1 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 21px;
    color: #3baf34;
    opacity: 1;
    margin-top: 17;
  }
  #font_color2 {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    opacity: 1;
    margin-top: 17;
  }
  .time {
    display: inline-block;
    margin-top: 21px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    opacity: 1;
  }
  p {
    text-align: center;
    margin-top: 21px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 19px;
    color: #999999;
    opacity: 1;
  }
  button {
    margin-top: 21px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #408df7;
    opacity: 1;
    background: #ffffff;
  }
}
#xuanze_yiqi {
  height: 60px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #ffffff;
  border-color: #e4e7ed;
  color: #c0c4cc;
  height: 60px;
  width: 100%;
  cursor: pointer;
}
/deep/.el-button--text {
  color: #409eff;
  background: 0 0;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}
/deep/.el-button {
  padding: 0;
}
/deep/.el-checkbox {
  color: #606266;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 20%;
  margin: 5px 0;
  height: 30px;
}
/deep/.el-checkbox__input {
  cursor: pointer;
  outline: 0;
  line-height: 1;
  vertical-align: middle;
  line-height: 30px;
  margin-top: -20px;
}
/deep/.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  line-height: 30px;
  padding-right: 15px;
}
/deep/.el-table__empty-text {
  /* line-height: 60px; */
  /* width: 50%; */
  color: #fff;
}
/deep/.el-table::before {
  background-color: #fff;
}
.zhansh {
  overflow: hidden;
  padding: 0 20px;
  background: #ffffff;
  position: relative;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  z-index: 2111;
}
.fenlei {
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #e6e6e6;
  span {
    margin-left: 20px;
  }
}
// /deep/.el-dialog {
//   width: 1200px;
//   height: 880px;
//   border-radius: 5px;
//   margin-top: 5vh !important;
// }
/deep/.tianjiaa {
  width: 1200px;
  height: 880px;
  padding: 0 50px;
  border-radius: 5px;
  margin-top: 5vh !important;
}
/deep/.el-dialog__title {
  display: none;
}
/deep/.el-dialog__header {
  display: none;
}
/deep/.el-dialog__body {
  padding: 1px;
  height: 880px;
}
.dialog-img {
  width: 13px;
  height: 13px;
  margin-top: 5px;
  margin-right: 5px;
  float: right;
}
.dialog-header {
  margin-top: 37px;
  height: 30px;
  padding: 0 50px;
  margin-bottom: 15px;
  .dialog-title {
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #333333;
    opacity: 1;
    line-height: 30px;
    float: left;
  }
  .dialog-sorting {
    float: right;
    height: 30px;

    .dialog-sort-bul {
      margin: 0 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #408df7;
      opacity: 1;
      line-height: 30px;
    }
    .dialog-sort {
      margin: 0 20px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      opacity: 1;
      line-height: 30px;
    }
    span:hover {
      cursor: pointer;
    }
  }
}
.dialogAll {
  padding: 0 30px 0 50px;
  height: 680px;
  overflow: scroll;
  overflow-x: hidden;
  .dialog-pop-bul {
    width: 358px;
    float: left;
    height: 160px;
    margin: 10px 10px 0 0;
    padding-right: 31px;
    background: #ffffff;
    border: 1px solid #408df7;
    opacity: 1;
    border-radius: 5px;
  }
  .dialog-pop {
    width: 358px;
    float: left;
    height: 160px;
    margin: 10px 10px 0 0;
    padding-right: 31px;
    background: #ffffff;
    border: 1px solid #cccccc;
    opacity: 1;
    border-radius: 5px;
  }
  .dialog-pop-img {
    width: 38%;
    float: left;
    text-align: center;
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      opacity: 1;
      margin: 30px 0 13px 0;
    }
  }
  .dialog-pop-content {
    width: 62%;
    float: right;
    .name {
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      height: 21px;
      font-size: 16px;
      color: #333333;
      span:nth-child(1) {
        width: 95px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
      }
      span:nth-child(2) {
        float: right;
      }
    }
    .fuw {
      margin-top: 9px;
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 16px;
      color: #333333;
      span:nth-child(1) {
        float: left;
      }
      span:nth-child(2) {
        float: right;
      }
    }
    .contente {
      display: inline-block;
      width: 100%;
      height: 34px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      opacity: 1;
      margin: 6px 0 10px 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
    }
    .happ {
      margin-top: 5px;
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 16px;
      color: #999999;
      span {
        width: 33.3%;
        display: inline-block;
        text-align: center;
      }
      span:nth-child(1) {
        text-align: left;
      }
      span:nth-child(3) {
        text-align: right;
      }
    }
  }

  .dialog-pop:hover {
    cursor: pointer;
  }
}
.but {
  text-align: center;
  height: 60px;
  margin: 20px 0;
  button {
    width: 300px;
    height: 60px;
    background: #408df7;
    opacity: 1;

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 60px;
    color: #ffffff;
  }
}
/deep/input[type="radio"] {
  height: 13px;
  width: 13px;
  border: solid 1px #dfdfdf;
  background-color: transparent;
  -webkit-appearance: none;
  position: relative;
  border-radius: 2px;
}
/deep/input[type="radio"]:checked:before {
  content: "\2713";
  display: inline-block;
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  height: 11px;
  width: 11px;
  background-size: 100% 100%;
  background: #fff;
  color: #408df7;
  border: 1px solid #408df7;
  border-radius: 2px;
  position: absolute;
  top: -0.04rem;
  left: -0.02rem;
  z-index: 2;
}
/deep/.el-divider--horizontal {
  margin: 0;
}
.el-icon-arrow-down {
  cursor: pointer;
}
.inputimg {
  position: relative;
  width: 100% !important;
  .imputimg {
    width: 10px;
    height: 5px;
    position: absolute;
    top: 47%;
    right: 30px;
  }
}
.abc {
  width: 380px;
  height: 500px;
  background: #fff;
  z-index: 3000;
}
.vantc {
  width: 380px;
  height: 640px;
  padding: 51px 19px 0 19px;
  img {
    width: 80px;
    height: 80px;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    opacity: 1;
    margin-top: 20px;
    margin-bottom: 21px;
  }
  p {
    width: 100%;
    span {
      display: inline-block;
      width: 50%;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 19px;
      color: #333333;
      opacity: 1;
      margin-top: 15px;
    }
  }
}
/deep/.el-dialog__wrapper {
  z-index: 2035 !important;
}
.van-popup--center {
  z-index: 20050 !important;
}
/deep/.van-overlay {
  z-index: 20049 !important;
}
/deep/.el-tabs__nav-wrap::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px !important;
  background-color: #e4e7ed;
  z-index: 1;
}
.baoyangf {
  width: 97% !important;
}
/deep/.el-input--suffix .el-input__inner {
  padding-right: 30px;
  height: 55px !important;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  cursor: pointer;
  width: 100%;
}
.second_all {
  width: 100%;
  padding: 0 20px;
  .second_content {
    width: 100%;
    height: 157px;
    margin: 10px 0;
    background: #ffffff;
    border: 1px solid #cccccc;
    opacity: 1;
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 14px;
    color: #333;
  }
  .second_content:nth-child(1) {
    margin-top: 0px;
  }
}
.widtt {
  width: 150px;
}
.shuru {
  display: none;
}
/deep/ .v-modal {
  z-index: 2003 !important;
}
/deep/.gongchegshi {
  margin-top: 5vh !important;
}
</style>
