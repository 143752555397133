<template>
  <div class="content-right">
    <div class="content-title">
      <p @click="goBack()">订单中心>订单详情</p>
    </div>
    <div class="content-main">
      <p class="title-text kehu">客户信息</p>
      <div class="t9" v-if="list.order_status == 4 && list.pay_status == 0">
        待支付
      </div>
      <div class="t9" v-if="list.pay_status == 1 && list.order_status == 5">
        已完成
      </div>
      <div class="t9" v-if="list.pay_status == 1 && list.order_status == 4">
        已支付
      </div>
      <div class="t9" v-if="list.order_status == 1 && list.order_report == 3">
        客户已拒绝
      </div>
      <div class="t9" v-if="list.order_status == 3">订单已拒绝</div>
      <div class="customer-box">
        <div class="name-pic">
          <img class="fl" :src="list.head_pic" alt="" />
          <p class="fl">{{ list.user_name }}</p>
        </div>
        <p>手机号：{{ list.mobile }}</p>
        <!-- <p>座机：{{ list.plane_tel }}</p> -->
        <p>单位：{{ list.unit_name }}</p>
      </div>
      <div class="clear"></div>
      <p class="title-text">客户需求</p>
      <div class="big-img" v-if="openBig" @click="closeBig">
        <img :src="nowImg" alt="" />
      </div>
      <div class="customer-box">
        <p v-if="list.instrument_dispose == 1">需求类别：仪器维修</p>
        <p v-if="list.instrument_dispose == 2">需求类别：仪器保养</p>
        <p v-if="list.instrument_dispose == 3">需求类别：安装调试</p>
        <p v-if="list.instrument_dispose == 4">需求类别：我的实验室</p>
        <p v-if="list.instrument_dispose == 5">需求类别：我的实验室方案</p>

        <div v-if="list.instrument_dispose == 1" class="box">
          <p class="one-letter">仪器维修：{{ list.instrument_name }}</p>
          <p>
            <span class="fl" style="line-height: 20px">故障说明：</span>
            <span class="more-letter">{{
              list.instrument_malfunction
            }}</span>
          </p>
          <p v-if="list.maintain_pic">
            图片：<img
              class="r8"
              v-for="(item, index) in list.maintain_pic"
              :key="index"
              :src="item"
              @click="getBigImg(item)"
            />
          </p>
          <p class="e9" v-if="list.maintain_video">
            <span>视频：</span>
            <a :href="list.maintain_video" target="view_window"
              ><video :src="list.maintain_video"></video
            ></a>
          </p>
        </div>
        <div v-if="list.instrument_dispose == 2" class="box">
          <p class="one-letter">保养仪器：{{ list.instrument_name }}</p>
          <p class="one-letter">保养方案：{{ list.maintain_plan }}</p>
          <p>
            <span class="fl" style="line-height: 20px">保养说明： </span>
            <span class="more-letter">{{ list.maintain_desc }}</span>
          </p>
          <p v-if="list.maintain_pic">
            图片：<img
              class="r8"
              v-for="(item, index) in list.maintain_pic"
              :key="index"
              :src="item"
              @click="getBigImg(item)"
            />
          </p>
          <p class="e9" v-if="list.maintain_video">
            <span>视频：</span>
            <a :href="list.maintain_video" target="view_window"
              ><video :src="list.maintain_video"></video
            ></a>
          </p>
        </div>
        <div v-if="list.instrument_dispose == 3" class="box">
          <p class="one-letter">保养名称：{{ list.instrument_name }}</p>
          <p class="one-letter">仪器型号：{{ list.instrument_size }}</p>
          <p>
            <sapn class="fl" style="line-height: 20px"> 仪器品牌：</sapn>
            <span class="more-letter">{{
              list.install_seller
            }}</span>
          </p>
          <p style="">
            <span class="fl" style="line-height: 20px">安装说明：</span>
            <span class="more-letter">{{ list.install_desc }} </span>
          </p>
          <p>
            <span class="fl" style="line-height: 20px"> 现场情况说明：</span>
            <span class="more-letter">{{
              list.install_condition_desc
            }}</span>
          </p>
          <p v-if="list.install_pic">
            <span>图片：</span>
            <img
              class="r8"
              v-for="(item, index) in list.install_pic"
              @click="getBigImg(item)"
              :key="index"
              :src="item"
            />
          </p>
          <p class="e9" v-if="list.maintain_video">
            <span>视频：</span>
            <a :href="list.maintain_video" target="view_window"
              ><video :src="list.maintain_video"></video
            ></a>
          </p>
        </div>
        <div v-if="list.instrument_dispose == 4" class="box">
          <p>管理需求：{{ list.sy_user }}</p>
        </div>
        <div v-if="list.instrument_dispose == 5" class="box">
          <p>整体布局规划设计：{{ list.lab_project }}</p>
          <p>家具工程：{{ list.lab_furniture }}</p>
          <p>通风工程：{{ list.lab_decoration }}</p>
          <p>装修工程：{{ list.lab_ventilate }}</p>
          <p>供气工程：{{ list.lab_air }}</p>
        </div>
      </div>
      <div class="clear"></div>
      <!-- <el-divider></el-divider> -->
      <div
        class="btn-box"
        v-if="list.order_report == 0 && list.order_status != 3"
      >
        <p class="fl" @click="dialogVisible = true">我要报价</p>
        <p class="fl" @click="qu()">推掉单子</p>
      </div>
      <div
        @click="queren()"
        class="btn-box"
        v-if="list.order_status == 2 && list.pay_status == 0"
      >
        <p>确认完成</p>
      </div>

      <div class="clear"></div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="350px">
      <div class="list_box_top">我要报价</div>
      <!-- <div class="list">
        <p>微信号</p>
        <input placeholder="填写您的微信号" v-model="wx" />
      </div>
      <div class="list">
        <p>手机号</p>
        <input placeholder="填写您的手机号" v-model="phone" />
      </div> -->
      <div class="list">
        <p>报价</p>
        <input placeholder="填写您的报价" v-model="price" />
      </div>
      <div class="list1">
        <p>备注</p>
        <textarea placeholder="填写您的备注" v-model="content" />
      </div>
      <div class="sub" @click="bao()">提价</div>
    </el-dialog>
  </div>
</template>
<script>
import { monad_info, cancel, quote } from "../../api/User";
import { browse } from "../../api/index";
export default {
  data() {
    return {
      id: "",
      list: "",
      dialogVisible: false,
      wx: "",
      phone: "",
      price: "",
      content: "",
      nowImg: "",
      openBig: false,
      token: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.id = localStorage.getItem("list_id");
    this.getInfo();
    this.getBrowse(); //订单的浏览
  },
  methods: {
    getBrowse() {
      browse({
        token: this.token,
        order_id: this.id,
        type: 2,
      }).then((res) => {});
    },
    queren() {
      cancel({ id: this.id, order_status: 4 }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "确认成功",
            type: "success",
          });
          setTimeout(() => {
            this.$parent.chose1();
          }, 1000);
        } else if (res.status == 0) {
          //  this.$message.error(res.info);
        }
      });
    },
    getBigImg(path) {
      this.nowImg = path;
      this.openBig = true;
    },
    closeBig() {
      this.openBig = false;
    },
    goBack() {
      this.$parent.chose1();
    },
    //工程师报价
    bao() {
      quote({
        id: this.id,
        order_price: this.price,
        quote_content: this.content,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.goBack();
          }, 1000);
          this.dialogVisible = false;
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        }
      });
    },
    //工程师取消订单
    qu() {
      cancel({ id: this.id, order_status: 3 }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: "取消成功",
            type: "success",
          });
          setTimeout(() => {
            this.$parent.chose1();
          }, 1000);
        } else if (res.status == 0) {
          //  this.$message.error(res.info);
        }
      });
    },
    getInfo() {
      monad_info({ id: this.id }).then((res) => {
        if (res.status == 1) {
          this.list = res.result;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 270px;
  padding: 0 25px 25px;
  background-color: #ffffff;
  min-width: 75%;
  max-width: 80%;
  margin-bottom: 20px;
  .content-title {
    padding: 0 30px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
  .content-main {
    padding: 30px;
    overflow: hidden;
    .title-text {
      height: 50px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      color: #333333;
    }
    .customer-box {
      float: left;
      width: 100%;
      height: 100%;
      .name-pic {
        img {
          width: 60px;
          height: 60px;
          background: rgba(0, 0, 0, 0);
          border-radius: 50%;
        }
        p {
          padding: 0 20px;
        }
      }
      div,
      p {
        float: left;
        width: 48%;
        margin: 0 25px 10px 0;
        height: 80px;
        line-height: 70px;
        border-bottom: 1px solid #eeeeee;
      }
      p:nth-child(even) {
        margin-right: 0;
      }
    }
    .el-divider--horizontal {
      margin: 30px 0;
      background: 0 0;
      border-top: 1px dashed #e8eaec;
    }
    .btn-box {
      p {
        width: 300px;
        height: 60px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
      p:nth-child(1) {
        background: #408df7;
        color: #ffffff;
        margin-right: 60px;
      }
      p:nth-child(2) {
        background: #ffffff;
        color: #333333;
        border: 1px solid #cccccc;
      }
    }
  }
}
.el-dialog__wrapper /deep/.el-dialog__body {
  height: 500px;
  padding-top: 0;
}
.list_box_top {
  width: 100%;
  height: 30px;
  float: left;
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
.list {
  height: 70px;
  width: 100%;
  line-height: 70px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
    float: left;
  }
  input {
    height: 69px;

    float: right;
    text-align: right;
  }
}
.list1 {
  width: 100%;
  height: 130px;
  padding-top: 30px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  p {
    font-size: 16px;
    color: #333333;
  }
  textarea {
    padding-top: 10px;
    width: 100%;
    height: 75px;
  }
}
.sub {
  margin-top: 60px;
  float: left;
  padding: 15px 135px;
  background: #408df7;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}
.box {
  width: 100% !important;
  height: 100% !important;
  margin-bottom: 20px;
  border: none !important;
}
.r8 {
  width: 50px;
  height: 50px;
  margin-top: 25px;
  object-fit: cover;
}
.e9 {
  span {
    float: left;
  }
  video {
    width: 200px;
    height: 80px;
  }
}
.big-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(54, 54, 54, 0.5);
  z-index: 999;
  img {
    width: 80%;
    height: 600px;
    margin-left: 10%;
    margin-top: 50px;
    object-fit: contain;
  }
}
.t9 {
  height: 60px;
  background: #ffffff;
  text-align: center;
  float: right;
  width: 40%;
  font-size: 16px;
  font-weight: bold;
  color: #408df7;
}
.more-letter {
  overflow-y: auto;
  width: 100%;
  line-height: 20px;
  float: left;
  height: 50px;
  span {
    line-height: 20px;
  }
}
.kehu {
  width: 40%;
  float: left;
}
</style>