import axios from "axios";
import qs from "qs";
import router from '../router';
import {
    Message
} from 'element-ui';
// const ApiUrl = "http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/"; //接口总地址

const ApiUrl = "https://www.yqgjy.com/Api/"; //接口总地址


axios.defaults.timeout = 30000;
// axios.defaults.baseURL = "/api";  //好像没啥用
// access-Control-Allow-Origin:*,

axios.defaults.headers.head["Access-Control-Allow-Origin"] = "*";
// ("Access-Control-Allow-Origin:*");
//http request 拦截器
// 请求超时时间
// post请求头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;application/json;charset=UTF-8";
// Content-Type: application/json ： 请求体中的数据会以json字符串的形式发送到后端
// Content-Type: application/x-www-form-urlencoded：请求体中的数据会以普通表单形式（键值对）发送到后端
// Content-Type: multipart/form-data： 它会将请求体的数据处理为一条消息，以标签为单元，用分隔符分开。既可以上传键值对，也可以上传文件。

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios
            .get(url, {
                params: params,
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
    return new Promise((resolve, reject) => {
        axios.post(ApiUrl + url, qs.stringify(data)).then(
            (response) => {
                if (response.data.status == 1) {
                    resolve(response.data);
                } else if (response.data.status == -100) {
                    if (location.href.split('#')[1] == "/Homepage") {
                        return
                    }
                    Message({
                        showClose: true,
                        message: '未登录，请登录',
                        type: "warning",
                    });
                    setTimeout(() => {
                        localStorage.removeItem('token')
                        router.replace({
                            path: '/',
                        });
                    }, 1000)

                } else if (response.data.status == -101) {
                    Message({
                        showClose: true,
                        message: '您的账号在其他地方登录，请重新登录',
                        type: "warning",
                    });
                    setTimeout(() => {
                        localStorage.removeItem('token')
                        router.replace({
                            path: '/',
                        });
                    }, 1000)

                } else {
                    resolve(response.data);
                    // Toast(response.data.info);
                    // reject(response.data); 
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
export function post2(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, data).then(
            (response) => {
                if (response.data.status == 1) {
                    resolve(response.data);
                }
            },
            (err) => {
                reject(err);
            }
        );
    });
}
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data).then(
            (response) => {
                resolve(response.data);
            },
            (err) => {
                reject(err);
            }
        );
    });
}

export function unescapeHTML(a) {
    a = "" + a;
    return a
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&amp;/g, "&")
        .replace(/&quot;/g, '"')
        .replace(/&apos;/g, "'")
        .replace(/&nbsp;/g, "")
        .replace(/strong/g, "span");
}
export function nbspHTML(a) {
    a = "" + a;
    return a.replace(/&amp;nbsp;/g, "");
}

// handleBridgeReady(params, id) {
//         const onBridgeReady = () => {
//             WeixinJSBridge.invoke(
//                 "getBrandWCPayRequest",
//                 {
//                     appId: params.appId, //公众号名称，由商户传入
//                     timeStamp: params.timeStamp, //时间戳，自1970年以来的秒数
//                     nonceStr: params.nonceStr, //随机串
//                     package: params.package, //预支付交易码
//                     signType: params.signType, //微信签名方式：
//                     paySign: params.paySign, //微信签名
//                 },
//                 (res) => {
//                     if (res.err_msg == "get_brand_wcpay_request:ok") {
//                         // // 全部订单的
//                         const list0Index = this.list[0].list.findIndex(
//                             (item) => item.id == id
//                         );
//                         this.list[0].list[list0Index]["order_status"] = 2;
//                         // 待确认的订单
//                         const index = this.list[1].list.findIndex(
//                             (item) => item.id == id
//                         );
//                         this.list[1].list[index]["order_status"] = 2;
//                         // // 使用以上方式判断前端返回,
//                         // //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
//                     } else {
//                         this.$toast("支付失败");
//                     }
//                 }
//             );
//         };
//         if (typeof WeixinJSBridge == "undefined") {
//             if (document.addEventListener) {
//                 document.addEventListener(
//                     "WeixinJSBridgeReady",
//                     onBridgeReady,
//                     false
//                 );
//             } else if (document.attachEvent) {
//                 document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
//                 document.attachEvent(
//                     "onWeixinJSBridgeReady",
//                     onBridgeReady
//                 );
//             }
//         } else {
//             onBridgeReady();
//         }
//     }