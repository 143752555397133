import {
    post
} from "../util/index";
// 电力
export function Voltage(query) {
    return post("Company/voltage", query);
}

// 电流
export function Current(query) {
    return post("Company/current", query);
}


// 功率
export function ElectricPower(query) {
    return post("Company/electric_power", query);
}


// 电能
export function Electricity(query) {
    return post("Company/electricity", query);
}
// 甲醛
export function formaldehyde(query) {
    return post("Company/cho", query);
}
// 湿度
export function humidity(query) {
    return post("Company/humidity", query);
}
// 温度
export function temperature(query) {
    return post("Company/temperature", query);
}
// co2
export function CO2(query) {
    return post("Company/CO2", query);
}
// PM10
export function PM10(query) {
    return post("Company/PM10", query);
}
// PM25
export function PM25(query) {
    return post("Company/PM25", query);
}
// tvoc
export function tvoc(query) {
    return post("Company/tvoc", query);
}
//空气检测
export function airData(query) {
    return post("Company/airData", query)
}

//电力检测
export function getFacility(query) {
    return post("Company/getFacility", query)
}

//统计
export function collect(query) {
    return post("Company/IntelligentLaboratoryData", query)
}


//客户端数据
export function client(query) {
    return post("Company/InstrumentData", query)
}
//后台客户数据
export function clientCity(query) {
    return post("Company/getClientData", query)
}


//后台数据统计
export function admin_toal(query) {
    return post("Company/adminData", query)
}

//招标数据分页
export function bids(query) {
    return post("Company/getBidsPage", query)
}

//数据类型
export function storeList(query) {
    return post("InstrumentCat/lab_cat", query)
}
//数据类型
export function storeNew(query) {
    return post("InstrumentCat/lab_tem", query)
}