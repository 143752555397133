<template>
  <div class="content-right fl">
    <div class="content-title fl">
      <p class="fl" @click="goBack()">商品订单</p>
      <p class="fl">/订单详情</p>
    </div>
    <div class="content-main">
      <div class="order-sn-status">
        <p class="item-title fl">订单号{{ list.order_sn }}</p>
        <p class="fr">{{ list.order_status_desc }}</p>
        <div class="clear"></div>
      </div>
      <div class="order-time">
        <p class="fl">创建时间：{{ list.add_time }}</p>
        <p class="fl">付款时间：{{ list.pay_time }}</p>
        <p class="fl" v-if="list.confirm_time">
          结束时间：{{ list.confirm_time }}
        </p>
        <div class="clear"></div>
      </div>
      <el-divider></el-divider>
      <p class="item-title">物流信息</p>
      <div class="item-logistics" v-if="type == 2">
        <p>物流方式：快递</p>
        <p>物流公司：{{ Info.com }}</p>
        <p v-if="Info.nu">物流单号：{{ Info.nu }}</p>
        <p v-if="Info.returnCode == 500">{{ Info.message }}</p>
        <p v-if="Info.returnCode == 400">{{ Info.message }}</p>
        <p v-if="Info.nu">物流跟踪：</p>
        <p v-for="(item, index) in InfoList" :key="index">
          {{ item.time }}{{ item.context }}
        </p>
      </div>
      <div v-if="type == 1" class="m1">{{ info }}</div>
      <el-divider></el-divider>
      <p class="item-title">收货信息</p>
      <p>
        {{ list.consignee }}，{{ list.mobile }},{{ list.province
        }}{{ list.city_name }} {{ list.district_name }}{{ list.address }}
      </p>
      <el-divider></el-divider>
      <p class="item-title">订单信息</p>
      <div class="info-title">
        <p class="fl">商品</p>
        <p class="fl">单价</p>
        <p class="fl">数量</p>
      </div>
      <div
        class="info-item"
        v-for="(item, index) in list.goods_list"
        :key="index"
      >
        <div class="info">
          <img class="fl" :src="item.original_img" alt="" />
          <div class="name-type-place fl">
            <p class="two-letter">{{ item.goods_name }}</p>
            <p class="one-letter">型号：{{ item.goods_sn }}</p>
            <p>产地：{{ item.goods_site }}</p>
          </div>
        </div>
        <div class="price fl">
          <p>¥{{ item.market_price }}</p>
          <p>¥{{ item.goods_price }}</p>
        </div>
        <div class="num fl">
          <p>X{{ item.goods_num }}</p>
        </div>

        <p
          class="t9 fr"
          v-if="list.order_status_desc == '待评价'"
          @click="goto_evaluate(item)"
        >
          立即评价
        </p>
        <div class="clear"></div>
      </div>
      <div class="price-box">
        <div class="price-item">
          <div class="fr">
            <p class="fl">商品总价：</p>
            <p class="fr">￥{{ list.goods_price }}</p>
          </div>
          <div class="clear"></div>
        </div>
           <div class="price-item">
          <div class="fr">
            <p class="fl">优惠券：</p>
            <p class="fr">￥{{ list.coupon_price }}</p>
          </div>
          <div class="clear"></div>
        </div>
        <div class="price-item">
          <div class="fr">
            <p class="fl">运费：</p>
            <p class="fr">￥{{ list.shipping_price }}</p>
          </div>
          <div class="clear"></div>
        </div>
        <div class="price-item">
          <div class="fr">
            <p class="fl">实付款：</p>
            <p class="fr price-pay" style="color: #ff0000;  font-size: 23px; ">￥{{ list.order_amount }}</p>
          </div>
          <div class="clear"></div>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="order-btn">
        <p class="order-del fr" v-if="list.receive_btn == 1" @click="queren()">
          确认收货
        </p>
        <div class="clear"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { order_Details, wl_info, orderConfirm } from "../../api/User";
import { browse } from "../../api/index";
export default {
  data() {
    return {
      token: "",
      id: "",
      list: "",
      info: "",
      type: "",
      Info: "",
      InfoList: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.id = localStorage.getItem("order_id");
    this.getList();
    this.getInfo();
    this.getBrowse(); //订单的浏览
  },
  methods: {
    getBrowse() {
      browse({ token: this.token, order_id: this.id, type: 1 }).then((res) => {
        // console.log(res);
      });
    },
    queren() {
      orderConfirm({ token: this.token, order_id: this.id }).then((res) => {
        if (res.status == 1) {
          this.$message("收货成功");
          this.goBack();
        }
      });
    },
    //物流信息
    getInfo() {
      wl_info({ order_id: this.id }).then((res) => {
        if (res.status == 1) {
          this.type = 2;
          this.Info = res.result;
          this.InfoList = res.result.data;
        } else if (res.status == -1) {
          this.type = 1;
          this.info = res.msg;
        }
      });
    },
    //去评价
    goto_evaluate(item) {
      this.$emit('change2');
      let data = JSON.stringify(item);
      localStorage.setItem("data", data);
    },
    //跳转商品订单
    goBack() {
      this.$emit('change1');
    },
    getList() {
      order_Details({ token: this.token, id: this.id }).then((res) => {
        if (res.status == 1) {
          this.list = res.result;
          // console.log(res);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-title {
    width: 100%;
    padding-left: 115px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
      cursor: pointer;
    }
  }
  .content-main {
    padding: 100px 115px;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 28px;
      color: #333333;
    }
    p.item-title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
    }
    .el-divider--horizontal {
      margin: 20px 0;
    }
    .order-sn-status {
      p:nth-child(2) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 28px;
        color: #408df7;
      }
    }
    .order-time {
      height: 50px;
      p {
        margin-right: 50px;
        line-height: 50px;
      }
    }
    .info-title {
      height: 40px;
      background: #efefef;
      padding: 0 30px;
      p {
        line-height: 40px;
      }
      p:nth-child(1) {
        width: 50%;
      }
      p:nth-child(2) {
        width: 25%;
        text-align: center;
      }
      p:nth-child(3) {
        width: 25%;
        text-align: right;
      }
    }
    .info-item {
      padding: 30px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      .info {
        width: 50%;
        .name-type-place {
          margin-left: 15px;
          width: 60%;
        }
        img {
          width: 120px;
          height: 100px;
        }
      }
      .price {
        width: 25%;
        p {
          text-align: center;
        }
        p:nth-of-type(1) {
          text-decoration: line-through;
          color: #999999;
        }
      }
      .num {
        width: 25%;
        p {
          text-align: right;
        }
      }
    }
    .price-box {
      padding: 50px 30px 30px;
      .price-item {
        div {
          p:nth-child(1) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #999999;
          }
          p:nth-child(2) {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 35px;
            color: #333333;
          }
        }
      }
      .price-item:nth-child(3) {
        div {
          .price-pay {
            font-size: 23px;
            color: #ff0000 !important;
          }
        }
      }
    }
    .order-btn {
      p {
        cursor: pointer;
        width: 100px;
        height: 45px;
        border: 2px solid #eeeeee;
        opacity: 1;
        border-radius: 5px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 45px;
        text-align: center;
        color: #333333;
        opacity: 1;
      }
    }
  }
}
.t9 {
  cursor: pointer;
  padding-top: 50px;
  font-size: 16px;
}
</style>