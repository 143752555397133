<template>
  <div class="wrap">
    <div class="head-bg">
      <img class="head-title" src="../../assets/img/head_img_title2.png" />
      <div class="btn-wrap">
        <div
          :class="['btn', activeTab == 1 ? 'btn-active' : '']"
          @click="switchTab(1)"
        >
          仪器管理数据
        </div>
        <div
          :class="['btn', activeTab == 2 ? 'btn-active' : '']"
          @click="switchTab(2)"
        >
          智能实验室数据
        </div>
      </div>
    </div>
    <preview-left v-if="activeTab==1"></preview-left>
    <preview-right v-if="activeTab==2"></preview-right>
  </div>
</template>

<script>
import PreviewLeft from "./components/preview1";
import PreviewRight from "./components/preview2";
export default {
  components: { PreviewLeft, PreviewRight },
  data() {
    return {
      activeTab: 2,
    };
  },

  mounted() {},
  methods: {
    switchTab(type) {
      this.activeTab = type;
    },
  },
};
</script>
<style lang='scss' scoped>
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding-right: 17px;
  .btn {
    margin-left: 20px;
    font-size: 16px;
    color: #fff;
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: linear-gradient(
      180deg,
      rgba(10, 84, 234, 0.32) 0%,
      rgba(11, 28, 71, 0.32) 100%
    );
    border-radius: 10px;
    cursor: pointer;
    &-active {
      background: linear-gradient(180deg, #0a54ea 0%, #0b1c47 100%);
    }
  }
}
.wrap {
  background: url("../../assets/img/bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
//   height: 100vh;
  padding: 0 20px 20px;
  min-width: 1440px;
  overflow-y: hidden;
}
.head-bg {
  background: url("../../assets/img/head_bg_1.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 82px;
  text-align: center;
  .head-title {
    margin-top: 25px;
    width: 312px;
    height: 32px;
  }
}
</style>