<template>
  <div class="content">
    <div class="progress-tab">
      <p :class="{ active: order_status == 1 }" @click="changeTab('已提交',1)">已提交</p>
      <p :class="{ active: order_status == 2 }" @click="changeTab('已报价',2)">已报价</p>
      <p :class="{ active: order_status == 3 }" @click="changeTab('同意报价',3)">同意报价</p>
      <p :class="{ active: order_status == 4 }" @click="changeTab('拒绝报价',4)">拒绝报价</p>
      <p :class="{ active: order_status == 5 }" @click="changeTab('待支付',5)">待支付</p>
      <p :class="{ active: order_status == 6 }" @click="changeTab('已完成', 6)">已完成</p>
      <p :class="{ active: order_status == 7 }" @click="changeTab('已评价', 7)">已评价</p>
      <div class="clear"></div>
    </div>
    <div class="progress-box">
      <div
        class="progress-item"
        v-for="(item, index) in progressList"
        :key="index"
      >
        <div class="item-top">
          <p class="order-sn fl">订单号：{{ item.order_sn }}</p>
          <p class="order-status fr">{{str}}</p>
          <div class="clear"></div>
        </div>
        <div class="item-bottom">
          <p>仪器：</p>
          <p>{{ item.instrument_name }}</p>
        </div>
        <div class="item-bottom">
          <p>报修时间：</p>
          <p>{{ item.add_time }}</p>
        </div>
        <div class="item-bottom">
          <p>工程师：</p>
          <p>{{ item.user_name }} {{ item.mobile }}</p>
        </div>
      </div>
    </div>
    <div class="page pd_space_260 clear" v-if="num>15">
      <van-pagination
        v-model="p"
        :total-items="num"
        :show-page-size="5"
        class="block fl"
        @change="gotopage"
        :items-per-page="15"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { order_instrument_list } from "../../api/index";
export default {
  data() {
    return {
      order_status: 1,
      progressList: [],
      p: 1, //当前页码
      total: 10, //
      token:"",
      num:"",
      str:"已提交"
    };
  },
  created() {
    this.token = localStorage.getItem('token')
    this.getProgressList()
  },
  methods: {
    //获取工程进度列表
    getProgressList() {
      order_instrument_list({
        token: this.token,
        p: this.p,
        order_status: this.order_status,
        instrument_dispose: 1,
      }).then((res) => {
        if (res.status == 1) {
          this.progressList = res.result.list;
          this.num = res.result.count
        }
      });
    },
    gotopage() {
      this.getProgressList();
    },
    //切换tab
    changeTab(str,order_status) {
      this.progressList = []
      this.str = str
      if (order_status != this.order_status) {
        this.order_status = order_status;
      }
      this.p = 1;
      this.getProgressList();
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  min-height: 1000px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  float: right;
  width: 78%;
  margin-left: 2%;
  margin-bottom: 20px;
  padding: 30px;
  .progress-tab {
    padding: 0 5%;
    border-bottom: 1px solid #eeeeee;
    p {
      float: left;
      height: 60px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 60px;
      text-align: center;
      color: #999999;
      width: 14%;
    }
    p.active {
      color: #333333;
      position: relative;
    }
    p.active::after {
      background-color: #408df7;
      width: 30px;
      height: 3px;
      content: " ";
      display: inline-block;
      position: absolute;
      top: 95%;
      left: 39%;
    }
  }
  .progress-box {
    padding: 20px 10px;
    .progress-item {
      float: left;
      width: 31.7%;
      // min-height: 200px;
      background: #ffffff;
      border: 1px solid #cccccc;
      opacity: 1;
      border-radius: 5px;
      padding: 1% 1%;
      margin: 5px;
      .item-top {
        padding: 5px 0;
        border-bottom: 1px solid #eeeeee;
        .order-status {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #999999;
          opacity: 1;
        }
      }
      .item-bottom {
        padding: 5px 0;
        p:nth-child(1) {
          width: 38%;
          float: left;
          padding: 5px 0;
          color: #999999;
          font-size: 14px;
        }
        p:nth-child(2) {
          width: 62%;
          float: left;
          padding: 5px 0;
          color: #333333;
          font-size: 14px;
          white-space:nowrap;
          overflow:hidden;
          text-overflow:ellipsis;
          max-width: 80%;
        }
        clear: both;
      }
    }
  }
  .page {
    padding: 30px 0;
    height: 75px;
    display: flex;
    justify-content: center;
    background: #ffffff;
    span {
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      color: #666666;
      float: left;
      border: 1px solid #cccccc;
      border-radius: 2px;
      margin: auto 5px;
    }
    .active {
      border: 1px solid #017eff;
    }
  }
}
</style>