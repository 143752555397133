<template>
  <div class="content-right fl">
    <p class="company-name">{{ User.company_name }}</p>
    <el-divider></el-divider>
    <p class="content-title">单位人员列表</p>
    <div class="content-item-title">
      <p>用户名</p>
      <p>手机号</p>
      <p>职务</p>
      <p>操作</p>
      <p>操作</p>
    </div>
    <div class="m1" v-if="list.length < 1">暂无人员</div>
    <div class="content-item" v-for="(item, index) in list.user" :key="index">
      <p>{{ item.nickname }}</p>
      <p>{{ item.mobile }}</p>
      <p>{{ item.position_name }}</p>
      <p>设置房间权限</p>
      <p>01,02,03</p>
    </div>
    <div class="unit-page" >
      <van-pagination
      v-if="list.count>1"
        v-model="p"
        :total-items="list.count"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
  </div>
</template>
<script>
import { my_company } from "../../api/User";
import { UseInfo } from "../../api/index";
export default {
  data() {
    return {
      token: "",
      p: 1,
      list: [],
      User: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
    this.getUserInfo();
  },
  methods: {
    //个人信息
    getUserInfo() {
      UseInfo({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.User = res.result;
        }
      });
    },
    getList() {
      my_company({ token: this.token, p: this.p }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
          // console.log(res);
        }
      });
    },
    home(item) {
      this.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  padding: 25px;
  background-color: #ffffff;
  width: 100%;
  .company-name {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 50px;
    color: #333333;
  }
  .content-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 28px;
    color: #333333;
    padding: 11px 0;
  }
  .content-item-title {
    height: 50px;
    margin-top: 20px;
    p {
      float: left;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      line-height: 50px;
      text-align: center;
      background-color: #efefef;
      color: #333333;
    }
    p:nth-child(1) {
      width: 15%;
    }
    p:nth-child(2) {
      width: 20%;
    }
    p:nth-child(3) {
      width: 15%;
    }
    p:nth-child(4) {
      width: 15%;
    }
    p:nth-child(5) {
      width: 35%;
    }
  }
  .content-item {
    height: 60px;
    p {
      float: left;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 60px;
      text-align: center;
      color: #333333;
      margin: 1px 0;
    }
    p:nth-child(1) {
      width: 15%;
    }
    p:nth-child(2) {
      width: 20%;
    }
    p:nth-child(3) {
      width: 15%;
    }
    p:nth-child(4) {
      width: 15%;
      color: #408df7;
    }
    p:nth-child(5) {
      width: 35%;
      color: #ff0000;
    }
  }
  .content-item:nth-child(even) {
    background-color: #efefef;
  }
  .content-item:nth-child(odd) {
    background-color: #fcfcfc;
  }
  .unit-page {
    margin: 40px 0;
    display: flex;
    justify-content: center;
  }
}
</style>
