import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios"; //使用axios
import vueAxios from "vue-axios";
import Vant from "vant";
import store from "./store";
import ElementUI from 'element-ui'

import VueAwesomeSwiper from "vue-awesome-swiper"; //轮播
import { DropdownMenu, DropdownItem,Area } from 'vant';
import { Toast } from 'vant';
import { Uploader } from 'vant';
import "element-ui/lib/theme-chalk/index.css";
import 'vant/lib/index.css'
import "swiper/css/swiper.css";
import XLSX from 'xlsx';




Vue.use(Uploader);
Vue.use(Toast);
Vue.use(vueAxios, axios);
Vue.use(DropdownMenu,Area);
Vue.use(DropdownItem);
Vue.use(Vant); 
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper);
Vue.use(XLSX);


router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
