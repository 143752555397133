import { render, staticRenderFns } from "./datapreview.vue?vue&type=template&id=55c0c724&scoped=true&"
import script from "./datapreview.vue?vue&type=script&lang=js&"
export * from "./datapreview.vue?vue&type=script&lang=js&"
import style0 from "./datapreview.vue?vue&type=style&index=0&id=55c0c724&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c0c724",
  null
  
)

export default component.exports