<template>
   <div id="app">
       <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  components: {},

  created() {
  },
  methods: {},
};
</script>
<style>
#app {
  width: 100%;
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.van-overlay{
    z-index: 1999 !important;
}
</style>
