<template>
  <div class="complate">
    <Header></Header>
    <!-- 面包屑 -->
    <div class="place">
      <span>当前位置：</span>
      <span>采购服务></span>
      <span>购物车</span>
    </div>
    <!-- 全部商品 -->
    <div class="allShoop">
      <span>全部商品 {{ total.num }}</span>
    </div>
    <div class="shopping_form">
      <div class="list_head">
        <span class="goods fl">商品</span>
        <span class="the_price fl">价格</span>
        <span class="number fl">数量</span>
        <span class="reporter fl">小记</span>
        <button class="del fl">操作</button>
      </div>
      <div class="shopping" v-for="(item, index) in list" :key="index">
        <div class="check_box" @click="checkSingle(item.id, index)">
          <img :src="item.selected ? imgUrl2 : imgUrl1" />
        </div>
        <img class="img2" :src="item.original_img" />
        <div class="shopp_name">
          <span class="two-letter">{{ item.goods_name }}</span>
        </div>

        <span class="shopp_money">¥{{ item.goods_price }}</span>
        <div class="num">
          <van-stepper
            v-model="item.goods_num"
            class="step"
            min="1"
            max="99"
            integer
            async-change
            @change="onChange(index, item.id, item.goods_num)"
          />
        </div>
        <span class="shopp_reporter"
          >¥{{ (item.goods_num * item.goods_price) | numFilter }}</span
        >
        <button class="delete" @click="del(item.id)">删除</button>
      </div>
    </div>
    <div class="settle_accounts">
      <div class="jies">
        <div class="quanxuan fl" @click="chooseAll()">
          <img :src="allFlag ? imgUrl2 : imgUrl1" />全选
        </div>
        <button @click="delAll" class="btn2">删除选中商品</button>
        <button class="btn3" @click="sub()">去结算</button>
        <div class="jiag">
          <p>
            已选择<span>{{ goods_num ? goods_num : 0 }}</span
            >件商品
          </p>
          <span class="shul">总价：¥{{ price ? price : 0 }}</span>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import { CartList, delCart, goodsprice, goodsNum } from "../../api/goods";
export default {
  components: { Header, Bottom },
  data() {
    return {
      token: "",
      imgUrl1: require("../../assets/img/but_xz_n.png"),
      imgUrl2: require("../../assets/img/but_xz_s.png"),
      list: [], //商品列表
      p: 1,
      checkIdArr: [],
      checkIndex: [],
      allFlag: false,
      num: "", //数量
      total: "",
      price: "",
      goods_num: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList(); //获取购物车
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  methods: {
    //提交订单
    sub() {
      if (this.checkIdArr.length < 1) {
        this.$message("请选择需要结算的商品");
      } else {
        this.$router.push({
          path: "/TheOrder",
        });
      }
    },
    //商品数量
    onChange(index, id, num) {
      goodsNum({ token: this.token, id: id, num: num }).then((res) => {
        if (res.status == 1) {
          this.getList(); //获取购物车
          this.getTotal();
        } else if (res.status == 0) {
          // this.$message.error(res.info);
        }
      });
    },
    delAll() {
      let ids = "";
      if (this.checkIdArr.length > 0) {
        ids = this.checkIdArr.join(",");
        this.del(ids);
        this.allFlag = false;
      } else if (this.checkIdArr.length < 1) {
        this.$message("请选择需要删除的商品");
      }
    },
    //删除
    del(id) {
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });

          that.delCart(id);
          setTimeout(() => {
            that.getList();
            that.getTotal();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delCart(id) {
      delCart({ ids: id }).then((res) => {
        if (res.status == 1) {
          // console.log(0)
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //计算价格
    getTotal() {
      let ids = "";
      if (this.checkIdArr.length > 0) {
        ids = this.checkIdArr.join(",");
      }
      goodsprice({ token: this.token, id: ids }).then((res) => {
        if (res.status == 1) {
          this.goods_num = res.data.total_num;
          this.price = res.data.total_price;
        }
      });
    },
    //全选
    chooseAll() {
      this.allFlag = !this.allFlag;
      for (let i = 0; i < this.list.length; i++) {
        if (this.allFlag) {
          this.checkIdArr.push(this.list[i].id);
          this.checkIndex.push(i);
          this.list[i].selected = 1;
        } else {
          this.list[i].selected = 0;
          this.checkIdArr = [];
          this.checkIndex = [];
        }
      }
      this.getTotal();
    },
    // 单选
    checkSingle(id, index) {
      let list = this.list;
      let arr = this.checkIdArr;
      let indexArr = this.checkIndex;
      if (list[index].selected) {
        arr.splice(arr.indexOf(id), 1);
        indexArr.splice(indexArr.indexOf(index), 1);
      } else {
        arr.push(id);
        indexArr.push(index);
      }
      list[index].selected = !list[index].selected;
      this.list = list;
      this.checkIdArr = arr;
      this.checkIndex = indexArr;
      if (this.checkIdArr.length == this.list.length) {
        this.allFlag = true;
      } else {
        this.allFlag = false;
      }
      this.getTotal();
    },
    //购物车列表
    getList() {
      CartList({ token: this.token, p: this.p }).then((res) => {
        if (res.status == 1) {
          let list = res.result.cartList;
          if (list.length > 0) {
            for (let i = 0; i < list.length; i++) {
              if (this.allFlag) {
                list[i].selected = 1;
              } else list[i].selected = 0; //false 未选中 true 选中
            }
          }
          this.list = res.result.cartList;
          this.total = res.result.total_price;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.complate {
  background: #ffffff;
}
.check_box {
  position: absolute;
  width: 25px;
  height: 25px;
  // background: #333333;
  margin-top: 35px;
  margin-left: 50px;
  img {
    width: 13px;
    height: 13px;
    cursor: pointer;
  }
}
.place {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 100%;
  padding: 0 14%;
  height: 50px;
  background: #f3f3f3;
  line-height: 50px;
  span {
    margin-left: 5px;
  }
}
.allShoop {
  width: 100%;
  padding: 0 14%;
  background: #ffffff;

  span {
    height: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 67px;
    color: #408df7;
    opacity: 1;
    display: inline-block;
    border-bottom: 1px solid #408df7;
  }
}
.shopping_form {
  padding: 0 260px;
  background: #ffffff;
  min-height: 400px;
  .list_head {
    width: 100%;
    height: 42px;
    background: #f9f9f9;
    margin-bottom: 20px;
  }
  span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  button {
    background: rgba(0, 0, 0, 0);
  }
  .goods {
    display: inline-block;
    text-align: center;
    width: 10%;
    margin-left: 10%;
    line-height: 42px;
  }
  .the_price {
    width: 10%;
    text-align: center;
    display: inline-block;
    margin-left: 15%;
    line-height: 42px;
  }
  .number {
    width: 10%;
    display: inline-block;
    text-align: center;
    margin-left: 6%;
    line-height: 42px;
  }
  .reporter {
    width: 10%;
    display: inline-block;
    margin-left: 10%;
    line-height: 42px;
  }
  .del {
    width: 10%;
    text-align: center;
    float: right;
    line-height: 42px;
  }
}
.shopping {
  width: 100%;
  padding: 30px 0 30px 0;
  height: 160px;
  margin-bottom: 20px;
  border: 1px solid #eeeeee;
  position: relative;
  .choose {
    top: 40%;
  }
  .img2 {
    position: absolute;
    width: 10%;
    height: 100px;
    object-fit: cover;
    margin-left: 10%;
  }
  .shopp_name {
    position: absolute;
    width: 10%;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-left: 24%;
  }
  .shopp_money {
    min-width: 60px;
    width: 10%;
    margin-left: 35%;
    position: absolute;
    text-align: center;
    display: inline-block;
    font-size: 14px;
  }
  .num {
    position: absolute;
    margin-left: 51.5%;
    .jian {
      width: 18%;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      border: 1px solid #eeeeee;
      height: 35px;
    }
    input {
      width: 60%;
      height: 35px;
      text-align: center;
      border: 1px solid #eeeeee;
    }
    .jia {
      width: 18%;
      text-align: center;
      border: 1px solid #eeeeee;
      height: 35px;
      font-size: 14px;
      font-family: Microsoft YaHei;
    }
  }
  .shopp_reporter {
    position: absolute;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-left: 70%;
  }
  .delete {
    float: right;
    margin-right: 4%;
    text-align: center;
  }
}

input[type="checkbox"] {
  width: 13px;
  height: 13px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  line-height: 13px;
  position: relative;
}
input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  border: 1px solid #d9d9d9;
}
input[type="checkbox"]:checked::before {
  content: "√";
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid #408df7;
  color: #408df7;
  font-size: 8px;
  font-weight: bold;
}
.settle_accounts {
  width: 100%;
  height: 90px;
  padding: 0 14%;
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.1);

  .jies {
    width: 100%;
    height: 100%;
    padding-left: 23px;
    .accounts_input {
      margin-right: 20px;
    }
    .btn1,
    .btn2 {
      top: 40%;
      padding-top: 2px;
      line-height: 85px;
      text-align: center;
      background: #ffffff;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-left: 27px;
      margin-right: 30px;
    }
    .btn3 {
      float: right;
      width: 231px;
      height: 90px;
      background: #408df7;
      color: #ffffff;
      text-align: center;
      font-size: 18px;
    }
    .jiag {
      float: right;
      height: 90px;
      max-width: 200px;
      margin-right: 42px;
      p {
        margin-top: 23px;
        margin-bottom: 7px;
       
        span {
          margin: 0 5px;
          font-weight: bold;
          color: #408df7;
        }
      }
      .shul {
        white-space: nowrap;
        float: left;
      }
    }
  }
}
.quanxuan {
  margin-top: 35px;
  font-size: 14px;
  color: #333333;
  img {
    margin-right: 10px;
  }
}
.step {
  border: 1px solid #cccccc;
  line-height: 0;
  /deep/ .van-stepper__input {
    color: #666666;
    width: 65px;
    height: 35px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    background: #ffffff;
  }
  /deep/.van-stepper__minus--disabled {
    background: #ffffff;
    color: #a1a1ab;
  }
  /deep/.van-stepper__minus {
    background: #ffffff;
  }
  /deep/ .van-stepper__plus {
    background: #ffffff;
  }
  .num-box {
    border: 1px solid #ddd;
    border-radius: 3px;
  }
}
</style>