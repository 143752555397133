<template>
  <div class="content-right fl">
    <div class="content-right-tab">
      <p :class="{ active: activeTab == 0 }" @click="toggleTab(0)">服务订单</p>
      <p :class="{ active: activeTab == 1 }" @click="toggleTab(1)">商品订单</p>
    </div>
    <div class="content-service" v-if="activeTab == 0">
      <div class="service-type-tab">
        <div class="tab-left fl">
          <p @click="mess(0)" :class="type == 0 ? 'active' : ''">全部</p>
          <p @click="mess(1)" :class="type == 1 ? 'active' : ''">仪器维修</p>
          <p @click="mess(2)" :class="type == 2 ? 'active' : ''">仪器保养</p>
          <p @click="mess(3)" :class="type == 3 ? 'active' : ''">安装调试</p>
          <p @click="mess(4)" :class="type == 4 ? 'active' : ''">我的实验室</p>
          <p @click="mess(5)" :class="type == 5 ? 'active' : ''">我的实验室方案</p>
        </div>
        <div class="tab-right fr">
          <input v-model="str" class="fl" type="text" placeholder="请输入关键字搜索" />
          <p class="fl search" @click="search">搜索</p>
        </div>
        <div class="clear"></div>
      </div>
      <div v-if="orderLists.list.length < 1" class="m1">暂无服务订单</div>
      <div
        class="service-item"
        v-for="(item, index) in orderLists.list"
        :key="index"
        @click="goFwDetails(item.id)"
      >
        <div class="item-title">
          <p class="item-title fl" v-if="item.instrument_dispose == 1">仪器维修</p>
          <p class="item-title fl" v-if="item.instrument_dispose == 2">仪器保养</p>
          <p class="item-title fl" v-if="item.instrument_dispose == 3">安装调试</p>
          <p class="item-title fl" v-if="item.instrument_dispose == 4">我的实验室</p>
          <p class="item-title fl" v-if="item.instrument_dispose == 5">我的实验室方案</p>
          <p class="fr">创建日期：{{ item.add_time }}</p>
          <div class="clear"></div>
        </div>
        <el-divider></el-divider>
        <div class="item-content">
          <div class="item-info fl">
            <img class="fl" :src="item.pic" />

            <div
              class="name-type-place fl"
              v-if="
                item.instrument_dispose == 1 ||
                item.instrument_dispose == 2 ||
                item.instrument_dispose == 4
              "
            >
              <p class="two-letter">{{ item.instrument_name }}</p>
              <p>型号：{{ item.instrument_size }}</p>
              <p>采购途径：{{ item.procurement }}</p>
            </div>
            <div class="name-type-place fl" v-if="item.instrument_dispose == 3">
              <p class="two-letter">{{ item.instrument_name }}</p>
              <p>型号：{{ item.instrument_size }}</p>
              <p class="two-letter">品牌：{{ item.procurement }}</p>
            </div>
            <div class="name-type-place fl" v-if="item.instrument_dispose == 5">
              <p>家具工程：{{ item.instrument_size }}</p>
              <p>通风工程：{{ item.procurement }}</p>
            </div>
          </div>
          <div class="item-scheme fl">
            <p></p>
            <p></p>
          </div>
          <div class="item-enginner fl" v-if="item.user_name">
            <p>工程师信息</p>
            <p>工程师：{{ item.user_name }}</p>
            <p>联系电话：{{ item.mobile }}</p>
          </div>
          <div class="item-operation fr">
            <p class="item-status" v-if="item.order_status == 1 && item.order_report == 0">已提交</p>
            <p class="item-status" v-if="item.order_status == 1 && item.order_report == 1">工程师已报价</p>
            <p class="item-status"
              v-if="
                item.order_status == 2 && item.order_report == 2 && item.pay_status == 0
              "
            >
              同意报价
            </p>
            <p class="item-status" v-if="item.order_status == 2 && item.pay_status == 1">已支付</p>
            <p class="item-status" v-if="item.order_status == 5">已评价</p>
            <p class="item-status" v-if="item.order_status == 6">已取消</p>
            <p class="item-status" v-if="item.order_status == 1 && item.order_report == 3">拒绝报价</p>
            <p v-if="item.order_status == 3" class="item-status">已拒绝</p>
            <p
              v-if="item.order_status == 4 && item.pay_status == 1"
              class="item-comment"
              @click.stop="goto(item)"
            >
              去评价
            </p>
            <p
              v-if="item.pay_status == 1 && item.order_status == 5"
              class="item-del"
              @click.stop="dele(item.id)"
            >
              删除订单
            </p>
            <p
              v-if="item.order_status == 4 && item.pay_status == 0"
              class="item-comment"
              @click.stop="goHref('/OrderInfo?id=' + item.id + '&type=' + 1)"
            >
              去付款
            </p>
          </div>
        </div>
        <div class="clear"></div>
      </div>
      <div class="service-page" v-if="orderLists.count > 1">
        <van-pagination
          v-model="p"
          :total-items="orderLists.count"
          class="block fl"
          @change="orderList()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <div class="content-goods" v-if="activeTab == 1">
      <div class="goods-title">
        <p>仪器</p>
        <p>单价</p>
        <p>数量</p>
        <p>实付款</p>
        <p>交易状态</p>
        <p>操作</p>
        <div class="clear"></div>
      </div>
      <div v-if="list.order_list.length < 1" class="m1">暂无商品订单</div>
      <div class="goods-item clear" v-for="(item, index) in list.order_list" :key="index">
        <div class="item-title">
          <p class="fl">订单号：{{ item.order_sn }}</p>
          <p class="fr">下单日期：{{ item.add_time }}</p>
          <div class="clear"></div>
        </div>
        <el-divider></el-divider>
        <div
          class="item-content"
          v-for="(itn, indx) in item.goods_list"
          :key="indx"
          @click="goDetails(item.order_id)"
        >
          <div class="item-info fl">
            <img class="fl" :src="itn.original_img" alt="" />
            <div class="name-type-place fl">
              <p class="two-letter">{{ itn.goods_name }}</p>
              <p class="one-letter">型号 : {{ itn.goods_sn }}</p>
              <p>产地 : {{ itn.goods_site }}</p>
            </div>
          </div>
          <div class="item-price fl">
            <p>¥{{ itn.market_price }}</p>
            <p>¥{{ itn.goods_price }}</p>
          </div>
          <div class="item-num fl">
            <p>X{{ itn.goods_num }}</p>
          </div>
          <div class="item-pay fl">
            <p>¥{{ item.order_amount }}</p>
          </div>
          <div class="item-status fl">
            <p>{{ item.order_status_desc }}</p>
          </div>
          <div class="item-operation fl">
            <p
              class="order-comment"
              v-if="item.comment_btn == 1"
              @click.stop="gotoPj(itn)"
            >
              评价
            </p>
            <p
              class="order-pay"
              v-if="item.pay_btn == 1"
              @click.stop="gotoPay(item.order_id)"
            >
              去付款
            </p>
            <p
              class="order-del"
              v-if="
                item.order_status_desc == '已作废' || item.order_status_desc == '已取消'
              "
              @click.stop="del(item.order_id)"
            >
              删除订单
            </p>
            <p
              class="order-del"
              v-if="item.shipping_btn == 1"
              @click.stop="goDetails(item.order_id)"
            >
              查看物流
            </p>
            <p
              class="order-del"
              v-if="item.receive_btn == 1"
              @click.stop="queren(item.order_id)"
            >
              确认收货
            </p>
            <p
              class="order-cancel"
              v-if="item.cancel_btn == 1"
              @click.stop="qu(item.order_id)"
            >
              取消订单
            </p>
          </div>
        </div>
      </div>

      <div class="goods-page" v-if="list.length > 1">
        <van-pagination
          v-model="p"
          :total-items="list.count"
          class="block fl"
          @change="getgoods()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { service_order, deleter, goods_Order, OrderPay } from "../../api/goods";
import { del_order, qu_order, del, orderConfirm } from "../../api/User";
export default {
  data() {
    return {
      activeTab: 0,
      token: "",
      p: 1, //分页
      type: 0, //全部、保养、维修、调试状态切换
      orderLists: [], // 服务订单列表
      list: [], //商品订单列表
      index: 0, //获取到的支付状态参数
      currentPage: 1, //切换分类分页自动跳回第一页
      str: "", //监控输入框搜索的内容
      p1: 1,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.orderList();
    this.getgoods();
    // this.$parent.getState();
  },
  methods: {
    gotoPay(id) {
      OrderPay({ order_id: id }).then((res) => {
        if (res.status == 1) {
          this.$router.push({
            path: "/OrderInfo",
            query: {
              id: id,
            },
          });
        } else {
          this.$message.error(res.info);
        }
      });
    },
    queren(id) {
      orderConfirm({ token: this.token, order_id: id }).then((res) => {
        if (res.status == 1) {
          this.$message("收货成功");
          this.getgoods();
        }
      });
    },
    goto(item) {
      this.$emit('change6');
      let data = JSON.stringify(item);
      localStorage.setItem("data", data);
    },
    //服务订单详情
    goFwDetails(id) {
      this.$emit('change5');
      // this.$parent.getUserInfo();
      localStorage.setItem("Fw_id", id);
    },
    //去评价
    gotoPj(item) {
      this.$emit('change2');
      let data = JSON.stringify(item);
      localStorage.setItem("data", data);
    },
    //c查看物流
    goDetails(id) {
      this.$emit('change');
      localStorage.setItem("order_id", id);
    },
    //取消订单
    qu(id) {
      let that = this;
      this.$confirm("即将取消订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已成功取消!",
          });
          this.qu_Order(id);
          setTimeout(() => {
            this.getgoods();
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消",
          });
        });
    },
    qu_Order(id) {
      qu_order({ token: this.token, order_id: id }).then((res) => {
        // console.log(res);
      });
    },
    //删除订单
    del(id) {
      let that = this;
      this.$confirm("即将删除订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });
          this.delOrder(id);
          setTimeout(() => {
            this.getgoods();
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delOrder(id) {
      del_order({ order_id: id }).then((res) => {
        if (res.status == 1) {
          // console.log(res);
        }
      });
    },
    //商品订单列表
    getgoods() {
      goods_Order({ token: this.token, p: this.p1 }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;

          // console.log(res);
        }
      });
    },
    //切换 商品订单 服务订单
    toggleTab(index) {
      this.activeTab = index;
      if (index == 0) {
        this.type = 0;
        this.orderList();
      } else {
        this.getgoods();
      }
    },

    //获取参数
    orderList() {
      let data = {
        token: this.token,
        type: this.type,
        p: this.p,
      };
      service_order(data).then((res) => {
        if (res.status == 1) {
          this.orderLists = res.data;
          // console.log(res);
        }
      });
    },

    //分类全部、保养、维修、调试
    mess(i) {
      this.type = i;
      this.p = 1;
      this.orderList();
    },
    //删除服务订单
    dele(id) {
      let that = this;
      this.$confirm("即将删除订单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });
          this.del_fu(id);
          setTimeout(() => {
            this.orderList();
          }, 1000);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    del_fu(id) {
      del({ token: this.token, id: id }).then((res) => {
        if (res.status == 1) {
          // console.log(res);
        }
      });
    },
    search() {
      const data = {
        token: this.token,
        type: this.type,
        p: this.p,
        keywords: this.str,
      };
      service_order(data).then((res) => {
        if (res.status == 1) {
          this.orderLists = res.data;
        }
      });
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-right-tab {
    height: 70px;
    border-bottom: 1px solid #eeeeee;
    p {
      width: 100px;
      height: 70px;
      line-height: 70px;
      margin: 0 35px;
      float: left;
      cursor: pointer;
    }
    p.active::after {
      background-color: #408df7;
      width: 42px;
      height: 3px;
      content: " ";
      display: inline-block;
      position: relative;
      top: 25%;
      left: -50%;
    }
  }
  //服务订单
  .content-service {
    padding: 25px;

    .service-type-tab {
      .tab-left {
        p {
          float: left;
          padding: 7px 10px;
          height: 35px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          font-size: 16px;
          text-align: center;
          color: #333333;
          margin-right: 10px;
        }
        p.active {
          background-color: #408df7;
          color: #ffffff;
        }
        p:last-of-type {
          margin: 0;
        }
      }
      .tab-right {
        input {
          width: 150px;
          height: 35px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          padding-left: 5px;
        }
        p {
          width: 100px;
          height: 35px;
          background: #408df7;
          border: 1px solid #408df7;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 35px;
          text-align: center;
          color: #ffffff;
        }
      }
    }
    .service-item {
      padding: 0 20px 20px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      margin: 10px 0;
      .item-title {
        height: 50px;
        p:nth-child(1) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 50px;
          color: #333333;
        }
        p:nth-child(2) {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 50px;
          color: #333333;
        }
      }
      .el-divider--horizontal {
        margin: 0;
      }
      .item-content {
        padding: 15px 0;
        .item-info {
          width: 35%;
          img {
            width: 120px;
            height: 100px;
          }
          .name-type-place {
            width: 60%;
            padding-left: 15px;
            p:nth-child(1) {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
              margin-bottom: 10px;
              // max-width: 200px;
            }
            p:nth-child(2),
            p:nth-child(3) {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 20px;
              color: #999999;
              // max-width: 200px;
            }
          }
        }
        .item-scheme {
          width: 20%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 10px;
          }
          p:nth-child(1) {
            color: #999999;
          }
          p:nth-child(2) {
            color: #333333;
          }
        }
        .item-enginner {
          width: 25%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            padding-bottom: 10px;
          }
          p:nth-child(1) {
            color: #999999;
          }
          p:nth-child(2),
          p:nth-child(3) {
            color: #333333;
          }
        }
        .item-operation {
          width: 20%;
          .item-status {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: #408df7;
            padding-bottom: 10px;
            cursor: pointer;
          }
          .item-del {
            cursor: pointer;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            text-align: center;
            color: #999999;
            padding-bottom: 10px;
          }
          .item-comment {
            width: 100px;
            height: 45px;
            border: 2px solid #eeeeee;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 45px;
            color: #333333;
            margin: 0 auto;
            cursor: pointer;
          }
        }
      }
    }
    .service-page {
      margin: 40px 0;
      display: flex;
      justify-content: center;
    }
  }
  //商品订单
  .content-goods {
    // height: 1200px;
    padding: 25px;
    .goods-title {
      height: 50px;
      background-color: #efefef;
      p {
        float: left;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
      }
      p:nth-child(1) {
        width: 35%;
        padding-left: 55px;
      }
      p:nth-child(2) {
        width: 10%;
      }
      p:nth-child(3) {
        width: 10%;
      }
      p:nth-child(4) {
        width: 15%;
      }
      p:nth-child(5) {
        width: 20%;
      }
    }
    .goods-item {
      width: 100%;
      height: 100%;
      background: #ffffff;
      border: 1px solid #eeeeee;
      padding: 0 20px;
      margin: 10px 0;
      .item-title {
        height: 50px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 50px;
          color: #333333;
        }
      }
      .el-divider--horizontal {
        margin: 0;
      }
      .item-content {
        width: 100%;
        height: 180px;
        padding: 15px 0;
        .item-info {
          width: 35%;
          img {
            width: 40%;
            height: 100px;
          }
          .name-type-place {
            width: 60%;
            padding-left: 15px;
            p:nth-child(1) {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #333333;
              margin-bottom: 10px;
              max-width: 200px;
            }
            p:nth-child(2),
            p:nth-child(3) {
              font-size: 13px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
            }
          }
        }
        .item-price {
          width: 10%;
          p:nth-child(1) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            text-decoration: line-through;
          }
          p:nth-child(2) {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .item-num {
          width: 10%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .item-pay {
          width: 15%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
          }
        }
        .item-status {
          width: 20%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 20px;
            color: #333333;
          }
        }
        .item-operation {
          p.order-comment,
          p.order-pay {
            font-size: 14px;
            cursor: pointer;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            color: #333333;
            width: 70px;
            height: 30px;
            background: #ffffff;
            border: 1px solid #eeeeee;
          }
          p.order-del,
          p.order-cancel {
            cursor: pointer;
            padding: 6px;
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #999999;
          }
        }
      }
    }
    .goods-page {
      margin: 40px 0;
      display: flex;
      justify-content: center;
    }
  }
}
.el-pagination {
  width: 100%;
  float: left;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 39px;
  /deep/ .el-pager li {
    padding: 0 3px;
    font-size: 16px;
    min-width: 35.5px;
    background: rgba(0, 0, 0, 0);
    height: 40px;
    width: 40px;
    line-height: 40px;
    margin: 0 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background: #ffffff;
  }
  /deep/ .el-pager li:hover {
    border: 1px solid #409eff;
    z-index: 2;
  }
  /deep/ .el-pager li.active {
    color: #409eff;
    border: 1px solid #409eff;
    cursor: default;
    background-color: #ffffff;
  }
  /deep/ button {
    padding: 0;
    font-size: 16px;
    min-width: 35.5px;
    height: 40px;
    width: 40px;
    line-height: 40px;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #ffffff;
    margin: 0 5px;
  }
  /deep/ button:hover {
    border: 1px solid #409eff;
  }
  /deep/ .el-pagination {
    white-space: nowrap;
    padding: 2px 5px;
    color: #666666;
    font-weight: 500;
  }
}

.message-stlye:hover {
  cursor: pointer;
}
.borderrr {
  border: 1px solid #408df7;
}
.el-submenu:nth-last-of-type(1) {
  padding: 0 !important;
}
.el-submenu {
  padding-bottom: 10px !important;
  // border-bottom:1px solid  #eeeeee;
}
.el-menu {
  border: none;
}
.el-menu-item {
  border: 0.5px solid #eeeeee !important;
  padding-left: 20px !important;
}

.el-submenu /deep/.el-submenu__title {
  height: 60px !important;
  border: 1px solid #eeeeee !important;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ffffff;
  color: #408df7;
}
/deep/ .el-pagination.is-background .el-pager li {
  background: rgba(0, 0, 0, 0);
}
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .btn-next {
  background: rgba(0, 0, 0, 0);
}
.search:hover {
  cursor: pointer;
}
</style>
