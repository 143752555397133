<template>
  <div class="content-right">
    <div class="content-title">
      <p>订单中心</p>
    </div>
    <div class="content-main">
      <div class="order-box">
        <div
          class="order-item"
          v-for="(item, index) in list.list"
          :key="index"
          @click="go(item.id)"
        >
          <div class="name-pic fl">
            <img :src="item.pic" alt="" />
            <p>{{ item.uname }}</p>
          </div>
          <div class="instrument fl">
            <p>
              仪器名称：<span class="one-letter">{{ item.instrument_name }}</span>
            </p>
            <p>
              单位：<span>{{ item.company_name }}</span>
            </p>
            <p v-if="item.instrument_dispose == 1">需求类别：仪器维修</p>
            <p v-if="item.instrument_dispose == 2">需求类别：仪器保养</p>
            <p v-if="item.instrument_dispose == 3">需求类别：安装调试</p>
            <p v-if="item.instrument_dispose == 4">需求类别：我的实验室</p>
            <p v-if="item.instrument_dispose == 5">需求类别：我的实验室方案</p>
            <p>
              提交时间：<span>{{ item.add_time }}</span>
            </p>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
    <div class="order-page fl" v-if="list.count > list.list.length">
      <van-pagination
        v-model="p"
        :total-items="list.count"
        :items-per-page="12"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
    <div class="clear"></div>
  </div>
</template>
<script>
import { monad } from "../../api/User";
export default {
  data() {
    return {
      toknen: "",
      p: 1,
      list: [],
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
  },
  methods: {
    go(id) {
      this.$parent.chose();
      this.$parent.getUserInfo();
      // this.$parent.state();
      localStorage.setItem("list_id", id);
    },
    getList() {
      monad({ token: this.token, p: this.p }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 270px;
  padding: 0 25px 25px;
  background-color: #ffffff;
  min-width: 75%;
  max-width: 80%;
  margin-bottom: 20px;
  .content-title {
    padding: 0 60px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
  .content-main {
    padding: 30px;
    .order-box {
      .order-item {
        float: left;
        width: 48%;
        background: #ffffff;
        border: 1px solid #eeeeee;
        padding: 30px 20px;
        margin: 10px 0;
        .name-pic {
          width: 30%;
          img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            position: relative;
            object-fit: cover;
            left: 25%;
          }
          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            color: #333333;
          }
        }
        .instrument {
          width: 70%;
          p {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 24px;
            color: #999999;
            span {
              color: #333333;
            }
          }
        }
      }
      .order-item:nth-child(1n) {
        margin-right: 2%;
      }
    }
  }
  .order-page {
    margin: 40px 40%;
  }
}
</style>