<template>
  <div class="complate">
    <!-- 导航 -->
    <Header></Header>
    <Tops ref="mychild"></Tops>
    <!-- 二级分类 -->
    <el-row>
    <div class="pd fl lunbot">
      <div class="classify fl">
        <el-col :span="4">
        <ul class="yiji fl" @mouseleave.stop="guan()">
          <li
            @mouseenter.stop="classifyClick(item.id)"
            v-for="(item, index) in classifyList"
            :key="index"
            :class="id == item.id ? 'classifyClick one-letter' : 'classifyNotClick'"
          >
            <p class="one-letter">{{ item.name }}</p>
            <img src="../../assets/img/spxq_yh_n.png" alt="" />
          </li>
        </ul>
        </el-col>
        <!-- 轮播图 -->
        <el-col :span="20">
        <div class="banner">
          <el-carousel height="500px" width="100%" indicator-position="none">
            <div
              v-show="show"
              class="pop"
              @mouseenter.stop="getStatus"
              @mouseleave.stop="guan()"
            >
              <div class="m1" v-if="nextList.length < 1">暂无数据</div>
              <img
                class="pop_img1 fr"
                src="../../assets/img/pop_but_gb.png"
                @click="close"
              />
              <div
                class="pop_list"
                v-for="(item, index) in nextList"
                :key="index"
              >
                <div class="pop_list_text">
                  <p class="one-letter">{{ item.mobile_name }} ></p>
                </div>

                <div class="pop_list_content fr">
                  <span
                    class="fl"
                    v-for="(iten, ind) in nextList[index].sub_category"
                    :key="ind"
                    @click="goto(iten.id)"
                    >{{ iten.mobile_name }}</span
                  >
                </div>
              </div>
            </div>
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="item.ad_code" />
            </el-carousel-item>
          </el-carousel>
        </div>
        </el-col>
      </div>
    </div>
    </el-row>
    <!-- 轮播图下三款PK商品 -->
    <el-row :gutter="25">
    <div class="pd">
      <div class="commodityPK">
          <el-col
            :span="8"
            :offset="0"
            v-for="(item, index) in goods.hot_goods"
            :key="index"
          >
            <div
              class="commodity_mess"
              @click="goHref('/CommodityDetails?id=' + item.goods_id)"
            >
              <img :src="item.original_img" />
              <div class="mess_name">
                <p class="mess_name_title two-letter">{{ item.goods_name }}</p>
                <div class="mess_name_bottom t9">
                  <div class="fl">
                    <span class="discount">¥{{ item.shop_price }}</span>
                    <span class="original">¥{{ item.market_price }}</span>
                  </div>
                  <p
                    class="blue"
                    @click.stop="funPk(item.goods_id, item.cat_id)"
                  >
                    加入pk
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        
      </div>
    </div>
    </el-row>
    <!-- 促销商品 -->
    <div class="pd">
      <div class="promotion fl">
        <span>促销商品</span>
        <p class="fr" @click.stop="goPage()">查看更多></p>
      </div>
    </div>
    <!-- 促销商品列表 -->
    <div class="pd">
      <div
        class="promotion_LunBo"
        id="classifyScroll"
      >
        <div class="classifys" :style="{ width: scrollerHeight }">
          <div
            class="promotion_item"
            v-for="(item, index) in goods.new_goods"
            :key="index"
          >
            <img
              :src="item.original_img"
              @click="goHref('/CommodityDetails?id=' + item.goods_id)"
            />
            <div class="mess_name i9">
              <p class="mess_name_title two-letter">{{ item.goods_name }}</p>
              <div class="mess_name_bottom t8">
                <div class="fl">
                  <span class="discount">¥{{ item.shop_price }}</span>
                  <span class="original">¥{{ item.market_price }}</span>
                </div>
                <p class="blue" @click="funPk(item.goods_id, item.cat_id)">
                  加入pk
                </p>
              </div>
            </div>
          </div>
        </div>
        <van-icon
          name="arrow"
          class="rightIcon"
          v-show="showRightIcon"
          @click="rightClick"
          v-if="yiru"
          :style="{ left: lookwidths - 85 + 'px' }"
        />
        <van-icon
          name="arrow-left"
          class="leftIcon"
          v-show="showLeftIcon"
          @click="leftClick"
          v-if="yiru"
        />
      </div>
    </div>
    <!-- 推荐商品 -->
    <el-row :gutter="20">
    <div class="pd">
      <div class="promotion fl">
        <span>推荐商品</span>
        <p class="fr" @click="goPage()">查看更多></p>
      </div>
    </div>
    <!-- 推荐商品列表 -->
    <div class="pd">
      <div class="recommend">
        
          <el-col
            :sm="6"
            :md="6"
            :lg="4"
            v-for="(item, index) in goods.recommend_goods"
            :key="index"
          >
            <div class="merchandiss">
              <img
                :src="item.original_img"
                @click="goHref('/CommodityDetails?id=' + item.goods_id)"
              />
              <div class="mess_name i9">
                <p class="mess_name_title two-letter">{{ item.goods_name }}</p>
                <div class="mess_name_bottom t8">
                  <span class="fl">¥{{ item.shop_price }}</span>
                  <p class="blue" @click="funPk(item.goods_id, item.cat_id)">
                    加入PK
                  </p>
                </div>
              </div>
            </div>
          </el-col>
        
      </div>
    </div>
    </el-row>
    <!-- 二级分类 -->

    <Bottom></Bottom>
    <Righter></Righter>
  </div>
</template>

<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import Tops from "../../components/Tops";
import Righter from "../../components/Righter";
import { Category, banner, Category_next, goods, addpk } from "../../api/goods";
export default {
  components: { Header, Bottom, Tops, Righter },
  data() {
    return {
      token: "",
      show: false,
      id: "", //分类id
      classifyList: [],
      imgList: [],
      nextList: [], //二级分类
      goods: [],
      show1: false, //购物车弹框
      border_blue: false,
      show2: false, //PK弹框
      border_blue1: false,
      checkArr: [],
      yiru: false,
      maxClickNum: 0, // 最大点击次数
      lastLeft: 0, // 上次滑动距离
      clickNum: 0,
      showRightIcon: true,
      lastItemOffsetLeft: "",
      jisuan: true,
      lookwidths: "",
      // time:0,
    };
  },
  computed: {
    showLeftIcon() {
      return this.clickNum > 0;
    },
    Instrumente() {
      if (this.goods.new_goods.length > 5) {
        return true;
      }
      return false;
    },
    scrollerHeight: function () {
      return (this.goods.new_goods.length) * 280 + "px";
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList(); //一级分类
    this.getBanner(); //轮播
    this.getGoods(); //展示的商品
    this.lookwidths = document.getElementById("classifyScroll").clientWidth;
    // console.log(this.lookwidths);
  },
  methods: {
    goPage() {
      console.log(23423)
      localStorage.setItem("name1", "仪器列表");
      this.$router.push({
        path: "./purchase",
        query: {
          type: 4,
        },
      });
    },
    computed() {
      this.rightClick();
    },
    getStatus() {
      this.show = true;
    },
    rightClick() {
      // 如果点击次数小于数组长度-1时，执行左滑动效果。
      if (this.clickNum < this.goods.new_goods.length) {
        // 获取当前元素宽度
        let width = document.querySelectorAll(".promotion_item")[this.clickNum]
          .offsetWidth;
        // 获取最后一个元素距离左侧的距离

        // console.log(this.lastItemOffsetLeft);
        // 获取可视区域宽度
        const lookWidth = document.getElementById("classifyScroll").clientWidth;
        this.lookwidths = lookWidth;
        // console.log(lookWidth);
        // 如果最后一个元素距离左侧的距离大于可视区域的宽度，表示最后一个元素没有出现，执行滚动效果

        if (this.lastItemOffsetLeft >= lookWidth) {
          // 公示：滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName("classifys")[0].style.marginLeft = `${
            -width + this.lastLeft
          }px`;
          this.lastLeft = -width + this.lastLeft;
          // 点击次数+1
          this.clickNum += 1;
          // 记录到最后一个元素出现在可视区域时，点击次数的最大值。用于后面点击左侧箭头时判断右侧箭头的显示
          this.maxClickNum = this.clickNum;
          this.lastItemOffsetLeft = this.lastItemOffsetLeft - 280;
          if (this.clickNum > this.goods.new_goods.length - 6) {
            this.showRightIcon = false;
            return;
          }
        }
        if (this.lookwidths - this.lastItemOffsetLeft < 280) {
          document.getElementsByClassName("classifys")[0].style.marginLeft = `${
            -(280 - (this.lookwidths - this.lastItemOffsetLeft)) + this.lastLeft
          }px`;
          this.lastLeft = -(this.lookwidths - this.lastItemOffsetLeft);
          this.clickNum += 1;
          // console.log(this.lastLeft);
        }
        this.showRightIcon = this.lastItemOffsetLeft > lookWidth + width;
      }
    },
    // 点击左箭头（右侧滚动）
    leftClick() {
      if (this.lookwidths - this.lastItemOffsetLeft < 280) {
        let width = document.querySelectorAll(".promotion_item")[
          this.clickNum - 1
        ].offsetWidth;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        // console.log(width + this.lastLeft);
        document.getElementsByClassName(
          "classifys"
        )[0].style.marginLeft = `0px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-1
        this.clickNum--;
        this.lastItemOffsetLeft = this.lastItemOffsetLeft + 280;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      } else if (this.clickNum > 0) {
        // 获取当前元素宽度
        let width = document.querySelectorAll(".promotion_item")[
          this.clickNum - 1
        ].offsetWidth;
        // 公示：滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        // console.log(width + this.lastLeft);
        document.getElementsByClassName("classifys")[0].style.marginLeft = `${
          this.lastLeft + width
        }px`;
        this.lastLeft = width + this.lastLeft;
        // 点击次数-1
        this.clickNum--;
        this.lastItemOffsetLeft = this.lastItemOffsetLeft + 280;
        // 如果点击次数小于最大点击次数，说明最后一个元素已经不在可是区域内了，显示右箭头
        if (this.clickNum < this.maxClickNum) {
          this.showRightIcon = true;
        }
      }
    },
    enter() {
      this.lookwidths = document.getElementById("classifyScroll").clientWidth;
      // console.log(this.lookwidths);
      if (this.jisuan) {
        this.jisuan = false;
        this.lastItemOffsetLeft = document.getElementsByClassName(
          "promotion_item"
        )[this.goods.new_goods.length - 1].offsetLeft;
      }
      // console.log(this.goods.new_goods.length - 1);
      // console.log(this.lastItemOffsetLeft);
      // console.log(document.getElementsByClassName("promotion_item"));
      // 获取可视区域宽度
      const lookWidth = document.getElementById("classifyScroll").clientWidth;
      // console.log(lookWidth);
      if (this.lastItemOffsetLeft >= lookWidth) {
        // console.log(656565);
        this.showRightIcon = true;
      }
      this.yiru = true;
    },
    leave() {
      this.yiru = false;
    },
    //加入PK
    funPk(id, cat_id) {
      addpk({ token: this.token, goods_id: id, cat_id: cat_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.$refs.mychild.getPklist();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        } else if (res.status == -100) {
          this.$message("您还未登录，请登录后再来");
          setTimeout(() => {
            this.$router.replace({ path: "/" });
          }, 1000);
        }
      });
    },
    //
    guan() {
      this.show = false;
      this.id = 0;
    },
    goto(id) {
      this.sou(id);
    },
    //点击二级分类搜索跳转列表
    sou(id) {
      if (this.token == undefined || this.token == null || this.token == "") {
        this.$message("您还未登录，请登录后再来");
        setTimeout(() => {
          this.$router.replace({ path: "/" });
        }, 1000);
      } else localStorage.setItem("cat_id", id);
      this.$router.push({
        path: "./purchase",
        query: {
          type: 3,
        },
      });
    },
    colse1() {
      this.show2 = false;
      this.border_blue1 = false;
    },
    showPK() {
      this.show2 = !this.show2;
      this.border_blue1 = !this.border_blue1;
    },
    showCart() {
      this.show1 = !this.show1;
      this.border_blue = !this.border_blue;
    },
    getGoods() {
      goods({}).then((res) => {
        this.goods = res.data;
      });
    },
    //二级分类
    getNextList() {
      Category_next({ parent_id: this.id }).then((res) => {
        this.nextList = res.result;
      });
    },
    //轮播
    getBanner() {
      banner({}).then((res) => {
        this.imgList = res.data;
      });
    },
    //关闭二级分类弹框
    close() {
      this.show = false;
    },
    //一级分类
    getList() {
      Category({}).then((res) => {
        this.classifyList = res.result;
      });
    },
    classifyClick(id) {
      this.id = id;
      this.show = true;
      this.getNextList();
    },
    PK() {
      return true;
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  .el-col-lg-4 {
    width: 20%;
  }
}
.complate {
  background-color: #fcfcfc;
  min-width: 1440px;
}
.seek {
  width: 100%;
  height: 80px;
  padding: 0 260px;
  display: flex;
  align-items: center;
  background: #ffffff;
  .img1 {
    width: 14px;
    height: 14px;
  }
  #logo {
    width: 157px;
    height: 33px;
    margin: 0 114px 0 35px;
  }
  input {
    width: 804px;
    height: 45px;
    background: #ffffff;
    border: 1px solid #408df7;
    opacity: 1;
    padding-left: 10px;
  }
  .ss {
    color: #ffffff;
    font-size: 16px;
    width: 120px;
    min-width: 50px;
    height: 45px;
    background: #408df7;
    border: 1px solid #408df7;
    opacity: 1;
    margin-right: 30px;
  }
  .gwc,
  .cp {
    width: 160px;
    min-width: 90px;
    height: 45px;
    background: #f9f9f9;
    border: 1px solid #eeeeee;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    div {
      width: 37px;
      height: 20px;
      background: #ff0000;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 11px;
      position: absolute;
      right: 0px;
      top: -10px;
      text-align: center;
      span {
        width: 16px;
        height: 19px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #ffffff;
        opacity: 1;
      }
    }
    img {
      width: 19px;
      height: 18px;
      margin-right: 9px;
    }
    span {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .gwc {
    margin-right: 20px;
  }
}
.original {
  font-size: 12px !important;
  color: #cccccc !important;
  margin-left: 10px;
  text-decoration: line-through;
}
.classify {
  width: 100%;
  height: auto;
  margin: 20px 0 40px;
  ul {
    width: 100%;
    background: #ffffff;
    li {
      width: 100%;
      height: 45px;
      padding: 0 6%;
      line-height: 45px;
      p {
        width: 90%;
        font-size: 16px;
        font-weight: 400;
        float: left;
      }
      img {
        float: right;
        width: 8px;
        height: 15px;
        margin-top: 15px;
      }
    }
    li:hover {
      cursor: pointer;
    }
  }
}
.classifyClick {
  height: 45px;
  background: #e6e6e6;

  p {
    color: #408df7;
  }
}
.classifyNotClick {
  white-space: nowrap;
  height: 45px;
  background: #ffffff;
  p {
    color: #333333;
  }
}
.commodity_mess:last-of-type {
  margin: 0 !important;
}
.commodityPK {
  margin-top: 30px;
  height: 200px;
  width: 100%;
  .commodity_mess {
    position: relative;
    float: left;
    width: 100%;
    // margin-right: 25px;
    height: 160px;
    padding: 20px;
    background: #ffffff;
    img {
      float: left;
      // width: 120px;
      // height: 120px;
      width: 40%;
      height: 120px;
      // margin-right: 30px !important;
    }
  }
}
.promotion {
  width: 100%;
  height: 40px;
  margin: 42px 0 32px 0;
  span {
    font-size: 30px;
    font-weight: bold;
    line-height: 26px;
    color: #333333;
    border-left: 7px solid #408df7;
    padding-left: 20px;
  }
  p {
    cursor: pointer;
    font-size: 16px;
    color: #333333;
  }
}
.promotion_LunBo {
  width: 100%;
  height: 400px;
  background-color: #ffffff;
  position: relative;
  .promotion_item {
    float: left;
    padding: 18px;
    width: 280px;
    height: 360px;
    border-left: 1px solid #eeeeee;
    background: #ffffff;
    position: relative;
    img {
      width: 100%;
      height: 220px;
    }
  }
}
.i9 {
  width: 100% !important;
}
.mess_name {
  width: 60%;
  float: left;
  padding-left: 10px;
  p {
    width: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
  }
  .mess_name_bottom {
    // position: absolute;
    // bottom: 30px;
    span {
      font-size: 16px;
      color: #ff0000;
      margin-right: 11px;
    }

    p {
      float: right;
      width: 49px;
      height: 20px;
      border: 1px solid #bbbbbb;
      font-size: 11px;
      border: 1px solid #bbbbbb;
      color: #999999;
      text-align: center;
      cursor: pointer;
      :active {
        color: red;
      }
    }
    .anNiu {
      border: 0px;
      background: #408df7;
      font-size: 11px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.XZ {
  position: absolute;
  top: 84px;
  left: 0;
  width: 84px;
  height: 84px;
  background: rgba($color: #000000, $alpha: 0.3);
}
.XY {
  position: absolute;
  top: 84px;
  right: 0px;
  width: 84px;
  height: 84px;
  background: rgba($color: #000000, $alpha: 0.3);
}
.XZ:hover {
  background: rgba($color: #000000, $alpha: 0.5);
}
.XY:hover {
  background: rgba($color: #000000, $alpha: 0.5);
}
.blue:hover {
  background: #408df7;
  color: #ffffff !important;
  border: none !important;
}
.recommend {
  width: 100%;
  .merchandiss {
    position: relative;
    // padding: 13px 18px 0;
    height: 350px;
    background: #ffffff;
    float: left;
    // width: 19%;
    // margin-right: 1%;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 230px;
      object-fit: cover;
      margin: 0 auto 5px;
    }
  }
}

.pop {
  z-index: 200000;
  position: absolute;
  width: 100%;
  height: 500px;
  background: #ffffff;
  top: 0;
  left: 0;
  padding: 20px 55px;
  overflow-y: auto;
  .pop_img1 {
    width: 14px !important;
    height: 14px !important;
  }
}
.banner {
  height: 500px;
  z-index: 1000000;
  /deep/ img {
    width: 100%;
    height: 500px;
    margin-left: 10px;
    object-fit: cover;
  }
  /deep/.el-carousel__arrow {
    width: 84px !important;
    height: 84px !important;
    background: #000000 !important;
    opacity: 0.45 !important;
    font-size: 40px !important;
    border-radius: 0 !important;
  }
  /deep/ .el-carousel__arrow--left {
    left: 20px !important;
  }
}
.pop_list {
  border-bottom: 1px solid #eeeeee;
  float: left;
  width: 100%;
  padding: 24px 0;

  .pop_list_content {
    display: inline-block;
    padding-left: 10px;
    width: 80%;
    margin-top: -20px;
    span {
      font-size: 16px;
      color: #666666;
      margin: 0 24px 10px 0;
      cursor: pointer;
    }
  }
}
.pop_list_text {
  p {
    max-width: 20%;
    text-align: right;
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }
}
.pop_img1 {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.t9 {
  // width: 60%;
  position: absolute;
  bottom: 30px;
}
.t8 {
  width: 90%;
  position: absolute;
  bottom: 10px;
  p {
    // margin-right: 40px;
  }
}
.pop2 {
  height: 400px;
  width: 260px;
  position: absolute;
  top: 210px;
  right: 285px;
  // background: #cccccc;
  z-index: 10;
}
.r7 {
  border: 1px solid #408df7 !important;
}
.pop2_list_box {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
  background: #ffffff;
}
.pop2_list {
  width: 100%;
  position: relative;
  height: 80px;
  border-bottom: 1px solid #eeeeee;
  img {
    width: 60px;
    height: 60px;
    float: left;
  }
}
.pop2_list_right {
  margin-left: 10px;
  span {
    font-size: 12px;
    color: #333333;
  }
}
.pop2_list_num {
  font-size: 12px;
  width: 70%;
  position: absolute;
  top: 45px;
  p {
    color: #ff0000;
    float: left;
  }
  div {
    color: #ffffff;
    float: right;
    cursor: pointer;
  }
}
.pop2_bottom {
  // position: absolute;
  // bottom: 0;
  padding: 18px 20px 18px 10px;
  width: 100%;
  height: 75px;
  background: #f3f3f3;
  span {
    float: right;
    color: #ffffff;
    font-size: 14px;
    width: 110px;
    height: 40px;
    background: #408df7;
    line-height: 40px;
    text-align: center;
  }
}
.pop_bottom_left {
  float: left;
  div {
    font-size: 14px;
    color: #333333;
    width: 100%;
    // max-width: 150px;
    p {
      float: left;
      color: #408df7;
      font-weight: bold;
    }
    ol {
      float: right;
    }
  }
}
// pk弹框
.pop3 {
  z-index: 10;
  position: absolute;
  top: 215px;
  width: 1400px;
  height: 200px;
  background: #ffffff;
  padding: 30px 0;
}
.close {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.pop3_left {
  float: left;
  width: 1200px;
  height: 140px;
}
.pop3_list_box {
  float: left;
  width: 33%;
  height: 100%;
  padding: 20px;
  border-right: 1px solid #eeeeee;
  position: relative;
  img {
    width: 100px;
    height: 100px;
  }
  div {
    margin-left: 15px;
    p {
      margin-top: 10px;
      font-size: 14px;
      color: #333333;
    }
    span {
      font-size: 12px;
      color: #cccccc;
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
  }
}
.pop_right {
  text-align: center;
  width: 200px;
  height: 140px;
  div {
    width: 80px;
    height: 35px;
    background: #408df7;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin: 40px auto 20px;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    color: #408df7;
    cursor: pointer;
  }
}
#classifyScroll {
  overflow-x: scroll;
  transition: all 0.3s;
  &.active {
    transition: all 0.3s;
    width: 90%;
    margin: 0 auto;
  }
  .classifys {
    position: relative;
    overflow: hidden;
    float: left;
    // margin-top: -65px;
    // 注意一下，这里没有用position: relative，，而图标用了position:absolute。因为这个东西是准备做组件的，我把相对定位写在父级的css标签上了。
    transition: all 0.3s;
    .rightIcon {
      position: absolute;
      right: 0;
      bottom: 47%;
      left: 1500px !important;
      transform: translate(0, 50%);
      z-index: 9;
    }
    .leftIcon {
      position: absolute;
      left: px(-5);
      bottom: 47%;
      transform: translate(0, 50%);
      z-index: 9;
    }
    &-item {
      font-size: px(11);
      font-weight: 400;
      color: #ffffff;
      line-height: px(16);
      // margin-right: px(52);
      padding-right: px(52);
      white-space: nowrap;
    }
  }
}
// .van-icon-arrow-left::before {
//   width: 85px;
//   height: 85px;
//   background: rgba(0, 0, 0, 0.5);
//   position: absolute;
//   left: 0;
//   top: 80px;
//   font-size: 40px;
//   color: #ffffff;
//   text-align: center;
//   line-height: 80px;
// }
// .van-icon-arrow::before {
//   width: 85px;
//   height: 85px;
//   background: rgba(0, 0, 0, 0.5);
//   position: absolute;
//   left: 1300px;
//   top: 80px;
//   font-size: 40px;
//   color: #ffffff;
//   text-align: center;
//   line-height: 80px;
// }
// /deep/.van-icon {
//   position: absolute;
//   z-index: 10;
// }
.lunbot {
  width: 100%;
  z-index: 1000000;
}
.van-icon-arrow {
  position: absolute;
  z-index: 10;
  width: 85px;
  height: 85px;
  top: 80px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
}
.van-icon-arrow-left {
  width: 85px;
  height: 85px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 80px;
  font-size: 40px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  z-index: 10;
}
/deep/#yincang {
  display: none !important;
}
</style>
