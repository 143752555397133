<template>
  <div class="container">
    <Top></Top>
    <div class="info_box pd_space_260">
      <el-row :gutter="20">
        <el-col :span="24"
          ><div class="grid-content bg-purple">
            <div class="info-tab">
              <el-row :gutter="20">
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    <p
                      @click="chose_list(1)"
                      :class="[tabindex == 1 ? 'tab-active' : '']"
                    >
                      <a> 新闻资讯</a>
                    </p>
                  </div></el-col
                >
                <el-col :span="4"
                  ><div class="grid-content bg-purple">
                    <p
                      @click="chose_list(2)"
                      :class="[tabindex == 2 ? 'tab-active' : '']"
                    >
                      <a> 仪器知识</a>
                    </p>
                  </div></el-col
                >

                <el-col :span="5"
                  ><div class="grid-content bg-purple">
                    <p @click="goHref('/industry')">
                      <a>行业招投标信息</a>
                    </p>
                  </div></el-col
                >
              </el-row>
            </div>
          </div></el-col
        >
      </el-row>
    </div>
    <el-row>
      <el-col :span="24"
        ><div class="grid-content bg-purple">
          <div class="swiper-box pd_space_260">
            <el-carousel height="418px" :autoplay="true">
              <el-carousel-item>
                <img
                  class="swiper-img"
                  src="../../assets/img/gywm_gsjj_img.png"
                />
              </el-carousel-item>
              <el-carousel-item>
                <img
                  class="swiper-img"
                  src="../../assets/img/gywm_gsjj_img.png"
                />
              </el-carousel-item>
            </el-carousel>
          </div></div
      ></el-col>
    </el-row>

    <div v-show="tabindex == 1">
      <div class="categoy-box pd_space_260 clear">
        <p
          v-for="(item, index) in newList"
          :key="index"
          @click="chose(item.cat_id)"
          :class="[newId == item.cat_id ? 'active' : '']"
        >
          <span>{{ item.cat_name }}</span>
        </p>
      </div>
      <div class="content-box pd_space_260 clear">
        <div class="m1" v-if="new_classifyList.article.length < 1">
          暂无数据
        </div>
        <el-row :gutter="20">
          <el-col :span="8"
              class="content-item"
              v-for="(item, index) in new_classifyList.article"
              :key="index"
              @click.native="
                goHref(
                  '/newsDetail?isvideo=' +
                    item.type +
                    '&id=' +
                    item.article_id +
                    '&types=' +
                    1
                )
              "
            >
              <img :src="item.thumb" alt="" />
              <div class="one-letter item-title">{{ item.title }}</div>
              <div class="item-time-category">
                <span class="fl">{{ item.add_time }}</span>
                <span class="fr" v-if="item.type == 1">新闻</span>
                <span class="fr" v-if="item.type == 2">视频</span>
              </div>
          </el-col>
        </el-row>
      </div>
      <div class="page pd_space_260 clear">
        <van-pagination
          v-model="p"
          :total-items="new_classifyList.count"
          class="block fl"
          @change="getNewList()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <div v-show="tabindex == 2">
      <div class="categoy-box pd_space_260 clear">
        <p
          v-for="(item, index) in newList"
          :key="index"
          @click="chose(item.cat_id)"
          :class="[newId == item.cat_id ? 'active' : '']"
        >
          {{ item.cat_name }}
        </p>
      </div>
      <div class="content-box pd_space_260 clear">
        <div class="m1" v-if="new_classifyList.article.length < 1">
          暂无数据
        </div>
        <el-row :gutter="20">
          <el-col :span="8"
              class="content-item"
              v-for="(item, index) in new_classifyList.article"
              :key="index"
              @click.native="
                goHref(
                  '/newsDetail?isvideo=' +
                    1 +
                    '&id=' +
                    item.article_id +
                    '&types=' +
                    2
                )
              "
            >
              <img :src="item.thumb" alt="" />
              <div class="one-letter item-title">{{ item.title }}</div>
              <div class="item-time-category">
                <span class="fl">{{ item.add_time }}</span>
                <span class="fr">新闻</span>
              </div>
          </el-col>
        </el-row>
      </div>
      <div class="page pd_space_260 clear">
        <van-pagination
          v-model="p"
          :total-items="new_classifyList.count"
          class="block fl"
          @change="getNewList()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>
<script>
import Top from "../../components/Top";
import Bottom from "../../components/Bottom";
import { new_classify, new_classifyList } from "../../api/index";
export default {
  components: { Top, Bottom },
  data() {
    return {
      tabindex: "",
      newList: [],
      type: "",
      newId: "",
      new_classifyList: [],
      p: 1,
    };
  },
  created() {
    this.tabindex = this.$route.query.tabindex;
    this.getnew_classify(); //仪器知识分类
  },
  methods: {
    getNewList() {
      if (this.tabindex == 1) {
        this.type = 2;
      } else if (this.tabindex == 2) {
        this.type = 1;
      }
      new_classifyList({ cat_id: this.newId, type: this.type, p: this.p }).then(
        (res) => {
          if (res.status == 1) {
            this.new_classifyList = res.data;
          }
        }
      );
    },
    chose_list(index) {
      this.tabindex = index;
      this.getnew_classify(); //仪器知识分类
    },
    gotopage() {
      this.getNewList();
    },
    chose(id) {
      this.newId = id;
      this.getNewList();
    },
    //仪器知识分类
    getnew_classify() {
      if (this.tabindex == 1) {
        this.type = 2;
      } else if (this.tabindex == 2) {
        this.type = 1;
      }
      new_classify({ type: this.type }).then((res) => {
        if (res.status == 1) {
          this.newList = res.data;
          this.newId = res.data[0].cat_id;
          this.getNewList();
        }
      });
    },
    goHref(path) {
      console.log(1111);
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  min-width: 1440px;
  background-color: #fcfcfc;
  
}
.info_box {
  width: 100%;
  height: 89px;
  background: #ffffff;
}
.info-tab {
  height: 89px;
  padding-top: 30px;
  border-top: 1px solid #eeeeee;
  p {
    color: #333333;
    font-size: 20px;
    float: left;
    cursor: pointer;
  }
  .tab-active {
    font-size: 25px;
    line-height: 33px;
    font-weight: bold;

    a {
      border-bottom: 4px solid #408df7;
    }
  }
}
.swiper-box {
  width: 100%;
  height: 418px;
  position: relative;
  .swiper-img {
    width: 100%;
    height: 418px;
    object-fit: cover;
  }
}
.categoy-box {
  height: 75px;
  margin: 15px auto;
  p {
    float: left;
    background-color: #ffffff;
    font-size: 16px;
    color: #333333;
    border: 1px solid #eeeeee;
    padding: 12px 25px;
    text-align: center;
    border-radius: 5px;
    margin: 15px 18px 15px;
    margin-left: 0;
    cursor: pointer;
  }
  .active {
    border-color: #408df7;
    background-color: #408df7;
    color: #ffffff;
  }
}
.content-box {
  .content-item {
    // width: 32%;
    height: 380px;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 5px;
    float: left;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 270px;
      object-fit: cover;
    }
    .item-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 26px;
      color: #333333;
      padding: 15px 20px;
    }
    .item-time-category {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #999999;
      padding: 15px 20px;
    }
  }
  .active {
    border-bottom: 4px solid #408df7;
    .item-title {
      color: #408df7;
    }
  }
  .content-item:nth-child(3n + 1) {
    margin-left: 0;
  }
}
.page {
  height: 75px;
  display: flex;
  justify-content: center;
  span {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    color: #666666;
    float: left;
    border: 1px solid #cccccc;
    border-radius: 2px;
    margin: auto 5px;
  }
  .active {
    border: 1px solid #017eff;
  }
}
</style>
