<template>
  <div class="complate">
    <Header></Header>
    <!-- 搜索 -->
    <el-row class="seek">
      <el-col :span="6">
        <img class="img1 fl" src="../../assets/img/sc_ss_yqgj.png" />
      </el-col>
      <el-col :span="10">
        <div class="seek_input fl">
          <input type="text" placeholder="请输入搜索关键词" v-model="content" />
          <p class="fl" @click="getSou">搜索</p>
        </div>
      </el-col>
      <el-col :span="8">
        <el-row :gutter="20">
          <el-col :span="12">
            <div
              class="gwc fr"
              @click="showCart()"
              :class="[border_blue ? 'r7' : '']"
            >
              <img src="../../assets/img/sc_top_gwc.png" alt="" />
              <span>购物车</span>
              <div>
                <span>{{ Cartlist.number ? Cartlist.number : 0 }}</span>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="cp fl"
              @click="showPK()"
              :class="[border_blue1 ? 'r7' : '']"
            >
              <img src="../../assets/img/sc_top_pk.png" alt="" />
              <span>加入PK</span>
              <div>
                <span v-if="pkList.data">{{
                  pkList.data.length ? pkList.data.length : 0
                }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <!-- </div> -->
      </el-col>
      <!-- Pk弹框 -->
      <div v-show="show2" class="pop3">
        <div class="pop3_left">
          <div
            class="pop3_list_box"
            v-for="(item, index) in pkList.data"
            :key="index"
          >
            <img class="fl" :src="item.original_img" />
            <div class="fl">
              <p class="two-letter">{{ item.goods_name }}</p>
              <span @click="delPK(item.id)">删除</span>
            </div>
          </div>
        </div>
        <div class="pop_right fr">
          <div @click="duiBI(pkList.id)">对比</div>
          <p @click="qingkong()">清空对比栏</p>
        </div>
        <div class="close" @click="colse1">隐藏</div>
      </div>
    </el-row>
    <!-- 购车  弹框 -->
    <div v-show="show1" class="pop2">
      <div class="pop2_list_box">
        <div
          class="pop2_list"
          v-for="(item, index) in Cartlist.cat"
          :key="index"
        >
          <img :src="item.image" />
          <div class="pop2_list_right fl">
            <span class="two-lettef two-letter">{{ item.goods_name }}</span>
            <div class="pop2_list_num">
              <p>¥{{ item.goods_price }}</p>
              <span>x{{ item.goods_num }}</span>
              <div @click="delCart(item.id)">删除</div>
            </div>
          </div>
        </div>
      </div>
      <div class="pop2_bottom">
        <div class="pop2_bottom_list">
          <div class="pop_bottom_left">
            <div>
              <p>{{ Cartlist.number ? Cartlist.number : 0 }}</p>
              件商品
            </div>
            <div>
              共计：¥
              <ol>
                0.0
              </ol>
            </div>
          </div>
          <span @click="goHref('/shopCart')">去购物车结算</span>
        </div>
      </div>
    </div>


    <div v-if="zuobiao == 1" class="block minh">
      <span class="title" v-if="type == 2 || type == 3">{{ content }}</span>
      <span class="title" v-if="type == 4">{{ name }}</span>
      <div class="flagstaff"></div>
      <!-- type==2是搜索-->
      <div class="bbb" v-if="type == 2 || type == 3">
        <div class="m1" v-if="list.count < 1">暂无数据</div>
        <div class="ccc" v-for="(item, index) in list.data" :key="index">
          <img
            :src="item.original_img"
            @click="goHref('/CommodityDetails?id=' + item.goods_id)"
          />
          <p class="abc_title two-letter">
            {{ item.goods_name }}
          </p>
          <div class="abc_price">
            <div class="money">
              <p>¥{{ item.shop_price }}</p>
              <span class="blue" @click="funPk(item.goods_id, item.cat_id)"
                >加入pk</span
              >
            </div>
            <div class="shopping_trolley" @click="add(item.goods_id)">
              <button>加入购物车</button>
            </div>
          </div>
        </div>
      </div>
      <div class="bbb" v-else>
        <div class="m1" v-if="list.data.length < 1">暂无数据</div>
        <div class="ccc" v-for="(item, index) in list.data" :key="index">
          <img
            :src="item.original_img"
            @click="goHref('/CommodityDetails?id=' + item.goods_id)"
          />
          <p class="abc_title two-letter">
            {{ item.goods_name }}
          </p>
          <div class="abc_price">
            <div class="money">
              <p>¥{{ item.shop_price }}</p>
              <span class="blue" @click="funPk(item.goods_id, item.cat_id)"
                >加入pk</span
              >
            </div>
            <div class="shopping_trolley" @click="add(item.goods_id)">
              <button>加入购物车</button>
            </div>
          </div>
        </div>
      </div>
   
      <div class="page_box fl" v-if="type == 3">
        <van-pagination
          v-model="p"
          :total-items="list.data.count"
          @change="getSouId()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
      <div class="page_box fl" v-if="type == 4">
        <van-pagination
          v-model="p"
          :total-items="list.count"
          @change="getGoodsList()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>

    <div v-if="zuobiao == 2" class="content-box pd_space_260 clear minh">
      <div class="m1" v-if="list.count < 1">暂无数据</div>
      <el-row :gutter="20">
        <el-col
          :span="8"
          class="content-item"
          v-for="(item, index) in list.data"
          :key="index"
          @click.native="
            goHref(
              '/newsDetail?isvideo=' +
                item.type +
                '&id=' +
                item.article_id +
                '&types=' +
                1
            )
          "
        >
          <img :src="item.thumb" alt="" />
          <div class="one-letter item-title">{{ item.title }}</div>
          <div class="item-time-category">
            <span class="fl">{{ item.add_time }}</span>
            <span class="fr" v-if="item.type == 1">新闻</span>
            <span class="fr" v-if="item.type == 2">视频</span>
          </div>
        </el-col>
      </el-row>
      
    </div>

    <div v-if="zuobiao == 3" class="information_box pd_space minh">
      <div class="content_title">
        <div class="title_left fl">
          <p>行业招投标信息</p>
          <span>Industry bidding information</span>
        </div>
        <div class="title_right fr" @click="goHref('/industry')">
          <span>查看更多></span>
        </div>
      </div>
      <div class="information_content clear">
        <el-row :gutter="50">
          <el-col
            :span="12"
            :offset="0"
            v-for="(item, index) in list.data"
            :key="index"
          >
            <div class="information_item" @click="showPop(item.id)">
              <div class="information_item_top">
                <div>
                  <img src="../../assets/img/icon_pdf_s.png" alt="" />
                  <p class="two-letter">{{ item.title }}</p>
                </div>
                <!-- <a href="javascript:;">下载</a> -->
              </div>
              <div class="information_item_bottom clear">
                <span>{{ item.publishdate }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="page_box fl">
        <van-pagination
          v-model="p"
          :total-items="list.count"
          @change="getSou()"
        >
          <template #prev-text>
            <van-icon name="arrow-left" />
          </template>
          <template #next-text>
            <van-icon name="arrow" />
          </template>
          <template #page="{ text }">{{ text }}</template>
        </van-pagination>
      </div>
    </div>
    <Righter></Righter>
    <footer>
      <Bottom></Bottom>
    </footer>
    <van-popup v-model="show3" class="pop">
      <div class="pop_top">
        <div class="pop_left">
          <img class="fl" src="../../assets/img/icon_pdf_s.png" />
          <p class="one-letter fl">{{ list1.title }}</p>
        </div>
        <img
          class="img2 fr"
          @click="show3 = false"
          src="../../assets/img/pop_but_gb.png"
        />
      </div>
      <div class="pop_content">{{ list1.source }}</div>
      <div v-html="list1.content" class="pop_bottom"></div>
    </van-popup>
  </div>
</template>
<script>
import Header from "../../components/Header";
import Bottom from "../../components/Bottom";
import Righter from "../../components/Righter";
import {
  List,
  Cart_List,
  delCart,
  pkList,
  delpk,
  removePk,
  addpk,
  addCart,
  moreList,
} from "../../api/goods";
import { bidinfo } from "../../api/index";
export default {
  components: { Header, Bottom, Righter },
  data() {
    return {
      p: 1,
      show: false,
      show1: false, //购物车弹框
      border_blue: false,
      show2: false, //PK弹框
      border_blue1: false,
      content: "",
      Cartlist: [], //购物车
      pkList: [],
      list: [],
      type: "",
      id: "",
      zuobiao: 1,
      show3: false,
      list1: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.name = localStorage.getItem("name1");
    this.id = localStorage.getItem("cat_id");
    this.type = this.$route.query.type;
    this.getList(); //购物车列表
    this.getPklist(); //PK列表
    // if (this.type == 2) {
    //   this.content = localStorage.getItem("name");
    //   this.getSou(); //  搜索 一进来就是传过来的要搜索的文字
    // } else if (this.type == 3) {
    //   this.content = localStorage.getItem("name");
    //   this.getSouId();
    // } else {
    //   this.getGoodsList(); //全部商品列表
    // }
    // console.log(this.$route.params.text)
    if (this.$route.params.text) {
      localStorage.setItem("text", this.$route.params.text);
      this.$route.params.text;
    }
    this.content = localStorage.getItem("text");
    if (this.$route.params.index) {
      localStorage.setItem("zuobiao", this.$route.params.index);
      this.$route.params.index;
    }
    this.zuobiao = localStorage.getItem("zuobiao");
    console.log(this.zuobiao);
    this.getSou();
  },
  methods: {
    //分类跳转列表
    getSouId() {
      List({
        token: this.token,
        type: this.zuobiao,
        cat_id: this.id,
        p: 1,
      }).then((res) => {
        if (res.status == 1) {
          this.type = 3;
          this.content = res.data.cat_name;
          this.list = res.data;
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          // 实现滚动效果
          const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 1);
        }
      });
    },
    //搜索
    getSou() {
      List({
        token: this.token,
        type: this.zuobiao,
        name: this.content,
        p: this.p,
      }).then((res) => {
        console.log(res);
        if (res.status == 1) {
          this.type = 2;
          this.list = res.data;
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          // 实现滚动效果
          const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 1);
        }
      });
    },
    //全部商品列表
    getGoodsList() {
      moreList({ p: this.p }).then((res) => {
        if (res.status == 1) {
          this.list = res.data;
          let top =
            document.documentElement.scrollTop || document.body.scrollTop;
          // 实现滚动效果
          const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 1);
        }
      });
    },
    duiBI(id) {
      if (id == "") {
        this.$message("暂无对比商品");
      } else {
        this.$router.push({
          path: "/Comparison",
          query: {
            id: id,
          },
        });
      }
    },
    add(id) {
      addCart({
        token: this.token,
        goods_id: id,
        goods_num: 1,
      }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getList(); //购物车列表
          this.$refs.mychild.getList();
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        } else if (res.status == -1) {
          this.$message.error(res.msg);
        }
      });
    },
    //加入PK
    funPk(id, cat_id) {
      addpk({ token: this.token, goods_id: id, cat_id: cat_id }).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.info,
            type: "success",
          });
          this.getPklist(); //PK列表
          this.$refs.mychild.getPklist();
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    qingkong() {
      let that = this;
      this.$confirm("即将清空, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已清空!",
          });
          that.qingK();
          setTimeout(() => {
            that.getPklist();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消清空",
          });
        });
    },
    qingK() {
      removePk({ token: this.token }).then((res) => {
        if (res.status == 1) {
          // console.log(0)
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //PK列表
    getPklist() {
      pkList({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.pkList = res.data;
        }
      });
    },
    //删除pk
    delPK(id) {
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });

          that.getDel(id);
          setTimeout(() => {
            that.getPklist();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getDel(id) {
      delpk({ id: id }).then((res) => {
        if (res.status == 1) {
          // this.$message({
          //   message: res.info,
          //   type: "success",
          // });
        } else if (res.status == 0) {
          this.$message.error(res.info);
        }
      });
    },
    //删除购物车列表
    delCart(id) {
      let that = this;
      this.$confirm("即将删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          that.$message({
            showClose: true,
            type: "success",
            message: "已删除!",
          });

          that.delCartList(id);
          setTimeout(() => {
            that.getList();
          }, 100);
        })
        .catch(() => {
          that.$message({
            showClose: true,
            type: "info",
            message: "已取消删除",
          });
        });
    },
    delCartList(id) {
      delCart({ ids: id }).then((res) => {});
    },
    //购物车列表
    getList() {
      Cart_List({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.Cartlist = res.data;
        }
      });
    },

    //下一页
    gotopage() {
      this.sou();
    },

    colse1() {
      this.show2 = false;
      this.border_blue1 = false;
    },
    showPK() {
      this.show1 = false;
      this.border_blue = false;
      this.show2 = !this.show2;
      this.border_blue1 = !this.border_blue1;
    },
    showCart() {
      this.show2 = false;
      this.border_blue1 = false;
      this.show1 = !this.show1;
      this.border_blue = !this.border_blue;
    },
    goHref(path) {
      this.$router.push(path);
    },
    showPop(id) {
      bidinfo({ id: id }).then((res) => {
        if (res.status == 1) {
          this.show3 = true;
          this.list1 = res.data;
        }
      });
    },
  },

  mounted() {},
};
</script>
<style lang="scss" scoped>
.complate {
  background-color: #fcfcfc;
}
.place {
  float: left;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  padding: 0 10%;
  height: 50px;
  background: #f3f3f3;
  line-height: 50px;
  span {
    cursor: pointer;
    margin-left: 5px;
  }
}
.block {
  margin-top: 48px;
  padding: 0 10%;
  width: 100%;
  text-align: center;
  background-image: url(../../assets/img/splb_top_bg.png);
  background-repeat: no-repeat;
  background-size: 100% 300px;
  padding-top: 45px;
}
.flagstaff {
  width: 55px;
  height: 7px;
  background: #408df7;
  opacity: 0.26;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 22px;
  margin-bottom: 27px;
}
.title {
  font-size: 42px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin: 0 auto;
  margin-top: 45px;
}
.ccc {
  width: 24%;
  margin-right: 10px;
  float: left;
  background: #ffffff;
  margin-bottom: 10px;
  padding: 20px;
  img {
    width: 90%;
    height: 300px;
    margin: 0 auto;
  }
  .abc_title {
    text-align: left;
    height: 44px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 22px;
    color: #333333;
    opacity: 1;
    margin: 17px auto;
  }
  .abc_price {
    width: 100%;
    .money,
    .shopping_trolley {
      width: 50%;
      float: left;
    }
    .shopping_trolley {
      padding: 0 3%;
      button {
        width: 120px;
        height: 49px;
        background: #408df7;
        color: #ffffff;
        margin-bottom: 26px;
      }
    }
    .money {
      padding: 0 3%;
      p {
        text-align: left;
        width: 75px;
        height: 21px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 26px;
        color: #ff0000;
        opacity: 1;
      }
      span {
        float: left;
        width: 49px;
        height: 20px;
        border: 1px solid #bbbbbb;
        opacity: 1;
        font-size: 11px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-top: 8px;
        cursor: pointer;
      }
    }
  }
}
.bbb {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 400px;
}
.ccc {
  width: 24%;
  float: left;
}
.t8 {
  bottom: 10px;
  margin: 50px 40%;
}

.seek {
  width: 100%;
  height: 80px;
  padding: 0 10%;
  background: #ffffff;
  .gwc,
  .cp {
    width: 120px;
    height: 45px;
    background: #f9f9f9;
    border: 1px solid #eeeeee;
    position: relative;
    margin-top: 20px;
    padding: 12px 0 0 20px;
    cursor: pointer;
    div {
      width: 37px;
      height: 20px;
      background: #ff0000;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 11px;
      position: absolute;
      right: 0px;
      top: -10px;
      text-align: center;
      span {
        width: 16px;
        height: 19px;
        font-size: 14px;
        font-weight: 400;
        margin-left: 8px;
        color: #ffffff;
      }
    }
    img {
      width: 19px;
      height: 18px;
      margin: 2px 8px 0 0;
      float: left;
    }
    span {
      font-size: 16px;
      float: left;
      font-weight: 400;
      color: #333333;
    }
  }
  .gwc {
    margin-right: 20px;
  }
}
.seek_input {
  width: 100%;
  height: 100%;
  padding-top: 20px;
  margin-right: 20px;
  input {
    width: 80%;
    height: 45px;
    background: #ffffff;
    border: 1px solid #408df7;
    padding-left: 10px;
    float: left;
  }
  p {
    float: left;
    color: #ffffff;
    font-size: 16px;
    width: 20%;
    height: 45px;
    background: #408df7;
    border: 1px solid #408df7;
    text-align: center;
    line-height: 45px;
    cursor: pointer;
  }
}
.img1 {
  width: 150px;
  height: 34px;
  margin-top: 24px;
  // padding: 24px 100px 0 24px;
}
.pop {
  z-index: 2;
  position: absolute;
  width: 1151px;
  height: 500px;
  background: #ffffff;
  top: 220px;
  left: 508px;
  padding: 20px 55px;
  overflow-y: auto;
}
.pop_list {
  border-bottom: 1px solid #eeeeee;
  float: left;
  width: 100%;
  padding: 24px 0;

  .pop_list_content {
    display: inline-block;
    padding-left: 10px;
    width: 80%;
    margin-top: -20px;
    span {
      font-size: 16px;
      color: #666666;
      margin: 0 24px 10px 0;
    }
  }
}
.pop_list_text {
  p {
    max-width: 200px;
    text-align: right;
    font-size: 18px;
    color: #333333;
    font-weight: bold;
  }
}
.pop_img1 {
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px;
}
.pop2 {
  height: 400px;
  width: 260px;
  position: absolute;
  top: 200px;
  right: 335px;
  z-index: 10;
}
.r7 {
  border: 1px solid #408df7 !important;
}
.pop2_list_box {
  height: 300px;
  padding: 10px;
  overflow-y: auto;
  background: #ffffff;
}
.pop2_list {
  width: 100%;
  position: relative;
  height: 80px;
  border-bottom: 1px solid #eeeeee;
  img {
    width: 60px;
    height: 60px;
    float: left;
  }
}
.pop2_list_right {
  max-width: 150px;
  margin-left: 10px;
  span {
    font-size: 12px;
    color: #333333;
  }
}
.pop2_list_num {
  font-size: 12px;
  width: 70%;
  position: absolute;
  top: 45px;
  p {
    color: #ff0000;
    float: left;
  }
  span {
    margin-left: 10px;
  }
  div {
    color: #cccccc;
    float: right;
    cursor: pointer;
  }
}
.pop2_bottom {
  // position: absolute;
  // bottom: 0;
  padding: 18px 20px 18px 10px;
  width: 100%;
  height: 75px;
  background: #f3f3f3;
  span {
    float: right;
    color: #ffffff;
    font-size: 14px;
    width: 110px;
    height: 40px;
    background: #408df7;
    line-height: 40px;
    text-align: center;
  }
}
.pop_bottom_left {
  float: left;
  div {
    font-size: 14px;
    color: #333333;
    width: 100%;
    // max-width: 150px;
    p {
      float: left;
      color: #408df7;
      font-weight: bold;
    }
    ol {
      float: right;
    }
  }
}
// pk弹框
.pop3 {
  z-index: 10;
  position: absolute;
  top: 110px;
  width: 80%;
  height: 200px;
  background: #ffffff;
  padding: 30px 0;
}
.close {
  cursor: pointer;
  font-size: 14px;
  color: #333333;
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.pop3_left {
  float: left;
  width: 80%;
  height: 140px;
}
.pop3_list_box {
  float: left;
  width: 33%;
  height: 100%;
  padding: 20px;
  border-right: 1px solid #eeeeee;
  position: relative;
  img {
    width: 100px;
    height: 100px;
  }
  div {
    margin-left: 15px;
    p {
      max-width: 230px;
      margin-top: 10px;
      font-size: 14px;
      color: #333333;
    }
    span {
      font-size: 12px;
      color: #cccccc;
      position: absolute;
      right: 20px;
      bottom: 20px;
      cursor: pointer;
    }
  }
}
.pop_right {
  text-align: center;
  width: 200px;
  height: 140px;
  div {
    width: 80px;
    height: 35px;
    background: #408df7;
    font-size: 16px;
    color: #ffffff;
    text-align: center;
    line-height: 35px;
    margin: 40px auto 20px;
    cursor: pointer;
  }
  p {
    font-size: 16px;
    color: #408df7;
    cursor: pointer;
  }
}
.blue:hover {
  background: #408df7;
  color: #ffffff !important;
  border: none !important;
}
.page_box {
  margin: 20px 40%;
}
//行业投标信息
.information_box {
  width: 100%;
}
.content_title {
  padding-top: 30px;
}
.title_left p {
  font-weight: normal;
  font-size: 38px;
  margin-bottom: 10px;
}
.title_right {
  padding-top: 20px;
}
.title_right span {
  display: inline-block;
  border: 1px solid #aaaaaa;
  border-radius: 5px;
  font-size: 20px;
  // padding: 12px 28px;
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  // margin-top: 23px;
  cursor: pointer;
}
.information_content {
  width: 100%;
  // height: 620px;
  padding: 30px 0;
  border-top: 1px solid #eeeeee;
}
.clear {
  clear: both;
}
.knowledge_item {
  width: 100%;
  height: 380px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  overflow: hidden;
  margin: 27px 0;
  cursor: pointer;
  p {
    margin-bottom: 24px;
  }
}
.knowledge_item img {
  width: 100%;
  height: 270px;
  object-fit: cover;
}
.knowledge_item p,
.knowledge_item span {
  padding-left: 20px;
}
.information_item_top {
  height: 70px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}
.information_item_top div {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
}
.information_item_top div img {
  width: 24px;
  height: 28px;
}
.information_item_top div p {
  // max-width: 550px;
  color: #333333;
  font-size: 20px;
  line-height: 26px;
  font-weight: normal;
  margin-left: 17px;
}
.information_item_top a {
  width: 98.88px;
  height: 35px;
  line-height: 30px;
  font-size: 16px;
  color: #408df7;
  border: 2px solid #408df7;
  background-color: #ebf3ff;
  text-align: center;
}
.information_item_bottom {
  height: 40px;
  display: table-cell;
  vertical-align: middle;
  padding-left: 41px;
}
.information_item_bottom span {
  color: #999999;
  font-size: 14px;
  line-height: 26px;
}

.information_item {
  width: 100%;
  height: 110px;
  border-bottom: 1px solid #eeeeee;
  float: left;
  margin-right: 100px;
  cursor: pointer;
}
.information_item:nth-of-type(2n) {
  margin: 0;
}
.pop {
  width: 1000px;
  height: 600px;
  background: #ffffff;
  border: 1px solid #707070;
  padding: 40px 30px 30px;
  text-align: inherit !important;
  overflow: hidden;
  img {
    cursor: pointer;
  }
}
.pop_top {
  height: 30px;
}
.pop_left {
  float: left;
  padding-left: 150px;
  width: 100%;
  p {
    font-size: 25px;
    color: #333333;
    font-weight: bold;
    margin-left: 5px;
    max-width: 700px;
  }
}
.img2 {
  margin-top: -30px;
}
.pop_content {
  margin: 20px 0;
  font-size: 16px;
  color: #333333;
  text-align: center;
}
.pop_bottom {
  width: 100%;
  height: 440px;
  overflow-y: auto;
  padding-bottom: 100px;
}
/deep/.van-popup--center {
  top: 50%;
  left: 50%;
}
.content-box {
  .content-item {
    // width: 32%;
    height: 380px;
    background-color: #ffffff;
    opacity: 1;
    border-radius: 5px;
    float: left;
    margin-bottom: 20px;
    img {
      width: 100%;
      height: 270px;
      object-fit: cover;
    }
    .item-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 26px;
      color: #333333;
      padding: 15px 20px;
    }
    .item-time-category {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      line-height: 24px;
      color: #999999;
      padding: 15px 20px;
    }
  }
  .active {
    border-bottom: 4px solid #408df7;
    .item-title {
      color: #408df7;
    }
  }
  .content-item:nth-child(3n + 1) {
    margin-left: 0;
  }
}
.minh {
  min-height: 550px;
  margin-top: 20px;
}
</style>