<template>
  <div class="content-right">
    <div class="my-customer">
      <p class="text-title">我的客户({{ list.length }})</p>
      <div v-if="list.length < 1" class="m1">暂无客户</div>
      <div class="customer-list">
        <div
          class="list-item"
          :class="[user_id == item.user_id ? 'active' : '']"
          v-for="(item, index) in list"
          :key="index"
          @click="
            chose(
              item.user_id,
              item.mobile,
              item.head_pic,
              item.user_name,
              item.company_name,
              item.user_instrument
            )
          "
        >
          <img :src="item.head_pic" alt="" />
          <p>{{ item.user_name }}</p>
        </div>
        <div class="clear"></div>
      </div>
    </div>
    <div class="customer-box">
      <p class="text-title">客户信息</p>
      <div v-if="list.length < 1" class="m1">暂无信息</div>
      <div class="customer-info" v-if="list.length > 1">
        <img class="info-pic fl" :src="head" alt="" />
        <div class="info-text fl">
          <p>{{ name }}</p>
          <p>
            <span>联系电话：{{ phone }} </span>
            <!-- <span>座机：0500-XXXXXXX</span> -->
          </p>
          <p>单位：{{ address }}</p>
        </div>
        <div class="clear"></div>
      </div>
      <el-divider></el-divider>
      <p class="text-title">仪器列表({{ qiList.length }})</p>
      <div v-if="qiList.length < 1" class="m1">暂无信息</div>
      <div class="instrument-box">
        <div
          class="instrument-item"
          v-for="(item, index) in qiList"
          :key="index"
        >
          <img :src="item.instrument_pic" alt="" />
          <p>{{ item.instrument_name }}</p>
        </div>

        <div class="clear"></div>
      </div>
      <el-divider></el-divider>
      <p class="text-title">服务历史({{ history.count }})</p>
      <div class="service-history">
        <div class="histtory-header">
          <p @click="mess(1)" :class="type == 1 ? 'active' : ''">仪器维修</p>
          <p @click="mess(2)" :class="type == 2 ? 'active' : ''">仪器保养</p>
          <p @click="mess(3)" :class="type == 3 ? 'active' : ''">安装调试</p>
          <p @click="mess(4)" :class="type == 4 ? 'active' : ''">我的实验室</p>
          <p @click="mess(5)" :class="type == 5 ? 'active' : ''">
            我的实验室方案
          </p>
          <div class="clear"></div>
        </div>
        <div class="m1" v-if="history.list.length < 1">暂无信息</div>
        <div
          class="history-item"
          v-for="(item, index) in history.list"
          :key="index"
        >
          <div class="item-top">
            <div class="top-left fl">
              <p class="text-title">客户信息</p>
            </div>
            <div class="top-center fl">
              <p class="text-title">服务信息</p>
            </div>
            <div class="top-right fl" v-if="item.order_status == 5">
              <p class="text-title fl">客户评价</p>

              <div class="star-box fl">
                <van-rate
                  v-model="item.major_level"
                  :icon="imgurl2"
                  :void-icon="imgurl1"
                  disabled
                />
              </div>
              <p class="fl">{{ item.evaluate }}</p>
            </div>
            <div class="clear"></div>
          </div>
          <div class="item-bottom">
            <div class="bottom-left fl">
              <p class="text-key">
                姓名：<span class="text-value">{{ item.nickname }}</span>
              </p>
              <p class="text-key">
                手机号：<span class="text-value">{{ item.mobile }}</span>
              </p>
              <p class="text-key">
                单位：<span class="text-value">{{ item.company_name }}</span>
              </p>
            </div>
            <div class="bottom-center fl" v-if="item.instrument_dispose == 1">
              <p class="text-key">
                仪器维修：<span class="text-value">{{
                  item.instrument_name
                }}</span>
              </p>
              <p class="text-key">
                仪器型号：<span class="text-value">{{
                  item.instrument_size
                }}</span>
              </p>
              <p class="text-key">
                故障说明：<span class="text-value two-letter">{{
                  item.instrument_malfunction
                }}</span>
              </p>
            </div>
            <div class="bottom-center fl" v-if="item.instrument_dispose == 2">
              <p class="text-key">
                保养仪器：<span class="text-value">{{
                  item.instrument_name
                }}</span>
              </p>
              <p class="text-key">
                保养方案：<span class="text-value">{{
                  item.maintain_plan
                }}</span>
              </p>
              <p class="text-key">
                保养说明：<span class="text-value two-letter">{{
                  item.maintain_desc
                }}</span>
              </p>
            </div>
            <div class="bottom-center fl" v-if="item.instrument_dispose == 3">
              <p class="text-key">
                仪器名称：<span class="text-value">{{
                  item.instrument_name
                }}</span>
              </p>
              <p class="text-key">
                仪器型号：<span class="text-value">{{
                  item.instrument_size
                }}</span>
              </p>
              <p class="text-key">
                安装说明：<span class="text-value two-letter">{{
                  item.install_desc
                }}</span>
              </p>
            </div>
            <div class="bottom-center fl" v-if="item.instrument_dispose == 4">
              <p class="text-key">
                需求类别：<span class="text-value">我的实验室</span>
              </p>
              <p class="text-key">
                管理需求：<span class="text-value">{{
                  item.sub_category
                }}</span>
              </p>
            </div>
            <div class="bottom-center fl" v-if="item.instrument_dispose == 5">
              <p class="text-key">
                需求类别：<span class="text-value">我的实验室方案</span>
              </p>
              <p class="text-key">
                整体布局规划设计：<span class="text-value">{{
                  item.lab_project
                }}</span>
              </p>
              <p class="text-key">
                家具设计：<span class="text-value">{{
                  item.lab_furniture
                }}</span>
              </p>
            </div>
            <div class="bottom-right fl" v-if="item.order_status == 5">
              <p class="text-value two-letter">
                {{ item.content }}
              </p>
            </div>
            <div class="clear"></div>
          </div>
        </div>

        <div class="history-page">
          <van-pagination
            v-model="p"
            :total-items="history.count"
            class="block fl"
            @change="getHistory()"
          >
            <template #prev-text>
              <van-icon name="arrow-left" />
            </template>
            <template #next-text>
              <van-icon name="arrow" />
            </template>
            <template #page="{ text }">{{ text }}</template>
          </van-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { kh_list, list } from "../../api/User";
export default {
  data() {
    return {
      imgurl2: require("../../assets/img/icon_pj_s.png"),
      imgurl1: require("../../assets/img/icon_pj_n.png"),
      token: "",
      list: [],
      user_id: "",
      phone: "",
      head: "",
      name: "",
      address: "",
      qiList: [],
      history: [],
      type: 1,
      p: 1,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getList();
    this.getHistory();
  },
  methods: {
    mess(i) {
      this.type = i;
      this.p = 1;
      this.getHistory();
    },
    getHistory() {
      list({
        token: this.token,
        uid: this.user_id,
        type: this.type,
        p: this.p,
      }).then((res) => {
        if (res.status == 1) {
          this.history = res.data;
        } else if (res.status == 0) {
          this.history = [];
        }
      });
    },
    //选择用户
    chose(id, phone, head, name, address, qiList) {
      this.user_id = id;
      (this.phone = phone),
        (this.head = head),
        (this.name = name),
        (this.address = address);
      this.qiList = qiList;
      this.getHistory();
      this.p = 1;
      this.type = 1;
    },
    //w客户列表
    getList() {
      kh_list({ token: this.token }).then((res) => {
        if (res.status == 1) {
          this.list = res.result;
          this.user_id = res.result[0].user_id;
          (this.phone = res.result[0].mobile),
            (this.head = res.result[0].head_pic),
            (this.name = res.result[0].user_name);
          this.address = res.result[0].company_name;
          this.qiList = res.result[0].user_instrument;
          this.getHistory();
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 270px;
  min-width: 75%;
  max-width: 80%;
  margin-bottom: 20px;
  .el-divider--horizontal {
    margin: 25px 0;
    background: 0 0;
    border-top: 1px dashed #e8eaec;
  }
  .text-title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    line-height: 50px;
    color: #333333;
  }
  .my-customer {
    padding: 25px;
    background-color: #ffffff;
    margin-bottom: 10px;
    .customer-list {
      overflow: hidden;
      padding: 10px 0;
      .list-item {
        width: 100px;
        padding: 10px;
        float: left;
        margin: 0 10px;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          object-fit: cover;
        }
        p {
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          text-align: center;
          color: #333333;
        }
      }
      .list-item.active {
        background: #ffffff;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        opacity: 1;
        border-radius: 5px;
        p {
          color: #408df7;
        }
      }
    }
  }
  .customer-box {
    padding: 25px;
    background-color: #ffffff;
    .customer-info {
      .info-pic {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        margin-right: 60px;
      }
      .info-text {
        p:nth-child(1) {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 54px;
          color: #333333;
        }
        p:nth-child(2) {
          span {
            margin-right: 60px;
          }
        }
        p:nth-child(2),
        p:nth-child(3) {
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 48px;
          color: #333333;
        }
      }
    }
    .instrument-box {
      .instrument-item {
        float: left;
        padding: 10px;
        width: 200px;
        background: #ffffff;
        border: 1px solid #eeeeee;
        margin-right: 10px;
        img {
          width: 160px;
          height: 140px;
          // margin: 10px;
        }
        p {
          width: 100%;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 22px;
          color: #333333;
        }
      }
    }
    .service-history {
      .histtory-header {
        padding: 10px 0;
        p {
          float: left;
          cursor: pointer;
          background: #ffffff;
          border: 1px solid #eeeeee;
          font-size: 16px;
          font-weight: 400;
          color: #333333;
          padding: 5px 18px;
          margin-right: 18px;
        }
        p.active {
          border: 1px solid #408df7;
          background: #408df7;
          color: #ffffff;
        }
      }
      .history-item {
        margin: 10px 0;
        background: #fcfcfc;
        border: 1px solid #eeeeee;
        padding: 10px 35px 20px;
        .item-top {
          .top-left {
            width: 30%;
          }
          .top-center {
            width: 30%;
          }
          .top-right {
            width: 40%;
            border-bottom: 1px solid #eeeeee;
            p:nth-child(1) {
              width: 30%;
            }
            .star-box {
              line-height: 60px;
              width: 50%;
              height: 50px;
            }
            p:nth-child(3) {
              width: 20%;
              font-size: 16px;
              font-weight: 400;
              line-height: 50px;
              color: #333333;
            }
          }
        }
        .item-bottom {
          .bottom-left {
            width: 30%;
          }
          .bottom-center {
            width: 30%;
          }
          .bottom-right {
            width: 40%;
          }
        }
        .text-key {
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          color: #999999;
        }
        .text-value {
          font-size: 14px;
          font-weight: 400;
          line-height: 28px;
          color: #333333;
        }
      }
      .history-page {
        margin: 40px 0;
        display: flex;
        justify-content: center;
      }
    }
  }
}
.van-rate /deep/ .van-icon__image {
  width: 26px;
  height: 25px;
}
.van-rate /deep/ .van-rate__item:not(:last-child) {
  padding-right: 10px;
}
</style>