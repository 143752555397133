<template>
  <div class="content-right fl">
    <div class="content-title">
      <p @click="goBack()">商品订单>订单详情>工程师评价</p>
    </div>
    <div class="content-main">
      <div class="enginner-box">
        <div class="info fl">
          <img class="fl" :src="item.head_pic" alt="" />
          <div class="enginner-info fl">
            <p>
              {{ item.engineer_name ? item.engineer_name : item.user_name }}
            </p>
            <p>{{ item.mobile }}</p>
            <p>成功服务：{{ item.ordercount?item.ordercount:item.serve_num}}次</p>
          </div>
          <div class="clear"></div>
        </div>
        <div class="specialty fl">
          <p>专业服务项目</p>
          <p>{{ item.serve_item }}</p>
        </div>
        <div class="comment-history fl">
          <p>历史评价</p>
          <p>
            好评{{ item.good_ping }}次，一般{{ item.ordinary_ping }}次，差评{{
              item.bad_ping
            }}次
          </p>
        </div>
        <div class="progress fl">
          <p>进度</p>
          <p v-if="item.order_status == 4">已完成</p>
        </div>
        <div class="clear"></div>
      </div>
      <div class="comment-content">
        <div class="content-left fl">
          <div class="comment-item">
            <p class="fl">专业程度</p>
            <div class="star-box fl">
              <van-rate
                v-model="score"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change1()"
              />
            </div>
            <p class="fl">{{ score }}星</p>
            <div class="clear"></div>
          </div>
          <div class="comment-item">
            <p class="fl">服务程度</p>
            <div class="star-box fl">
              <van-rate
                v-model="score1"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change2()"
              />
            </div>
            <p class="fl">{{ score1 }}星</p>
            <div class="clear"></div>
          </div>
          <div class="comment-item">
            <p class="fl">响应速度</p>
            <div class="star-box fl">
              <van-rate
                v-model="score2"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change3()"
              />
            </div>
            <p class="fl">{{ score2 }}星</p>
            <div class="clear"></div>
          </div>
          <div class="comment-item">
            <p class="fl">环境卫生</p>
            <div class="star-box fl">
              <van-rate
                v-model="score3"
                :icon="imgurl2"
                :void-icon="imgurl1"
                @change="change4()"
              />
            </div>
            <p class="fl">{{ score3 }}星</p>
            <div class="clear"></div>
          </div>
          <p>评价说明：4-5星为好评，3星为一般，1-2星为差评</p>
        </div>
        <div class="content-right fl">
          <p>评价内容</p>
          <textarea
            name=""
            id=""
            placeholder="请输入评价内容"
            v-model="content"
          ></textarea>
        </div>
        <div class="clear"></div>
      </div>
      <div class="upload-header">
        <p class="fl">晒图片</p>
        <p class="">最多上传9张图片</p>
        <div class="clear"></div>
      </div>
      <div class="upload-box">
        <el-upload
          action="http://yiqiguanjia.pro6.liuniukeji.net/index.php/Api/InstrumentData/instrument_pic"
          :on-success="qiandiao"
          name="img_file"
          accept="image/*"
          :multiple="true"
          class="e5 fl"
        >
          <img class="upload-pic" src="../../assets/img/pj_but_tjts.png" />
        </el-upload>
        <div class="upload-item1 fl" v-for="(item, index) in Img" :key="index">
          <img class="upload-pic fl" :src="item" />
          <img
            class="upload-del"
            src="../../assets/img/sc_img_but_sc.png"
            @click="del(index)"
          />
        </div>
        <div class="clear"></div>
      </div>
      <p class="upload-text">晒出您的照片，让更多用户看到吧~</p>
      <el-divider></el-divider>
      <p class="comment-btn" @click="sub()">提交评价</p>
    </div>
  </div>
</template>
<script>
import { pj } from "../../api/User";
export default {
  data() {
    return {
      score: 5,
      score1: 5,
      score2: 5,
      score3: 5,
      item: "",
      imgurl2: require("../../assets/img/icon_pj_s.png"),
      imgurl1: require("../../assets/img/icon_pj_n.png"),
      Img: [],
      content: "",
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.item = JSON.parse(localStorage.getItem("data"));
    // console.log(this.item);
  },
  methods: {
    //提交评价
    sub() {
      let data = {
        token: this.token,
        id: this.item.id,
        major_level: this.score,
        serve_level: this.score1,
        answer_level: this.score2,
        clear_level: this.content3,
        imgs: this.Img,
        content: this.content,
      };
      pj(data).then((res) => {
        if (res.status == 1) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          setTimeout(() => {
            this.goBack();
          }, 1000);
        } else if (res.status == 0) {
          this.$message.error(res.msg);
        } else if (res.status == -4) {
          this.$message.error(res.msg);
        }
      });
    },
    del(index) {
      // console.log(this.Img);
      this.Img.splice(index, 1);
    },
    //上传图片
    qiandiao(response, file, fileList) {
      if (fileList.length > 9) {
        this.$message("最多可上传9张");
        this.Img.push(response.result.url);
        this.Img.splice(9, this.Img.length - 9);
      } else {
        this.Img.push(response.result.url);
      }
    },
    //跳转商品订单
    goBack() {
      this.$emit('change1');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  background-color: #ffffff;
  width: 100%;
  .content-title {
    padding: 0 115px;
    border-bottom: 1px solid #eeeeee;
    p {
      margin: 25px 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      color: #333333;
    }
  }
  .content-main {
    padding: 30px 115px;
    .enginner-box {
      padding: 30px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
      }
      .info {
        width: 30%;
        img {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-right: 25px;
        }
      }
      .specialty {
        width: 30%;
        padding: 0 25px;
      }
      .comment-history {
        width: 30%;
        padding: 0 25px;
      }
      .progress {
        width: 10%;
        p:nth-child(2) {
          font-weight: bold;
          color: #408df7;
        }
        p {
          text-align: center;
        }
      }
    }
    .comment-content {
      padding: 20px 0;
      .content-left {
        width: 40%;
        padding-top: 50px;
        .comment-item {
          border-bottom: 1px solid #eeeeee;
          p:nth-child(1) {
            font-size: 16px;
            font-weight: bold;
            line-height: 70px;
            color: #333333;
            margin-right: 30px;
          }
          .star-box {
            height: 70px;
            margin-right: 20px;
            img {
              width: 26px;
              height: 25px;
              position: relative;
              top: 30%;
              margin: 0 5px;
            }
          }
          p:nth-child(3) {
            font-size: 16px;
            font-weight: bold;
            line-height: 70px;
            color: #408df7;
          }
        }
        p {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 70px;
          color: #999999;
        }
      }
      .content-right {
        width: 58%;
        padding-left: 80px;
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          line-height: 60px;
          color: #333333;
        }
        textarea {
          width: 100%;
          height: 160px;
          background: #ffffff;
          border: 1px solid #eeeeee;
          border-radius: 5px;
          padding: 15px;
        }
      }
    }
    .upload-header {
      p:nth-child(1) {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #333333;
        margin-right: 40px;
      }
      p:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #ff0000;
      }
    }
    .upload-box {
      .upload-item {
        position: relative;
        margin-right: 10px;
        .upload-pic {
          width: 76px;
          height: 76px;
        }
        .upload-del {
          width: 30px;
          height: 20px;
          position: absolute;
          left: 46px;
        }
      }
    }
    .upload-text {
      font-size: 12px;
      font-weight: 400;
      line-height: 50px;
      color: #999999;
    }
    .comment-btn {
      width: 210px;
      height: 50px;
      background: #408df7;
      font-size: 16px;
      font-weight: 400;
      line-height: 50px;
      text-align: center;
      color: #ffffff;
    }
  }
}
.van-rate /deep/ .van-icon__image {
  width: 26px;
  height: 25px;
  margin: 20px 5px;
}
.van-rate /deep/ .van-rate__item:not(:last-child) {
  padding-right: 17px;
}
.upload-item1 {
  position: relative;
  margin: 0 5px;
  .upload-pic {
    width: 76px;
    height: 76px;
    object-fit: cover;
  }
  .upload-del {
    width: 30px;
    height: 20px;
    position: absolute;
    left: 46px;
  }
}
.e5 /deep/ .el-upload--picture-card {
  float: left;
  border: none !important;
  width: 0 !important;
  height: 0 !important;
  margin-right: 10px !important;
}
.e5 /deep/ .el-upload-list--picture-card {
  margin-left: 80px !important;
}
.e5 /deep/ .el-upload-list {
  display: none !important;
}
.e5 /deep/ .el-upload-list__item-status-label {
  display: none !important;
}
.e5 /deep/.el-upload-list__item-actions {
  margin-left: 100px !important;
}
.e5 /deep/.el-upload-list__item {
  width: 76px !important;
  height: 76px !important;
  img {
    object-fit: cover;
  }
}
</style>