<template>
  <div class="content-right fl">
    <div class="right-top">
      <div class="top-text">
        <p class="text-b">{{File}}</p>
        <div class="text-l">
          <p>档案专项管理</p>
          <p>一年免费保养三次</p>
          <div class="clear"></div>
        </div>
        <div class="text-l">
          <p>免费安装</p>
          <p>贵宾专线</p>
          <div class="clear"></div>
        </div>
      </div>
      <div class="equity-box" @click="showPop()">
        <img class="fl" src="../../assets/img/gz_xq.png" alt="" />
        <p class="fl">会员权益</p>
      </div>
    </div>
    <p class="coupon-title">优惠券</p>
    <div class="coupon-box">
      <div class="coupon-item" v-for="(item, index) in coupon" :key="index">
        <div class="item-left fl">
          <p>满减券</p>
          <p>
            <span>￥</span><span>{{ item.money }}</span>
          </p>
          <p>满{{ item.condition }}元可用</p>
        </div>
        <div class="item-right fl">
          <p>{{ item.goods_cat_name }}</p>
          <p>{{ item.send_time }}-{{ item.use_end_time }}</p>
        </div>
        <div class="clear"></div>
      </div>

      <div class="clear"></div>
    </div>
    <el-divider></el-divider>
    <div class="vip-goods-title">
      <p class="fl">会员专属商品</p>
      <p class="fr" @click="chose()">查看更多></p>
      <div class="clear"></div>
    </div>
    <div class="vip-goods-box">
      <div
        class="goods-item"
        v-for="(item, index) in list.list"
        :key="index"
        @click="goHref('/CommodityDetails?id=' + item.goods_id)"
      >
        <img :src="item.original_img" alt="" />
        <p class="goods-name one-letter">{{ item.goods_name }}</p>
        <p class="vip-price">
          会员价：<span class="price">￥{{ item.shop_price }}</span>
        </p>
      </div>
    </div>
    <div class="page_box">
      <van-pagination
        v-model="p"
        :total-items="list.count"
        class="block fl"
        @change="getList()"
      >
        <template #prev-text>
          <van-icon name="arrow-left" />
        </template>
        <template #next-text>
          <van-icon name="arrow" />
        </template>
        <template #page="{ text }">{{ text }}</template>
      </van-pagination>
    </div>
    <van-popup v-model="show" class="pop" position:center>
      <img
        class="pop_img1 fr"
        src="../../assets/img/pop_but_gb.png"
        @click="show = false"
      />
      <div class="pop_top">会员权益</div>
      <div class="pop_list">
        <div class="fl clear">
          <img class="fl" src="../../assets/img/icon_jd_d.png" />
          <p class="fl">普通会员</p>
        </div>
        <span class="fl"
          >完善个人信息及仪器登记，即成为本产品会员，并享受会员服务</span
        >
      </div>
      <div class="pop_list1">
        <div class="fl">
          <img class="fl" src="../../assets/img/icon_jd_d.png" />
          <p class="fl">银牌会员</p>
        </div>
        <span class="fl">使用本产品相关服务{{ level1 }}次以上</span>
      </div>
      <div class="pop_list1">
        <div class="clear">
          <img class="fl" src="../../assets/img/icon_jd_d.png" />
          <p class="fl">金牌会员</p>
        </div>
        <span class="fl">使用本产品相关服务{{ level2 }}次以上</span>
      </div>
      <div class="pop_list1">
        <div class="clear">
          <img class="fl" src="../../assets/img/icon_jd_d.png" />
          <p class="fl">钻石会员</p>
        </div>
        <span class="fl">使用本产品相关服务{{ level3 }}次以上</span>
      </div>
      <div class="pop_list1">
        <div class="clear">
          <img class="fl" src="../../assets/img/icon_jd_d.png" />
          <p class="fl">终身会员</p>
        </div>
        <span class="fl">使用本产品相关服务{{ level4 }}次以上</span>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { vipGoods, getCouponList } from "../../api/User";
import { level_info} from "../../api/index";
export default {
  data() {
    return {
      tiken: "",
      show: false,
      list: [],
      level: "",
      coupon: [],
      level1: "",
      level2: "",
      level3: "",
      level4: "",
      p:1,
      File:''
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.level = localStorage.getItem("level");
    this.File = localStorage.getItem('File')
    this.getList();
    this.getCoupon();
    this.getInfo();
    this.getUser_file();
  },
  methods: {
    getInfo() {
      level_info({}).then((res) => {
        this.level1 = res.result[1].servecount;
        this.level2 = res.result[2].servecount;
        this.level3 = res.result[3].servecount;
        this.level4 = res.result[5].servecount;
      });
    },
    getCoupon() {
      getCouponList({ token: this.token, type: this.level }).then((res) => {
        this.coupon = res.result;
      });
    },
    chose() {
      this.$emit('change3');
    },
    getList() {
      vipGoods({ p:this.p }).then((res) => {
        this.list = res.data;
      });
    },
    showPop() {
      this.show = true;
    },
    goHref(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-right {
  margin-left: 10px;
  padding: 25px;
  background-color: #ffffff;
  width: 100%;
  margin-bottom: 20px;
  .right-top {
    height: 210px;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    background: url("../../assets/img/pthy_bg.png");
    background-size: cover;
    position: relative;
    margin-bottom: 20px;
    .top-text {
      position: absolute;
      width: 50%;
      left: 25%;
      top: 15%;
      .text-b {
        text-align: center;
        font-weight: bold;
        line-height: 26px;
        color: #ffffff;
        padding: 20px 0;
      }
      .text-l {
        p {
          width: 50%;
          float: left;
          font-weight: bold;
          line-height: 16px;
          color: #ffffff;
          padding: 0 10px;
          margin: 10px 0;
        }
        p:nth-child(1) {
          text-align: right;
          border-right: 1px solid #ffffff;
        }
        p:nth-child(2) {
          text-align: left;
          border-left: 1px solid #ffffff;
        }
      }
    }
    .equity-box {
      cursor: pointer;
      width: 102px;
      height: 32px;
      border: 1px solid #ffffff;
      opacity: 1;
      border-radius: 3px;
      position: absolute;
      top: 10%;
      left: 85%;
      img {
        width: 16px;
        height: 16px;
        float: left;
        margin: 5px 5px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        color: #ffffff;
      }
    }
  }
  .coupon-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 50px;
    color: #333333;
  }
  .coupon-box {
    .coupon-item {
      width: 32%;
      height: 104px;
      background: url("../../assets/img/ptyh_yhq.png");
      background-size: cover;
      margin: 1% 2% 1% 0;
      border-radius: 5px;
      float: left;
      .item-left {
        width: 41%;
        padding: 10px 10px 10px 33px;
        p:nth-child(1),
        p:nth-child(3) {
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          color: #ffffff;
        }
        p:nth-child(2) {
          span:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            line-height: 28px;
            color: #ffffff;
          }
          span:nth-child(2) {
            font-size: 20px;
            font-weight: bold;
            line-height: 28px;
            color: #ffffff;
          }
        }
      }
      .item-right {
        width: 59%;
        padding: 15px;
        p {
          width: 100%;
        }
        p:nth-child(1) {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #666666;
          padding-bottom: 10px;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          color: #999999;
        }
      }
    }
    .coupon-item:nth-child(3n) {
      margin-right: 0;
    }
  }
  .vip-goods-title {
    padding: 20px 0;
    p:nth-child(1) {
      font-size: 16px;
      font-weight: bold;
      line-height: 28px;
      color: #333333;
    }
    p:nth-child(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #999999;
      cursor: pointer;
    }
  }
  .vip-goods-box {
    .goods-item {
      background: #ffffff;
      border: 1px solid #eeeeee;
      float: left;
      width: 18%;
      padding: 10px;
      margin: 1%;
      img {
        width: 100%;
        height: 160px;
        object-fit: cover;
      }
      .goods-name {
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        color: #333333;
      }
      .vip-price {
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        color: #999999;
        .price {
          font-weight: bold;
          color: #ff0000;
        }
      }
    }
  }
}
.pop {
  width: 380px;
  height: 650px;
  background: #ffffff;
  border-radius: 5px;
  padding: 30px;
}
.pop_img1 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.pop_top {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
}
.pop_list {
  padding: 20px 0 25px;
  border-bottom: 1px solid #eeeeee;
  height: 130px;
  div {
    img {
      margin: 5px 5px 0 0;
    }
    p {
      font-size: 16px;
      color: #408df7;
      font-weight: bold;
    }
  }
  span {
    width: 100%;
    font-size: 14px;
    color: #333333;
    margin-top: 14px;
  }
}
.pop_list1 {
  height: 100px;
  border-bottom: 1px solid #eeeeee;
  margin-bottom: 10px;
  div {
    img {
      margin: 5px 5px 0 0;
    }
    p {
      font-size: 16px;
      color: #408df7;
      font-weight: bold;
    }
  }
  span {
    width: 100%;
    font-size: 14px;
    color: #333333;
    margin-top: 14px;
  }
}
.pop_list1:last-of-type {
  border: none;
  margin: 0;
}
.page_box{
  float: left;
  margin: 20px   40%;
}
</style>