<template>
    <div>
        <!-- 此页面不要删除，作为重置路由刷新页面使用 -->
    </div>
</template>
<script>
export default {
    data() {
        this.$router.replace({
            path:"./laboratory",
            component: "laboratory"
        })
        return{

        }
    },
}
</script>